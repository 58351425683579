<template>
  <v-container name="vehicleFuelPaymentList" fluid>
    <div v-if="isPermissionCanView">
      <v-row>
        <v-col cols="auto">
          <h1 class="text-h5 mt-1">รายการเติมน้ำมัน</h1>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AppAutoCompleteVehicleFuelSearching
            :key="componentRefreshCounter"
            :tableLoading="isLoading"
            ref="AppAutoCompleteVehicleFuelSearching"
            @searchItemChange="getSearchItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>
            <VehicleFuelPaymentGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
              @resetFilterGroup="getResetFilterGroup"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-card outline elevation="0" class="py-2 px-4 rounded-lg d-flex">
            <VehicleFuelFilterStatus
              :key="componentRefreshCounter"
              v-model="statusFilterValue"
            />
            <v-spacer></v-spacer>

            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense v-if="!isLoading && items.length !== 0">
        <v-col cols="12" v-for="(item, itemIdx) in items" :key="item.code">
          <v-card outlined class="mx-auto rounded-lg">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="3">
                  <v-list dense class="pa-0">
                    <v-list-item class="pl-1">
                      <v-list-item-content>
                        <v-list-item-title class="text-h5 ma-0">
                          <a @click="openDialog(item, itemIdx)"
                            >{{ item.code }}
                          </a>
                          <v-btn small text target="_blank" :href="item.mapUrl">
                            <v-icon>mdi-map-marker</v-icon>
                            จุดเติม
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-avatar>
                        <v-icon> mdi-car-outline </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-app-detail-normal font-weight-bold"
                        >
                          <p class="text-app-title mb-0 font-weight-bold">
                            {{ item.veData.licenseNumber }}
                            {{ item.veData.type ? item.veData.type.value : "" }}
                            {{
                              item.veData.color
                                ? `สี${item.veData.color.value}`
                                : ""
                            }}
                          </p>
                          <span
                            v-if="
                              (item.veData.licenseType !== null) &
                              (item.veData.licenseType !== undefined)
                            "
                            class="text-app-normal"
                          >
                            <span class="mx-0 px-0">
                              <v-icon
                                v-if="
                                  vehicleLicenseTypeColor(
                                    item.veData.licenseType.key
                                  ) === 'white'
                                "
                                >mdi-card-text-outline</v-icon
                              >
                              <v-icon
                                v-else
                                :color="
                                  vehicleLicenseTypeColor(
                                    item.veData.licenseType.key
                                  )
                                "
                                >mdi-card-text</v-icon
                              >
                            </span>
                          </span>

                          {{
                            item.veData.licenseType
                              ? item.veData.licenseType.value
                              : ""
                          }}
                          {{
                            item.veData.engine.value
                              ? item.veData.engine.value
                              : ""
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0">
                      <v-list-item-avatar>
                        <v-icon> mdi-calendar </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-app-title font-weight-bold"
                        >
                          {{ item.createdAtStr }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pl-2">
                      <v-list-item-avatar>
                        <v-img
                          :src="
                            item.userId.profileImage === null
                              ? defaultPFImage
                              : item.userId.profileImage
                          "
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-app-title font-weight-bold"
                        >
                          {{ item.userId.dpNameTh }} ({{ item.userId.empId }})
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.userId.dpPhoneNumeber }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card outlined class="fill-height mx-3" color="#f2f5f9">
                    <v-slide-group class="fill-height" show-arrows>
                      <v-slide-item
                        v-for="(step, indexStep) in item.step"
                        :key="`${item._id}_` + indexStep"
                      >
                        <v-card
                          v-if="step === null"
                          :width="cardWidth"
                          color=""
                          class="ma-1 d-flex flex-column justify-center"
                          outlined
                          title
                        >
                          <v-card-text class="">
                            <p
                              class="ma-0 text-center"
                              v-if="indexStep === 's1'"
                            >
                              ภาพหน้าปัดรถก่อนเติม <br />
                              ไม่มีข้อมูล
                            </p>
                            <p
                              class="ma-0 text-center"
                              v-if="indexStep === 's2'"
                            >
                              ภาพหน้าตู้จ่ายน้ำมัน <br />
                              ไม่มีข้อมูล
                            </p>
                            <p
                              class="ma-0 text-center"
                              v-if="indexStep === 's3'"
                            >
                              ภาพหน้าปัดรถหลังเติม <br />
                              ไม่มีข้อมูล
                            </p>
                          </v-card-text>
                        </v-card>
                        <v-card
                          v-else
                          :width="cardWidth"
                          class="ma-1"
                          outlined
                          title
                          elevation="3"
                          @click="openDialog(item, itemIdx)"
                        >
                          <v-card-text>
                            <p
                              class="ma-0 text-center"
                              v-if="indexStep === 's1'"
                            >
                              ภาพหน้าปัดรถก่อนเติม <br />
                              {{
                                dateStringToDateFormat(step.createdAt, {
                                  returnThai: false,
                                  type: "datetime",
                                })
                              }}
                            </p>
                            <p
                              class="ma-0 text-center"
                              v-if="indexStep === 's2'"
                            >
                              ภาพหน้าตู้จ่ายน้ำมัน <br />
                              +{{ step.intervalTime }}
                            </p>
                            <p
                              class="ma-0 text-center"
                              v-if="indexStep === 's3'"
                            >
                              ภาพหน้าปัดรถหลังเติม <br />
                              +{{ step.intervalTime }}
                            </p>
                          </v-card-text>
                          <v-img
                            contain
                            max-height="150"
                            class="mb-2"
                            :src="step.src"
                          ></v-img>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card
                    class="rounded-lg d-flex flex-column fill-height"
                    outlined
                    tile
                  >
                    <v-card-text>
                      <p class="text-body-1 font-weight-bold ma-0">
                        ค่าใช้จ่าย: {{ item.amountStr }}
                      </p>
                      <p class="text-app-detail-normal font-weight-bold ma-0">
                        ชนิดน้ำมัน: {{ item.fuelTypeStr }}
                      </p>
                      <p class="text-app-detail-normal font-weight-bold ma-0">
                        ราคาต่อหนึ่งลิตร: {{ item.pricePerUnitStr }}
                      </p>
                      <p class="text-app-detail-normal font-weight-bold ma-0">
                        จำนวนลิตรโดยประมาณ: {{ item.amountUnitStr || "-" }}
                      </p>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-text class="py-2">
                      <p
                        class="text-app-detail-normal font-weight-bold ma-0"
                        v-if="item.checklisttorId"
                      >
                        โดย: {{ item.checklisttorId.dpNameTh }}
                      </p>
                    </v-card-text>
                    <v-card-actions class="pa-0">
                      <VehicleFuelStatusSelectionBtn
                        :value="item"
                        :fuelPaymentId="item._id"
                        :confirmDialog="false"
                        @status-onchange="statustChangeHandler"
                        :show-amount-input="false"
                        :key="componentRefreshCounter"
                      />
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!isLoading && items.length === 0">
        <v-col cols="12">
          <div class="text-center">
            <p>ไม่มีข้อมูล</p>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="isLoading">
        <v-col cols="12">
          <div class="text-center">
            <p>กำลังดึงข้อมูล</p>
          </div>
        </v-col>
      </v-row>

      <!--  -->
      <v-row v-if="!isLoading">
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              v-model="queryOptionsTable.page"
              :length="pageDataLength"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </div>

    <VehicleFuelDetailDialog ref="vehicleFuelDetailComp" />
    <AppOverlay :isLoading="isLoading" />
    <AppPageNotFound :show="isPermissionCanView" />
  </v-container>

  <!--  -->
</template>

<script>
import AppAutoCompleteVehicleSearching from "@/components/AppAutoCompleteVehicleSearching.vue";
import { getVehicleFuelPaymentService } from "@/services/api/vehicle_fuel_payment";
import AppOverlay from "@/components/AppOverlay.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import VehicleFuelDetailDialog from "./VehicleFuelDetailDialog.vue";
import VehicleFuelStatusSelectionBtn from "./components/VehicleFuelStatusSelectionBtn.vue";
import { mapGetters } from "vuex";
import NormalButton from "@/components/NormalButton.vue";
import VehicleFuelFilterStatus from "./components/VehicleFuelFilterStatus.vue";
import VehicleFuelPaymentGroup from "./components/vehicleFuelPaymentGroup.vue";
import { checkEmptyValueHelpper } from "@/services/appFuncHelpper";
import AppAutoCompleteVehicleFuelSearching from "./components/AppAutoCompleteVehicleFuelSearching.vue";
import defaultImage from "@/assets/user.png";
import _ from "lodash";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import {
  createAmountStr,
  createAmountUnitStr,
  createCreatedAtStr,
  createfuelTypeStr,
  createPriceUnitStr,
} from "./vehicleFuelPaymentHelper";

import {
  appDecodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";

export default {
  components: {
    AppAutoCompleteVehicleSearching,
    AppOverlay,
    VehicleFuelDetailDialog,
    VehicleFuelStatusSelectionBtn,
    NormalButton,
    VehicleFuelFilterStatus,
    VehicleFuelPaymentGroup,
    AppAutoCompleteVehicleFuelSearching,
    AppPageNotFound,
  },
  data: () => ({
    componentRefreshCounter: 0,
    items: [],
    defaultPFImage: defaultImage,
    //
    // _licenseNumber: null,
    queryStringSearch: "",
    queryStringSearchList: {},
    querySearchAndFilterList: {},
    field_sort_by: null,
    // sortVal: null,
    sortList: [],
    pageDataLength: 0,

    queryOptionsTable: {
      limit: 10, // itemsPerPage = limit
      order: "desc", // asc or desc
      page: 1, // current page
      status: "P",
    },

    isShowMenu: false,
    isLoading: false,
    statusFilterValue: "P",
    permList: {},
    isPermissionCanView: false,
    isPermissionCanEdit: false,
  }),
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleStatusText: "vehicle/vehicleStatusText",
      vehicleStatusTextInvert: "vehicle/vehicleStatusTextInvert",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",

      vehicleFuelStatusColorObj: "vehicle/vehicleFuelStatusColorObj",
      vehicleFuelStatusObj: "vehicle/vehicleFuelStatusObj",
      vehicleFuelStatusValue: "vehicle/vehicleFuelStatusValue",
      vehicleFuelStatusColorValue: "vehicle/vehicleFuelStatusColorValue",
    }),
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          // console.log("xs");
          return "150px";
        case "sm":
          // console.log("sm");
          return "250px";
        case "md":
          // console.log("md");
          return "180px";
        case "lg":
          // console.log("lg");
          return "210px";
        case "xl":
          // console.log("xl");
          return "300px";
      }
    },
  },

  mounted() {
    this.checkUserPermissions();
    this.processRouterQuery(this.$route.query);
  },
  watch: {
    "queryOptionsTable.page": function (newVal) {
      this.tablePageHandler(newVal);
    },

    statusFilterValue: function (newValue) {
      this.getData();
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      const _queryObj = appEncodeURIComponentHelper(queryObj);
      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },

    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              case "status":
                this.statusFilterValue = _queryRoute[key];
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.setRouterQuery(this.querySearchAndFilterList);
    },

    processStatusFilter() {
      if (checkEmptyValueHelpper(this.statusFilterValue) === false) {
        this.querySearchAndFilterList["status"] = this.statusFilterValue;
      } else {
        if (this.querySearchAndFilterList.hasOwnProperty("status")) {
          delete this.querySearchAndFilterList["status"];
        }
      }
      this.setRouterQuery(this.querySearchAndFilterList);
    },
    async getData() {
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // this.processStatusFilter();
      // this.processURIParameter();
      // //
      // let queryString = `${
      //   this.queryStringSearch.length === 0 ? "" : `${this.queryStringSearch}&`
      // }limit=${itemsPerPage}&page=${page}`;
      // if (sortBy.length !== 0) {
      //   queryString = `${queryString}&by=${sortBy}&order=${sortDesc}`;
      // }

      // this.isLoading = true;
      // this.tableData = [];

      // let responseData = await getVehicleFuelPaymentService(
      //   this.$store,
      //   queryString
      // );

      this.processStatusFilter();

      this.isLoading = true;
      this.tableData = [];
      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();
      let responseData = await getVehicleFuelPaymentService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData["code"] == 200) {
        let preData = _.clone(responseData.data["fuelPaymentList"]);
        //
        for (let i = 0; i < preData.length; i++) {
          preData[i]["rowIdx"] = i;
          preData[i]["fuelTypeStr"] = createfuelTypeStr(preData[i]);
          preData[i]["createdAtStr"] = createCreatedAtStr(preData[i]);
          preData[i]["amountStr"] = createAmountStr(preData[i]);
          preData[i]["amountUnitStr"] = createAmountUnitStr(preData[i]);
          preData[i]["pricePerUnitStr"] = createPriceUnitStr(preData[i]);
          //
          if (!_.isNil(preData[i]["lat"]) && !_.isNil(preData[i]["lon"])) {
            preData[i][
              "mapUrl"
            ] = `https://www.google.co.th/maps?q=${preData[i]["lat"]},${preData[i]["lon"]}&hl=th`;
          } else {
            preData[i]["mapUrl"] = "https://www.google.co.th/maps";
          }
        }

        this.items = preData;

        this.pageDataLength = parseInt(responseData.data["totalPages"]);
      }

      this.isLoading = false;
    },

    // processURIParameter() {
    //   this.queryStringSearch = new URLSearchParams(
    //     this.queryStringSearchList
    //   ).toString();
    // },

    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      // this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["vehicle"]; // id
      delete this.querySearchAndFilterList["user"]; // name
      delete this.querySearchAndFilterList["text"]; // id
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["useId"] === true) {
          if (_searchingParameter["c"] == "ve") {
            _queryParameter["vehicle"] = _searchingParameter["key"];
            _queryParameter["text"] = _searchingParameter["value"];
          } else {
            _queryParameter["user"] = _searchingParameter["key"];
            _queryParameter["text"] = _searchingParameter["value"];
          }
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["text"] = _searchingParameter["value"];
          }
        }
      }
      // _searchingParameter
      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getfilterItem(_queryParameter) {
      // this.queryStringSearchList = { ...this.queryStringSearchList, ...value };
      // this.getData();

      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryFilterList)) {
        // if (_.isEmpty(_queryParameter)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
        // this.queryFilterList = {};
        // }
      }
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getResetFilterGroup(value) {
      for (let i = 0; i < value.length; i++) {
        delete this.querySearchAndFilterList[value[i]];
      }
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE_FUEL");

        if (
          this.permList.admin_all === true ||
          this.permList.fuelpayment_list === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },

    async openDialog(item, itemIndex) {
      // loading data from api
      const dialogRespData = await this.$refs.vehicleFuelDetailComp.open(item);
      this.items[itemIndex]["status"] = dialogRespData["status"];
      this.componentRefreshCounter += 1;
    },

    reset() {
      this.statusFilterValue = "P";
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }

      this.queryOptionsTable = {
        limit: 10, // itemsPerPage = limit
        order: "desc", // asc or desc
        page: 1, // current page
        status: "P",
      };

      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.getData();
    },

    statustChangeHandler(newRowData) {
      this.items[newRowData["rowIdx"]]["status"] = newRowData["status"];
      this.componentRefreshCounter += 1;
    },
  },
};
</script>
<style scoped></style>
