<template>
  <v-container fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <h1 class="text-h5 mt-1">รายการรถ</h1>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="4">
          <AppAutoCompleteVehicleSearching
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            ref="AppAutoCompleteVehicleSearchingRef"
            @searchItemChange="getSearchItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>
            <VehicleFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>

        <v-col cols="auto" v-if="isPermissionCanEdit">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>

            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:[`item.licenseNumber`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="text-app-title font-weight-bold">
                <router-link
                  v-if="isPermissionCanViewInfo || isPermissionCanEdit"
                  class="text-decoration-none"
                  :to="{ name: 'vehicleEdit', params: { id: item._id } }"
                >
                  {{ item.licenseNumber }}
                </router-link>
                <span v-else> {{ item.licenseNumber }}</span>
              </v-list-item-title>

              <span
                v-if="
                  (item.licenseType !== null) & (item.licenseType !== undefined)
                "
                class="text-app-normal"
              >
                <span class="mx-0 px-0">
                  <v-icon
                    v-if="vehicleLicenseTypeColor(item.licenseType) === 'white'"
                    >mdi-card-text-outline</v-icon
                  >
                  <v-icon
                    v-else
                    :color="vehicleLicenseTypeColor(item.licenseType)"
                    >mdi-card-text</v-icon
                  >
                </span>

                {{ vehicleLicenseTypeText(item.licenseType) }}</span
              >
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                class="text-app-title font-weight-bold"
                v-if="item.type !== null"
              >
                {{ vehicleTypeText(item.type) }}</v-list-item-title
              >
              <span
                class="mx-0 px-0 text-app-normal"
                v-if="(item.seat !== null) & (item.seat !== undefined)"
              >
                <v-icon>{{ vehicleTypeIcon(item.type) }}</v-icon>
                {{ item.seat }} ที่นั่ง </span
              ><span v-else>-</span>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:[`item.cassieId`]="{ item }">
          <span class="text-app-title"> {{ item.cassieId }}</span>
        </template>

        <template v-slot:[`item.BrMoYe`]="{ item }">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title class="text-app-title">
                <span v-if="item.brand !== null">
                  {{ item.brand }}
                </span>
                <span v-if="item.model !== null">
                  {{ item.model }}
                </span>
                <span v-if="item.year !== null"> {{ item.year }} </span>
              </v-list-item-title>
              <span class="text-app-normal">{{ item.color }}</span>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            label
            small
            class="text-app-title"
            :color="vehicleStatusColor(item.status)"
            dark
          >
            {{ vehicleStatusText(item.status) }}
          </v-chip>
        </template>

        <template v-slot:[`item.date_insurance`]="{ item }">
          <v-list-item
            v-if="item.date_insurance !== null"
            two-line
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="
                  'text-app-title font-weight-bold ' +
                  getExpiredColor(item.expiry_diff_insurance)
                "
              >
                {{ item.expiry_diff_insurance }} วัน</v-list-item-title
              >
              <span class="text-app-normal">{{
                dateStringToDateFormat(item.date_insurance, {
                  returnThai: false,
                })
              }}</span>
            </v-list-item-content>
          </v-list-item>

          <div v-else>-</div>
        </template>

        <template v-slot:[`item.date_act`]="{ item }">
          <v-list-item v-if="item.date_act !== null" two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                :class="
                  'text-app-title font-weight-bold ' +
                  getExpiredColor(item.expiry_diff_act)
                "
              >
                {{ item.expiry_diff_act }} วัน</v-list-item-title
              >
              <span class="text-app-normal">{{
                dateStringToDateFormat(item.date_act, { returnThai: false })
              }}</span>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.date_tax`]="{ item }">
          <v-list-item v-if="item.date_tax !== null" two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                :class="
                  'text-app-title font-weight-bold ' +
                  getExpiredColor(item.expiry_diff_tax)
                "
              >
                {{ item.expiry_diff_tax }} วัน</v-list-item-title
              >
              <span class="text-app-normal">{{
                dateStringToDateFormat(item.date_tax, { returnThai: false })
              }}</span>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.gpsExpiryDate`]="{ item }">
          <v-list-item v-if="item.gpsExpiryDate !== null" two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title
                ><a
                  :class="
                    'text-app-title font-weight-bold ' +
                    getExpiredColor(item.expiry_diff_gps)
                  "
                  @click="gpsStatusActionClick(item)"
                >
                  {{ item.expiry_diff_gps }} วัน</a
                ></v-list-item-title
              >
              <span class="text-app-normal">{{
                dateStringToDateFormat(item.gpsExpiryDate, {
                  returnThai: false,
                })
              }}</span>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.loanExpiryDate`]="{ item }">
          <v-list-item
            v-if="item.loanExpiryDate === null && item.loanCounter !== null"
            two-line
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-app-title font-weight-bold primary--text"
              >
                ปิดงวดสำเร็จ</v-list-item-title
              >
              <span class="text-app-normal"
                >ทั้งหมด {{ item.loanCounter }} งวด</span
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="item.loanExpiryDate !== null"
            two-line
            class="px-0"
          >
            <v-list-item-content>
              <v-list-item-title>
                <a
                  :class="
                    'text-app-title font-weight-bold ' +
                    getExpiredColor(item.expiry_diff_loan)
                  "
                  @click="loanStatusActionClick(item)"
                  >{{ item.expiry_diff_loan }} วัน</a
                ></v-list-item-title
              >
              <span class="text-app-normal">{{
                dateStringToDateFormat(item.loanExpiryDate, {
                  returnThai: false,
                })
              }}</span>
            </v-list-item-content>
          </v-list-item>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :disabled="!isPermissionCanEdit">
                <v-list-item-icon>
                  <v-icon> mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  ที่อยู่ปัจจุบัน</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="(item.gps !== null) | (item.gpsExpiryDate !== null)"
                @click="gpsStatusActionClick(item)"
                :disabled="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-access-point</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  จ่ายค่า GPS</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                v-if="item.loanExpiryDate !== null"
                @click="loanStatusActionClick(item)"
                :disabled="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-cash-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  จ่ายค่างวด</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                @click="openVehicleDashboard(item._id)"
                v-if="permList.vehicle_report || permList.admin_all"
              >
                <v-list-item-icon>
                  <v-icon> mdi-file-chart-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  รายงานรถ
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="openPenalityTab(item)"
                v-if="permList.user_penality || permList.admin_all"
              >
                <v-list-item-icon>
                  <v-icon> mdi-account-details </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  พฤติกรรม</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                @click="handleDeleteClick(item._id)"
                :disabled="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title"
                  >ลบข้อมูล</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>

    <AppDialogConfirm ref="confirmDelete" />
    <AppDialogConfirm ref="alertSaving" />
    <AppDialogConfirm ref="confirmSaving" />
    <AppDialogConfirm ref="dialogAlert" />
    <VehicleGpsStatusDialog ref="gpsStatusDialog" />
    <VehicleLoanStatusDialog ref="loanStatusDialog" />
    <AppPageNotFound :show="isPermissionCanView" />
  </v-container>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";
import SortButton from "@/components/SortButton.vue";

import {
  getVehicleService,
  deleteVehicleService,
} from "@/services/api/vehicle";
import AppAutoCompleteVehicleSearching from "@/components/AppAutoCompleteVehicleSearching.vue";
import VehicleFilterGroup from "@/views/vehicle/components/VehicleFilterGroup.vue";
import {
  getVehicleFundamental,
  postVehicleGpsService,
  postVehicleLoanService,
} from "@/services/api/vehicle";
import { mapGetters } from "vuex";
import { dateStringToDateFormat } from "@/services/appDate";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { processPermission } from "@/services/permissions";
import { DialogType } from "@/services/dialog";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import VehicleGpsStatusDialog from "./components/VehicleGpsStatusDialog";
import VehicleLoanStatusDialog from "./components/VehicleLoanStatusDialog";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import _ from "lodash";
import {
  appDecodeSingleURIComponentHelper,
  appEncodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";
import { URL_EMPLOYEE_PENALTY } from "@/services/routerQueryPath";

export default {
  name: "VehicleListView",
  components: {
    PrimaryButton,
    NormalButton,
    SortButton,
    VehicleFilterGroup,
    AppAutoCompleteVehicleSearching,
    AppDialogConfirm,
    VehicleGpsStatusDialog,
    VehicleLoanStatusDialog,
    AppPageNotFound,
  },

  data: () => ({
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,

    licenseType: [],
    vehicleStatus: [],
    options: {},
    tableLoading: false,
    serverItemsLength: -1,
    //
    tableData: [],
    respTableData: [],
    // search
    queryStringSearch: "",
    queryStringSearchList: {},
    queryFilterList: {},
    querySearchAndFilterList: {},
    queryOrder: {
      // query : formData
      minseat: "minseat",
      maxseat: "maxseat",
      insur: "insur",

      mininsurdate: "mininsurdate",
      maxinsurdate: "maxinsurdate",

      minactdate: "minactdate",
      maxactdate: "maxactdate",

      mintaxdate: "mintaxdate",
      maxtaxdate: "maxtaxdate",

      mingpsdate: "mingpsdate",
      maxgpsdate: "maxgpsdate",

      minloandate: "minloandate",
      maxloandate: "maxloandate",

      insurexpired: "insurexpired",
      actexpired: "actexpired",
      taxexpired: "taxexpired",
      status: "status",
    },
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },

    field_sort_by: null,
    sortVal: null,
    sortList: [
      {
        text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
        value: "createddate-asc",
      },
      {
        text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
        value: "createddate-desc",
      },
      {
        text: "หมายเลขทะเบียน เรียงจาก A-Z",
        value: "licenseNumber-asc",
      },
      {
        text: "หมายเลขทะเบียน เรียงจาก Z-A",
        value: "licenseNumber-desc",
      },
      {
        text: "เวลาประกันที่เหลือ น้อยไปมาก",
        value: "ins_remaining-asc",
      },
      {
        text: "เวลาประกันที่เหลือ มากไปน้อย",
        value: "ins_remaining-desc",
      },
      {
        text: "เวลา พ.ร.บ. ที่เหลือ น้อยไปมาก",
        value: "act_remaining-asc",
      },
      {
        text: "เวลา พ.ร.บ. ที่เหลือ มากไปน้อย",
        value: "act_remaining-desc",
      },
      {
        text: "เวลาภาษีที่เหลือ น้อยไปมาก",
        value: "tax_remaining-asc",
      },
      {
        text: "เวลาภาษีที่เหลือ มากไปน้อย",
        value: "tax_remaining-desc",
      },
      {
        text: "เวลา gps ที่เหลือ น้อยไปมาก",
        value: "gps_remaining-asc",
      },
      {
        text: "เวลา gps ที่เหลือ มากไปน้อย",
        value: "gps_remaining-desc",
      },
      {
        text: "เวลาค่างวดที่เหลือ น้อยไปมาก",
        value: "loan_remaining-asc",
      },
      {
        text: "เวลาค่างวดที่เหลือ มากไปน้อย",
        value: "loan_remaining-desc",
      },
    ],
    //
    editTextBtn: "แก้ไขข้อมูล",

    // menu
    isShowMenu: false,
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    isPermissionCanViewInfo: false,
    permList: {},
  }),

  // watch: {
  //   options: {
  //     handler() {
  //       this.getData();
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    ...mapGetters({
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleStatusText: "vehicle/vehicleStatusText",
      vehicleStatusTextInvert: "vehicle/vehicleStatusTextInvert",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
    }),

    tableHeaders() {
      let defaultdata = [
        {
          text: "ป้ายทะเบียน",
          align: "start",
          sortable: false,
          value: "licenseNumber",
        },
        {
          text: "ชนิดรถยนต์",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "ข้อมูลรถ",
          value: "BrMoYe",
          sortable: false,
          align: "left",
          width: "10%",
        },

        {
          text: "Cassie ID (VIN)",
          align: "start",
          sortable: false,
          value: "cassieId",
        },
        { text: "สถานะ", value: "status", sortable: false, align: "left" },
        {
          text: "ผู้ขับล่าสุด",
          value: "driver",
          sortable: false,
          align: "center",
        },
        {
          text: "ประกันภัยที่เหลือ",
          value: "date_insurance",
          sortable: false,
          align: "left",
        },
        {
          text: "พ.ร.บ. ที่เหลือ",
          value: "date_act",
          sortable: false,
          align: "left",
        },
        {
          text: "ภาษีที่เหลือ",
          value: "date_tax",
          sortable: false,
          align: "left",
        },
        {
          text: "วันหมดอายุ GPS",
          value: "gpsExpiryDate",
          sortable: false,
          align: "left",
        },
        {
          text: "กำหนดชำระค่างวด",
          value: "loanExpiryDate",
          sortable: false,
          align: "left",
        },
      ];

      if (this.isPermissionCanView) {
        defaultdata.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        });
      }
      //
      return defaultdata;
    },

    tableRows() {
      let rows = this.respTableData.map((row) => {
        return {
          _id: row._id,
          licenseNumber: row.licenseNumber,
          type: row.type,
          seat: row.seat,
          licenseType: row.licenseType,
          cassieId: this.checkUndefinedAndNull(row, "cassieId", true)
            ? "..." +
              row.cassieId.substring(
                row.cassieId.length - 5,
                row.cassieId.length
              )
            : "-",
          color: this.checkUndefinedAndNull(row, "color", false),
          BrMoYe: this.checkUndefinedAndNull(row, "BrMoYe", false),
          status: this.checkUndefinedAndNull(row, "status", true),
          driver: this.checkUndefinedAndNull(row, "lastDriver", false),
          actions_location: this.checkUndefinedAndNull(
            row,
            "actions_location",
            false
          ),
          date_insurance: row.date_insurance,
          date_act: row.date_act,
          date_tax: row.date_tax,
          gps: row.gps,
          gpsExpiryDate: row.gpsExpiryDate,
          loanAmount: row.loanAmount,
          loanCounter: row.loanCounter,
          loanRoundpayDay: row.loanRoundpayDay,
          loanExpiryDate: row.loanExpiryDate,
          loanCodeNumber: row.loanCodeNumber || null,
          financeCompany: row.financeCompany,
          expiry_diff_act: row.expiry_diff_act,
          expiry_diff_tax: row.expiry_diff_tax,
          expiry_diff_insurance: row.expiry_diff_insurance,
          expiry_diff_gps: row.expiry_diff_gps,
          expiry_diff_loan: row.expiry_diff_loan,
          brand: row.brand,
          year: row.year,
          model: row.model,
          tools: [],
        };
      });
      return rows;
    },
  },
  mounted() {
    // routed from vehicle detail
    this.processRouterQuery(this.$route.query);
    this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.getVehicleFund();
    }
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      const _queryObj = appEncodeURIComponentHelper(queryObj);

      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    checkUndefinedAndNull(item, key, returnBoolean) {
      if (item.hasOwnProperty(key) === false) {
        return returnBoolean ? false : "-";
      }

      if (item[key] === null) {
        return returnBoolean ? false : "-";
      }
      // if string
      if (item[key].length === 0) {
        return returnBoolean ? false : "-";
      }

      return item[key];
    },
    getExpiredColor(day) {
      if (day < this.$store.getters["vehicle/vehicleExpiryDay"]["red"]) {
        return "red--text text--darken-1 font-weight-bold";
      } else if (
        day < this.$store.getters["vehicle/vehicleExpiryDay"]["orange"]
      ) {
        return "amber--text text--darken-2 font-weight-bold";
      }
      return "green--text text--darken-1 font-weight-bold";
    },
    handleAddClick() {
      this.$router.push("/vehicle/create");
    },
    handleEditClick(_id) {
      if (this.querySearchAndFilterList !== null) {
        this.$store.state.preSearchAndFilter = this.querySearchAndFilterList;
      } else {
        this.$store.state.preSearchAndFilter = null;
      }
      this.$router.push(`/vehicle/edit/${_id}`);
    },
    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteVehicleService(this.$store, _id);
        this.getData();
      }
    },
    // sortByHandler(sortValue) {
    //   this.field_sort_by = sortValue.split("-");
    //   this.options["sortBy"] = [this.field_sort_by[0]];
    //   this.options["sortDesc"] = [this.field_sort_by[1]];
    // },

    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    async getData() {
      this.tableLoading = true;
      this.tableData = [];
      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getVehicleService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData.code == 200) {
        this.serverItemsLength = responseData.data["total"];
        let vehicleList = responseData.data["vehicleList"];
        this.respTableData = vehicleList;
        this.tableData = this.tableRows;
      }

      this.tableLoading = false;
    },

    async getVehicleFund() {
      let resp_data = await getVehicleFundamental(this.$store, "type=view");
      if (resp_data.code === 200) {
        //
        this.licenseType = resp_data.data.licenseType;
        this.vehicleStatus = resp_data.data.status;
      } else {
        this.licenseType = [];
        this.vehicleStatus = [];
      }
    },
    // getPreSearchAndFilter() {
    //   if (this.$store.state.preSearchAndFilter !== null) {
    //     this.querySearchAndFilterList = this.$store.state.preSearchAndFilter;
    //     this.queryStringSearch = new URLSearchParams(
    //       this.querySearchAndFilterList
    //     ).toString();
    //   }
    // },
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.setRouterQuery(this.querySearchAndFilterList);
    },
    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["text"];
      delete this.querySearchAndFilterList["cassieid"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["key"])) {
          _queryParameter["cassieid"] = _searchingParameter["key"];
          _queryParameter["text"] = _searchingParameter["value"];
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["text"] = _searchingParameter["value"];
          }
        }
      }

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      // if (!_.isEmpty(this.queryFilterList)) {
      //   Object.keys(this.queryFilterList).forEach((key) => {
      //     delete this.querySearchAndFilterList[key];
      //   });
      // }
      // this.queryFilterList = _queryParameter;
      Object.keys(this.queryOrder).forEach((key) => {
        delete this.querySearchAndFilterList[key];
      });

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    openVehicleDashboard(_id) {
      this.$router.push(`/vehicle/dashboard_personal/${_id}`);
    },

    async alertGpsSaveChange() {
      await this.$refs.alertSaving.open(
        "ยืนยันการชำระค่า GPS สำเร็จ!",
        null,
        DialogType.POSITIVE,
        this.alertOptions
      );
    },

    async alertGpsFailedChange() {
      await this.$refs.alertSaving.open(
        "ยืนยันการชำระค่า GPS ไม่สำเร็จ!",
        "กรุณาตรวจสอบข้อมูลอีกครั้ง",
        DialogType.ERROR,
        this.alertOptions
      );
    },

    async alertLoanSaveChange() {
      await this.$refs.alertSaving.open(
        "ยืนยันการชำระค่างวดสำเร็จ!",
        null,
        DialogType.POSITIVE,
        this.alertOptions
      );
    },

    async alertLoanFailedChange() {
      await this.$refs.alertSaving.open(
        "ยืนยันการชำระค่างวดไม่สำเร็จ!",
        null,
        DialogType.ERROR,
        this.alertOptions
      );
    },

    async gpsStatusActionClick(selectedItem) {
      let mongoId = selectedItem["_id"];
      const _item = {
        gps: selectedItem["gps"],
        gpsExpiryDate: selectedItem["gpsExpiryDate"],
        gpsRemainingDay: selectedItem["expiry_diff_gps"],
      };
      const updateGpsStatusData = await this.$refs.gpsStatusDialog.open(_item);
      if (updateGpsStatusData !== null) {
        this.isLoading = true;
        if (
          (updateGpsStatusData["gps"] !== "") &
          (updateGpsStatusData["gpsExpiryDate"] !== null)
        ) {
          if (
            await this.$refs.confirmSaving.open(
              "คุณได้ชำระค่า GPS เรียบร้อยแล้ว ใช่หรือไม่ ?",
              null,
              DialogType.ACTION,
              {
                noconfirm: true,
              }
            )
          ) {
            let respData = await postVehicleGpsService(
              this.$store,
              mongoId,
              updateGpsStatusData
            );
            //
            if (respData["code"] === 200) {
              if (respData.data["insertTrans"]) {
                this.alertGpsSaveChange();
                // this.getData();
                this.updatGpsDataTable(mongoId, respData["data"]);
              } else {
                this.alertGpsFailedChange();
              }
              this.isLoading = false;
            }

            //
          }
        } else {
          await this.$refs.dialogAlert.open(
            "กรุณากรอกข้อมูล GPS ทั้งหมด",
            null,
            DialogType.ERROR,
            {
              noconfirm: false,
            }
          );
          this.gpsStatusActionClick(selectedItem);
        }
      }
    },

    async loanStatusActionClick(selectedItem) {
      let mongoId = selectedItem["_id"];
      const _item = {
        loanAmount: selectedItem["loanAmount"],
        loanCounter: selectedItem["loanCounter"],
        loanRoundpayDay: selectedItem["loanRoundpayDay"],
        financeCompany: selectedItem["financeCompany"],
        loanExpiryDate: selectedItem["loanExpiryDate"],
        expiry_diff_loan: selectedItem["expiry_diff_loan"],
        loanCodeNumber: selectedItem["loanCodeNumber"],
      };
      const updateLoanStatusData = await this.$refs.loanStatusDialog.open(
        _item
      );

      if (updateLoanStatusData !== null) {
        this.isLoading = true;
        if (
          (updateLoanStatusData["loanAmount"] !== "") &
          (updateLoanStatusData["loanRoundpayDay"] !== null) &
          (updateLoanStatusData["financeCompany"] !== null) &
          (updateLoanStatusData["financeCompany"] !== "")
        ) {
          if (
            await this.$refs.confirmSaving.open(
              "คุณได้ชำระค่างวดเรียบร้อยแล้ว ใช่หรือไม่ ?",
              null,
              DialogType.ACTION,
              {
                noconfirm: true,
              }
            )
          ) {
            let loanData = {
              loanAmount: updateLoanStatusData["loanAmount"],
              loanRoundpayDay: updateLoanStatusData["loanRoundpayDay"],
              financeCompany: updateLoanStatusData["financeCompany"],
              loanCodeNumber: selectedItem["loanCodeNumber"],
            };
            let respData = await postVehicleLoanService(
              this.$store,
              mongoId,
              loanData
            );
            //
            if (respData["code"] === 200) {
              if (respData.data["insertTrans"]) {
                this.alertLoanSaveChange();
                // this.getData();
                this.updateLoanDataTable(mongoId, respData["data"]);
              } else {
                this.alertLoanFailedChange();
              }
              this.isLoading = false;
            }
          }
        } else {
          await this.$refs.dialogAlert.open(
            "กรุณากรอกข้อมูลค่างวดทั้งหมด",
            null,
            DialogType.ERROR,
            {
              noconfirm: false,
            }
          );
          this.loanStatusActionClick(selectedItem);
        }

        //
      }
    },

    updatGpsDataTable(mongoId, respData) {
      const updateIdx = this.tableData.findIndex(
        (element) => element._id === mongoId
      );
      this.tableData[updateIdx].gps = respData["gps"];
      this.tableData[updateIdx].expiry_diff_gps = respData["expiry_diff_gps"];
      this.tableData[updateIdx].gpsAmount = respData["gpsAmount"];
      this.tableData[updateIdx].gpsExpiryDate = respData["gpsExpiryDate"];
    },

    updateLoanDataTable(mongoId, respData) {
      const updateIdx = this.tableData.findIndex(
        (element) => element._id === mongoId
      );
      this.tableData[updateIdx].expiry_diff_loan = respData["expiry_diff_loan"];
      this.tableData[updateIdx].loanAmount = respData["loanAmount"];
      this.tableData[updateIdx].loanCounter = respData["loanCounter"];
      this.tableData[updateIdx].loanExpiryDate = respData["loanExpiryDate"];
      this.tableData[updateIdx].loanRoundpayDay = respData["loanRoundpayDay"];
      this.tableData[updateIdx].financeCompany = respData["financeCompany"];
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE");

        if (this.permList.vehicle_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.vehicle_info === true) {
          this.isPermissionCanViewInfo = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.vehicle_info_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanViewInfo = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },

    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }

      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.getData();
    },
    //
    openPenalityTab(item) {
      const params = new URLSearchParams({
        vehicle: item._id,
        cjvtext: appEncodeSingleURIComponentHelper(
          `รถโดยสาร ${item.licenseNumber}`
        ),
      }).toString();

      const goTo = `${window.location.origin}/employee${URL_EMPLOYEE_PENALTY}&${params}`;
      window.open(goTo, "_blank");
    },
  },
  // mounted() {
  //   this.checkUserPermissions();
  //   if (this.isPermissionCanView) {
  //     this.getVehicleFund();
  //   }
  // },
};
</script>
