<template>
  <v-dialog persistent v-model="show" width="900px" origin="center top">
    <v-card v-if="show" height="100%">
      <v-toolbar elevation="0" color="primary" dark>
        <p class="mt-4 px-3">
          <v-icon class="mr-1"> mdi-map-marker </v-icon> {{ title }}
        </p>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-3">
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              label="ค้นหาสถานที่"
              placeholder="พิมพ์ชื่อและกด Enter"
              v-model="searchValues"
              @keyup="autoCompleteEnterToSearch"
              :searchboxLoading="searchboxLoading"
              hide-details=""
              class="app-custom-input custom-placeholer-color"
              :disabled="mode === 2"
            ></v-text-field>
            <v-card
              elevation="0"
              v-if="searchboxLoading"
              class="rounded-0"
              width="100%"
              height="100%"
            >
              <v-overlay :absolute="true" :value="true" :opacity="0.05">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="30"
                ></v-progress-circular
              ></v-overlay>
            </v-card>

            <div v-if="!searchboxLoading">
              <v-list dense style="overflow-y: auto">
                <v-list-item-subtitle class="font-weight-bold">
                  ผลการค้นหา
                </v-list-item-subtitle>
                <v-list-item-group v-model="selectedPlace" color="primary">
                  <!--  default item -->
                  <div :key="`place_r_no_data`">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title class="pl-2">
                          ไม่กำหนดตำแหน่ง
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-wrap pl-2">
                          ไม่ระบุตำแหน่งลงบนตำแหน่งแผนที่
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>

                  <!--  -->
                  <div v-for="(place, i) in places" :key="`place_r${i}`">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title class="pl-2">
                          {{ place.structured_formatting.main_text }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="text-wrap pl-2"
                          v-text="place.description"
                        >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="i < places.length - 1"
                      :key="`places_v${i}`"
                    ></v-divider>
                  </div>
                </v-list-item-group>
              </v-list>
            </div>

            <v-card
              elevation="0"
              class="rounded-0 d-flex justify-center align-center mt-4"
              v-else-if="!initSearch"
            >
              <p class="text-app-detail-normal">
                พิมพ์ชื่อสถานที่ในกล่องและกด Enter
              </p>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card flat>
              <v-card-text class="pa-0">
                <v-form ref="markerDataForm">
                  <CustomTextInputImprove
                    data-input-name="locationName"
                    v-model="formData.name"
                    label="ชื่อตำแหน่ง / สถานที่"
                    :hide-detail="true"
                    :disabled="disabledForm || mode === 2"
                    :expend-margin="false"
                    :dense="false"
                    :input-view-bottom-margin="false"
                    :show-required-bullet="false"
                    :is-required="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                  />
                  <v-row class="mt-3" dense>
                    <v-col>
                      <CustomTextInputImprove
                        data-input-name="lat"
                        v-model="formData.coordinates[0]"
                        input-type="floatNumberWithOutSign"
                        label="ตำแหน่งละติจูด"
                        :hide-detail="true"
                        :expend-margin="false"
                        :disabled="disabledForm || mode === 2"
                        @blurHandler="checkLocationAction"
                        :input-view-bottom-margin="false"
                        :show-required-bullet="false"
                        :is-required="!allowZeroLntLng"
                        :rules="
                          !allowZeroLntLng
                            ? [(v) => !!v || 'จำเป็นต้องระบุ']
                            : []
                        "
                      />
                    </v-col>
                    <v-col>
                      <CustomTextInputImprove
                        data-input-name="lng"
                        v-model="formData.coordinates[1]"
                        input-type="floatNumberWithOutSign"
                        label="ตำแหน่งลองจิจูด"
                        :hide-detail="true"
                        :expend-margin="false"
                        :disabled="disabledForm || mode === 2"
                        @blurHandler="checkLocationAction"
                        :input-view-bottom-margin="false"
                        :show-required-bullet="false"
                        :is-required="!allowZeroLntLng"
                        :rules="
                          !allowZeroLntLng
                            ? [(v) => !!v || 'จำเป็นต้องระบุ']
                            : []
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row class="mt-3" dense v-if="currrentMarkerIdx !== 0">
                    <!-- <v-col cols="12">
                    <v-row> <p>ประมาณจากจุดล่าสุด</p></v-row>
                  </v-col> -->

                    <v-col>
                      <CustomTextInputImprove
                        data-input-name="distance"
                        v-model="kmDistance"
                        label="ระยะทางโดยประมาณ (กิโลเมตร)"
                        :hide-detail="true"
                        input-type="floatNumberWithOutSign"
                        :expend-margin="false"
                        :disabled="disabledForm || mode === 2"
                        :input-view-bottom-margin="false"
                        :show-required-bullet="false"
                        :is-required="true"
                        :rules="
                          !allowZeroLntLng
                            ? [(v) => !!v || 'จำเป็นต้องระบุ']
                            : []
                        "
                      />
                    </v-col>
                    <v-col>
                      <CustomTextInputImprove
                        data-input-name="duration"
                        v-model="minuteDuration"
                        label="ระยะเวลาโดยประมาณ (นาที)"
                        :hide-detail="true"
                        input-type="floatNumberWithOutSign"
                        :expend-margin="false"
                        :disabled="disabledForm || mode === 2"
                        :input-view-bottom-margin="false"
                        :show-required-bullet="false"
                        :is-required="true"
                        :rules="
                          !allowZeroLntLng
                            ? [(v) => !!v || 'จำเป็นต้องระบุ']
                            : []
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-3"
                    dense
                    v-if="
                      currrentMarkerIdx !== 0 && vuexStoreName === 'manageRoute'
                    "
                  >
                    <v-col>
                      <table width="100%">
                        <tr>
                          <td class="text-left" width="53%">เริ่มงาน</td>
                          <td class="text-right">{{ jobStartDate }}</td>
                        </tr>
                        <tr>
                          <td class="text-left">จากจุดที่แล้ว (ประมาณ)</td>
                          <td class="text-right">
                            {{ estimateArrivalTimeText }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">ถึงจุดนี้ (ประมาณ)</td>
                          <td class="text-right">
                            {{ estimateArrivalTimeFromLastMarker }}
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <v-row class="mt-3 mb-1" dense>
                    <v-col cols="6" v-if="lastMarkerData !== null">
                      <CustomTextInputImprove
                        data-input-name="limitSpeed"
                        v-model="formData.checkpointLimitSpeedKmPerHours"
                        label="จำกัดความเร็ว (กม/ชม)"
                        :hide-detail="true"
                        input-type="floatNumberWithOutSign"
                        :expend-margin="false"
                        :disabled="disabledForm || mode === 2"
                        :input-view-bottom-margin="false"
                        :show-required-bullet="false"
                        :is-required="true"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                      />
                    </v-col>
                    <v-col cols="6">
                      <CustomTextInputImprove
                        data-input-name="checkpointRadiusInMeter"
                        v-model="formData.checkpointRadiusInMeter"
                        label="รัศมีเข้าใกล้ (เมตร)"
                        :hide-detail="true"
                        input-type="floatNumberWithOutSign"
                        :expend-margin="false"
                        :disabled="disabledForm || mode === 2"
                        :input-view-bottom-margin="false"
                        :show-required-bullet="false"
                        :is-required="true"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                      />
                    </v-col>
                  </v-row>

                  <v-row no-gutters v-if="lastMarkerData !== null">
                    <v-col cols="12">
                      <v-slider
                        class="py-1 align-center"
                        label="ค่าหน่วงเวลา (%)"
                        :hide-details="true"
                        :disabled="disabledForm || mode === 2"
                        v-model="formData.checkpointDurationPercent"
                      >
                        <template v-slot:append>
                          <CustomTextInputImprove
                            data-input-name="checkpointDurationPercent"
                            v-model="formData.checkpointDurationPercent"
                            label=""
                            :hide-detail="true"
                            :expend-margin="false"
                            :disabled="disabledForm || mode === 2"
                            style="width: 70px"
                            input-type="floatNumberWithOutSign"
                            :input-view-bottom-margin="false"
                            :show-required-bullet="false"
                            :is-required="true"
                            :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                          />
                          <!-- <v-text-field
                          v-model="formData.checkpointDurationPercent"
                          class="app-custom-input mt-0 pt-0"
                          hide-details
                          outlined
                          dense
                         
                          type="number"
                        ></v-text-field> -->
                        </template>
                      </v-slider>
                    </v-col>

                    <v-col cols="12">
                      <v-checkbox
                        dense
                        hide-details
                        v-model="formData.gDirectionOption.avoidTolls"
                        label="หลีกเลี่ยงเส้นที่มีค่าผ่านทาง (Tolls)"
                        :disabled="mode === 2"
                        @change="setDirectionOption"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        class="mb-1"
                        dense
                        hide-details
                        :disabled="mode === 2"
                        v-model="formData.gDirectionOption.avoidHighways"
                        label="หลีกเลี่ยงเส้นทางหลวง (Highways)"
                        @change="setDirectionOption"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>

              <v-alert
                v-if="errorReMarkerText"
                dark
                class="mt-1 text-caption"
                color="red"
                dense
                >ข้อมูลไม่ถูกต้องหรือตำแหน่งซ้ำ
                ไม่สามารถแสดงผลตำแหน่งได้</v-alert
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <GoogleMapLoader
              ref="createMarkerMap"
              vuex-status-action-name="updateCreateGMapActive"
              :vuex-store-name="vuexStoreName"
              style="width: 100%; height: 300px"
              :map-config="mapConfig"
            >
              <template slot-scope="{ google, map }">
                <GoogleMapMarkerById
                  v-if="marker"
                  :google="google"
                  :map="map"
                  :key="marker.gplaceId"
                  :marker="marker"
                  @updateLocation="drapDropAction"
                />
                <GoogleMapMarker
                  v-if="lastMarkerData !== null"
                  :google="google"
                  :marker="lastMapMarker"
                  :map="map"
                />
              </template>
            </GoogleMapLoader>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-1"
          color="success"
          @click="save"
          :disabled="disabledSaveBtn || mode === 2"
        >
          บันทึก
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GoogleMapLoader from "../../googleMapComponents/GoogleMapLoader.vue";
import GoogleMapMarkerById from "../../googleMapComponents/GoogleMapMarkerById.vue";
import GoogleMapMarker from "../../googleMapComponents/GoogleMapMarker.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import {
  getDistanceFromResponseDataMapService,
  getDurationFromResponseDataMapService,
} from "../../googleMapComponents/GoogleMapService";
import moment from "moment";
import {
  processDistanceObject,
  processDurationObject,
  processKmtoMeterDistance,
  processMetertoKmDistanceCeil,
  processMinuteToSecondDuration,
  processSecondtoMinuteDurationCeil,
} from "@/services/appFuncHelpper";
import { dateStringToDateFormat } from "@/services/appDate";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "JobMapCreateMarkerDialog",
  props: {
    vuexStoreName: {
      type: String,
      default: "manageRoute",
    },
    vuexStoreMainKeyName: {
      type: String,
      default: "jobData",
    },
    allowZeroLntLng: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // dialog
      show: false,
      dialogLoading: true,
      initComponent: true,
      mode: 0,
      // search (right)
      // edit lag log
      searchValues: "",
      selectedPlace: null,
      initSearch: false,
      bufferPlaces: {},
      searchboxLoading: false,
      places: [],
      // marker
      marker: null,
      disabledForm: false,
      disabledSaveBtn: true,
      errorReMarkerText: false,
      // from data (processing)
      defaultFormData: {
        status: "W",
        gplaceId: null,
        name: null,
        coordinates: [null, null],
        duration: null,
        distance: null,
        gplaceData: null,
        gplaceGeocode: null,
        gDirection: null,
        gDirectionOption: {
          avoidTolls: true,
          avoidHighways: false,
          drivingOptions: {
            departureTime: null,
            trafficModel: "bestguess",
          },
        },
        checkpointRadiusInMeter: null,
        checkpointDurationPercent: null,
        checkpointLimitSpeedKmPerHours: null,
      },
      oriformData: null,
      formData: null,
      // distance and duration
      kmDistance: null,
      minuteDuration: null,
      //
      selectedMarkerData: null,
      lastMarkerData: null,
      nextMarkerData: null,
      currentPlaceIdMarker: null,
      dialogDataShouldCalculateDepartureTime: false,
      placeIdList: [],
      currrentMarkerIdx: 0,
      isPermissionCanEdit: true,
      miniMapInitIntervalID: undefined,
      miniMapInitCount: 0,
      miniMapInitMaxCount: 6,
      //
      resolve: null,
      reject: null,
    };
  },

  computed: {
    title() {
      if (this.mode === 1) {
        return "แก้ไขตำแหน่งบนแผนที่";
      } else if (this.mode === 2) {
        return "ดูตำแหน่งบนแผนที่";
      }

      return "เพิ่มตำแหน่งบนแผนที่";
    },
    mapConfig() {
      if (_.isNil(this.lastMarkerData)) {
        return {
          center: { lat: 13.283859, lng: 100.915876 },
          zoom: 18,
        };
      }
      return {
        // center: { lat: 13.283859, lng: 100.915876 },
        center: {
          lat: this.lastMarkerData.coordinates[0],
          lng: this.lastMarkerData.coordinates[1],
        },
        zoom: 18,
      };
    },
    lastMapMarker() {
      return {
        id: `lastMarker_${new Date().getTime()}`,
        position: {
          lat: this.lastMarkerData.coordinates[0],
          lng: this.lastMarkerData.coordinates[1],
        },
        label: {
          text: String(this.lastMarkerData.order),
          fontSize: "18px",
          fontWeight: "700",
          color: "#ffffff",
        },
        icon: {
          path: faLocationPin.icon[4],
          fillColor: "#659bde",
          fillOpacity: 1,
          scale: 0.085,
          anchor: [faLocationPin.icon[0], faLocationPin.icon[1] / 2],
        },
        processMarkerPoint: true,
      };
    },
    estimateArrivalTimeText() {
      return !_.isNil(this.lastMarkerData)
        ? dateStringToDateFormat(
            moment(this.lastMarkerData.estimateArrivalTimePosix).toDate(),
            { type: "datetime", returnThai: true }
          )
        : "-";
    },
    estimateArrivalTimeFromLastMarker() {
      return !_.isNil(this.lastMarkerData) && !_.isNil(this.minuteDuration)
        ? dateStringToDateFormat(
            moment(this.lastMarkerData.estimateArrivalTimePosix)
              .add(this.minuteDuration, "minutes")
              .toDate(),
            { type: "datetime", returnThai: true }
          )
        : "-";
    },
    jobStartDate() {
      let _jobStart =
        this.$store.state[this.vuexStoreName][this.vuexStoreMainKeyName][
          "startDate"
        ];

      if (!_.isNil(_jobStart)) {
        return dateStringToDateFormat(moment(_jobStart).toDate(), {
          type: "datetime",
          returnThai: true,
        });
      }

      return "-";
    },
    //
  },

  methods: {
    open(
      lastMarker,
      selectedMarker,
      nextMarker,
      placeIdList,
      currrentMarkerIdx,
      canEdit,
      dialogDataShouldCalculateDepartureTime
    ) {
      this.dialogLoading = true;
      // console.log("LOG:: lastMarker", lastMarker);
      // console.log("LOG:: selectedMarker", selectedMarker);
      // console.log("LOG:: nextMarker", nextMarker);
      this.setMarkerConfig();
      this.oriformData = _.cloneDeep(this.defaultFormData);
      this.formData = _.cloneDeep(this.defaultFormData);
      this.show = true;

      this.lastMarkerData = lastMarker;
      this.selectedMarkerData = selectedMarker;
      this.nextMarkerData = nextMarker;
      this.placeIdList = placeIdList;
      this.currrentMarkerIdx = currrentMarkerIdx;
      //
      this.dialogDataShouldCalculateDepartureTime = !_.isNil(
        dialogDataShouldCalculateDepartureTime
      )
        ? dialogDataShouldCalculateDepartureTime
        : false;

      if (!_.isNil(this.selectedMarkerData)) {
        if (canEdit) {
          this.mode = 1; // edit
        } else {
          this.mode = 2; // view
        }
      } else {
        this.mode = 0; // insert
      }

      this.setFormData();
      this.setMiniMapInit();

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.resolve(null);
      this.setMiniMapClear();
      this.$store.dispatch(
        `${this.vuexStoreName}/updateCreateGMapActive`,
        false
      );
      this.selectedPlace = null;
      this.disabledSaveBtn = true;
      this.disabledForm = false;
      this.initSearch = false;
      this.dialogLoading = false;
      this.show = false;
    },

    setMarkerConfig() {
      this.defaultFormData = {
        ...this.defaultFormData,
        checkpointRadiusInMeter: _.get(
          this.$store.state[`${this.vuexStoreName}`][
            `${this.vuexStoreMainKeyName}`
          ],
          "config.checkpointRadiusInMeter",
          null
        ),
        checkpointDurationPercent: _.get(
          this.$store.state[`${this.vuexStoreName}`][
            `${this.vuexStoreMainKeyName}`
          ],
          "config.checkpointDurationPercent",
          null
        ),
        checkpointLimitSpeedKmPerHours: _.get(
          this.$store.state[`${this.vuexStoreName}`][
            `${this.vuexStoreMainKeyName}`
          ],
          "config.checkpointLimitSpeedKmPerHours",
          null
        ),
      };
    },

    isNetworkConnection() {
      if (!window.navigator.onLine) {
        this.close();
      }
    },

    async save() {
      try {
        // validate form
        if (!this.$refs.markerDataForm.validate()) {
          return;
        }

        //
        let _formData = _.clone(this.formData);
        let _nextMarkerData = null;
        this.dialogLoading = true;

        // for distance and duration current marker
        _formData["distance"] = processKmtoMeterDistance(this.kmDistance);
        _formData["duration"] = processMinuteToSecondDuration(
          this.minuteDuration
        );

        // for edit
        if (this.mode === 1) {
          // CASE :: update distance and duration text
          _formData["distanceText"] = processDistanceObject(
            _formData["distance"]
          );
          _formData["durationText"] = processDurationObject(
            _formData["duration"]
          );

          // CASE::: in case have nextMarkerDarta
          if (!_.isNil(this.nextMarkerData)) {
            _nextMarkerData = _.clone(this.nextMarkerData);

            // Estimate time to from current direction
            let currentMarkerDurationInSecond =
              getDurationFromResponseDataMapService(_formData["gDirection"]);

            let estimateArrialTimeFornextMarkerData = moment(
              _formData["gDirectionOption"]["drivingOptions"]["departureTime"]
            );

            // get poxis time
            let estimateArrialTimeForNextMarkerDate =
              estimateArrialTimeFornextMarkerData
                .add(currentMarkerDurationInSecond, "s", true)
                .utc();

            _nextMarkerData["gDirectionOption"]["drivingOptions"][
              "departureTime"
            ] = this.calculateGDirectionDepartureTime(
              estimateArrialTimeForNextMarkerDate
            );

            // child marker
            let nextMarkerGDirection = await this._calculateGoogleDirection(
              _formData.coordinates,
              this.nextMarkerData.coordinates,
              _nextMarkerData["gDirectionOption"]
            );
            // set new value
            _nextMarkerData["distance"] = !_.isNil(nextMarkerGDirection)
              ? getDistanceFromResponseDataMapService(nextMarkerGDirection)
              : null;
            _nextMarkerData["duration"] = !_.isNil(nextMarkerGDirection)
              ? getDurationFromResponseDataMapService(nextMarkerGDirection)
              : null;
            _nextMarkerData["distance"] = processKmtoMeterDistance(
              processMetertoKmDistanceCeil(_nextMarkerData["distance"])
            );
            _nextMarkerData["duration"] = processMinuteToSecondDuration(
              processSecondtoMinuteDurationCeil(_nextMarkerData["duration"])
            );

            //
            _nextMarkerData["distanceText"] = processDistanceObject(
              _nextMarkerData["distance"]
            );
            _nextMarkerData["durationText"] = processDurationObject(
              _nextMarkerData["duration"]
            );
            //

            _nextMarkerData["gDirection"] = nextMarkerGDirection || null;
          }
        }

        // browser not suppport
        // this.isNetworkConnection();
        //
        this.resolve({
          newData: _formData,
          newNextMarker: _nextMarkerData,
        });
      } catch (error) {
        console.error("Create Marker Fail");
        console.log(error);
      }

      this.setMiniMapClear();
      this.$store.dispatch(
        `${this.vuexStoreName}/updateCreateGMapActive`,
        false
      );

      this.disabledForm = false;
      this.disabledSaveBtn = true;
      this.initSearch = false;
      this.dialogLoading = false;
      this.show = false;
    },
    checkDuplicatePlaceId(_placeId) {
      if (this.mode === 1) {
        // edit
        if (_placeId === this.currentPlaceIdMarker) {
          // same old
          return true;
        } else if (this.placeIdList.includes(_placeId)) {
          // false
          return false;
        }
      } else {
        // insert
        if (this.placeIdList.includes(_placeId)) {
          return false;
        }
      }
      return true;
    },
    // for autocomplete
    autoCompleteProcessFindPlace(placeData) {
      if (_.isNil(placeData)) {
        // Finding Error
        this.selectedPlace = null;
        this.places = [];
        this.marker = null;
        return;
      }
      // auto complete
      this.places = placeData;
    },
    // Enter and search
    async autoCompleteEnterToSearch(event) {
      if (event.code == "Enter") {
        if (!this.initSearch) {
          this.initSearch = true;
        }
        if (this.searchValues.length === 0) {
          this.searchboxLoading = false;
          return;
        }

        if (this.initComponent) {
          this.initComponent = false;
        }

        this.searchboxLoading = true;
        this.selectedPlace = null;
        let respData = await this.$refs.createMarkerMap.getAutoCompleteGService(
          this.searchValues
        );
        this.autoCompleteProcessFindPlace(respData);
        this.searchboxLoading = false;
      }
    },
    // main:autoCompleteSelectAction when click
    async autoCompleteSelectAction(indexData) {
      this.disabledForm = true;
      this.disabledSaveBtn = true;
      this.errorReMarkerText = false;
      try {
        // if 0 is no data
        let placeDataIdx = 0;
        let selectFromPlaceList = false;
        if (indexData > 0) {
          placeDataIdx = indexData - 1;
          selectFromPlaceList = true;
        }

        if (selectFromPlaceList) {
          let _place = _.cloneDeep(this.places[placeDataIdx]);

          // set maker
          this.marker = null;
          // save place to buffer
          if (this.bufferPlaces.hasOwnProperty(_place.place_id) === false) {
            // call api get place detail
            _place["gplaceGeocode"] =
              await this.$refs.createMarkerMap.getPlaceUsePlaceIdByGService({
                placeId: _place.place_id,
              });

            this.bufferPlaces[_place.place_id] = _place;
          } else {
            // get from buffer
            _place = this.bufferPlaces[_place.place_id];
          }
          // end buffer

          if (!this.checkDuplicatePlaceId(_place.place_id)) {
            this.disabledSaveBtn = true;
            this.errorReMarkerText = true;
            return;
          }

          if (_.isNil(_.get(_place, "gplaceGeocode", null))) {
            throw new Error("place emtpy:: Network fail");
          }

          // query direction from google
          let _gDirection = null;
          if (!_.isNil(this.lastMarkerData)) {
            let _options = this._processDrivingOption();
            _gDirection = await this._calculateGoogleDirection(
              this.lastMarkerData.coordinates,
              _place.gplaceGeocode.geometry.location,
              _options
            );
          }

          // set form data
          this.formData = {
            ...this.formData,
            gplaceId: _place.place_id,
            name: _place.structured_formatting.main_text,
            coordinates: [
              _place.gplaceGeocode.geometry.location.lat(),
              _place.gplaceGeocode.geometry.location.lng(),
            ],
            duration: !_.isNil(_gDirection)
              ? getDurationFromResponseDataMapService(_gDirection)
              : null,
            distance: !_.isNil(_gDirection)
              ? getDistanceFromResponseDataMapService(_gDirection)
              : null,
            gplaceData: _.cloneDeep(_place),
            gplaceGeocode: _.cloneDeep(_place.gplaceGeocode),
            gDirection: _.cloneDeep(_gDirection),
          };

          this.oriformData = _.cloneDeep(this.formData);
          this.kmDistance = processMetertoKmDistanceCeil(
            this.formData.distance
          );
          this.minuteDuration = processSecondtoMinuteDurationCeil(
            this.formData.duration
          );

          // set marker and center of map
          this.setMarkerOnMap(
            _place.place_id,
            _place.gplaceGeocode.geometry.location
          );
          this.setCenterOnMap(_place.gplaceGeocode.geometry.location);
        } else {
          this.marker = null;
          // set form data
          const _gPlaceId = `TIME:${new Date().getTime()}`;
          const defaultLnt = 13.5000052;
          const defaultLng = 100.5974251;
          this.formData = {
            ...this.formData,
            gplaceId: _gPlaceId,
            name: "ไม่มีตำแหน่ง",
            coordinates: [defaultLnt, defaultLng],
            duration: 1,
            distance: 1,
            gplaceData: null,
            gplaceGeocode: null,
            gDirection: null,
          };
          this.oriformData = _.cloneDeep(this.formData);
          this.kmDistance = 0.001;
          this.minuteDuration = 0.01;
          //
          const lngLatPosition =
            this.$refs.createMarkerMap.convertLatLngByGService(
              defaultLnt,
              defaultLng
            );
          this.setMarkerOnMap(_gPlaceId, lngLatPosition);
          this.setCenterOnMap(lngLatPosition);
        }

        //
        this.disabledSaveBtn = false;
        this.disabledForm = false;
      } catch (error) {
        this.errorReMarkerText = true;
      }
    },
    // handler lag and lon change.
    checkLocationAction(keyName) {
      let selectedIndex = keyName === "lat" ? 0 : 1;
      this.refreshMapMarkerFromTextInputChange(selectedIndex);
    },
    // refreshMapMarker() when latitude and longtude change.
    async refreshMapMarkerFromTextInputChange(selectedIndex) {
      try {
        // check parseing
        let _lat = parseFloat(this.formData.coordinates[0]);
        let _lng = parseFloat(this.formData.coordinates[1]);
        let checkValue = selectedIndex === 0 ? _lat : _lng;
        if (_.isNaN(_lat) || _.isNaN(_lng)) {
          this.oriformData["coordinates"][selectedIndex] = null;
          throw new Error("not parseFloat");
        }
        this.formData.coordinates = [_lat, _lng];
        // Check condition: check same data.
        if (this.oriformData.coordinates[selectedIndex] === checkValue) {
          return 0;
        }

        const processMarkerStatus = await this.processMarkerFromInputChange();
        if (!processMarkerStatus) {
          throw new Error("Process Marker Status failed");
        }
        this.disabledSaveBtn = false;
        this.errorReMarkerText = false;
      } catch (error) {
        console.error(`${error}`);
        this.disabledSaveBtn = true;
        this.errorReMarkerText = true;
      }
    },
    async processMarkerFromInputChange() {
      try {
        this.marker = null;

        let _googleLatLng =
          this.$refs.createMarkerMap.processListOfLocationToGoogleObject(
            this.formData.coordinates
          );

        // set form data
        const gplaceGeocode =
          await this.$refs.createMarkerMap.getPlaceUsePlaceIdByGService({
            location: _googleLatLng,
          });

        if (gplaceGeocode) {
          // find direction
          let _gDirection = null;
          if (!_.isNil(this.lastMarkerData)) {
            const _options = this._processDrivingOption();
            _gDirection = await this._calculateGoogleDirection(
              this.lastMarkerData.coordinates,
              gplaceGeocode.geometry.location,
              _options
            );
          }

          // set origin data
          this.oriformData = _.cloneDeep(this.formData);
          //
          this.formData = {
            ...this.formData,
            gplaceId: `TIME:${new Date().getTime()}`,
            duration: !_.isNil(_gDirection)
              ? getDurationFromResponseDataMapService(_gDirection)
              : null,
            distance: !_.isNil(_gDirection)
              ? getDistanceFromResponseDataMapService(_gDirection)
              : null,
            gplaceData: null,
            gplaceGeocode: _.cloneDeep(gplaceGeocode),
            gDirection: _.cloneDeep(_gDirection),
          };
          // set kmDistance and minuteDuration
          this.kmDistance = processMetertoKmDistanceCeil(
            this.formData.distance
          );
          this.minuteDuration = processSecondtoMinuteDurationCeil(
            this.formData.duration
          );

          // set new marker
          this.setMarkerOnMap(this.formData.gplaceId, _googleLatLng);
          // set center of map
          this.setCenterOnMap(_googleLatLng);
          return true;
        } else if (this.allowZeroLntLng && _.isNil(gplaceGeocode)) {
          this.oriformData = _.cloneDeep(this.formData);
          this.formData = {
            ...this.formData,
            gplaceId: `TIME:${new Date().getTime()}`,
            duration: null,
            distance: null,
            gplaceData: null,
            gplaceGeocode: null,
            gDirection: null,
          };
          return true;
        } else {
          throw new Error("Error Loading Geocode data");
        }
      } catch (error) {
        console.error(`Process Marker From Input Change Failed ${error}`);
        return false;
      }
    },
    // setFormData and get direaction
    _processDrivingOption() {
      // origin => last marker
      // destination => current process marker
      // process date posix and set gOption
      if (this.dialogDataShouldCalculateDepartureTime) {
        this.formData["gDirectionOption"]["drivingOptions"]["departureTime"] =
          this.calculateGDirectionDepartureTime(new Date());
      } else if (!_.isNil(this.lastMarkerData)) {
        this.formData["gDirectionOption"]["drivingOptions"]["departureTime"] =
          this.calculateGDirectionDepartureTime(
            this.lastMarkerData["estimateArrivalTimePosix"]
          );
      }

      return { ...this.formData["gDirectionOption"] };
    },
    calculateGDirectionDepartureTime(date) {
      // date is posix utc date
      const nowDate = new Date();
      if (nowDate.valueOf() < date) {
        return moment(date).utc().toDate();
      } else {
        return moment()
          .add(60 * 10, "s", true)
          .utc()
          .toDate();
      }
    },
    async _calculateGoogleDirection(origin, destination, _options) {
      let resp = await this.$refs.createMarkerMap.getDirectionByGService(
        this.$refs.createMarkerMap.processListOfLocationToGoogleObject(origin),
        this.$refs.createMarkerMap.processListOfLocationToGoogleObject(
          destination
        ),
        _options
      );
      //
      if (resp["status"] === "OK") {
        return resp["data"];
      } else {
        // console.log(resp, this.formData);
        console.log(null);
      }
    },
    setMarkerOnMap(_id, _googleLatLng) {
      if (this.marker === null) {
        this.marker = {
          placeId: _id,
          location: {
            geometry: {
              location: _googleLatLng,
            },
          },
          isRawLocation: false,
        };
      }
    },
    setCenterOnMap(_googleLatLng) {
      this.$refs.createMarkerMap.setCenterFromLocation(_googleLatLng, false);
    },
    // clear form
    setFormData() {
      // set marker
      this.marker = null;
      this.searchValues = "";
      this.places = [];
      this.resetFormData();
      this.kmDistance = null;
      this.minuteDuration = null;

      if (this.mode >= 1) {
        this.currentPlaceIdMarker = this.selectedMarkerData["gplaceId"];
        // this edit mode
        this.formData = {
          ...this.selectedMarkerData,
        };
        this.oriformData = _.cloneDeep(this.formData);

        // set:: kmDistance and minuteDuration
        this.kmDistance = processMetertoKmDistanceCeil(this.formData.distance);
        this.minuteDuration = processSecondtoMinuteDurationCeil(
          this.formData.duration
        );

        // set save save btn to available
        if (this.mode <= 1) {
          this.disabledSaveBtn = false;
          this.disabledForm = false;
        }
      }
      this.dialogLoading = false;
    },
    resetFormData() {
      this.formData = _.cloneDeep(this.defaultFormData);
    },
    async setDirectionOption() {
      if (
        !_.isNil(this.lastMarkerData) &&
        !_.isNil(this.formData["gplaceId"])
      ) {
        let _options = this._processDrivingOption();
        let _gDirection = await this._calculateGoogleDirection(
          this.lastMarkerData.coordinates,
          this.formData["coordinates"],
          _options
        );

        this.formData["duration"] = !_.isNil(_gDirection)
          ? getDurationFromResponseDataMapService(_gDirection)
          : null;

        this.formData["distance"] = !_.isNil(_gDirection)
          ? getDistanceFromResponseDataMapService(_gDirection)
          : null;

        this.formData["gDirection"] = _.cloneDeep(_gDirection);
        this.oriformData = _.cloneDeep(this.formData);

        // set:: kmDistance and minuteDuration
        this.kmDistance = processMetertoKmDistanceCeil(this.formData.distance);
        this.minuteDuration = processSecondtoMinuteDurationCeil(
          this.formData.duration
        );
      }
    },
    drapDropAction(movingMarkerEvent) {
      // moving marker event
      if (this.mode === 2) {
        return;
      }

      let _position = _.get(movingMarkerEvent, "position", null);
      try {
        if (!_.isNil(_position)) {
          this.formData.coordinates = [_position.lat(), _position.lng()];
          this.processMarkerFromInputChange();
        }
      } catch (error) {
        console.error(error);
      }
    },
    setMiniMapClear() {
      this.miniMapInitCount = 0;
      if (this.miniMapInitIntervalID !== undefined) {
        clearInterval(this.miniMapInitIntervalID);
        this.miniMapInitIntervalID = undefined;
      }
    },
    setMiniMapInit() {
      if (this.mode < 1) {
        this.setMiniMapClear();
      } else {
        this.miniMapInitCount = 0;
        if (this.miniMapInitIntervalID === undefined) {
          this.miniMapInitIntervalID = setInterval(() => {
            if (
              this.$store.state[`${this.vuexStoreName}`]["createGMapActive"] ===
              true
            ) {
              if (this.mode >= 1) {
                const _setMarker = () => {
                  this.setMarkerOnMap(
                    this.selectedMarkerData.gplaceId,
                    this.$refs.createMarkerMap.processListOfLocationToGoogleObject(
                      this.selectedMarkerData.coordinates
                    )
                  );

                  this.$refs.createMarkerMap.setCenterFromLocation(
                    this.$refs.createMarkerMap.processListOfLocationToGoogleObject(
                      this.selectedMarkerData.coordinates
                    ),
                    false
                  );
                };

                try {
                  _setMarker();
                  this.setMiniMapClear();
                } catch (error) {
                  console.error(error);
                  this.setMiniMapClear();
                }
              }
            } else {
              this.miniMapInitCount += 1;
              if (this.miniMapInitCount > this.miniMapInitMaxCount) {
                this.setMiniMapClear();
              }
            }
          }, 1000);
        }
      }
    },
  },
  watch: {
    selectedPlace: function (newValue) {
      if (!_.isNil(newValue)) {
        this.autoCompleteSelectAction(newValue);
      } else {
        // clear form if user unselect
        this.resetFormData();
        this.disabledSaveBtn = true;
        this.disabledForm = true;
      }
    },
    // "$store.state.manageRoute.createGMapActive": function () {
    //   if (this.$store.state.manageRoute.createGMapActive === true) {
    //     console.log(
    //       "open modal",
    //       this.$store.state.manageRoute.createGMapActive
    //     );
    //     // is edit or view mode
    //     if (this.mode >= 1) {
    //       const _setMarker = () => {
    //         this.setMarkerOnMap(
    //           this.selectedMarkerData.gplaceId,
    //           this.$refs.createMarkerMap.processListOfLocationToGoogleObject(
    //             this.selectedMarkerData.coordinates
    //           )
    //         );

    //         this.$refs.createMarkerMap.setCenterFromLocation(
    //           this.$refs.createMarkerMap.processListOfLocationToGoogleObject(
    //             this.selectedMarkerData.coordinates
    //           ),
    //           false
    //         );
    //       };
    //       // marker setting
    //       // https://stackoverflow.com/questions/61061763/why-is-this-refs-undefined-in-vue-child-component
    //       // TODO:: check it later
    //       try {
    //         _setMarker();
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }
    //   }
    // },
  },
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapMarkerById,
    AppOverlay,
    CustomTextInputImprove,
  },

  destroyed() {
    this.setMiniMapClear();
    this.$store.dispatch(`${this.vuexStoreName}/updateCreateGMapActive`, false);
    this.disabledForm = false;
    this.disabledSaveBtn = false;
    this.initSearch = false;
    this.dialogLoading = false;
    this.show = false;
  },
};
</script>

<style scoped>
.v-dialog__content {
  align-items: start !important;
}
</style>
