<template>
  <div :class="[{ white: !isPermissionCanView }]">
    <v-container v-if="isPermissionCanView" fluid class="pb-6">
      <v-row class="mb-1">
        <v-col cols="auto">
          <div class="text-h5 mt-1">รายการใบตรวจสภาพ</div>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" sm="3">
          <AppAutoCompleteUserSearching
            :key="componentRefreshCounter"
            :tableLoading="isLoading"
            ref="AppAutoCompleteUserSearchingRef"
            @searchItemChange="getSearchItem"
            :isDark="false"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <AppAutoCompleteVehicleSearching
            :key="componentRefreshCounter"
            :tableLoading="isLoading"
            ref="AppAutoCompleteVehicleSearchingRef"
            @searchItemChange="getSearchVehicleItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>

            <VehicleCheckListFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-col
        class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
      >
        <v-card class="d-flex flex-row py-0" flat tile width="320" height="40">
          <v-select
            class="app-custom-input"
            :items="sortList"
            label="เรียงลำดับโดย"
            dense
            outlined
            v-model="sortVal"
            @change="tableSortHandler"
          ></v-select>
        </v-card>
        <v-divider class="mx-3 mb-2" vertical></v-divider>
        <VehicleCheckListFilterStatus
          :key="componentRefreshCounter"
          :value="statusFilterValue"
          @input="processStatus"
        />

        <v-spacer></v-spacer>
        <NormalButton icon="mdi-refresh" @on-click="reset()">
          รีเซ็ต
        </NormalButton>
      </v-col>
      <v-list v-if="vehicleCheckListDataList.length > 0" class="pb-0 list_main">
        <v-list-item
          class="px-0"
          v-for="(item, index) in vehicleCheckListDataList"
          :key="index"
        >
          <v-card class="mb-4 pr-5" width="100%">
            <v-row>
              <v-col cols="9" class="pb-8 pt-4">
                <v-row no-gutters class="ml-4">
                  <v-list-item-content>
                    <div>
                      <v-list-item-title
                        class="text-app-detail-large font-weight-bold"
                      >
                        {{
                          `${
                            item.checkerSalutation === null
                              ? ""
                              : item.checkerSalutation.th
                          }${
                            item.checkerFirstName === null
                              ? ""
                              : item.checkerFirstName.th
                          } ${
                            item.checkerLastName === null
                              ? ""
                              : item.checkerLastName.th
                          } `
                        }}
                        {{
                          item.checkerNickName !== null
                            ? item.checkerNickName.th !== null
                              ? `(${item.checkerNickName.th})`
                              : "-"
                            : "-"
                        }}</v-list-item-title
                      >
                    </div>
                    <div class="mt-2">
                      <span class="text-app-normal mr-6">
                        <v-icon class="mr-1">mdi-folder-plus-outline</v-icon
                        >สร้างเมื่อ:
                        {{
                          item.createdDate
                            ? dateStringToDateFormat(item.createdDate, {
                                type: "datetime",
                              })
                            : "-"
                        }}
                      </span>
                      <span class="text-app-normal mr-6">
                        <v-icon class="mr-1">mdi-pencil-outline</v-icon
                        >อัพเดทเมื่อ:
                        {{
                          item.updatedDate
                            ? dateStringToDateFormat(item.updatedDate, {
                                type: "datetime",
                              })
                            : "-"
                        }}
                      </span>
                      <span class="text-app-normal">
                        <v-icon class="mr-1"
                          >mdi-format-list-bulleted-type</v-icon
                        >ประเภทใบตรวจ: {{ item.group }}
                      </span>
                    </div>
                  </v-list-item-content>
                </v-row>
                <v-list-item>
                  <v-row class="ml-0">
                    <v-col cols="4" class="px-0">
                      <p
                        class="ma-0 text-app-detail font-weight-bold black--text"
                      >
                        {{ item.vehicleLicenseNumber }}
                        {{ item.vehicleType }}
                      </p>
                      <span
                        class="text-app-small-detail pr-6"
                        v-if="item.vehicleLicenseTypeKey"
                      >
                        <span class="mx-0 px-0">
                          <v-icon v-if="item.vehicleLicenseTypeKey == 'WHITE'"
                            >mdi-card-text-outline</v-icon
                          >
                          <v-icon
                            v-else
                            :color="
                              vehicleLicenseTypeColor(
                                item.vehicleLicenseTypeKey
                              )
                            "
                            >mdi-card-text</v-icon
                          >
                        </span>
                        {{ item.vehicleLicenseType }}</span
                      >
                      <span
                        class="text-app-small-detail"
                        v-if="item.vehicleSeat"
                      >
                        <span class="mx-0 px-0">
                          <v-icon>mdi-car-side</v-icon>
                          {{ item.vehicleType }}
                        </span>
                        {{ item.vehicleSeat }} ที่นั่ง</span
                      >
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-col>

              <v-col cols="3" class="text-right" align-self="center">
                <v-row no-gutters>
                  <v-col cols="10" align="center" class="pb-0">
                    <!-- Status จ่ายแล้ว -->

                    <v-menu offset-y>
                      <template v-slot:activator="{ props, on }">
                        <v-card
                          v-bind="props"
                          v-on="on"
                          dark
                          outlined
                          :color="vehicleCheckListStatusCardColor(item.status)"
                          height="34"
                          width="130"
                        >
                          <div class="status_text_style">
                            {{ vehicleCheckListStatusText(item.status) }}
                          </div>
                        </v-card>
                      </template>

                      <v-list>
                        <v-card
                          elevation="0"
                          :disabled="
                            !isPermissionCanView ||
                            !isPermissionCanViewInfo ||
                            !isPermissionCanEdit
                          "
                        >
                          <v-list-item
                            v-for="(status, idx) in _vehicleCheckListStatusText"
                            :key="idx"
                            :value="idx"
                            @click="updateCheckListStatus(item._id, idx)"
                            :disabled="item.status == idx"
                          >
                            <v-list-item-title
                              :class="`text-app-title ${vehicleCheckListStatusCardColorDisabled(
                                idx,
                                item.status
                              )}`"
                            >
                              {{ status }}</v-list-item-title
                            >
                          </v-list-item>
                        </v-card>
                      </v-list>
                    </v-menu>

                    <span class="text-app-detail-normal font-weight-bold my-0">
                      {{ item.amountCheckItem }} ข้อ
                    </span>
                    |
                    <span
                      class="text-app-detail-normal font-weight-bold success--text"
                      >ผ่าน
                      <span class="success--text">{{ item.amountPassed }}</span>
                    </span>
                    |
                    <span
                      class="text-app-detail-normal font-weight-bold error--text"
                      >ไม่ผ่าน:
                      <span class="error--text">{{ item.amountFailed }}</span>
                    </span>
                  </v-col>
                  <v-col cols="2" align-self="center" class="text-right">
                    <v-btn @click="handleEditClick(item._id)" icon
                      ><v-icon> mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-list-item>
      </v-list>
      <v-row v-if="!isLoading && vehicleCheckListDataList.length === 0">
        <v-col cols="12">
          <div dense class="text-center text-app-detail-md" color="#222831">
            <p>ไม่มีข้อมูล</p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isLoading">
        <v-col cols="12">
          <div dense class="text-center text-app-detail-md" color="#222831">
            <p>กำลังดึงข้อมูล</p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!isLoading">
        <v-col cols="12">
          <div class="text-center">
            <v-pagination
              v-model="queryOptionsTable.page"
              :length="pageDataLength"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
      <!-- <vehicleCheckListSummaryDetail ref="vehicleCheckListSummaryDialog" /> -->
    </v-container>
    <AppOverlay :absolute="false" :isLoading="isLoading" />
    <AppPageNotFound :show="isPermissionCanView" />
    <VehicleCheckListDetail ref="vehicleCheckListDetailDialog" />
  </div>
</template>

<script>
import VehicleCheckListDetail from "./VehicleCheckListDetail.vue";
// import VehicleCheckListSummaryDetail from "./worksheet_summary_detail/VehicleCheckListSummaryDetail.vue";
import VehicleCheckListFilterGroup from "./components/VehicleCheckListFilterGroup.vue";
import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
import VehicleCheckListFilterStatus from "./components/VehicleCheckListFilterStatus.vue";
import NormalButton from "@/components/NormalButton.vue";
import { processPermission } from "@/services/permissions";
import { dateStringToDateFormat } from "@/services/appDate";
import AppOverlay from "@/components/AppOverlay.vue";
import defaultImage from "@/assets/user.png";
import AppAutoCompleteWorksheetSearching from "@/components/AppWorksheetSearching.vue";
import AppAutoCompleteVehicleSearching from "@/components/AppAutoCompleteVehicleSearching.vue";
import {
  getVehicleCheckListService,
  putVehicleCheckListService,
} from "@/services/api/vehicle_checklist";
import { mapGetters } from "vuex";
import AppPageNotFound from "@/components/AppPageNotFound.vue";

export default {
  name: "vehicleCheckListSummaryList",

  components: {
    AppOverlay,
    VehicleCheckListDetail,
    AppAutoCompleteUserSearching,
    VehicleCheckListFilterGroup,
    VehicleCheckListFilterStatus,
    AppAutoCompleteVehicleSearching,
    NormalButton,
    AppAutoCompleteWorksheetSearching,
    AppPageNotFound,
  },

  data() {
    return {
      isLoading: false,
      componentRefreshCounter: 0,
      vehicleCheckListDataList: [],
      queryFilterList: {},
      queryStringSearch: "", // combin filter and search text
      querySearchAndFilterList: {}, // combine filter and search
      querySearchAndFilterListBackground: {},
      statusFilterValue: "ALL",
      pageDataLength: 0,
      currentPage: 1,

      queryOptionsTable: {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      },
      sortList: [
        {
          text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
          value: "createddate-asc",
        },
        {
          text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
          value: "createddate-desc",
        },
        {
          text: "ชื่อ-นามสกุล เรียงจาก A-Z",
          value: "name-asc",
        },
        {
          text: "ชื่อ-นามสกุล เรียงจาก Z-A",
          value: "name-desc",
        },
        // {
        //   text: "ยอดสุทธิ เรียงจาก A-Z",
        //   value: "netAmount-asc",
        // },
        // {
        //   text: "ยอดสุทธิ เรียงจาก Z-A",
        //   value: "netAmount-desc",
        // },
      ],
      financialDocURL: null,
      checkboxData: {
        thisMonth: 0,
      },
      sortVal: null,
      isShowMenu: false,
      permList: {
        admin_all: false,
      },
      defaultPFImage: defaultImage,
      editTextBtn: "แก้ไข",
      rejectTextBtn: "ปฏิเสธ",
      isPermissionCanEdit: true,
      isPermissionCanView: true,
      isPermissionCanViewInfo: true,
      shiftedData: [],
      countShifted: 0,
    };
  },
  watch: {
    "queryOptionsTable.page": function (newVal) {
      this.currentPage = newVal;
      this.vehicleCheckListDataList = [];
      this.tablePageHandler(newVal);
      // this.getData();
    },
  },
  computed: {
    ...mapGetters({
      vehicleCheckListStatusColor:
        "vehicle_checklist/vehicleCheckListStatusColor",
      vehicleCheckListStatusCardColor:
        "vehicle_checklist/vehicleCheckListStatusCardColor",
      vehicleCheckListStatusText:
        "vehicle_checklist/vehicleCheckListStatusText",
    }),
    _vehicleCheckListStatusText: function () {
      return this.$store.state.vehicle_checklist.vehicleCheckListStatusText;
    },
  },
  mounted() {
    // this.checkUserPermissions();
    if (this.isPermissionCanView) {
      this.processRouterQuery(this.$route.query);
    }
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    setRouterQuery(queryObj) {
      this.$router
        .replace({
          path: this.$router.path,
          query: queryObj,
        })
        .catch(() => {});
    },
    setQueryStringSearch(queryObj) {
      this.queryStringSearch = new URLSearchParams(queryObj).toString();
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "PAYMENT");
        // TODO::
        // if (this.permList.payment_list === true) {
        //   this.isPermissionCanView = true;
        // }

        // if (this.permList.payment_report === true) {
        //   this.isPermissionCanView = true;
        //   // this.isPermissionCanEdit = true;
        //   this.isPermissionCanViewInfo = true;
        //   this.editTextBtn = "ดูข้อมูล";
        // }

        // if (
        //   this.permList.admin_all === true ||
        //   this.permList.payment_crud === true
        // ) {
        //   this.isPermissionCanView = true;
        //   this.isPermissionCanViewInfo = true;
        //   this.isPermissionCanEdit = true;
        //   this.editTextBtn = "แก้ไข";
        // }
      }
    },

    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "limit":
              // this.queryOptionsTable[key] = 2;
              // break;
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.setRouterQuery(this.querySearchAndFilterList);
    },

    async getData() {
      this.isLoading = true;
      this.vehicleCheckListDataList = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getVehicleCheckListService(
        this.$store,
        this.queryStringSearch
      );

      if (responseData.code == 200) {
        //
        this.serverItemsLength = responseData.data["total"];
        //
        let checklist = responseData.data["paginatedResults"];
        this.vehicleCheckListDataList = _.cloneDeep(checklist);
        this.pageDataLength = Math.ceil(
          responseData.data["total"] / this.queryOptionsTable["limit"]
        );
      } else {
        this.vehicleCheckListDataList = [];
      }
      this.isLoading = false;
    },

    async getShiftedData() {
      let shiftedData = [];

      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterListBackground
      ).toString();

      let responseData = await getVehicleCheckListService(
        this.$store,
        this.queryStringSearch
      );
      if (responseData.code == 200) {
        shiftedData = _.cloneDeep(responseData.data["paginatedResults"]);
      }
      return shiftedData;
    },

    async handleEditClick(id) {
      const respVehicleCheckList =
        await this.$refs.vehicleCheckListDetailDialog.open(id);
      if (!_.isNil(respVehicleCheckList)) {
        if (
          !_.isNil(respVehicleCheckList["id"]) &&
          !_.isNil(respVehicleCheckList["status"])
        ) {
          let editIndex = this.vehicleCheckListDataList.findIndex(
            (x) => x["_id"] == respVehicleCheckList["id"]
          );
          this.vehicleCheckListDataList[editIndex]["status"] =
            respVehicleCheckList["status"];
        }
      } else {
        // insert fail
      }
    },

    async processShiftingItem(editIndex) {
      let _queryParameter = {};

      _queryParameter["skip"] =
        this.queryOptionsTable["limit"] * this.currentPage - 1;
      _queryParameter["limit"] = 1;

      this.processSearchingParamsBackground(_queryParameter);
      this.shiftedData = await this.getShiftedData();

      if (!_.isNil(this.shiftedData)) {
        this.vehicleCheckListDataList.splice(editIndex, 1);
        if (this.shiftedData.length > 0) {
          this.vehicleCheckListDataList.push(this.shiftedData[0]);
        }

        this.countShifted += 1;

        this.pageDataLength = Math.ceil(
          (this.serverItemsLength - this.countShifted) /
            this.queryOptionsTable["limit"]
        );

        if (!_.isNil(this.vehicleCheckListDataList)) {
          if (
            this.pageDataLength > 0 &&
            this.vehicleCheckListDataList.length === 0
          ) {
            this.queryOptionsTable["page"] -= 1;
          }
        }
      }
    },

    async handleDownloadPDF(id) {
      this.isLoading = true;
      if (!_.isNil(id)) {
        let queryString = { id: id };
        let respData = await postDownloadPDFService(this.$store, queryString);
        //
        if (respData["code"] === 200) {
          this.financialDocURL = respData["data"]["pdfURL"];
          window.open(this.financialDocURL, "_blank");
          // await this.getData();
        } else {
          // insert fail
        }
      }
      this.isLoading = false;
    },

    changeValueOfCheckboxStatus() {
      let searchObj = {};
      Object.keys(this.checkboxData).forEach((key) => {
        if (this.checkboxData[key]) {
          searchObj[key] = "1";
        } else {
          delete this.querySearchAndFilterList[key];
        }
      });

      this.querySearchAndFilterList["page"] = 1;

      this.processSearchingParams(searchObj);
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();

      this.tablePageHandler(1);
    },

    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },

    // === end action button of table
    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    //
    // search method scope
    //
    processSearchingParams(_queryParameter) {
      // combine query search
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      this.setRouterQuery(this.querySearchAndFilterList);
    },

    processSearchingParamsBackground(_queryParameter) {
      // combine query search
      this.querySearchAndFilterListBackground = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
    },

    processStatus(newStatusVal) {
      this.vehicleCheckListDataList = [];
      this.statusFilterValue = newStatusVal;
      this.countShifted = 0;

      if (_.isNil(this.statusFilterValue)) {
        // remove from list
        delete this.querySearchAndFilterList["status"];
        return;
      }

      delete this.querySearchAndFilterList["skip"];

      this.queryOptionsTable["page"] = 1;
      this.querySearchAndFilterList["status"] = this.statusFilterValue;
      if (this.querySearchAndFilterList["status"] === "ALL") {
        // remove from list
        delete this.querySearchAndFilterList["status"];
      }
      // this.setQueryStringSearch(this.querySearchAndFilterList);
      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },

    getSearchItemByPaymentName(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["codeSearch"]; // id
      let _queryParameter = {};

      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["codeSearch"]) {
          // is true => select at
          _queryParameter["codeSearch"] = _searchingParameter.value;
        } else if (
          !_searchingParameter["codeSearch"] &&
          !_.isNil(_searchingParameter.value)
        ) {
          if (_searchingParameter.value.length > 0) {
            // not empty
            _queryParameter["codeSearch"] = _searchingParameter.value;
          }
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    async updateCheckListStatus(_id, status) {
      this.isLoading = true;
      const sendingFormData = {
        status: status,
      };
      if (!_.isNil(status)) {
        let respData = await putVehicleCheckListService(
          this.$store,
          _id,
          sendingFormData
        );
        if (respData["code"] === 200) {
          let editIndex = this.vehicleCheckListDataList.findIndex(
            (x) => x["_id"] == _id
          );
          this.vehicleCheckListDataList[editIndex]["status"] = status;
        } else {
        }
      }
      this.isLoading = false;
    },

    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["strSearch"]; // id
      delete this.querySearchAndFilterList["strSearch"]; // name
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (_searchingParameter["strSearch"]) {
          // is true => select at
          _queryParameter["strSearch"] = _searchingParameter.key;
        } else if (
          !_searchingParameter["strSearch"] &&
          !_.isNil(_searchingParameter.key)
        ) {
          if (_searchingParameter.key.length > 0) {
            // not empty
            _queryParameter["strSearch"] = _searchingParameter.key;
          }
        } else if (!_.isNil(_searchingParameter["value"])) {
          _queryParameter["strSearch"] = _searchingParameter["value"];
        }
      }

      // _searchingParameter

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    vehicleLicenseTypeColor: function (licenseType) {
      return this.$store.getters["vehicle/vehicleLicenseTypeColor"](
        licenseType
      );
    },

    getSearchVehicleItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["vehicleLicense"];
      delete this.querySearchAndFilterList["vehicleCassie"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["key"])) {
          _queryParameter["vehicleCassie"] = _searchingParameter["key"];
          // _queryParameter["vehicleLicense"] = _searchingParameter["value"];
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["vehicleLicense"] = _searchingParameter["value"];
          }
        }
      }

      this.processSearchingParams(_queryParameter);
      this.getData();
    },

    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //
      if (!_.isEmpty(this.queryFilterList)) {
        Object.keys(this.queryFilterList).forEach((key) => {
          delete this.querySearchAndFilterList[key];
        });
      }
      this.queryOptionsTable["page"] = 1;
      this.queryFilterList = _queryParameter;

      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    vehicleCheckListStatusCardColorDisabled(status, disabledStatus) {
      if (status === disabledStatus) {
        return "borderColor--text";
      } else {
        return `${this.vehicleCheckListStatusCardColor(status)}--text`;
      }
    },
    //
    //  end method scope
    //
    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.currentPage = 1;
      this.shiftedData = [];
      this.countShifted = 0;
      this.processSearchingParams();
    },
  },
};
</script>

<style scoped>
#WSSummaryList {
  display: flex;
  height: 100%;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  background-color: #222831;
}

.list_main {
  background-color: #f2f5f9;
}

.one_row_only {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name_style {
  font-size: 20px;
  font-weight: 500;
}

.subtitle_style {
  font-size: 14px;
  font-weight: normal;
  /* color: #8a96a4; */
}

.value_style {
  padding-top: 6px;
  font-size: 14px;
  font-weight: bold;
  /* color: #fcfdfd; */
}

.status_paid_style {
  border: 2px solid #6ecc95;
  background-color: rgba(110, 204, 149, 0.3);
}

.status_not_yet_paid_style {
  border: 2px solid #8a96a4;
  background-color: rgba(138, 150, 164, 0.3);
}

.status_text_style {
  font-size: 16px;
  /* font-weight: 600; */
  /* color: #ffffff; */
  text-align: center;
  padding-top: 4px;
}

.total_money_style {
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
}
</style>
