<template>
	<div name="homeView">
		<v-col cols="auto" v-if="isPermissionCanView">
			<!-- <h1 class="headline mt-1">ภาพรวม</h1> -->
			<div class="text-h5 mt-1">
				ภาพรวมของวันที่:
				{{
					dateStringToDateFormat(currentDate, {
						returnThai: false,
						type: "date",
					}) || "-"
				}}
			</div>
			<!-- <v-btn to="/example"> Example view </v-btn>
        <v-btn to="/gps-csv"> Csv Gps View </v-btn> -->
			<!-- <img :src="QRData" /> -->
		</v-col>
		<!-- <v-spacer></v-spacer> -->
		<!-- <v-col cols="auto">
        <PrimaryButton :large="true" @on-click="handleScheduleClick()">
          บุคคลและงาน
        </PrimaryButton>
      </v-col> -->
		<v-container fluid v-if="isPermissionCanView">
			<v-row no-gutters>
				<v-col cols="12">
					<div class="mb-3">
						<CustomCardFormViewModern
							title="งาน"
							:isShowTitle="true"
							:isAddTitleMargin="true"
						>
							<v-row no-gutters class="px-3">
								<v-col cols="12" class="px-3 mt-3">
									<v-row no-gutters>
										<v-col cols="4" class="px-0">
											<div class="mt-6 mb-3 text-center">
												<span
													class="dashboard-large font-weight-bold black--text"
													>{{
														jobNumberStatsAmount
															? jobNumberStatsAmount["total"]
																? jobNumberStatsAmount["total"]
																: 0
															: 0
													}}</span
												>
											</div>
											<div class="pl-0 text-center">
												<span class="dashboard-small px-1"
													>จำนวนงานทั้งหมด</span
												>
											</div>
										</v-col>
										<v-col cols="4" class="px-0">
											<div class="mt-6 mb-3 text-center">
												<span
													class="dashboard-large font-weight-bold black--text"
													>{{
														jobNumberStatsAmount
															? jobNumberStatsAmount["success"]
																? jobNumberStatsAmount["success"]
																: 0
															: 0
													}}</span
												>
											</div>
											<div class="pl-0 text-center">
												<span class="dashboard-small px-1">ทำไปแล้ว</span>
											</div>
										</v-col>
										<v-col cols="4" class="px-0">
											<div
												class="new-tab text-center"
												@click="handlePreparingJobOnClick"
											>
												<div class="mt-6 mb-3">
													<span
														class="dashboard-large font-weight-bold black--text"
														>{{
															jobNumberStatsAmount
																? jobNumberStatsAmount["left"]
																	? jobNumberStatsAmount["left"]
																	: 0
																: 0
														}}</span
													>
												</div>
												<div class="pl-0 text-center">
													<span class="dashboard-small px-1">คงเหลือ</span>
													<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
												</div>
											</div>
										</v-col>
									</v-row>
									<v-divider class="mx-1 my-3" />
									<v-row no-gutters class="px-2 pb-0 mb-0">
										<v-col cols="2" class="mt-3">
											<span class="mr-2">
												<div class="mt-3 ml-0">
													<span
														v-if="jobNumberStatsStatus"
														class="dashboard-small font-weight-bold black--text"
														>{{
															jobNumberStatsStatus["PLANNING"]
																? jobNumberStatsStatus["PLANNING"]
																: 0
														}}</span
													>
													<span
														v-else
														class="dashboard-small font-weight-bold black--text"
														>0</span
													>
												</div>
												<div>
													<span :class="`${jobsStatusColor('PLANNING')}--text`"
														>วางแผน
													</span>
												</div>
											</span>
										</v-col>
										<v-col cols="2" class="mt-3">
											<span class="mr-2">
												<div class="mt-3 ml-0">
													<span
														v-if="jobNumberStatsStatus"
														class="dashboard-small font-weight-bold black--text"
														>{{
															jobNumberStatsStatus["PREPARING"]
																? jobNumberStatsStatus["PREPARING"]
																: 0
														}}</span
													>
													<span
														v-else
														class="dashboard-small font-weight-bold black--text"
														>0</span
													>
												</div>
												<div>
													<span :class="`${jobsStatusColor('PREPARING')}--text`"
														>รอยืนยัน
													</span>
												</div>
											</span>
										</v-col>
										<v-col cols="2" class="mt-3">
											<span class="mr-2">
												<div class="mt-3 ml-0">
													<span
														v-if="jobNumberStatsStatus"
														class="dashboard-small font-weight-bold black--text"
														>{{
															jobNumberStatsStatus["CONFIRM"]
																? jobNumberStatsStatus["CONFIRM"]
																: 0
														}}</span
													>
													<span
														v-else
														class="dashboard-small font-weight-bold black--text"
														>0</span
													>
												</div>
												<div>
													<span :class="`${jobsStatusColor('CONFIRM')}--text`"
														>รับงานแล้ว
													</span>
												</div>
											</span>
										</v-col>
										<v-col cols="2" class="mt-3">
											<span class="mr-2">
												<div class="mt-3 ml-0">
													<span
														v-if="jobNumberStatsStatus"
														class="dashboard-small font-weight-bold black--text"
														>{{
															jobNumberStatsStatus["PROCESS"]
																? jobNumberStatsStatus["PROCESS"]
																: 0
														}}</span
													>
													<span
														v-else
														class="dashboard-small font-weight-bold black--text"
														>0</span
													>
												</div>
												<div>
													<span :class="`${jobsStatusColor('PROCESS')}--text`"
														>กำลังทำงาน
													</span>
												</div>
											</span>
										</v-col>
										<v-col cols="2" class="mt-3">
											<span class="mr-2">
												<div class="mt-3 ml-0">
													<span
														v-if="jobNumberStatsStatus"
														class="dashboard-small font-weight-bold black--text"
														>{{
															jobNumberStatsStatus["SUCCESS"]
																? jobNumberStatsStatus["SUCCESS"]
																: 0
														}}</span
													>
													<span
														v-else
														class="dashboard-small font-weight-bold black--text"
														>0</span
													>
												</div>
												<div>
													<span :class="`${jobsStatusColor('SUCCESS')}--text`"
														>จบงาน
													</span>
												</div>
											</span>
										</v-col>
										<v-col cols="2" class="mt-3">
											<span class="mr-2">
												<div class="mt-3 ml-0">
													<span
														v-if="jobNumberStatsStatus"
														class="dashboard-small font-weight-bold black--text"
														>{{
															jobNumberStatsStatus["CANCEL"]
																? jobNumberStatsStatus["CANCEL"]
																: 0
														}}</span
													>
													<span
														v-else
														class="dashboard-small font-weight-bold black--text"
														>0</span
													>
												</div>
												<div>
													<span :class="`${jobsStatusColor('CANCEL')}--text`"
														>ถูกยกเลิก
													</span>
												</div>
											</span>
										</v-col>
									</v-row>
								</v-col></v-row
							>
							<v-row no-gutters class="px-3 mb-3">
								<v-col cols="12" class="px-3">
									<div id="chart">
										<apexchart
											type="bar"
											height="250"
											:options="chartOptions"
											:series="jobNumberStatsGraphData"
										></apexchart>
									</div>
								</v-col>
							</v-row>
						</CustomCardFormViewModern>
					</div>
				</v-col>
			</v-row>

			<v-row no-gutters>
				<v-col cols="6">
					<div class="mb-3">
						<CustomCardFormViewModern
							title="การกระทำผิด"
							:isShowTitle="true"
							:isAddTitleMargin="true"
						>
							<div class="px-6 pb-0">
								<v-row no-gutters>
									<v-col cols="4" class="px-0">
										<div
											class="mt-6 pt-0 new-tab"
											@click="
												handlePenaltyTypeClick('Safety & GPS - ขับรถไม่เหมาะสม')
											"
										>
											<div class="pl-0">
												<span class="dashboard-small px-1">ขับเร็ว</span>
												<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
											</div>
											<div class="mt-8 mb-3">
												<span
													class="dashboard-large font-weight-bold black--text"
													>{{ speedingPenalty ? speedingPenalty : 0 }}</span
												>
												<span class="text-app-detail-normal"> ครั้ง</span>
											</div>
										</div>
										<div>
											<div class="mt-12 pl-0">
												<span class="dashboard-small px-1">ทั้งหมด</span>
											</div>
											<div class="mt-8 mb-3">
												<span
													class="dashboard-large font-weight-bold black--text"
													>{{ totalPenalty ? totalPenalty : 0 }}</span
												><span class="text-app-detail-normal"> ครั้ง</span>
											</div>
										</div>
									</v-col>
									<v-col cols="8" class="mb-3">
										<!-- <v-card-title class="px-0">
                      <div class="pt-2 text-title font-weight-bold black--text">
                        ทั้งหมด {{ totalPenalty ? totalPenalty : 0 }} ครั้ง
                      </div>
                    </v-card-title> -->
										<v-data-table
											:headers="tableHeaders"
											:items="penalityTypeList"
											:items-per-page="20"
											:no-data-text="dataTableNoDataText"
											:loading-text="dataTableLoadingText"
											:hide-default-footer="true"
											class="app-datatable"
											@click:row="handlePenaltyTypeRowClick"
										>
											<template v-slot:[`item.order`]="{ item }">
												<v-list-item class="px-0 new-tab">
													<v-list-item-content>
														<v-list-item-title
															class="text-app-title"
															v-if="item.order !== null"
														>
															<span>
																{{ item.order }}
															</span>
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</template>
											<template v-slot:[`item.title`]="{ item }">
												<v-list-item class="px-0 new-tab">
													<v-list-item-content>
														<v-list-item-title
															class="text-app-title"
															v-if="item.title !== null"
														>
															<span>
																{{ item.title }}
															</span>
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</template>

											<template v-slot:[`item.num`]="{ item }">
												<v-list-item class="px-0 new-tab">
													<v-list-item-content>
														<v-list-item-title
															class="text-app-title"
															v-if="item.num !== null"
														>
															<span> {{ item.num }} ครั้ง </span>
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</template>
										</v-data-table>

										<!-- <v-simple-table
                      class="pt-2 pb-6"
                      v-if="
                        penalityTypeList !== null && penalityTypeList.length > 0
                      "
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(penalty, idx) in penalityTypeList"
                            :key="idx"
                            class="new-tab"
                            @click="handlePenaltyTypeClick(penalty.title)"
                          >
                            <td>
                              {{ idx + 1 }}
                            </td>
                            <td>
                              {{ penalty.title }}
                            </td>
                            <td align="right">{{ penalty.num }} ครั้ง</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-card-text class="pa-5 text-center" v-else>
                      ไม่มีข้อมูล
                    </v-card-text> -->
									</v-col>
								</v-row>
							</div>
						</CustomCardFormViewModern>
					</div>
				</v-col>
				<v-col cols="6">
					<v-row no-gutters>
						<v-col cols="12">
							<div class="mb-3 ml-3">
								<CustomCardFormViewModern
									title="เติมน้ำมัน"
									:isShowTitle="true"
									:isAddTitleMargin="true"
								>
									<div class="px-6 pb-3">
										<v-row no-gutters>
											<v-col cols="4" lg="4" sm="6" class="px-0">
												<div class="mt-6">
													<div class="pl-0">
														<span class="dashboard-small px-1">ทั้งหมด</span>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="fuelData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																fuelData
																	? fuelData["total"]
																		? fuelData["total"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0
														</span>
														<span class="text-app-detail-normal"> ครั้ง</span>
													</div>
												</div>
											</v-col>
											<v-col cols="4" lg="4" sm="6" class="px-0">
												<div>
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">ตรวจแล้ว</span>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="fuelData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																fuelData
																	? fuelData["done"]
																		? fuelData["done"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0 </span
														><span class="text-app-detail-normal"> ครั้ง</span>
													</div>
												</div>
											</v-col>
											<v-col
												cols="4"
												lg="4"
												sm="6"
												class="new-tab px-0"
												@click="handleFuelOnClick"
											>
												<div>
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">ยังไม่ตรวจ</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="fuelData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																fuelData
																	? fuelData["process"]
																		? fuelData["process"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0 </span
														><span class="text-app-detail-normal"> ครั้ง</span>
													</div>
												</div>
											</v-col>
										</v-row>
									</div>
								</CustomCardFormViewModern>
							</div>
						</v-col>
						<v-col cols="12">
							<div class="mb-3 ml-3">
								<CustomCardFormViewModern
									title="การซ่อมบำรุงคงค้าง"
									:isShowTitle="true"
									:isAddTitleMargin="true"
								>
									<div class="px-6 pb-3">
										<v-row no-gutters>
											<v-col cols="4" lg="4" sm="6" class="px-0">
												<div class="mt-6">
													<div class="pl-0">
														<span class="dashboard-small px-1">ทั้งหมด</span>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="vehicleEventData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																vehicleEventData
																	? vehicleEventData["total"]
																		? vehicleEventData["total"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0
														</span>
														<span class="text-app-detail-normal"> รายการ</span>
													</div>
												</div>
											</v-col>
											<v-col cols="4" lg="4" sm="6" class="px-0">
												<div
													@click="handleProcessEventsOnClick"
													class="new-tab"
												>
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">รอตรวจสอบ</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="vehicleEventData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																vehicleEventData
																	? vehicleEventData["process"]
																		? vehicleEventData["process"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> รายการ</span>
													</div>
												</div>
											</v-col>
											<v-col cols="4" lg="4" sm="6" class="px-0">
												<div
													@click="handleInspectEventsOnClick"
													class="new-tab"
												>
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">รอปิดตั๋ว</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="vehicleEventData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																vehicleEventData
																	? vehicleEventData["inspect"]
																		? vehicleEventData["inspect"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> รายการ</span>
													</div>
												</div>
											</v-col>
										</v-row>
									</div>
								</CustomCardFormViewModern>
							</div>
						</v-col>
						<v-col cols="12">
							<div class="mb-3 ml-3">
								<CustomCardFormViewModern
									title="ใกล้หมดอายุ"
									:isShowTitle="true"
									:isAddTitleMargin="true"
								>
									<div class="px-6 pb-3">
										<v-row no-gutters>
											<v-col cols="3" lg="3" sm="6" class="px-0 pt-1">
												<div
													class="mt-6 new-tab"
													@click="handleInsuranceOnClick"
												>
													<div class="pl-0">
														<span class="dashboard-small px-1">ประกัน</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="almostExpiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																almostExpiredData
																	? almostExpiredData["insurance"]
																		? almostExpiredData["insurance"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														>
														<span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
											<v-col cols="3" lg="3" sm="6" class="px-0 pt-1">
												<div class="new-tab" @click="handleActOnClick">
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">พรบ</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="almostExpiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																almostExpiredData
																	? almostExpiredData["act"]
																		? almostExpiredData["act"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
											<v-col cols="3" lg="3" sm="6" class="px-0 pt-1">
												<div class="new-tab" @click="handleTaxOnClick">
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">ภาษี</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="almostExpiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																almostExpiredData
																	? almostExpiredData["tax"]
																		? almostExpiredData["tax"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
											<v-col cols="3" lg="3" sm="6" class="px-0 pt-1">
												<div class="new-tab" @click="handleTaxOnClick">
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">งวดรถ</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="almostExpiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																almostExpiredData
																	? almostExpiredData["tax"]
																		? almostExpiredData["tax"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
										</v-row>
									</div>
								</CustomCardFormViewModern>
							</div>
						</v-col>
						<v-col cols="12">
							<div class="mb-3 ml-3">
								<CustomCardFormViewModern
									title="หมดอายุ"
									:isShowTitle="true"
									:isAddTitleMargin="true"
								>
									<div class="px-6 pb-3">
										<v-row no-gutters>
											<v-col cols="3" lg="3" sm="6" class="px-0">
												<div
													class="mt-6 new-tab"
													@click="handleInsuranceOnClick"
												>
													<div class="pl-0">
														<span class="dashboard-small px-1">ประกัน</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="expiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																expiredData
																	? expiredData["insurance"]
																		? expiredData["insurance"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														>
														<span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
											<v-col cols="3" lg="3" sm="6" class="px-0">
												<div class="new-tab" @click="handleActOnClick">
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">พรบ</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="expiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																expiredData
																	? expiredData["act"]
																		? expiredData["act"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
											<v-col cols="3" lg="3" sm="6" class="px-0">
												<div class="new-tab" @click="handleTaxOnClick">
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">ภาษี</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="expiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																expiredData
																	? expiredData["tax"]
																		? expiredData["tax"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
											<v-col cols="3" lg="3" sm="6" class="px-0">
												<div class="new-tab" @click="handleTaxOnClick">
													<div class="mt-6 pl-0">
														<span class="dashboard-small px-1">งวดรถ</span>
														<v-icon class="pb-2">mdi-file-eye-outline</v-icon>
													</div>
													<div class="mt-8 mb-3">
														<span
															v-if="expiredData"
															class="dashboard-large font-weight-bold black--text"
															>{{
																expiredData
																	? expiredData["tax"]
																		? expiredData["tax"]
																		: 0
																	: 0
															}}</span
														>
														<span
															v-else
															class="dashboard-large font-weight-bold black--text"
															>0</span
														><span class="text-app-detail-normal"> คัน</span>
													</div>
												</div>
											</v-col>
										</v-row>
									</div>
								</CustomCardFormViewModern>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<EmployeeJobDialog ref="employeeJobDialogRef" />
			<AppOverlay :isLoading="isLoading" />
		</v-container>

		<v-container fluid v-if="!isPermissionCanView">
			<h2 class="ma-6 text-center">ยินดีต้อนรับ</h2>
		</v-container>
	</div>
</template>

<script>
	// import { getPassengerQRCode } from "@/services/api/jobs";
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";
	import { processPermission } from "@/services/permissions";
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import { mapGetters } from "vuex";
	import AppOverlay from "@/components/AppOverlay.vue";
	import VueApexCharts from "vue-apexcharts";
	import { dateStringToDateFormat } from "@/services/appDate";
	import moment from "moment";
	import {
		dataTableFooterProps,
		dataTableNoDataText,
		dataTableLoadingText,
	} from "@/services/AppConig";
	import {
		getSummaryJobReportService,
		getSummaryPenaltyReportService,
		getSummaryOtherReportService,
	} from "@/services/api/report";
	import { appEncodeURIComponentHelper } from "@/services/appStringHelpper";

	const EmployeeStatus = Object.freeze({
		APPLIED: "ขอสมัคร",
		REJECTED: "ไม่รับ",
		PROBATION: "ทดลองงาน",
		ACTIVE: "ทำงาน",
		RESIGNED: "ลาออก",
		TERMINATED: "เลิกจ้าง",
		FIRED: "ไล่ออก",
	});

	export default {
		name: "HomeView",

		components: {
			AppOverlay,
			PrimaryButton,
			EmployeeJobDialog,
			CustomCardFormViewModern,
			apexchart: VueApexCharts,
		},

		data() {
			return {
				userInfo: "",
				QRData: "",
				dataTableFooterProps,
				dataTableNoDataText,
				dataTableLoadingText,

				isPermissionCanView: false,

				isLoading: false,
				jobNumberStatsAmount: {},
				jobNumberStatsStatus: {},
				jobNumberStatsGraph: {},

				// intervalData: undefined,

				currentAnnotations: [],
				currentDate: null,
				preparingJobURL: "",
				allJobURL: "",
				processFuelURL: "",
				processEventsURL: "",
				inspectEventsURL: "",
				insuranceURL: "",
				actURL: "",
				taxURL: "",
				penaltyTypeURL: "",

				jobNumberStatsGraphData: [
					{
						name: "จำนวนงาน",
						data: [],
					},
				],

				graphJobStatus: [
					"PLANNING",
					"PREPARING",
					"CONFIRM",
					"PROCESS",
					"SUCCESS",
					"CANCEL",
				],

				speedingPenalty: 0,
				totalPenalty: 0,
				penaltyList: {},
				penalityTypeList: [],

				fuelData: {},
				vehicleEventData: {},
				almostExpiredData: {},
				expiredData: {},

				series: [
					{
						name: "จำนวนงาน",
						data: [
							0, 0, 0, 0, 0, 3, 2, 12, 20, 4, 0, 15, 0, 5, 5, 4, 1, 15, 8, 5, 0,
							1, 0, 0,
						],
					},
				],

				seriesPenaltyTypes: [
					{
						data: [12, 11, 11, 11, 10, 9, 8, 7, 6, 5, 4, 4, 3, 3, 2, 1, 0],
					},
				],

				chartOptionsPenaltyTypes: {
					chart: {
						type: "bar",
						parentHeightOffset: 0,
						toolbar: {
							show: false,
						},
						fontFamily: "'Noto Sans Thai', sans-serif",
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: true,
							distributed: true,
							dataLabels: {
								enabled: false,
							},
						},
					},
					// plotOptions: {
					//   bar: {
					//     barHeight: "100%",
					//     distributed: true,
					//     horizontal: true,
					//     dataLabels: {
					//       position: "bottom",
					//     },
					//   },
					// },
					// colors: ["#AAB7B8"],
					colors: [
						"#567DF4",
						"#567DF4",
						"#567DF4",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
						"#AAB7B8",
					],
					dataLabels: {
						enabled: false,
					},
					grid: {
						show: false,
					},
					legend: {
						show: false,
					},
					xaxis: {
						labels: {
							formatter: function (val) {
								return val + "%";
							},
						},
						categories: [
							"Operation Excellent - ถูกร้องเรียน",
							"Operation Excellent - เข้ารับไม่ตรงจุด",
							"Operation Excellent - ไม่รับงาน",
							"Operation Excellent - การแก้ไขปัญหา",
							"Operation Excellent - การติดต่อสื่อสาร",
							"Operation Excellent - ติดต่อล่าช้า",
							"Operation Excellent - ภาษาและวาจา",
							"Operation Excellent - การทำงานเป็นทีม",
							"Safety & GPS - อุบัติเหตุร้ายแรง",
							"Safety & GPS - อุบัติเหตุเล็กน้อย",
							"Safety & GPS - ความพร้อม",
							"Safety & GPS - ใช้รถนอกเวลา",
							"Safety & GPS - ทำผิดจราจร",
							"Safety & GPS - ขับรถไม่เหมาะสม",
							"HR & Admin - ลางาน ครึ่งวัน",
							"HR & Admin - ลางาน เต็มวัน",
							"HR & Admin - การให้ความร่วมมือ",
						],
					},
					yaxis: {
						labels: {
							maxWidth: 260,
						},
					},
				},
			};
		},
		computed: {
			...mapGetters({
				jobsStatusColorValue: "jobs/jobsStatusColorValue",
				jobsStatusColor: "jobs/jobsStatusColor",
				jobsStatusText: "jobs/jobsStatusText",
				jobsStatusObj: "jobs/jobsStatusObj",
			}),

			tableHeaders() {
				let defaultdata = [
					{
						text: "ลำดับ",
						align: "start",
						sortable: false,
						value: "order",
						width: "10%",
					},
					{
						text: "ชื่อ-สกุล",
						value: "title",
						sortable: true,
						align: "left",
						width: "60%",
					},
					{
						text: "จำนวน",
						align: "start",
						sortable: true,
						value: "num",
						width: "30%",
					},
				];

				return defaultdata;
			},

			timeCategorie: function () {
				let time = [];
				for (let i = 0; i < 24; i++) {
					if (i < 10) {
						time.push("0" + i.toString() + ":00");
					} else {
						time.push(i.toString() + ":00");
					}
				}
				return time;
			},

			jobsStatusBarColor: function () {
				let statusColors = [];
				for (let i = 0; i < this.graphJobStatus.length; i++) {
					statusColors.push(this.jobsStatusColorValue(this.graphJobStatus[i]));
				}
				return statusColors;
			},

			chartOptions: function () {
				return {
					chart: {
						type: "bar",
						stacked: true,
						height: 250,
						parentHeightOffset: 0,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						fontFamily: "'Noto Sans Thai', sans-serif",
						events: {
							click: (event, chartContext, config) => {
								if (config.seriesIndex > -1) {
									var jobTime = config.dataPointIndex;
									this.handleAllJobOnClick(jobTime);
								}
							},
						},
					},
					colors: this.jobsStatusBarColor,
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: "30%",
							endingShape: "rounded",
						},
					},
					annotations: {
						xaxis: this.currentAnnotations,
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						show: true,
						width: 2,
						colors: ["transparent"],
					},
					xaxis: {
						categories: this.timeCategorie,
					},
					yaxis: {
						title: {
							text: "จำนวนงาน",
							offsetX: 0,
							offsetY: -65,
						},
					},
					fill: {
						opacity: 1,
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val + " งาน";
							},
						},
					},
				};
			},
		},
		watch: {
			jobNumberStatsGraph(value) {
				// console.log("jobNumberStatsGraph", value);
				if (!_.isNil(value)) {
					this.jobNumberStatsGraphData = [];
					for (let i = 0; i < this.graphJobStatus.length; i++) {
						this.jobNumberStatsGraphData.push({
							name: this.jobsStatusText(this.graphJobStatus[i]),
							data: [],
						});
					}

					Object.keys(value).forEach((key) => {
						if (!_.isNil(value[key])) {
							for (let i = 0; i < this.graphJobStatus.length; i++) {
								this.jobNumberStatsGraphData[i]["data"].push(
									value[key][this.graphJobStatus[i]]
								);
							}
						}
					});
				}
				// console.log("jobNumberStatsGraphData", this.jobNumberStatsGraphData);
			},

			// series(val) {
			//   if (val.length > 0) {
			//     this.currentAnnotations = [];
			//     this.processAnnoations();
			//   } else {
			//     this.currentAnnotations = [];
			//   }
			// },

			penaltyList(value) {
				if (!_.isNil(value)) {
					this.penalityTypeList = [];
					let count = 0;
					Object.keys(value).forEach((key) => {
						if (!_.isNil(value[key])) {
							count += 1;
							this.penalityTypeList.push({
								order: count,
								title: key.toString(),
								num: value[key],
							});
						}
					});
				}
			},
		},
		methods: {
			dateStringToDateFormat: dateStringToDateFormat,
			// async init() {
			//   let result = await getPassengerQRCode(
			//     this.$store,
			//     "643ebb6e8c5aaf8c49d11bda"
			//   );
			//   if (result["code"] == 200) {
			//     this.QRData = "data:image/png;base64," + result.data.passengerQRCode;
			//   }
			// },

			async handleScheduleClick() {
				const empJob = await this.$refs.employeeJobDialogRef.open();
				// console.log("employeeJob:", empJob);
			},

			processAnnoations() {
				// console.log("currentAnnotations", this.currentAnnotations);
				// console.log("current hour", moment().format("HH") + ":00");
				this.addInitCurrentTimeAnnotation();
			},

			addInitCurrentTimeAnnotation() {
				let currentTime = moment().format("HH") + ":00";
				this.currentAnnotations = [];
				this.currentAnnotations.push({
					// id: "currentDateTime",
					x: currentTime,
					strokeDashArray: 5,
					borderWidth: 2,
					// borderColor: "#AAB7B8",
					borderColor: "#49c496",
					label: {
						offsetY: -8,
						offsetX: 13,
						borderColor: "#49c496",
						style: {
							color: "#fff",
							background: "#49c496",
							fontSize: "12px",
						},
						text: "Now",
					},
				});
			},

			async getData(isInit) {
				if (isInit) {
					this.isLoading = true;
				}

				if (this.isPermissionCanView) {
					// Stat
					this.jobNumberStatsAmount = {};
					this.jobNumberStatsStatus = {};
					this.jobNumberStatsGraph = {};

					let respStatsData = await getSummaryJobReportService(this.$store);
					// console.log("respStatsData", respStatsData);
					if (respStatsData["code"] === 200) {
						if (!_.isNil(respStatsData["data"])) {
							this.jobNumberStatsAmount = _.clone(
								respStatsData["data"]["amount"]
							);

							this.jobNumberStatsStatus = _.clone(
								respStatsData["data"]["status"]
							);
							this.jobNumberStatsGraph = _.clone(
								respStatsData["data"]["graph"]
							);
						} else {
							this.jobNumberStatsAmount = {};
							this.jobNumberStatsStatus = {};
							this.jobNumberStatsGraph = {};
						}
					} else {
						this.isFail = true;
					}

					// Penalty
					let respPenaltyData = await getSummaryPenaltyReportService(
						this.$store
					);
					// console.log("respPenaltyData", respPenaltyData);
					if (respPenaltyData["code"] === 200) {
						if (!_.isNil(respPenaltyData["data"])) {
							this.speedingPenalty = _.clone(
								respPenaltyData["data"]["list"][
									"Safety & GPS - ขับรถไม่เหมาะสม"
								]
							);
							this.totalPenalty = _.clone(respPenaltyData["data"]["amount"]);
							this.penaltyList = _.clone(respPenaltyData["data"]["list"]);
						} else {
							this.speedingPenalty = 0;
							this.totalPenalty = 0;
							this.penaltyList = {};
						}
					} else {
						this.isFail = true;
					}

					let respOtherData = await getSummaryOtherReportService(this.$store);
					// console.log("respOtherData", respOtherData);
					if (respOtherData["code"] === 200) {
						if (!_.isNil(respOtherData["data"])) {
							this.fuelData = _.clone(respOtherData["data"]["fuel"]);
							this.vehicleEventData = _.clone(
								respOtherData["data"]["vehicleEvent"]
							);
							this.almostExpiredData = _.clone(
								respOtherData["data"]["almostExpired"]
							);
							this.expiredData = _.clone(respOtherData["data"]["expired"]);
						} else {
							this.fuelData = {};
							this.vehicleEventData = {};
							this.almostExpiredData = {};
							this.expiredData = {};
						}
						console.log("fuelData", this.fuelData);
					} else {
						this.isFail = true;
					}
				}
				if (isInit) {
					this.isLoading = false;
				}
			},

			async handlePreparingJobOnClick() {
				this.isLoading = true;
				let currentDate = moment().format("YYYY-MM-DD");
				this.preparingJobURL = `/caj/jobs/list?limit=20&by=createddate&order=desc&page=1&status=preparing&dateround=${currentDate}`;
				window.open(this.preparingJobURL, "_blank");
				this.isLoading = false;
			},

			async handleAllJobOnClick(time) {
				this.isLoading = true;
				let currentDate = moment().format("YYYY-MM-DD");
				let startTime = `${time.toString()}:00`;
				let endTime = `${time.toString()}:59`;
				// console.log(currentDate, startTime, endTime);
				const routeData = this.$router.resolve({
					name: "jobsList",
					query: {
						limit: 20,
						by: "createddate",
						order: "desc",
						page: 1,
						status: "all",
						dateround: currentDate,
						starttimeround: startTime,
						endtimeround: endTime,
					},
				});
				window.open(routeData.href, "_blank");
				this.isLoading = false;
			},

			async handleFuelOnClick() {
				this.isLoading = true;
				let currentDate = moment().format("YYYY-MM-DD");
				this.processFuelURL = `/vehicle/fuel?limit=10&order=desc&page=1&status=P&mincreatedate=${currentDate}`;
				window.open(this.processFuelURL, "_blank");
				this.isLoading = false;
			},

			async handleProcessEventsOnClick() {
				this.isLoading = true;
				this.processEventsURL =
					"/vehicle/events?limit=20&by=createddate&order=desc&page=1&status=P";
				window.open(this.processEventsURL, "_blank");
				this.isLoading = false;
			},

			async handleInspectEventsOnClick() {
				this.isLoading = true;
				this.inspectEventsURL =
					"/vehicle/events?limit=20&by=createddate&order=desc&page=1&status=I";
				window.open(this.inspectEventsURL, "_blank");
				this.isLoading = false;
			},

			async handleInsuranceOnClick() {
				this.isLoading = true;
				let startDate = moment().format("YYYY-MM-DD");
				let endDate = moment().add(30, "days").format("YYYY-MM-DD");
				this.insuranceURL = `/vehicle/ins?doc=ins&limit=20&by=createddate&order=desc&page=1&minexpdate=${startDate}&maxexpdate=${endDate}`;
				window.open(this.insuranceURL, "_blank");
				this.isLoading = false;
			},

			async handleActOnClick() {
				this.isLoading = true;
				let startDate = moment().format("YYYY-MM-DD");
				let endDate = moment().add(30, "days").format("YYYY-MM-DD");
				this.actURL = `/vehicle/act?doc=act&limit=20&by=createddate&order=desc&page=1&minexpdate=${startDate}&maxexpdate=${endDate}`;
				window.open(this.actURL, "_blank");
				this.isLoading = false;
			},

			async handleTaxOnClick() {
				this.isLoading = true;
				let startDate = moment().format("YYYY-MM-DD");
				let endDate = moment().add(30, "days").format("YYYY-MM-DD");
				this.taxURL = `/vehicle/tax?doc=tax&limit=20&by=createddate&order=desc&page=1&minexpdate=${startDate}&maxexpdate=${endDate}`;
				window.open(this.taxURL, "_blank");
				this.isLoading = false;
			},

			async handlePenaltyTypeClick(penaltyType) {
				this.isLoading = true;
				let startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
				let endDate = moment().format("YYYY-MM-DD");
				let penaltyTopic = encodeURIComponent(penaltyType);
				this.penaltyTypeURL = `/employee/penality/list?limit=20&by=createddate&order=desc&page=1&topic=${penaltyTopic}&startdate=${startDate}&enddate=${endDate}`;
				window.open(this.penaltyTypeURL, "_blank");
				this.isLoading = false;
			},

			handlePenaltyTypeRowClick(row) {
				this.isLoading = true;
				if (!_.isNil(row["title"])) {
					let startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
					let endDate = moment().format("YYYY-MM-DD");
					let penaltyTopic = encodeURIComponent(row["title"]);
					this.penaltyTypeURL = `/employee/penality/list?limit=20&by=createddate&order=desc&page=1&topic=${penaltyTopic}&startdate=${startDate}&enddate=${endDate}`;
					window.open(this.penaltyTypeURL, "_blank");
				}
				this.isLoading = false;
			},

			getStatusEmployeeText(status) {
				switch (status) {
					case "APPLIED":
						return EmployeeStatus.APPLIED;
					case "REJECTED":
						return EmployeeStatus.REJECTED;
					case "PROBATION":
						return EmployeeStatus.PROBATION;
					case "ACTIVE":
						return EmployeeStatus.ACTIVE;
					case "RESIGNED":
						return EmployeeStatus.RESIGNED;
					case "TERMINATED":
						return EmployeeStatus.TERMINATED;
					case "FIRED":
						return EmployeeStatus.FIRED;
					default:
						return null;
				}
			},

			getStatusEmployeeColor(status) {
				switch (status) {
					case "APPLIED":
						return "subtitle--text darken-2";
					case "REJECTED":
						return "subtitle--text darken-4";
					case "PROBATION":
						return "primary--text";
					case "ACTIVE":
						return "success--text";
					case "RESIGNED":
						return "warning--text";
					case "TERMINATED":
						return "error--text lighten-1";
					case "FIRED":
						return "error--text darken-1";
					default:
						return null;
				}
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "OTHER");
					if (
						this.permList.admin_all === true ||
						this.permList.other_dashboard_view === true
					) {
						this.isPermissionCanView = true;
					}
				}
			},
		},
		mounted() {
			this.currentDate = moment().format("YYYY-MM-DD");
			this.checkUserPermissions();
			this.processAnnoations();
			this.getData(true);

			// this.intervalData = setInterval(() => {
			//   // location.reload();
			//   this.processAnnoations();
			//   this.getData(false);
			// }, 60000); // reload every 60 seconds
		},

		// destroyed() {
		//   // exist and reset mode
		//   clearInterval(this.intervalData);
		// },
	};
</script>

<style scoped>
	.new-tab {
		position: relative;
		cursor: pointer;
	}

	.dashboard-large {
		font-size: 80px !important;
	}

	.dashboard-medium {
		font-size: 60px !important;
	}
	.dashboard-small {
		font-size: 30px !important;
	}
</style>
