<template>
  <v-dialog scrollable v-model="dialog" :max-width="700">
    <v-card v-if="userInfoData" :loading="loading">
      <v-toolbar color="primary" dark>
        <v-card-title> จัดข้อมูลรถประจำตัว </v-card-title> <v-spacer></v-spacer
        ><v-btn icon @click.native="cancel"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >
      <v-card-text>
        <v-list-item two-line class="px-0 pt-1">
          <v-list-item-avatar size="60" color="primary">
            <v-img
              :src="
                userInfoData.profileImage ? userInfoData.profileImage.src : null
              "
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="mb-1">
              <span class="text-app-md font-weight-bold">
                {{
                  userInfoData ? userInfoData.dpData.dpNameTh : "ไม่มีข้อมูล"
                }}</span
              >
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="text-app-detail-normal font-weight-medium">
                ID:
                {{ userInfoData.empId ? userInfoData.empId : "..." }}
              </span>
              <br />
              <span class="text-app-detail-normal">
                เบอร์โทรศัพท์:
                {{
                  userInfoData.dpData.dpPhoneNumeber
                    ? userInfoData.dpData.dpPhoneNumeber
                    : "-"
                }}
              </span>
              ,
              <span class="text-app-detail-normal">
                Line ID:
                {{ userInfoData.lineId ? userInfoData.lineId : "-" }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- <AppAutoCompleteVehicle
          placeholder="ค้นหารถ"
          v-model="selectedVehicle"
          data-input-name="ownedVehicleAutoCompleteVehicle"
          :isCassieSub="true"
          :expend-margin="false"
          :disabled="disabled"
        /> -->

        <AppComboboxVehicleSearching
          ref="AppComboboxVehicleSearchingRef"
          v-model="vehicleSearch"
          :isRequired="false"
        />

        <v-row
          no-gutters
          justify="center"
          class="mt-0 mb-3"
          v-if="requestVehicleResp !== null"
        >
          <v-col cols="auto">
            <v-card class="pa-0" elevation="5">
              <v-img
                :src="requestVehicleResp ? requestVehicleResp.image.src : null"
                height="300"
                width="300"
                contain
                class="backgroud-image"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" justify="center">
                    <v-icon x-large>mdi-image</v-icon>
                  </v-row>
                </template>
              </v-img>
              <p class="text-app-detail py-2 text-center">
                <span class="font-weight-bold">ร้องขอเมื่อ:</span>
                <!-- {{
                            dateStringToDateFormat(requestedFormData.lastUsed, {
                              returnThai: false,
                              type: "datetime",
                            }) || "-"
                          }} -->
                {{
                  dateStringToDateFormat("2023-02-10", {
                    returnThai: false,
                    type: "datetime",
                  }) || "-"
                }}
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          justify="center"
          v-if="!loading && selectedVehicleData !== null"
        >
          <v-sheet
            outlined
            width="300"
            class="rounded-lg pt-2"
            :color="
              isUsingVehicle
                ? 'primary'
                : vehicleStatus === 1
                ? 'red'
                : 'success'
            "
          >
            <v-card outlined elevation="0" class="rounded-b-lg">
              <v-card-text>
                <div class="d-flex">
                  <div>
                    <p
                      class="ma-0 text-app-detail font-weight-bold black--text mb-1"
                    >
                      {{ selectedVehicleData.licenseNumber }}
                      <span v-if="selectedVehicleData.type !== null"
                        >({{ vehicleTypeText(selectedVehicleData.type) }})</span
                      >
                    </p>
                    <span class="text-app-small-detail pr-6">
                      <span class="mx-0 px-0">
                        <v-icon
                          v-if="selectedVehicleData.licenseType === 'WHITE'"
                          >mdi-card-text-outline</v-icon
                        >
                        <v-icon
                          v-else
                          :color="
                            vehicleLicenseTypeColor(
                              selectedVehicleData.licenseType
                            )
                          "
                          >mdi-card-text</v-icon
                        >
                      </span>
                      {{
                        vehicleLicenseTypeText(selectedVehicleData.licenseType)
                      }}</span
                    >
                    <span class="text-app-small-detail">
                      <span class="mx-0 px-0">
                        <v-icon>{{
                          vehicleTypeIcon(selectedVehicleData.type)
                        }}</v-icon>
                      </span>
                      {{ selectedVehicleData.seat }}
                      ที่นั่ง</span
                    >
                  </div>
                  <v-spacer></v-spacer>
                  <div class="align-center d-flex font-weight-bold">
                    <div v-if="isUsingVehicle">
                      <span class="primary--text"> กำลังใช้งาน </span>
                    </div>
                    <div v-else>
                      <span v-if="vehicleStatus === 1" class="red--text">
                        ไม่ว่าง
                      </span>
                      <span v-if="vehicleStatus === 0" class="green--text">
                        ว่าง
                      </span>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-row>
        <!--  -->
        <v-row no-gutters width="300" justify="center">
          <v-card
            outlined
            elevation="0"
            width="300"
            v-if="!loading && selectedVehicleData === null"
          >
            <v-card-text
              ><p class="font-weight-bold black--text ma-0 text-center">
                กรุณาเลือกรถประจำตัวจากกล่องด้านบน
              </p></v-card-text
            >
          </v-card>

          <v-card width="300" outlined elevation="0" v-if="loading">
            <v-card-text
              ><p class="ma-0 text-center">กำลังทำงาน....</p></v-card-text
            >
          </v-card>
        </v-row>
        <!--  -->
        <!-- <p class="my-2 mx-0 font-weight-bold">ประวัติการใช้งานรถ</p> -->

        <v-simple-table v-if="!loading && historyData.length > 0" class="mt-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ผู้ขับ</th>
                <th class="text-left">เริ่มต้น</th>
                <th class="text-left">สิ้นสุด</th>
              </tr>
            </thead>
            <tbody v-if="historyData.length !== 0">
              <tr v-for="(hist, index) in historyData" :key="index">
                <td>
                  {{ `${hist.driver.dpNameTh} (${hist.driver.empId})` }}
                  <v-chip color="success" small v-if="hist.status">
                    กำลังใช้งาน</v-chip
                  >
                </td>
                <td>
                  {{
                    dateStringToDateFormat(hist.startDate, {
                      returnThai: false,
                      type: "datetime",
                    }) || "-"
                  }}
                </td>
                <td>
                  <v-btn
                    small
                    color="error"
                    @click="unsetDataAction(hist)"
                    v-if="hist.endDate === null"
                  >
                    นำออก</v-btn
                  >
                  <span v-else>
                    {{
                      dateStringToDateFormat(hist.endDate, {
                        returnThai: false,
                        type: "datetime",
                      }) || "-"
                    }}</span
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="2" class="text-center">
                  <span class="text-app-small-detail">
                    ไม่พบประวัติการใช้งาน
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!--  -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-3">
        <v-spacer></v-spacer>
        <!-- <v-btn color="error" text @click.native="cancel"> ปิด </v-btn> -->
        <v-btn
          v-if="isShowRejectBtn"
          color="error"
          class="px-6"
          outlined
          @click.native="handleDeleteClick()"
        >
          ไม่อนุมัติ
        </v-btn>
        <v-btn
          color="primary"
          class="px-6"
          @click.native="setDataAction"
          :disabled="submitDisabled"
        >
          อนุมัติ
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppDialogConfirm ref="comfirmToUnset" />
  </v-dialog>
</template>
<script>
import InputView from "@/components/InputView.vue";
import AppAutoCompleteVehicle from "@/components/AppAutoCompleteVehicle.vue";
import {
  getUsedVehicleHistoryService,
  unsetUsedVehicleHistoryService,
  setUsedVehicleHistoryService,
} from "@/services/api/vehicle";
import { getEmployeeOwnedVehicleService } from "@/services/api/employee";
import _ from "lodash";
import { mapGetters } from "vuex";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import AppOverlay from "@/components/AppOverlay.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import { deleteRequestOwnedVehicleService } from "@/services/api/vehicle";
import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";

export default {
  name: "EmployeeOwnedVehicleDialog",
  data: () => ({
    dialog: false,
    userId: null,
    userInfoData: null,
    resolve: null,
    reject: null,
    selectedVehicle: null,
    //
    vehicleStatus: false,
    selectedVehicleData: null,
    vehicleSearch: null,
    historyData: [],
    isUsingVehicle: false,
    //
    disabled: false,
    submitDisabled: true,
    isShowRejectBtn: false,
    loading: false,

    requestVehicleResp: null,
  }),
  watch: {
    selectedVehicle: function (newValue) {
      this.getVehicleInfo();
    },
    vehicleSearch(value) {
      if (!_.isNil(value)) {
        if (!_.isString(value)) {
          // this.formData["vehicle"] = _.cloneDeep(value);
          this.getVehicleInfo();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      vehicleTypeText: "vehicle/vehicleTypeText",
      vehicleTypeIcon: "vehicle/vehicleTypeIcon",
      vehicleStatusText: "vehicle/vehicleStatusText",
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
      vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
    }),
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    clearForm() {
      // this.selectedVehicle = null;
      this.vehicleSearch = null;
      this.vehicleStatus = null;
      this.selectedVehicleData = null;
      this.historyData = [];
      this.loading = false;
      this.disabled = false;
      this.submitDisabled = true;
      this.isShowRejectBtn = false;
      this.requestVehicleResp = null;
    },
    async open(userId, data) {
      this.dialog = true;
      this.clearForm();
      this.userId = userId;
      this.userInfoData = data;
      //  set selectedVehicleData
      if (!_.isNil(this.userInfoData["requestOwnedVehicle"])) {
        this.submitDisabled = false;
        this.isShowRejectBtn = true;
        // this.selectedVehicle =
        //   this.userInfoData["requestOwnedVehicle"]["select"];

        await this.getRequestVehicleData(
          this.userInfoData["requestOwnedVehicle"]["_id"]
        );
        if (!_.isNil(this.requestVehicleResp)) {
          // this.selectedVehicle = this.requestVehicleResp["select"];
          this.vehicleSearch = this.requestVehicleResp["select"];
        }
      } else if (!_.isNil(this.userInfoData["ownedVehicle"])) {
        this.submitDisabled = false;
        this.isShowRejectBtn = false;
        // this.selectedVehicle = this.userInfoData["ownedVehicle"]["select"];
        this.vehicleSearch = this.userInfoData["ownedVehicle"]["select"];
      }
      //

      // console.log("userInfoData", this.requestVehicleResp);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    async getRequestVehicleData(reqId) {
      let responseData = await getEmployeeOwnedVehicleService(
        this.$store,
        reqId
      );

      // console.log("Response:", responseData);

      if (responseData.code == 200) {
        this.requestVehicleResp = responseData["data"];
      }
    },
    // get vehicle info
    async getVehicleInfo() {
      // console.log("vehicleSearch", this.vehicleSearch);
      if (_.isNil(this.vehicleSearch)) {
        return;
      }
      this.loading = true;
      this.disabled = true;
      this.submitDisabled = true;
      // create query params
      let params = new URLSearchParams({
        id: this.vehicleSearch.key,
      }).toString();

      let respData = await getUsedVehicleHistoryService(this.$store, params);
      if (respData["code"] === 200) {
        this.vehicleStatus = respData["data"]["status"];
        this.selectedVehicleData = respData["data"]["vehicle"];
        this.historyData = respData["data"]["history"];
        if (this.historyData.length > 0) {
          if (
            this.historyData[0].status === 1 &&
            this.historyData[0]["driver"]["empId"] ===
              this.userInfoData["empId"]
          ) {
            this.isUsingVehicle = true;
          } else {
            this.isUsingVehicle = false;
          }
        } else {
          this.isUsingVehicle = false;
        }
        if (this.vehicleStatus === 0) {
          this.submitDisabled = false;
        } else {
          this.submitDisabled = true;
        }
      } else {
        this.submitDisabled = true;
      }
      this.disabled = false;
      this.loading = false;
    },
    // set from vehicle select
    async setDataAction() {
      this.loading = true;
      this.disabled = true;
      this.submitDisabled = true;
      let payload = {
        vehicleId: this.selectedVehicleData["_id"],
      };

      if (!_.isNil(this.userInfoData["requestOwnedVehicle"])) {
        payload = {
          ...payload,
          requestId: this.userInfoData["requestOwnedVehicle"]["_id"],
        };
      } else {
        payload = {
          ...payload,
          requestId: null,
        };
      }

      let params = new URLSearchParams({
        id: this.userId,
      }).toString();

      let respUpdate = await setUsedVehicleHistoryService(
        this.$store,
        params,
        payload
      );

      //
      // if (respUpdate["code"] !== 200) {
      //   this.close();
      // }

      this.clearForm();
      this.resolve(true);
      this.dialog = false;
    },
    // unset from vehicle select
    async unsetDataAction(hist) {
      // dialog
      let isConfirm = await this.$refs.comfirmToUnset.open(
        null,
        `นำ ${hist.driver.dpNameTh} ออกจากรถป้ายทะเบียน ${this.selectedVehicleData["licenseNumber"]}`,
        DialogType.ACTION,
        {
          width: 400,
          zIndex: 200,
          noconfirm: true,
        }
      );
      // isConfirm
      if (isConfirm) {
        this.loading = true;
        this.disabled = true;
        this.submitDisabled = true;
        // create payload
        let payload = {
          historyId: hist._id,
          vehicleId: this.selectedVehicleData["_id"],
        };
        let params = new URLSearchParams({
          id: this.userId,
        }).toString();

        // CALL API
        // respUpdate
        let respUpdate = await unsetUsedVehicleHistoryService(
          this.$store,
          params,
          payload
        );
        // CALL API: success
        if (respUpdate["code"] === 200) {
          if (respUpdate["data"]["status"] === true) {
            this.getVehicleInfo();
            return;
          }
        }
        // CALL API: fail
        this.cancel();
      }
    },
    async handleDeleteClick(_id) {
      if (!_.isNil(this.userInfoData["requestOwnedVehicle"])) {
        let requestId = this.userInfoData["requestOwnedVehicle"]["_id"];
        await deleteRequestOwnedVehicleService(this.$store, requestId);

        this.clearForm();
        this.resolve(true);
        this.dialog = false;
      }
    },
    cancel() {
      this.clearForm();
      this.resolve(false);
      this.dialog = false;
    },
  },
  components: {
    InputView,
    AppAutoCompleteVehicle,
    AppDialogConfirm,
    AppOverlay,
    AppComboboxVehicleSearching,
  },
};
</script>
<style scoped>
.backgroud-image {
  background: #f4f6f6;
}
</style>
