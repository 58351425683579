<template>
  <div>
    <CustomCardFormViewModern
      title="ข้อมูลส่วนตัว"
      :isShowTitle="true"
      :isTitleHighlight="true"
    >
      <v-form ref="employeePersonalInformationForm" v-model="valid">
        <v-row no-gutters class="px-0">
          <v-col cols="12">
            <v-card elevation="0" class="px-0">
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6" v-if="userId">
                  <EmployeePersonalEmpIdInputComponent
                    ref="id"
                    v-model="formData.empId"
                    :disabled="!enableEdit"
                    :edit-at-emp-id="oldEmpId"
                    :is-edit="userId !== null"
                    @handler-processing-func="checkLoadingStatus"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.idCardNumber"
                    ref="idCard"
                    inputType="numberWithoutSign"
                    title="เลขประจำตัวประชาชน"
                    placeholder="ตัวเลข 13 ตัว"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[
                      (v) => !!v || 'จำเป็นต้องระบุ',
                      (v) =>
                        (v && v.length === 13) ||
                        'เลขบัตรประชาชนต้องมี 13 ตัวอักษร',
                    ]"
                    dataInputName="idCardNumber"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomDateTypingPickerFormInput
                    ref="idCardExpirationDate"
                    v-model="formData.idCardExpiryDate"
                    :title="
                      idCardExpirationView
                        ? idCardExpirationView
                        : 'วันหมดอายุบัตรประชาชน'
                    "
                    placeholder="วัน/เดือน/ปีคศ"
                    class="px-3"
                    :expendMargin="false"
                    :is-required="true"
                    :disabled="!enableEdit || foreverIdCardDate"
                    :rules="
                      !foreverIdCardDate ? [(v) => !!v || 'จำเป็นต้องระบุ'] : []
                    "
                    dataInputName="idCardExpiryDate"
                  />
                  <v-checkbox
                    class="px-3 pt-0 mb-3"
                    v-model="foreverIdCardDate"
                    hide-details
                    dense
                    label="ตลอดชีวิต"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomDateTypingPickerFormInput
                    ref="birthday"
                    v-model="formData.birthdayDate"
                    :title="ageView ? ageView : 'วันเดือนปีเกิด'"
                    placeholder="วัน/เดือน/ปีคศ"
                    :isMaxToday="true"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="birthday"
                  />
                </v-col>
              </v-row>

              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="salutationTh"
                    v-model="formData.salutation.th"
                    placeholder="เลือก"
                    title="คำนำหน้า (ภาษาไทย)"
                    :is-required="true"
                    :items="['นาย', 'นางสาว', 'นาง']"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="salutationTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.firstName.th"
                    ref="firstnameTh"
                    title="ชื่อ (ภาษาไทย)"
                    inputType="thOnly"
                    placeholder=""
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="firstnameTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.lastName.th"
                    ref="lastnameTh"
                    title="นามสกุล (ภาษาไทย)"
                    inputType="thOnly"
                    placeholder=""
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="lastnameTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.nickName.th"
                    ref="nickNameTh"
                    title="ชื่อเล่น (ภาษาไทย)"
                    inputType="thOnly"
                    placeholder=""
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="nickNameTh"
                  />
                </v-col>
              </v-row>

              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="salutationEng"
                    v-model="formData.salutation.en"
                    title="คำนำหน้า (ภาษาอังกฤษ)"
                    :is-required="true"
                    :items="['Mr.', ' Miss', 'Ms.', 'Mrs.']"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="salutationEng"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    ref="firstnameEng"
                    v-model="formData.firstName.en"
                    title="ชื่อ (ภาษาอังกฤษ)"
                    placeholder="Firstname"
                    inputType="enOnly"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="firstnameEng"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    ref="lastnameEng"
                    v-model="formData.lastName.en"
                    title="นามสกุล (ภาษาอังกฤษ)"
                    placeholder="Surname"
                    inputType="enOnly"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="lastnameEng"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.nickName.en"
                    ref="nickNameEng"
                    title="ชื่อเล่น (ภาษาอังกฤษ)"
                    placeholder="Nickname"
                    inputType="enOnly"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="nickNameEng"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-6 mt-1"></v-divider>
              <v-row no-gutters class="px-6"
                ><v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="nationalityTh"
                    v-model="formData.nationality"
                    placeholder="เลือก"
                    title="สัญชาติ"
                    :is-required="true"
                    :items="nationalities"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="nationalityTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.nationalityName"
                    ref="nationalityNameTh"
                    title="ระบุสัญชาติ (กรณีสัญชาติ อื่นๆ)"
                    placeholder="สัญชาติ"
                    :is-required="true"
                    :disabled="!enableEdit || formData.nationality !== 'อื่นๆ'"
                    :rules="
                      formData.nationality == 'อื่นๆ'
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    dataInputName="nationalityNameTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="religionTh"
                    v-model="formData.religion"
                    placeholder="เลือก"
                    title="ศาสนา"
                    :is-required="true"
                    :items="religionItems"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="religionTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.religionName"
                    ref="religionNameTh"
                    title="ระบุศาสนา (กรณีศาสนา อื่นๆ)"
                    placeholder="ศาสนา"
                    :is-required="true"
                    :disabled="!enableEdit || formData.religion !== 'อื่นๆ'"
                    :rules="
                      formData.religion == 'อื่นๆ'
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    dataInputName="religionNameTh"
                  /> </v-col
              ></v-row>
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="maritalStatusTh"
                    v-model="formData.maritalStatus"
                    placeholder="เลือก"
                    title="สถานภาพ"
                    :is-required="true"
                    :items="maritalItems"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="maritalStatusTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="militaryStatusTh"
                    v-model="formData.militaryStatus"
                    placeholder="เลือก"
                    title="ภาวะทางทหาร"
                    :is-required="true"
                    :items="militaryStatusItems"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="militaryStatusTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.militaryStatusName"
                    ref="militaryStatusNameTh"
                    title="ระบุภาวะทางทหาร (กรณีภาวะ อื่นๆ)"
                    placeholder="ภาวะทางทหาร"
                    :is-required="true"
                    :disabled="
                      !enableEdit || formData.militaryStatus !== 'อื่นๆ'
                    "
                    :rules="
                      formData.militaryStatus == 'อื่นๆ'
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    dataInputName="militaryStatusNameTh"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-6 mt-1"></v-divider>
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="bloodGroupTh"
                    v-model="formData.bloodGroup"
                    placeholder="เลือก"
                    title="กรุ๊ปเลือด"
                    :is-required="true"
                    :items="bloodGroupItems"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="bloodGroupTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.bloodGroupName"
                    ref="bloodGroupNameTh"
                    title="ระบุกรุ๊ปเลือด (กรณีกรุ๊ปเลือด อื่นๆ)"
                    placeholder="กรุ๊ปเลือด"
                    :is-required="true"
                    :disabled="!enableEdit || formData.bloodGroup !== 'อื่นๆ'"
                    :rules="
                      formData.bloodGroup == 'อื่นๆ'
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    dataInputName="bloodGroupNameTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.height"
                    ref="height"
                    title="ส่วนสูง (เซนติเมตร)"
                    inputType="floatNumberWithOutSignTwo"
                    placeholder="ส่วนสูง"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="height"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.weight"
                    ref="weight"
                    title="น้ำหนัก (กิโลกรัม)"
                    inputType="floatNumberWithOutSignTwo"
                    placeholder="น้ำหนัก"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="weight"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-6 mt-1"></v-divider>
              <v-row no-gutters class="px-6">
                <v-col cols="12" md="3" sm="6">
                  <CustomTextInputImprove
                    v-model="telInput[0]"
                    ref="tel1"
                    title="เบอร์โทร"
                    placeholder="เบอร์โทรหลัก"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="tel"
                  />
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <CustomTextInputImprove
                    v-model="telInput[1]"
                    ref="tel2"
                    title="เบอร์โทร (สำรอง)"
                    placeholder="เบอร์โทรสำรอง"
                    :disabled="!enableEdit"
                    dataInputName="tel2"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.personalEmail"
                    ref="personalEmail"
                    title="อีเมลส่วนตัว"
                    placeholder="อีเมลส่วนตัว"
                    :disabled="!enableEdit"
                    dataInputName="personalEmail"
                    :rules="personalEmailRules"
                  />

                  <!-- <AccountUsernameInputComponent
                    v-model="formData.personalEmail"
                    ref="personalEmail"
                    title="อีเมลส่วนตัว"
                    placeholder="อีเมลส่วนตัว"
                    :isEmailOnly="true"
                    :is-edit="true"
                    :disabled="!enableEdit"
                    @handler-processing-func="checkLoadingStatus"
                  /> -->
                </v-col>
              </v-row>
              <v-divider class="mb-6 mt-1"></v-divider>
              <v-row no-gutters class="px-6">
                <v-col cols="12" md="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.emergencyPhoneNumber"
                    ref="tel3"
                    title="เบอร์ติดต่อฉุกเฉิน"
                    placeholder="เบอร์ติดต่อฉุกเฉิน"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="tel3"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="salutationUrgentTh"
                    v-model="formData.salutationUrgent"
                    placeholder="เลือก"
                    title="คำนำหน้าผู้ติดต่อฉุกเฉิน (ภาษาไทย)"
                    :is-required="true"
                    :items="['นาย', 'นางสาว', 'นาง']"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="salutationUrgentTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.firstNameUrgent"
                    ref="firstnameUrgentTh"
                    title="ชื่อผู้ติดต่อฉุกเฉิน (ภาษาไทย)"
                    inputType="thOnly"
                    placeholder="ชื่อผู้ติดต่อฉุกเฉิน"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="firstnameUrgentTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.lastNameUrgent"
                    ref="lastnameUrgentTh"
                    title="นามสกุลผู้ติดต่อฉุกเฉิน (ภาษาไทย)"
                    inputType="thOnly"
                    placeholder="ชื่อผู้ติดต่อฉุกเฉิน"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="lastnameUrgentTh"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.urgentRelation"
                    ref="urgentRelationTh"
                    title="เกี่ยวข้องเป็น"
                    inputType="thOnly"
                    placeholder="เกี่ยวข้องเป็น"
                    :is-required="true"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="urgentRelationTh"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-6 mt-1"></v-divider>
              <v-row no-gutters class="px-6">
                <v-col cols="12" lg="3" sm="6">
                  <CustomSelectsImprove
                    ref="applyMethod"
                    v-model="formData.applyMethod"
                    placeholder="เลือก"
                    title="ช่องทางการรับสมัคร"
                    :is-required="true"
                    :items="applyMethodItems"
                    :disabled="!enableEdit"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                    dataInputName="applyMethod"
                  />
                </v-col>
                <v-col cols="12" lg="3" sm="6">
                  <CustomTextInputImprove
                    v-model="formData.applyMethodName"
                    ref="applyMethodNameTh"
                    title="ระบุช่องทางการรับสมัคร (กรณี อื่นๆ)"
                    placeholder="ช่องทางการรับสมัคร"
                    :is-required="true"
                    :disabled="!enableEdit || formData.applyMethod !== 'อื่นๆ'"
                    :rules="
                      formData.applyMethod == 'อื่นๆ'
                        ? [(v) => !!v || 'จำเป็นต้องระบุ']
                        : []
                    "
                    dataInputName="applyMethodNameTh"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- <div class="mt-6 mb-4">
          <v-divider></v-divider>
        </div> -->

        <AddressFormView
          class="mx-0 pb-3"
          ref="permanentAddressRef"
          title="ที่อยู่ตามบัตรประชาชน"
          :isTitleHighlight="true"
          :addressData="formData.address.permanentAddress"
          :disabled="!enableEdit"
          prefixName="permanentAddress"
        >
          <template v-slot:fileupload>
            <v-row no-gutters class="px-3">
              <v-col cols="12" lg="6" md="6" sm="12"
                ><div class="px-12 mx-12">
                  <span class="title-input">
                    รูปภาพหน้าบัตรประชาชน (ไม่เกิน 10MB)</span
                  >
                  <!-- <span class="red--text"> * </span> -->
                  <div class="mt-2"></div>
                  <AppFilesUpload
                    placeholder="เลือกรูปภาพ"
                    :isClearInput="false"
                    :multiple="false"
                    :small-chips="false"
                    :allow-image-only="true"
                    :upload-with-scale="false"
                    :isPrependInnerIcon="true"
                    v-model="formData.idCardImage"
                    ref="AppFilesUploadIdCardImage"
                    :disabled="!canEdit"
                    uploadTag="EMP_ID_CARD"
                  />
                </div>
                <v-img
                  :src="idCardImageInput.src"
                  height="365"
                  contain
                  class="backgroud-image mx-12"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" justify="center">
                      <v-icon x-large>mdi-image</v-icon>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12"
                ><div class="px-12 mx-12">
                  <span class="title-input">
                    รูปภาพสำเนาทะเบียนบ้าน (ไม่เกิน 10MB)</span
                  >
                  <div class="mt-2"></div>
                  <AppFilesUpload
                    placeholder="เลือกรูปภาพ"
                    :isClearInput="false"
                    :multiple="false"
                    :small-chips="false"
                    :allow-image-only="true"
                    :upload-with-scale="false"
                    :isPrependInnerIcon="true"
                    v-model="formData.houseRegistImage"
                    ref="AppFilesUploadhouseRegist"
                    :disabled="!canEdit"
                    uploadTag="EMP_HOUSE_REGIST"
                  />
                </div>
                <v-img
                  :src="houseRegistrationInput.src"
                  height="365"
                  contain
                  class="backgroud-image mx-12"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" justify="center">
                      <v-icon x-large>mdi-image</v-icon>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </template>
        </AddressFormView>

        <!-- <div class="mt-6 mb-4">
          <v-divider></v-divider>
        </div> -->

        <AddressFormView
          class="mx-0 pb-5"
          ref="currentAddressRef"
          title="ที่อยู่ปัจจุบัน"
          :isTitleHighlight="true"
          :addressData="formData.address.currentAddress"
          :disabled="sameAddressAsIDCardCheckbox || !enableEdit"
          prefixName="permanentAddress"
        >
          <template v-slot:checkbox>
            <v-checkbox
              class="px-5"
              v-model="sameAddressAsIDCardCheckbox"
              label="ใช้ที่อยู่เดียวกับบัตรประชาชน"
              dense
            ></v-checkbox>
          </template>
        </AddressFormView>
      </v-form>
      <AppSubmitFormButton
        @on-click-save="saveChange"
        v-if="enableEdit"
        :disabled="submitBtnState"
        text="บันทึกข้อมูลส่วนตัว"
      />
    </CustomCardFormViewModern>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextarea from "@/components/CustomTextarea.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import AddressFormView from "./AddressFormView.vue";
import CustomSelects from "@/components/CustomSelects.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import _ from "lodash";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import EmployeePersonalEmpIdInputComponent from "./EmployeePersonalEmpIdInputComponent.vue";
import AccountUsernameInputComponent from "./AccountUsernameInputComponent.vue";
import { getDateStringNow } from "@/services/appDate";
import moment from "moment";

export default {
  name: "EmployeePersonalInformationForm",
  components: {
    CustomTextInput,
    CustomTextarea,
    CustomAutocomplete,
    AddressFormView,
    EmployeePersonalEmpIdInputComponent,
    CustomSelects,
    CustomDateTypingPickerFormInput,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    CustomSelectsImprove,
    AppSubmitFormButton,
    AppFilesUpload,
    AccountUsernameInputComponent,
  },

  props: {
    canEdit: true,
    userId: {
      type: [String, null],
      default: null,
    },
    data: {
      type: [Object, null],
      default: null,
    },
  },

  data() {
    return {
      // formdata
      valid: false,
      submitBtnState: false,
      sameAddressAsIDCardCheckbox: false,
      oldEmpId: null,
      foreverIdCardDate: false,
      finalDate: "3000-12-31",

      formData: {
        empId: null,
        idCardNumber: null,
        idCardExpiryDate: null,
        salutation: {
          th: null,
          en: null,
        },
        firstName: {
          th: null,
          en: null,
        },
        lastName: {
          th: null,
          en: null,
        },
        nickName: {
          th: null,
          en: null,
        },
        salutationUrgent: null,
        firstNameUrgent: null,
        lastNameUrgent: null,
        urgentRelation: null,
        birthdayDate: null,
        nationality: null,
        // nationalityName: null,
        maritalStatus: null,
        bloodGroup: null,
        // bloodGroupName: null,
        religion: null,
        // religionName: null,
        height: null,
        weight: null,
        militaryStatus: null,
        // militaryStatusName: null,
        personalEmail: null,
        applyMethod: null,
        // applyMethodName: null,
        idCardImage: null,
        houseRegistImage: null,
        address: {
          permanentAddress: {
            prefix: null,
            road: null,
            subdistrict: null,
            district: null,
            province: null,
            postalCode: null,
            provinceId: null,
            districtId: null,
            subDistrictId: null,
          },
          currentAddress: {
            prefix: null,
            road: null,
            subdistrict: null,
            district: null,
            province: null,
            postalCode: null,
            provinceId: null,
            districtId: null,
            subDistrictId: null,
          },
          emergencyPhoneNumber: null,
        },
      },

      addressTemplete: {
        prefix: null,
        road: null,
        subdistrict: null,
        district: null,
        province: null,
        postalCode: null,
        provinceId: null,
        districtId: null,
        subDistrictId: null,
      },
      idCardRawSource: null,
      houseRegistrationRawSource: null,
      idCardImageInput: {
        src: "",
      },
      houseRegistrationInput: {
        src: "",
      },
      telInput: [null, null, null],

      ageView: null,
      idCardExpirationView: null,
      nationalities: [
        "ไทย",
        "พม่า",
        "กัมพูชา",
        "ลาว",
        "จีน",
        "ญี่ปุ่น",
        "เกาหลี",
        "อังกฤษ",
        "อื่นๆ",
      ],
      maritalItems: [
        "โสด",
        "สมรส",
        "สมรสไม่จดทะเบียน",
        "แยกกันอยู่",
        "หย่า",
        "หม้าย",
      ],
      bloodGroupItems: ["O", "A", "B", "AB", "อื่นๆ"],
      religionItems: ["พุทธ", "อิสลาม", "คริสต์", "ไม่มีศาสนา", "อื่นๆ"],
      militaryStatusItems: [
        "ได้รับการยกเว้น",
        "ปลดเป็นกองหนุน",
        "ยังไม่ได้เกณฑ์",
        "อื่นๆ",
      ],
      applyMethodItems: [
        "JobThai",
        "Facebook",
        "Line",
        "เพื่อนแนะนำ",
        "Walk-in",
        "อื่นๆ",
      ],
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
      personalEmailRules: [
        (value) => {
          if (value === null) {
            return true;
          } else if (typeof value === "string") {
            if (value.length === 0) {
              return true;
            }
          }

          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "รูปแบบของอีเมลส่วนตัวไม่ถูกต้อง";
        },
      ],
    };
  },

  computed: {
    enableEdit: {
      get() {
        return this.canEdit;
      },
    },
  },

  watch: {
    data(newValue) {
      this.processData(newValue);
    },

    "formData.birthdayDate"(newValue) {
      try {
        if (moment(newValue, "YYYY-MM-DD", true).isValid() === false) {
          throw "error";
        }
        let moDate = moment().diff(moment(newValue), "year");
        if (_.isNaN(moDate)) {
          throw "error";
        }
        this.ageView = `วันเดือนปีเกิด (อายุ ${moDate} ปี)`;
      } catch (error) {
        this.ageView = "วันเดือนปีเกิด";
      }
    },
    "formData.idCardExpiryDate"(newValue) {
      try {
        if (moment(newValue, "YYYY-MM-DD", true).isValid() === false) {
          throw "error";
        }
        let moDate = moment(newValue).diff(moment(), "day");
        if (_.isNaN(moDate)) {
          throw "error";
        }
        this.idCardExpirationView = `วันหมดอายุบัตรประชาชน (${moDate} วัน)`;
      } catch (error) {
        this.idCardExpirationView = "วันหมดอายุบัตรประชาชน";
      }
    },
    "formData.idCardImage"(newFile) {
      if (!_.isNil(newFile)) {
        let isSelectedFile = Array.isArray(newFile);
        if (isSelectedFile) {
          if (newFile.length !== 0) {
            this.idCardImageInput = _.clone(newFile[0]);
          } else {
            if (!_.isNil(this.idCardRawSource)) {
              this.idCardImageInput.src = this.idCardRawSource.src;
            } else {
              this.idCardImageInput.src = "";
            }
          }
        } else {
          this.idCardRawSource = newFile;
          this.idCardImageInput.src = newFile.src;
          // this.saveChange();
        }
      }
    },

    "formData.houseRegistImage"(newFile) {
      if (!_.isNil(newFile)) {
        let isSelectedFile = Array.isArray(newFile);
        if (isSelectedFile) {
          if (newFile.length !== 0) {
            this.houseRegistrationInput = _.clone(newFile[0]);
          } else {
            if (!_.isNil(this.houseRegistrationRawSource)) {
              this.houseRegistrationInput.src =
                this.houseRegistrationRawSource.src;
            } else {
              this.houseRegistrationInput.src = "";
            }
          }
        } else {
          this.houseRegistrationRawSource = newFile;
          this.houseRegistrationInput.src = newFile.src;
          // this.saveChange();
        }
      }
    },

    sameAddressAsIDCardCheckbox(newValue) {
      if (newValue === true) {
        let getPermanentAddress =
          this.$refs.permanentAddressRef.getCurrentStateData();
        this.formData.address.currentAddress = _.clone(getPermanentAddress);
      } else {
        this.formData.address.currentAddress = _.clone(this.addressTemplete);
      }
    },
  },

  methods: {
    checkLoadingStatus(loadingStatus) {
      this.submitBtnState = loadingStatus;
    },

    processData(_vd) {
      if (_.isPlainObject(_vd)) {
        this.formData = _.clone(_vd);
        if (this.formData["idCardExpiryDate"] === this.finalDate) {
          this.formData["idCardExpiryDate"] = getDateStringNow();
          this.foreverIdCardDate = true;
        }
        this.oldEmpId = this.formData["empId"] || null;
        // set phone
        let totalPhoneNumber = this.formData["phoneNumber"].length;
        for (let i = 0; i < totalPhoneNumber; i++) {
          this.telInput[i] = this.formData["phoneNumber"][i];
        }
      } else {
        this.canEdit = false;
      }
    },

    calculateAge() {
      if (this.birthdayInput) {
        var ageInMilliseconds = new Date() - new Date(this.birthdayInput);
        this.ageView = `วันเดือนปีเกิด (อายุ ${Math.floor(
          ageInMilliseconds / 1000 / 60 / 60 / 24 / 365
        )} ปี)`;
      }
    },

    redictToInvalid() {
      this.$refs.employeePersonalInformationForm.$el.scrollIntoView();
      // if (!this.idCardInput || this.idCardInput === "") {
      //   this.idCardInput = "";
      //   this.$refs.idCard.$el.scrollIntoView(this.focus);
      // } else if (this.idCardInput.length != 13) {
      //   this.$refs.idCard.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.idCardExpirationDateInput ||
      //   this.idCardExpirationDateInput === ""
      // ) {
      //   this.idCardExpirationDateInput = "";
      //   this.$refs.idCardExpirationDate.$el.scrollIntoView(this.focus);
      // } else if (!this.salutationThInput || this.salutationThInput === "") {
      //   this.salutationThInput = "";
      //   this.$refs.salutationTh.$el.scrollIntoView(this.focus);
      // } else if (!this.firstnameThInput || this.firstnameThInput === "") {
      //   this.firstnameThInput = "";
      //   this.$refs.firstnameTh.$el.scrollIntoView(this.focus);
      // } else if (!this.lastnameThInput || this.lastnameThInput === "") {
      //   this.lastnameThInput = "";
      //   this.$refs.lastnameTh.$el.scrollIntoView(this.focus);
      // } else if (!this.nickNameThInput || this.nickNameThInput === "") {
      //   this.nickNameThInput = "";
      //   this.$refs.nickNameTh.$el.scrollIntoView(this.focus);
      // } else if (!this.salutationEngInput || this.salutationEngInput === "") {
      //   this.salutationEngInput = "";
      //   this.$refs.salutationEng.$el.scrollIntoView(this.focus);
      // } else if (!this.firstnameEngInput || this.firstnameEngInput === "") {
      //   this.firstnameEngInput = "";
      //   this.$refs.firstnameEng.$el.scrollIntoView(this.focus);
      // } else if (!this.lastnameEngInput || this.lastnameEngInput === "") {
      //   this.lastnameEngInput = "";
      //   this.$refs.lastnameEng.$el.scrollIntoView(this.focus);
      // } else if (!this.nickNameEngInput || this.nickNameEngInput === "") {
      //   this.nickNameEngInput = "";
      //   this.$refs.nickNameEng.$el.scrollIntoView(this.focus);
      // } else if (!this.birthdayInput || this.birthdayInput === "") {
      //   this.birthdayInput = "";
      //   this.$refs.birthday.$el.scrollIntoView(this.focus);
      // } else if (!this.telInput || this.telInput === "") {
      //   this.telInput = "";
      //   this.$refs.tel.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.permanentPrefixAddressInput ||
      //   this.permanentPrefixAddressInput === ""
      // ) {
      //   this.permanentPrefixAddressInput = "";
      //   this.$refs.address.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.permanentProvinceInput ||
      //   this.permanentProvinceInput === ""
      // ) {
      //   this.permanentProvinceInput = "";
      //   this.$refs.address.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.permanentDistrictInput ||
      //   this.permanentDistrictInput === ""
      // ) {
      //   this.permanentDistrictInput = "";
      //   this.$refs.address.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.permanentSubdistrictInput ||
      //   this.permanentSubdistrictInput === ""
      // ) {
      //   this.permanentSubdistrictInput = "";
      //   this.$refs.address.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.permanentPostalCodeInput ||
      //   this.permanentPostalCodeInput === ""
      // ) {
      //   this.permanentPostalCodeInput = "";
      //   this.$refs.address.$el.scrollIntoView(this.focus);
      // } else if (!this.currentAddressInput || this.currentAddressInput === "") {
      //   this.currentAddressInput = "";
      //   this.$refs.currentAddress.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.currentProvinceInput ||
      //   this.currentProvinceInput === ""
      // ) {
      //   this.currentProvinceInput = "";
      //   this.$refs.currentAddress.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.currentDistrictInput ||
      //   this.currentDistrictInput === ""
      // ) {
      //   this.currentDistrictInput = "";
      //   this.$refs.currentAddress.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.currentSubdistrictInput ||
      //   this.currentSubdistrictInput === ""
      // ) {
      //   this.currentSubdistrictInput = "";
      //   this.$refs.currentAddress.$el.scrollIntoView(this.focus);
      // } else if (
      //   !this.currentPostalCodeInput ||
      //   this.currentPostalCodeInput === ""
      // ) {
      //   this.currentPostalCodeInput = "";
      //   this.$refs.currentAddress.$el.scrollIntoView(this.focus);
      // }

      return true;
    },

    async saveChange() {
      if (this.$refs.employeePersonalInformationForm.validate() === true) {
        let getPermanentAddressData =
          this.$refs.permanentAddressRef.getCurrentStateData();
        let getCurrentAddressData =
          this.$refs.currentAddressRef.getCurrentStateData();
        //
        let addressDataList = {
          currentAddress: { ...getCurrentAddressData },
          permanentAddress: { ...getPermanentAddressData },
        };

        let returnIdCardImage = null;
        if (!_.isNil(this.formData.idCardImage)) {
          let isNewIdCard = Array.isArray(this.formData.idCardImage);
          if (isNewIdCard) {
            // Adding New ID Card
            if (this.formData.idCardImage.length > 0) {
              let resultIdCardImageUpload =
                await this.$refs.AppFilesUploadIdCardImage.uploadAction();
              if (resultIdCardImageUpload.length !== 0) {
                if (resultIdCardImageUpload[0]["code"] >= 400) {
                  // is error
                  throw resultIdCardImageUpload[0];
                  this.$refs.AppFilesUploadIdCardImage.clearInput();
                } else {
                  returnIdCardImage = resultIdCardImageUpload[0].data;
                }
              }
            } else if (!_.isNil(this.idCardRawSource)) {
              returnIdCardImage = {
                fileKey: this.idCardRawSource.fileKey,
              };
            }
          } else {
            returnIdCardImage = {
              fileKey: this.formData.idCardImage.fileKey,
            };
          }
        }

        let returnHouseRegistImage = null;
        if (!_.isNil(this.formData.houseRegistImage)) {
          let isNewHouseRegist = Array.isArray(this.formData.houseRegistImage);
          if (isNewHouseRegist) {
            if (this.formData.houseRegistImage.length > 0) {
              let resultHouseRegistImageUpload =
                await this.$refs.AppFilesUploadhouseRegist.uploadAction();
              if (resultHouseRegistImageUpload.length !== 0) {
                if (resultHouseRegistImageUpload[0]["code"] >= 400) {
                  // is error
                  throw resultHouseRegistImageUpload[0];
                  this.$refs.AppFilesUploadhouseRegist.clearInput();
                } else {
                  returnHouseRegistImage = resultHouseRegistImageUpload[0].data;
                }
              }
            } else if (!_.isNil(this.houseRegistrationRawSource)) {
              returnHouseRegistImage = {
                fileKey: this.houseRegistrationRawSource.fileKey,
              };
            }
          } else {
            returnHouseRegistImage = {
              fileKey: this.formData.houseRegistImage.fileKey,
            };
          }
        }

        //
        let responseData = _.pick(_.cloneDeepWith(this.formData), [
          "empId",
          "salutation",
          "firstName",
          "lastName",
          "nickName",
          "birthdayDate",
          "idCardNumber",
          "idCardExpiryDate",
          "phoneNumber",
          "personalEmail",
          "nationality",
          "nationalityName",
          "religion",
          "religionName",
          "maritalStatus",
          "militaryStatus",
          "militaryStatusName",
          "bloodGroup",
          "bloodGroupName",
          "height",
          "weight",
          "firstNameUrgent",
          "salutationUrgent",
          "lastNameUrgent",
          "urgentRelation",
          "applyMethod",
          "applyMethodName",
          "emergencyPhoneNumber",
        ]);

        //
        this.oldEmpId = responseData["empId"] || null;
        //
        responseData["phoneNumber"] = _.filter(
          this.telInput,
          (value) => value !== null
        );
        responseData["address"] = { ...addressDataList };
        responseData["height"] = parseFloat(responseData["height"]).toFixed(2);
        responseData["weight"] = parseFloat(responseData["weight"]).toFixed(2);
        // filter
        let filterOutField = ["subDistrictList", "districtList"];
        responseData["address"]["currentAddress"] = _.pickBy(
          responseData["address"]["currentAddress"],
          (value, key) => !filterOutField.includes(key)
        );

        responseData["address"]["permanentAddress"] = _.pickBy(
          responseData["address"]["permanentAddress"],
          (value, key) => !filterOutField.includes(key)
        );

        if (this.foreverIdCardDate) {
          responseData["idCardExpiryDate"] = this.finalDate;
        }
        //

        const filterNameTh = ["district", "subdistrict", "province"];
        const addressField = ["currentAddress", "permanentAddress"];
        for (let i = 0; i < addressField.length; i++) {
          for (let j = 0; j < filterNameTh.length; j++) {
            responseData["address"][addressField[i]][filterNameTh[j]] =
              !_.isNil(
                responseData["address"][addressField[i]][filterNameTh[j]]
              )
                ? responseData["address"][addressField[i]][filterNameTh[j]][
                    "nameTh"
                  ]
                : null;
          }
        }

        responseData["idCardImage"] = { ...returnIdCardImage };
        responseData["houseRegistImage"] = { ...returnHouseRegistImage };

        this.$emit("on-click-save", responseData, addressDataList);
      } else {
        this.redictToInvalid();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-form {
  font-size: 16px;
  color: rgb(77, 86, 86);
  font-weight: bold;
}

.text-age {
  font-size: 14px;
  color: var(--v-bodytext);
  font-weight: normal;
}

.backgroud-image {
  background: #f4f6f6;
}
</style>
