<template>
  <v-container name="vehicleEvents" fluid>
    <div v-if="isPermissionCanView">
      <v-row class="mb-1">
        <v-col cols="auto">
          <h1 class="text-h5 mt-1">รายการซ่อม/บำรุง/ตกแต่ง/ตรวจสภาพ</h1>
        </v-col>

        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <AppAutoCompleteVehicleSearching
            :key="componentRefreshCounter"
            :tableLoading="tableLoading"
            ref="AppAutoCompleteVehicleSearchingRef"
            @searchItemChange="getSearchItem"
          />
        </v-col>
        <v-col cols="auto">
          <v-menu
            v-model="isShowMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            :nudge-bottom="10"
            :nudge-left="100"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D5DBDB"
                light
                large
                v-bind="attrs"
                v-on="on"
                elevation="0"
                outlined
                :style="{
                  backgroundColor: 'white !important',
                }"
              >
                <v-icon left color="#4D5656"> mdi-filter </v-icon>
                <div class="normal-button-text">{{ "ตัวกรอง" }}</div>
              </v-btn>
            </template>
            <VehicleEventFilterGroup
              :key="componentRefreshCounter"
              @close-menu="isShowMenu = false"
              @returnFiltedData="getfilterItem"
            />
          </v-menu>
        </v-col>
        <v-col cols="auto" v-if="isPermissionCanEdit">
          <PrimaryButton
            :large="true"
            icon="mdi-plus"
            @on-click="handleAddClick()"
          >
            เพิ่มข้อมูล
          </PrimaryButton>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="queryOptionsTable.limit"
        :page="queryOptionsTable.page"
        :loading="tableLoading"
        :server-items-length="serverItemsLength"
        :footer-props="dataTableFooterProps"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        @update:page="tablePageHandler"
        @update:items-per-page="tableItemPerPageHandler"
        class="app-datatable"
      >
        <template v-slot:top>
          <div
            class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
          >
            <v-card class="d-flex flex-row" flat tile width="320">
              <v-select
                class="app-custom-input"
                :items="sortList"
                label="เรียงลำดับโดย"
                dense
                outlined
                v-model="sortVal"
                @change="tableSortHandler"
              ></v-select>
            </v-card>

            <NormalButton icon="mdi-refresh" @on-click="reset()">
              รีเซ็ต
            </NormalButton>
          </div>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <router-link
            v-if="isPermissionCanEdit || isPermissionCanViewDetail"
            class="text-decoration-none text-app-title font-weight-bold"
            :to="'/vehicle/event/detail/' + item._id"
            >{{ item.title }}</router-link
          >
          <span v-else> {{ item.title }} </span>
        </template>

        <template v-slot:[`item.totalAmount`]="{ item }">
          {{ item.totalAmount ? item.totalAmount.toLocaleString() : "-" }}
        </template>

        <template v-slot:[`item.type`]="{ item }">
          <span :class="vehicleEventTypeColor(item.type)">
            {{ vehicleEventTypeValue(item.type) }}
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            label
            small
            block
            :class="vehicleEventProcessStatusColor(item.status)"
          >
            {{ vehicleEventProcessStatus(item.status) }}
          </v-chip>
        </template>

        <template v-slot:[`item.tools`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- <v-list-item
                @click="handleEditClick(item._id)"
                :disabled="!isPermissionCanEdit && !isPermissionCanViewDetail"
              >
                <v-list-item-icon>
                  <v-icon> mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title">
                  {{ editTextBtn }}</v-list-item-title
                >
              </v-list-item> -->
              <v-list-item
                @click="handleDeleteClick(item._id)"
                :disabled="!isPermissionCanEdit"
              >
                <v-list-item-icon>
                  <v-icon> mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-app-title"
                  >ลบข้อมูล</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>

    <AppPageNotFound :show="isPermissionCanView" />
    <AppDialogConfirm ref="confirmDelete" />
  </v-container>
</template>
<script>
import PrimaryButton from "@/components/PrimaryButton.vue";
import NormalButton from "@/components/NormalButton.vue";
import SortButton from "@/components/SortButton.vue";
import { mapGetters } from "vuex";
import {
  getVehicleEventService,
  deleteVehicleEventServiceService,
} from "@/services/api/vehicle_event";
import { dateStringToDateFormat } from "@/services/appDate";
import { processPermission } from "@/services/permissions";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";

import { DialogType } from "@/services/dialog";
import AppAutoCompleteVehicleSearching from "@/components/AppAutoCompleteVehicleSearching.vue";
import VehicleEventFilterGroup from "../components/VehicleEventFilterGroup.vue";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import _ from "lodash";
import {
  appDecodeSingleURIComponentHelper,
  appEncodeURIComponentHelper,
} from "@/services/appStringHelpper";

export default {
  name: "VehicleEventListView",
  components: {
    PrimaryButton,
    NormalButton,
    SortButton,
    AppDialogConfirm,
    AppAutoCompleteVehicleSearching,
    VehicleEventFilterGroup,
    AppPageNotFound,
  },

  data: () => ({
    _licenseNumber: null,
    componentRefreshCounter: 0,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    tableHeaders: [
      {
        text: "ชื่อรายการ",
        align: "start",
        sortable: false,
        value: "title",
        width: "20%",
      },
      {
        text: "ป้ายทะเบียนรถ",
        value: "licenseNumber",
        sortable: false,
        align: "center",
        width: "15%",
      },
      {
        text: "ประเภท",
        align: "start",
        sortable: false,
        value: "type",
        width: "15%",
      },
      {
        text: "สถานะ",
        align: "start",
        sortable: false,
        value: "status",
        width: "10%",
      },
      {
        text: "จำนวนบันทึก",
        value: "count_note",
        sortable: false,
        align: "center",
        width: "10%",
      },
      {
        text: "จำนวนสรุป",
        value: "count_report",
        sortable: false,
        align: "center",
        width: "10%",
      },
      {
        text: "ค่าใช้จ่าย (บาท)",
        value: "totalAmount",
        align: "right",
        sortable: false,
        width: "10%",
      },
      {
        text: "เครื่องมือ",
        value: "tools",
        sortable: false,
        align: "center",
        width: "15%",
      },
    ],
    tableLoading: false,
    serverItemsLength: -1,
    //
    tableData: [],
    // search
    queryOrder: {
      // query : formData
      type: "type",
      status: "status",
      ///
      mincreatedate: "mincreatedate",
      maxcreatedate: "maxcreatedate",
      //
      minappointmentdate: "minappointmentdate",
      maxappointmentdate: "maxappointmentdate",
    },
    queryOptionsTable: {
      limit: 20, // itemsPerPage = limit
      by: "createddate", // key
      order: "desc", // asc or desc
      page: 1, // current page
    },
    queryStringSearch: "",
    queryStringSearchList: {},
    queryFilterList: {},
    querySearchAndFilterList: {},
    field_sort_by: null,
    sortVal: null,
    sortList: [
      {
        text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
        value: "createddate-asc",
      },
      {
        text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
        value: "createddate-desc",
      },
      {
        text: "ชื่อรายการ เรียงจาก น้อย-มาก",
        value: "title-asc",
      },
      {
        text: "ชื่อรายการ เรียงจาก มาก-น้อย",
        value: "title-desc",
      },
      {
        text: "วันที่นัดหมาย เรียงจาก น้อย-มาก",
        value: "appointment-asc",
      },
      {
        text: "วันที่นัดหมาย เรียงจาก มาก-น้อย",
        value: "appointment-desc",
      },
    ],

    // menu
    isShowMenu: false,
    isPermissionCanEdit: false,
    isPermissionCanView: false,
    isPermissionCanViewDetail: false,
    //
    permList: {},
    editTextBtn: "แก้ไขข้อมูล",
  }),

  // watch: {
  //   options: {
  //     handler() {
  //       this.getData();
  //     },
  //     deep: true,
  //   },
  // },
  computed: {
    ...mapGetters({
      vehicleEventTypeValue: "vehicle/vehicleEventTypeValue",
      vehicleEventTypeColor: "vehicle/vehicleEventTypeColor",
      vehicleEventProcessStatus: "vehicle/vehicleEventProcessStatus",
      vehicleEventProcessStatusColor: "vehicle/vehicleEventProcessStatusColor",
    }),
    tableRows() {},
  },
  mounted() {
    this.checkUserPermissions();
    this.processRouterQuery(this.$route.query);
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    processRouterQuery(_queryRoute) {
      if (Object.keys(_queryRoute).length > 0) {
        Object.keys(_queryRoute).forEach((key) => {
          if (!_.isNil(_queryRoute[key])) {
            switch (key) {
              case "text":
                this.querySearchAndFilterList[key] =
                  appDecodeSingleURIComponentHelper(_queryRoute[key]);
                break;
              case "limit":
              case "page":
                try {
                  this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
                } catch (error) {
                  this.queryOptionsTable[key] = this.queryOptionsTable[key];
                }
                break;
              case "by":
              case "order":
                this.queryOptionsTable[key] = _queryRoute[key];
                break;
              default:
                // filter and search
                this.querySearchAndFilterList[key] = _queryRoute[key];
                break;
            }
          }
        });

        this.querySearchAndFilterList = {
          ...this.queryOptionsTable,
          ...this.querySearchAndFilterList,
        };
      } else {
        // set default parameter
        this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
      }
      //
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      // this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    setRouterQuery(queryObj) {
      const _queryObj = appEncodeURIComponentHelper(queryObj);

      this.$router
        .replace({
          path: this.$router.path,
          query: _queryObj,
        })
        .catch(() => {});
    },
    tableUpdateHandler(type, value) {
      switch (type) {
        case "sort":
          let _fieldSortBy = value.split("-");
          this.queryOptionsTable["by"] = [_fieldSortBy[0]];
          this.queryOptionsTable["order"] = [_fieldSortBy[1]];
          break;
        default:
          this.queryOptionsTable[type] = value;
          break;
      }

      this.querySearchAndFilterList = {
        ...this.querySearchAndFilterList,
        ...this.queryOptionsTable,
      };

      this.setRouterQuery(this.querySearchAndFilterList);
      this.getData();
    },
    tablePageHandler(value) {
      this.tableUpdateHandler("page", value);
    },
    tableItemPerPageHandler(value) {
      this.tableUpdateHandler("limit", value);
    },
    tableSortHandler(value) {
      this.tableUpdateHandler("sort", value);
    },
    processSearchingParams(_queryParameter) {
      // combine query search
      console.log("Before", this.querySearchAndFilterList);
      this.querySearchAndFilterList = {
        ...this.queryOptionsTable, // default table
        ...this.querySearchAndFilterList, // old query data
        ..._queryParameter,
      };
      console.log("After", this.querySearchAndFilterList);
      this.setRouterQuery(this.querySearchAndFilterList);
    },

    checkUndefinedAndNull(item, key, returnBoolean) {
      if (item.hasOwnProperty(key) === false) {
        return returnBoolean ? false : "-";
      }

      if (item[key] === null) {
        return returnBoolean ? false : "-";
      }
      // if string
      if (item[key].length === 0) {
        return returnBoolean ? false : "-";
      }

      return item[key];
    },
    handleAddClick() {
      this.$router.push("/vehicle/event/create");
    },
    handleEditClick(_id) {
      this.$router.push(`/vehicle/event/detail/${_id}`);
    },
    async handleDeleteClick(_id) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        await deleteVehicleEventServiceService(this.$store, _id);
        this.getData();
      }
    },
    // sortByHandler(sortValue) {
    //   this.field_sort_by = sortValue.split("-");
    //   this.options["sortBy"] = [this.field_sort_by[0]];
    //   this.options["sortDesc"] = [this.field_sort_by[1]];
    // },
    async getData() {
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // if (_.isNil(sortBy)) {
      //   return;
      // }

      // let queryString = `${
      //   this.queryStringSearch.length === 0 ? "" : `${this.queryStringSearch}&`
      // }limit=${itemsPerPage}&page=${page}`;
      // if (sortBy.length !== 0) {
      //   queryString = `${queryString}&by=${sortBy}&order=${sortDesc}`;
      // }

      // this.tableLoading = true;
      // this.tableData = [];

      this.tableLoading = true;
      this.tableData = [];
      this.queryStringSearch = new URLSearchParams(
        this.querySearchAndFilterList
      ).toString();

      let responseData = await getVehicleEventService(
        this.$store,
        this.queryStringSearch
      );

      // let responseData = await getVehicleEventService(this.$store, queryString);
      if (responseData.code == 200) {
        this.serverItemsLength = responseData.data["total"];
        this.tableData = responseData.data["eventList"];
      } else {
        this.tableData = [];
      }

      this.tableLoading = false;
    },
    // getSearchCassieId() {
    //   // reset value
    //   delete this.queryStringSearchList["idname"];
    //   if (this._licenseNumber !== null) {
    //     this.queryStringSearchList["idname"] = this._licenseNumber;
    //     this.$refs.AppAutoCompleteVehicleSearchingRef.setValue(
    //       this._licenseNumber
    //     );
    //   }
    //   // build query string
    //   this.queryStringSearch = new URLSearchParams(
    //     this.queryStringSearchList
    //   ).toString();
    //   this.getData();
    // },
    getSearchItem(_searchingParameter) {
      //
      // callback from searching component when enter
      //
      this.queryStringSearchList = {};
      delete this.querySearchAndFilterList["text"];
      delete this.querySearchAndFilterList["cassieid"];
      let _queryParameter = {};
      if (!_.isNil(_searchingParameter)) {
        if (!_.isNil(_searchingParameter["key"])) {
          _queryParameter["cassieid"] = _searchingParameter["key"];
          _queryParameter["text"] = _searchingParameter["value"];
        } else {
          if (!_.isNil(_searchingParameter.value)) {
            _queryParameter["text"] = _searchingParameter["value"];
          }
        }
      }

      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    getfilterItem(_queryParameter) {
      //
      // callback from filter group
      //

      // if (!_.isEmpty(this.queryFilterList)) {
      //   Object.keys(this.queryFilterList).forEach((key) => {
      //     delete this.querySearchAndFilterList[key];
      //   });
      // }
      // this.queryFilterList = _queryParameter;

      Object.keys(this.queryOrder).forEach((key) => {
        delete this.querySearchAndFilterList[key];
      });
      this.processSearchingParams(_queryParameter);
      this.getData();
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "VEHICLE");

        if (this.permList.vehireparation_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.vehireparation_info === true) {
          this.isPermissionCanViewDetail = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.vehireparation_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },

    reset() {
      if (this.queryStringSearch.length !== 0) {
        this.componentRefreshCounter += 1;
      }
      this.queryOptionsTable = {
        limit: 20, // itemsPerPage = limit
        by: "createddate", // key
        order: "desc", // asc or desc
        page: 1, // current page
      };
      this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
      this.querySearchAndFilterList = {};
      this.processSearchingParams();
      this.getData();
    },
  },
};
</script>
