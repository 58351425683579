<template>
  <div class="text-center">
    <v-dialog scrollable v-model="show" width="1000" persistent>
      <v-card>
        <v-card-title class="text-h5"> เพิ่มรายการ </v-card-title>

        <v-card-text class="pt-3">
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12">
                <InputView title="ชื่อร้าน" :isRequired="true">
                  <DynamicReportElement
                    v-model="formData.garages"
                    column="garages"
                    :isMultiple="false"
                    :isChips="false"
                    placeholder="พิมพ์ค้นหาชื่อร้านหรือเพิ่มชื่อร้าน"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                  />
                </InputView>
                <!-- <CustomAutocomplete
                  v-model="formData.garages"
                  :items="garagesList"
                  title="ชื่อร้าน"
                  placeholder="พิมพ์ค้นหาชื่อร้านหรือเพิ่มชื่อร้าน"
                  :isRequired="true"
                  itemText="nameTh"
                  itemValue="key"
                  :returnObject="true"
                  :readonly="!canEdit"
                >
                </CustomAutocomplete> -->
              </v-col>
              <v-col cols="6">
                <CustomDateTimePickerFormInput
                  placeholder="เลือกเวลาวันที่เริ่ม"
                  title="เวลาวันที่เริ่ม"
                  v-model="formData.startDate"
                  @input="processStartDateFromPicker"
                  :isRequired="true"
                  :hideDetail="false"
                  :clearable="false"
                  :expendMargin="false"
                  :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                />
              </v-col>
              <v-col cols="6">
                <CustomDateTimePickerFormInput
                  placeholder="เลือกเวลาวันที่สิ้นสุด"
                  title="เวลาวันที่สิ้นสุด"
                  v-model="formData.endDate"
                  @input="processEndDateFromPicker"
                  :minDate="minDate"
                  :minTime="minTime"
                  :isRequired="true"
                  :hideDetail="false"
                  :clearable="false"
                  :expendMargin="false"
                  :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                />
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="tableHeaders"
                  :items="formData.partList"
                  :loading="tableLoading"
                  :no-data-text="dataTableNoDataText"
                  :loading-text="dataTableLoadingText"
                  :hide-default-footer="true"
                  class="app-datatable"
                >
                  <template v-slot:[`item.parts`]="{ item }">
                    <div v-if="item.parts !== null">
                      <div v-if="item.parts.text !== undefined">
                        {{ item.parts.text }}
                      </div>
                      <div v-else>{{ item.parts }}</div>
                    </div>
                  </template>
                  <template v-slot:[`item.problem`]="{ item }">
                    <div v-if="item.problem !== null">
                      <div
                        v-for="(problem, probIdx) in item.problem"
                        :key="probIdx"
                      >
                        <div v-if="problem.text !== undefined">
                          {{ problem.text }}
                        </div>
                        <div v-else>{{ problem }}</div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.detail`]="{ item }">
                    <div v-if="item.detail !== null">
                      <div v-if="item.detail.text !== undefined">
                        {{ item.detail.text }}
                      </div>
                      <div v-else>{{ item.detail }}</div>
                    </div>
                  </template>
                  <template v-slot:[`item.itemTotalPrice`]="{ item }">
                    <div v-if="item.itemTotalPrice !== null">
                      <div v-if="item.itemTotalPrice !== undefined">
                        {{ item.itemTotalPrice }}
                      </div>
                      <div v-else>-</div>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <template v-slot:[`item.tools`]="{ item, index }">
                    <!-- <DetailButton
                      text
                      class="mt-0"
                      color="deep-orange"
                      @on-click="handleDeleteItem(item)"
                      >ลบ</DetailButton
                    > -->
                    <v-btn
                      plain
                      class="text-decoration-underline error--text diplay--inline-block"
                      @click="handleDeleteItem(item, index)"
                    >
                      ลบ
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>

              <!-- <v-col cols="12">
                <InputView title="อะไหล่" :isRequired="false">
                  <p class="mb-2 text-body">
                    การเลือกอะไหล่ให้พิมพ์ตามลักษณะดังนี้
                    &lt;รหัสอะไหล่&gt;:&lt;ชื่ออะไหล่&gt;
                  </p>
                  <DynamicReportElement
                    v-model="formData.parts"
                    column="parts"
                    placeholder="พิมพ์ค้นหาอะไหล่หรือเพิ่มอะไหล่"
                  />
                </InputView>
              </v-col>
              <v-col cols="12">
                <InputView title="อู๋ซ่อม" :isRequired="false">
                  <DynamicReportElement
                    v-model="formData.garages"
                    column="garages"
                    placeholder="พิมพ์ค้นหาอู๋ซ่อมหรือเพิ่มอู๋ซ่อม"
                  />
                </InputView>
              </v-col> -->
            </v-row>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pt-3" align="center">
                <v-btn
                  elevation="0"
                  outlined
                  color="primary"
                  @click="addReplacementAction"
                >
                  + เพิ่มอะไหล่
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-divider class="py-3 mt-3"></v-divider>
            <v-row class="pb-3">
              <v-col cols="6" class="pa-0">
                <v-col class="px-0 py-0">
                  <CustomTextInputImprove
                    ref="netFee"
                    inputType="floatNumberWithOutSign"
                    v-model="formData.sumPriceBeforeVat"
                    :hideDetail="true"
                    title="ราคารวมก่อน VAT (บาท)"
                    placeholder="ราคารวมก่อน VAT (บาท)"
                    :isRequired="true"
                    :rules="[(v) => !!v || 'จำเป็นต้องระบุราคารวมก่อน VAT']"
                  ></CustomTextInputImprove>
                </v-col>

                <v-col class="py-0 pl-3 pr-0">
                  <v-row no-gutters>
                    <v-checkbox
                      class="pr-6"
                      v-model="formData.vatCalculate"
                      label="VAT (%)"
                      hide-details
                      color="primary"
                    ></v-checkbox>
                    <v-spacer></v-spacer>
                    <CustomTextInputImprove
                      class="ma-0"
                      :disabled="!formData.vatCalculate"
                      ref="totalFee"
                      inputType="floatNumberWithOutSign"
                      :hideDetail="true"
                      v-model="formData.vatPercent"
                      title=""
                      placeholder="VAT (%)"
                      :isRequired="false"
                      :rules="[(v) => !!v || 'จำเป็นต้องระบุ VAT']"
                    ></CustomTextInputImprove>
                  </v-row>
                </v-col>
              </v-col>
              <v-col cols="6" align="right">
                <v-col class="pt-6 pb-0">ราคารวม</v-col>
                <v-col class="pt-0 text-app-detail-large font-weight-bold">
                  {{ totalPrice }} บาท
                </v-col>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelStatus"> ยกเลิก </v-btn>
          <v-btn color="primary" text @click="saveStatus"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <VehicleReplacementDialog ref="eventReplacementDialog" />
    <AppDialogConfirm ref="confirmToRemovePart" />
    <AppDialogConfirm ref="alertAddingPart" />
  </div>
</template>

<script>
import _ from "lodash";
import { getDateStringNow } from "@/services/appDate";
import DynamicReportElement from "./components/DynamicReportElement.vue";
import InputView from "@/components/InputView.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTimePickerFormInput from "@/components/CustomTimePickerFormInput.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import VehicleReplacementDialog from "./components/VehicleReplacementDialog.vue";
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import { getVehiclePartsService } from "@/services/api/vehicle_event";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";

export default {
  name: "VehicleEventReportDialog",
  props: ["dialogStatus"],
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    currectItem: null,
    isMounted: false,
    formData: {
      garages: null,
      startDate: null,
      endDate: null,
      sumPriceBeforeVat: 0,
      vatPercent: 7,
      vatCalculate: true,

      partList: [],
    },

    minDate: null,
    minTime: null,
    // sumPriceBeforeVat: 0,
    // totalPrice: null,

    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    tableLoading: false,
    tableHeaders: [
      {
        text: "ชื่ออะไหล่",
        align: "start",
        sortable: false,
        value: "parts",
      },
      {
        text: "อาการ",
        align: "start",
        sortable: false,
        value: "problem",
      },
      {
        text: "ตำแหน่งที่เสียหาย",
        align: "start",
        sortable: false,
        value: "detail",
        width: "15%",
      },
      {
        text: "จำนวนชิ้น",
        align: "start",
        sortable: false,
        value: "amount",
        width: "10%",
      },
      {
        text: "ราคาต่อชิ้น",
        align: "start",
        sortable: false,
        value: "pricePerUnit",
        width: "15%",
      },
      {
        text: "ราคารวม",
        align: "start",
        sortable: false,
        value: "itemTotalPrice",
        width: "15%",
      },
      {
        text: "",
        value: "tools",
        sortable: false,
        align: "center",
        width: "7%",
      },
    ],
  }),
  watch: {
    "formData.partList"(newVal) {
      let nPrice = 0;
      if (!_.isNil(newVal)) {
        this.formData["sumPriceBeforeVat"] = "0";
        if (newVal.length > 0) {
          for (let idx in newVal) {
            nPrice += newVal[idx]["pricePerUnit"] * newVal[idx]["amount"];
          }
          if (nPrice > 0) {
            this.formData["sumPriceBeforeVat"] = nPrice;
          } else {
            this.formData["sumPriceBeforeVat"] = "0";
          }
        }

        if (this.isMounted) {
          this.formData["sumPriceBeforeVat"] = this.netPrice;
          this.isMounted = false;
        }
        // this.total = (this.sumPriceBeforeVat * 1.07).toFixed(2);
      }
    },
    // sumPriceBeforeVat(newVal) {
    //   if (!_.isNil) {
    //     this.totalPrice = (newVal * 1.07).toFixed(2);
    //   }
    // },
  },
  computed: {
    // sumPriceBeforeVat() {
    //   let nPrice = 0;
    //   if (!_.isNil(this.formData["partList"])) {
    //     if (this.formData["partList"].length > 0) {
    //       for (let idx in this.formData["partList"]) {
    //         nPrice +=
    //           this.formData["partList"][idx]["pricePerUnit"] *
    //           this.formData["partList"][idx]["amount"];
    //       }
    //     }
    //   }
    //   return nPrice;
    // },
    totalPrice() {
      let totalPrice = 0;
      if (!_.isNil(this.formData["sumPriceBeforeVat"])) {
        if (this.formData["vatCalculate"]) {
          totalPrice =
            this.formData["sumPriceBeforeVat"] *
            (1 + this.formData["vatPercent"] / 100);
          totalPrice = totalPrice.toFixed(2);
        } else {
          totalPrice = (this.formData["sumPriceBeforeVat"] * 1.0).toFixed(2);
        }
      }
      return totalPrice;
    },
    startDateRules() {
      return [
        (v) =>
          (_.isNil(this.formData.startTime) && !v) || !!v || "จำเป็นต้องระบุ",
      ];
    },
    endDateRules() {
      return [
        (v) =>
          (_.isNil(this.formData.endTime) && !v) || !!v || "จำเป็นต้องระบุ",
      ];
    },
  },
  methods: {
    resetFormData() {
      this.formData["garages"] = null;
      this.formData["startDate"] = null;
      this.formData["endDate"] = null;
      this.formData["sumPriceBeforeVat"] = 0;
      this.formData["vatPercent"] = 7;
      this.formData["vatCalculate"] = true;
      this.formData["partList"] = [];
    },

    getDate(val) {
      return val.split("T")[0];
    },
    getTime(val) {
      return val.split("T")[1];
    },

    processStartDateFromPicker(value) {
      this.formData["startDate"] = value;
      if (value !== null) {
        if (!_.isNil(this.formData["endDate"])) {
          let start = new Date(this.formData["startDate"]);
          let end = new Date(this.formData["endDate"]);

          if (start > end) {
            this.formData["endDate"] = this.formData["startDate"];
          }

          if (start.getDate() < end.getDate()) {
            this.minTime = null;
          } else {
            this.minTime = this.getTime(this.formData["startDate"]);
          }
        } else {
          this.minTime = this.getTime(this.formData["startDate"]);
        }
        this.minDate = this.getDate(this.formData["startDate"]);
      }
    },

    processEndDateFromPicker(value) {
      this.formData["endDate"] = value;
      if (value !== null) {
        if (!_.isNil(this.formData["startDate"])) {
          let start = new Date(this.formData["startDate"]);
          let end = new Date(this.formData["endDate"]);

          // if (start > end) {
          //   this.formData["endDate"] = this.formData["startDate"];
          // }

          if (start.getDate() === end.getDate()) {
            this.minTime = this.getTime(this.formData["startDate"]);
          } else {
            this.minTime = null;
          }
        } else {
          this.minTime = null;
        }
        // this.minDate = this.getDate(this.formData["startDate"]);
      }
    },

    async addReplacementAction() {
      const insertData = await this.$refs.eventReplacementDialog.open();
      this.isLoading = true;
      if (!_.isNil(insertData)) {
        this.formData["partList"].push(insertData);
        // let respData = await postVehicleEventReportService(
        //   this.$store,
        //   this._id,
        //   insertData
        // );
        // //
        // if (respData["code"] === 200) {
        //   await this.getEventReportList();
        // } else {
        //   // insert fail
        // }
      }
      this.isLoading = false;
    },

    async handleDeleteItem(part, index) {
      if (
        await this.$refs.confirmToRemovePart.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.ACTION,
          {
            noconfirm: true,
          }
        )
      ) {
        this.isLoading = true;

        this.deleteVehiclePartService(part, index);
        // await deleteVehicleEventReportService(this.$store, reportId);
        // await this.getEventReportList();
        this.isLoading = false;
      }
    },

    deleteVehiclePartService(part, index) {
      let partList = _.cloneDeep(this.formData["partList"]);
      partList.splice(index, 1);
      this.formData["partList"] = _.cloneDeep(partList);
    },

    processDateAndTime(dateTime, isStartDate) {
      if (_.isNil(dateTime)) return;
      // const splitValue = dateTime.split("T");
      // console.log("splitValue", splitValue);
      // if (splitValue.length == 1) {
      //   let addTime = `${dateTime} 00:00:00`;
      //   this.processStartDateFromPicker(addTime);
      //   return addTime;
      // }
      // let dateTimeStr = dateTime.replace("T", " ");
      if (isStartDate) {
        this.processStartDateFromPicker(dateTime);
      } else {
        this.processEndDateFromPicker(dateTime);
      }
      return dateTime;
    },

    async setFormData(item) {
      this.formData["garages"] = item["garages"];
      this.formData["startDate"] = item["startDate"];
      this.formData["endDate"] = item["endDate"];

      this.formData["vatPercent"] = item["vatPercent"];
      this.formData["vatCalculate"] = item["vatCalculate"];

      let partListResp = await getVehiclePartsService(this.$store, item["_id"]);

      if (partListResp["code"] === 200) {
        //
        // let itemTotalPriceList = this.getItemTotalPriceList(partListResp["data"]);
        let partListInit = _.clone(partListResp["data"]);
        if (!_.isNil(partListInit) && partListInit.length > 0) {
          for (let idx in partListInit) {
            let total =
              partListInit[idx]["pricePerUnit"] * partListInit[idx]["amount"];
            partListInit[idx]["itemTotalPrice"] = _.clone(total);
          }
          this.formData["partList"] = _.clone(partListInit);
        }
      }

      this.isMounted = true;
      if (item["sumPriceBeforeVat"] == 0) {
        this.netPrice = "0";
      } else {
        this.netPrice = item["sumPriceBeforeVat"];
      }
      // this.formData["sumPriceBeforeVat"] = item["sumPriceBeforeVat"];

      // this.formData["partList"] = _.cloneDeep(item["partList"])
      //   ? item["partList"]
      //   : [];

      this.processStartDateFromPicker(this.formData["startDate"]);
      this.processEndDateFromPicker(this.formData["endDate"]);
    },

    open(item) {
      this.resetFormData();
      if (!_.isNil(item)) {
        this.setFormData(item);
      }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async saveStatus() {
      if (this.$refs.form.validate() === true) {
        if (this.formData["partList"].length > 0) {
          let _formData = _.clone(this.formData);

          for (let idx in _formData["partList"]) {
            delete _formData["partList"][idx]["itemTotalPrice"];
          }

          if (!_formData["vatCalculate"]) {
            _formData["vatPercent"] = 0;
          }
          if (_formData["sumPriceBeforeVat"]) {
            _formData["sumPriceBeforeVat"] = parseFloat(
              _formData["sumPriceBeforeVat"]
            );
          }

          this.resetFormData();
          this.resolve(_formData);
          this.show = false;
        } else {
          await this.$refs.alertAddingPart.open(
            "กรุณาเพิ่มอะไหล่",
            null,
            DialogType.ACTION,
            {
              noconfirm: false,
            }
          );
        }
      }
    },
    cancelStatus() {
      this.resetFormData();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    DynamicReportElement,
    InputView,
    CustomTextInputImprove,
    CustomTimePickerFormInput,
    PrimaryButton,
    VehicleReplacementDialog,
    CustomDateTimePickerFormInput,
    CustomAutocomplete,
    AppDialogConfirm,
  },
};
</script>

<style scoped></style>
