<template>
	<div>
		<v-form ref="form" v-model="valid">
			<v-subheader
				:class="[
					'px-4 mt-2 mb-1 text-app-detail-large black--text font-weight-bold',
				]"
				>รายละเอียด</v-subheader
			>
			<v-row no-gutters class="px-1">
				<v-col cols="12">
					<CustomTextInputImprove
						v-model="formData.name"
						ref="name"
						title="หัวข้อ"
						placeholder="หัวข้อ"
						:isTitleDisabled="isControlDisabled()"
						:isRequired="true"
						:disabled="!isPermissionCanEdit || isControlDisabled()"
						dataInputName="name"
						:rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
					/>
				</v-col>
			</v-row>
			<!-- :rules="checklistInfo ? [(v) => !!v || 'จำเป็นต้องระบุ'] : []" -->
			<v-row no-gutters class="px-1">
				<v-col cols="12">
					<CustomTextareaImprove
						v-model="formData.detail"
						title="คำอธิบายโจทย์"
						type="text"
						:isTitleDisabled="isControlDisabled()"
						:isRequired="false"
						:disabled="!isPermissionCanEdit || isControlDisabled()"
						:height="140"
					>
					</CustomTextareaImprove>
				</v-col>
			</v-row>
			<v-row no-gutters class="px-4">
				<v-col cols="12">
					<FormDataUploadInput
						ref="checklistCRUDUploadImageFile"
						component-name="checklistCRUDUploadImageFileForm"
						title="รูปภาพ (jpg, png)"
						placeholder="เพิ่มรูปภาพที่นี่"
						:multiple="false"
						:isFullWidth="true"
						:isImageType="true"
						:uploaded-data="formData.images"
						:is-permission-can-edit="
							!isPermissionCanEdit || isControlDisabled()
						"
						:isTitleDisabled="isControlDisabled()"
						uploadTag="CHECK_LIST"
					/>
				</v-col>
			</v-row>

			<AppSubmitFormButton
				:isDivider="false"
				:isMarginBtn="false"
				@on-click-save="saveChange()"
				:disabled="isControlDisabled()"
			/>
		</v-form>
		<AppDialogConfirm ref="alertSaving" />
		<AppDialogConfirm ref="dialogAlert" />
	</div>
</template>
<script>
	import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
	import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
	import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
	import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
	import {
		putChecklistInfoService,
		postChecklistInfoService,
	} from "@/services/api/vehicle_checklist.js";
	import { DialogType } from "@/services/dialog";
	export default {
		name: "vehicleCreateCheckListManage",
		components: {
			CustomTextareaImprove,
			FormDataUploadInput,
			CustomTextInputImprove,
			AppSubmitFormButton,
			AppDialogConfirm,
		},
		props: {
			isItemsReOrder: {
				type: Boolean,
				default: false,
			},

			isTypesReOrder: {
				type: Boolean,
				default: false,
			},

			checklistInfo: {
				type: Object,
				default: null,
			},

			selectedTypeName: {
				type: String,
				default: null,
			},

			selectedType: {
				type: Object,
				default: null,
			},

			isAddingNewItem: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				formData: {
					_id: null,
					group: null,
					name: null,
					detail: "",
					images: [],
				},
				fetchDataError: 0,
				valid: false,
				isPermissionCanEdit: true,
				isPermissionCanView: false,
				isLoading: false,
			};
		},
		watch: {
			isItemsReOrder(val) {
				this.isControlDisabled();
			},

			isTypesReOrder(val) {
				this.isControlDisabled();
			},

			selectedType(val) {
				this.isControlDisabled();
			},

			checklistInfo(val) {
				// this.fetchDataError = 1;
				this.resetFormData();
				// this.formData = {group: null, name: null, detail: null, images: [] };
				if (!_.isNil(val)) {
					// an checklist item was selected
					this.formData = _.cloneDeep(val);
					this.fetchDataError = 2;
				} else {
					// checklistInfo is null
					this.fetchDataError = 0;
				}
			},

			selectedTypeName(val) {
				this.resetFormData();
			},

			// isAddingNewItem(val) {
			// 	if (val) {
			// 		this.reset();
			// 	}
			// },
		},

		computed: {},

		methods: {
			isControlDisabled() {
				if (
					this.isItemsReOrder ||
					this.isTypesReOrder ||
					_.isNil(this.selectedType)
				) {
					return true;
				}
				return false;
			},
			getSavingFormData() {
				let savedFormData = {
					id: _.cloneDeep(this.formData["_id"]),
					group: _.cloneDeep(this.formData["group"]),
					name: _.cloneDeep(this.formData["name"]),
					detail: _.cloneDeep(this.formData["detail"]),
					images: [],
				};

				return savedFormData;
			},

			getAddingFormData() {
				let savedFormData = {
					group: _.cloneDeep(this.formData["group"]),
					name: _.cloneDeep(this.formData["name"]),
					detail: _.cloneDeep(this.formData["detail"]),
					images: [],
				};

				return savedFormData;
			},
			async saveChange() {
				if (this.$refs.form.validate() === true) {
					this.isLoading = true;
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});

					let _toUpdateByAPI = false;
					let respUploadImage = await this.$refs[
						"checklistCRUDUploadImageFile"
					].upload();

					// check file upload is faill alert error
					// TODO::
					if (!respUploadImage.status) {
						// is upload has error response restore all file
						this.callUploadFileErrorDialog();
						this.$refs["checklistCRUDUploadImageFile"].restoreFile();
						// this.disableLoading();
					} else {
						if (!_.isNil(this.checklistInfo)) {
							// edit
							const sendingFormData = this.getSavingFormData();

							if (respUploadImage["result"].length > 0) {
								delete respUploadImage["result"][0]["src"];
							}
							sendingFormData["images"] = respUploadImage["result"];

							let resp = await putChecklistInfoService(
								this.$store,
								sendingFormData
							);
							if (resp["code"] === 200) {
								this.isLoading = false;
								this.alertSaveChange(sendingFormData["id"]);
								_toUpdateByAPI = true;
								this.$emit("checklistManageSaved", this.selectedType);
							} else {
								_toUpdateByAPI = false;
							}
						} else {
							// add new checklist item
							const sendingFormData = this.getAddingFormData();
							sendingFormData["images"] = respUploadImage["result"];
							let resp = await postChecklistInfoService(
								this.$store,
								sendingFormData
							);
							if (resp["code"] === 200) {
								this.isLoading = false;
								this.alertSaveChange(sendingFormData["id"]);
								_toUpdateByAPI = true;
								this.$emit("checklistManageSaved", this.selectedType);
							} else {
								_toUpdateByAPI = false;
							}
						}
					}
					if (_toUpdateByAPI === false) {
						// alert fail to update data
						this.$refs["checklistCRUDUploadImageFile"].restoreFile();
					}
					this.resetFormData();
					this.isLoading = false;
				}
			},

			async callUploadFileErrorDialog(respMessage) {
				let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
				for (let i = 0; i < respMessage.length; i++) {
					custom_error_text =
						custom_error_text + errorMessageMapper[respMessage[i]];
				}
				await this.$refs.dialogAlert.open(
					custom_error_text,
					null,
					DialogType.ERROR,
					{
						noconfirm: false,
					}
				);
				// clear data
			},

			async alertSaveChange(_id) {
				if (_id !== null) {
					await this.$refs.alertSaving.open(
						"แก้ไขข้อมูลสำเร็จ!",
						null,
						DialogType.POSITIVE,
						this.alertOptions
					);
				}
			},

			resetFormData() {
				this.$refs["form"].reset();
				this.formData = {
					group: this.selectedTypeName,
					name: null,
					detail: "",
					images: [],
				};
			},
		},
	};
</script>
