<template>
  <div class="text-center">
    <v-dialog scrollable v-model="show" width="750" persistent>
      <v-card>
        <!-- <v-card-title class="text-h5"> ใบลางาน </v-card-title> -->
        <v-toolbar color="primary" dark>
          <v-card-title> ใบลางาน </v-card-title> <v-spacer></v-spacer
          ><v-btn icon @click.native="cancelStatus"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-toolbar
        >

        <v-card-text>
          <v-sheet class="mb-6 pb-3 pt-3" elevation="0">
            <v-row no-gutters class="mb-6">
              <v-col cols="9" class="d-inline-flex">
                <v-avatar size="58">
                  <v-img
                    :src="
                      !timeOffRequestForm
                        ? defaultPFImage
                        : timeOffRequestForm.profileImage === null
                        ? defaultPFImage
                        : timeOffRequestForm.profileImage
                    "
                  >
                  </v-img>
                  <!-- <v-img :src="defaultPFImage"> </v-img> -->
                </v-avatar>
                <div class="pl-2 align-self-center">
                  <p class="ma-0 font-weight-bold black--text">
                    <!-- {{ timeOffRequestForm.name }} -->
                    {{
                      `${
                        timeOffRequestForm.salutation === null
                          ? ""
                          : timeOffRequestForm.salutation.th
                      } ${
                        timeOffRequestForm.firstName === null
                          ? ""
                          : timeOffRequestForm.firstName.th
                      } ${
                        timeOffRequestForm.lastName === null
                          ? ""
                          : timeOffRequestForm.lastName.th
                      } `
                    }}
                    {{
                      timeOffRequestForm.nickName === null
                        ? ""
                        : `(${timeOffRequestForm.nickName.th})`
                    }}
                  </p>
                  <p class="ma-0">
                    เบอร์โทร:
                    <span
                      v-for="(
                        phoneNumber, index
                      ) in timeOffRequestForm.phoneNumber"
                      :key="index"
                      ><span
                        v-if="
                          index > 0 &&
                          index < timeOffRequestForm.phoneNumber.length
                        "
                      >
                        , </span
                      >{{ phoneNumber }}</span
                    >
                  </p>
                </div>
                <!-- <v-spacer></v-spacer> -->
                <v-divider class="mx-3" vertical></v-divider>
                <div class="pl-2 align-self-center">
                  <p class="ma-0">
                    รหัสพนักงาน:
                    <span>{{ timeOffRequestForm.empId }}</span>
                  </p>
                  <p class="ma-0">
                    ตำแหน่ง:
                    <span>{{ timeOffRequestForm.position }}</span>
                  </p>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-chip
                class="mr-1 mt-3"
                filter
                outlined
                :color="getStatusEmployeeColor('ACTIVE')"
              >
                <span class="px-1">{{ getStatusEmployeeText("ACTIVE") }}</span>
              </v-chip>
            </v-row>

            <v-row dense class="pb-3">
              <v-col cols="4">
                <div class="text-app-detail-normal">ลาไปแล้วทั้งหมด</div>
                <div class="text-app-title font-weight-bold">
                  {{ calculatePrevTotal }} วัน
                </div>
              </v-col>

              <v-col cols="4">
                <div class="text-app-detail-normal">ลาป่วย</div>
                <div class="text-app-title font-weight-bold">
                  {{
                    statData.stats !== null
                      ? `${statData.stats.sickLeave}/${statData.limit.sickLeave}`
                      : "-"
                  }}
                  วัน
                </div>
              </v-col>

              <v-col cols="4">
                <div class="text-app-detail-normal">ลากิจ</div>
                <div class="text-app-title font-weight-bold">
                  {{
                    statData.stats !== null
                      ? `${statData.stats.businessLeave}/${statData.limit.businessLeave}`
                      : "-"
                  }}
                  วัน
                </div>
              </v-col>
            </v-row>

            <v-row dense class="pb-3">
              <v-col cols="4">
                <div class="text-app-detail-normal">
                  ลาบุคคลในครอบครัวเสียชีวิต
                </div>
                <div class="text-app-title font-weight-bold">
                  {{
                    statData.stats !== null
                      ? `${statData.stats.familyFuneralLeave}/${statData.limit.familyFuneralLeave}`
                      : "-"
                  }}
                  วัน
                </div>
              </v-col>

              <v-col cols="4">
                <div class="text-app-detail-normal">ลาพักร้อน</div>
                <div class="text-app-title font-weight-bold">
                  {{
                    statData.stats !== null
                      ? `${statData.stats.holidayLeave}/${statData.limit.holidayLeave}`
                      : "-"
                  }}
                  วัน
                </div>
              </v-col>

              <v-col cols="4">
                <div class="text-app-detail-normal">ลาคลอด</div>
                <div class="text-app-title font-weight-bold">
                  {{
                    statData.stats !== null
                      ? `${statData.stats.giveBirthLeave}/${statData.limit.giveBirthLeave}`
                      : "-"
                  }}
                  วัน
                </div>
              </v-col>
            </v-row>

            <v-row dense class="pb-3">
              <v-col cols="4">
                <div class="text-app-detail-normal">ลารับราชการทหาร</div>
                <div class="text-app-title font-weight-bold">
                  {{
                    statData.stats !== null
                      ? `${statData.stats.militaryServiceLeave}/${statData.limit.militaryServiceLeave}`
                      : "-"
                  }}
                  วัน
                </div>
              </v-col>
              <v-col cols="8">
                <div class="text-app-detail-normal">วันที่ลาล่าสุด</div>
                <div v-if="statData.lastOffStartDate !== null">
                  <span class="text-app-title font-weight-bold">
                    {{
                      statData.lastOffStartDate !== null
                        ? statData.lastOffStartDate !== undefined
                          ? dateStringToDateFormat(statData.lastOffStartDate)
                          : "-"
                        : "-"
                    }}
                  </span>
                  ถึง
                  <span class="text-app-title font-weight-bold">
                    {{
                      statData.lastOffEndDate !== null
                        ? statData.lastOffEndDate !== undefined
                          ? dateStringToDateFormat(statData.lastOffEndDate)
                          : "-"
                        : "-"
                    }}
                  </span>
                </div>
                <div v-else>-</div>
              </v-col>
            </v-row>

            <v-divider class="my-3" />
            <v-row dense class="py-3">
              <v-col cols="4">
                <div class="text-app-detail-normal">จำนวนวันลา</div>
                <div
                  class="text-app-detail-large font-weight-bold primary--text"
                >
                  {{ timeOffRequestForm.period }} วัน
                  {{ timeOffRequestForm.timeType }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="text-app-detail-normal">เริ่มลาวันที่</div>
                <div class="text-app-title font-weight-bold">
                  {{ timeOffRequestForm.startDate }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="text-app-detail-normal">ลาถึงวันที่</div>
                <div class="text-app-title font-weight-bold">
                  {{ timeOffRequestForm.endDate }}
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <div class="text-app-detail-normal">ประเภทการลา</div>
                <div
                  class="text-app-detail-large font-weight-bold primary--text"
                >
                  {{ timeOffRequestForm.timeoffType }}
                </div>
              </v-col>
              <v-col cols="8">
                <div class="text-app-detail-normal">สาเหตุการลา</div>
                <div class="text-app-title font-weight-bold">
                  {{ timeOffRequestForm.note }}
                </div>
              </v-col>
            </v-row>
          </v-sheet>

          <v-sheet elevation="0" class="px-8 mx-6" v-if="jobInRange">
            <p class="pl-2 red--text mb-1">
              ระวัง: งานเดินรถที่อยู่ในช่วงเวลาหรือใกล้เคียงวันลา
            </p>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">งานเดินรถ</th>
                    <th class="text-left">สถานะ</th>
                    <th class="text-left">เวลายืนยัน</th>
                    <th class="text-left">เวลาทำงาน</th>
                    <th class="text-left">เวลาจบงาน</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in jobInRange" :key="item.code">
                    <td>
                      <router-link
                        class="text-decoration-none"
                        target="_blank"
                        :to="{ name: 'jobsEdit', params: { id: item._id } }"
                      >
                        {{ item.name }} ({{ item.jobCode }})
                      </router-link>
                    </td>
                    <td>
                      <span :class="`${jobsStatusColor(item.status)}--text`"
                        >{{ jobsStatusText(item.status) }}
                      </span>
                    </td>
                    <td>{{ processDateToDisplay(item.confirmDate) }}</td>
                    <td>{{ processDateToDisplay(item.startDate) }}</td>
                    <td>
                      {{
                        item.endDate
                          ? processDateToDisplay(item.endDate)
                          : processDateToDisplay(item.estimateEndDate)
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-4" />
          </v-sheet>

          <v-row no-gutters>
            <v-col cols="12">
              <!-- <FormDataUploadInput
                ref="vehicleCRUDUploadFile"
                component-name="vehicleCRUDUploadFileForm"
                title="ชุดเอกสาร (pdf)"
                placeholder="เพิ่มเอกสารกดที่นี่"
                :uploaded-data="timeOffRequestForm.files"
                @waitToUploadFiles="isWaitToUploadFiles"
                uploadTag="EMPLOYEE_TIMEOFF"
              /> -->

              <FormDataUploadInput
                ref="vehicleCRUDUploadImageFile"
                component-name="vehicleCRUDUploadImageFileForm"
                title="รูปภาพ (jpg, png)"
                placeholder="เพิ่มรูปภาพที่นี่"
                :isImageType="true"
                :uploaded-data="timeOffRequestForm.images"
                @waitToUploadImages="isWaitToUploadImages"
                uploadTag="EMPLOYEE_TIMEOFF"
              />
            </v-col>
          </v-row>

          <v-row class="pt-0"> <v-divider class="pt-3 pb-6" /></v-row>

          <v-row no-gutters>
            <v-divider></v-divider>
            <!-- <v-col cols="12">
              <span class="title-input"> สถานะ </span>
              <span class="required-color"> * </span>
            </v-col> -->
            <v-col cols="12" class="pa-0">
              <v-row no-gutters>
                <v-col cols="6" class="px-1">
                  <div class="text-app-detail-normal">รายได้ต่อวัน</div>
                  <div
                    class="text-app-title font-weight-bold black--text"
                    v-if="timeOffRequestForm['salaryInDay']"
                  >
                    {{
                      getPriceString(
                        timeOffRequestForm["salaryInDay"].toFixed(2)
                      )
                    }}
                    บาท
                  </div>
                  <div
                    v-else
                    class="text-app-title font-weight-bold black--text"
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="6" class="px-1 mb-5">
                  <CustomTextInputCurrency
                    v-if="timeOffRequestForm.status !== null"
                    :disabled="
                      timeOffRequestForm.status.key === 'NOT_APPROVED' ||
                      !canEditPayment
                    "
                    v-model="timeOffRequestForm.fine"
                    ref="fineAmount"
                    title="ค่าปรับ (บาท)"
                    :is-required="false"
                    :expen-margin="false"
                    :hide-detail="true"
                    data-input-name="amount"
                  ></CustomTextInputCurrency>
                  <!-- <div v-else>
                    <div class="text-app-detail-normal">ค่าปรับ (บาท)</div>
                    <div class="text-app-title font-weight-bold black--text">
                      -
                    </div>
                  </div> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" class="px-0">
                <v-row no-gutters align="center" justify="center" class="px-0">
                  <v-col
                    cols="12"
                    sm="6"
                    lg="6"
                    class="px-1"
                    v-for="(value, key) in statusList"
                    :key="key"
                  >
                    <v-card
                      :disabled="!canEditPayment"
                      :color="getStatusColor(value)"
                      @click="statusOnClick(value)"
                      :outlined="getStatusOutline(value)"
                      ><v-card-title class="pt-2 pl-2 pb-0">
                        <v-icon
                          class="status-color"
                          :dark="timeOffStatus[value.key]"
                        >
                          mdi-check-circle
                        </v-icon> </v-card-title
                      ><v-card-text align="center">
                        <v-icon x-large :dark="timeOffStatus[value.key]">
                          {{ statusIcon[value.key] }}
                        </v-icon>
                        <div v-if="getStatusOutline(value)" class="icon pt-2">
                          {{ value.value }}
                        </div>
                        <div v-else class="white--text icon pt-2">
                          {{ value.value }}
                        </div>
                      </v-card-text></v-card
                    ></v-col
                  >
                </v-row>
              </v-card></v-col
            ></v-row
          >
          <v-row>
            <v-col cols="12">
              <CustomTextareaImprove
                v-model="timeOffRequestForm.approvedDetail"
                :hide-detail="true"
                title="หมายเหตุการอนุมัติ"
                :is-required="false"
                :expend-margin="false"
              ></CustomTextareaImprove>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="isNotEdited" @click="saveStatus">
            <span class="px-3">บันทึก</span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <AppOverlay :isLoading="isLoading" />
    </v-dialog>
    <!-- <AppDialogConfirm ref="confirmToRemovePart" /> -->
    <AppDialogConfirm ref="alertApproval" />
    <AppDialogConfirm ref="dialogAlert" />
  </div>
</template>

<script>
import _ from "lodash";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import { getVehicleFundamental } from "@/services/api/vehicle";
import defaultImage from "@/assets/user.png";
import { mapGetters } from "vuex";
import { DialogType } from "@/services/dialog";
import { getEmployeeTimeOffStatService } from "@/services/api/employee";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import AppOverlay from "@/components/AppOverlay.vue";

const EmployeeStatus = Object.freeze({
  APPLIED: "ขอสมัคร",
  REJECTED: "ไม่รับ",
  PROBATION: "ทดลองงาน",
  ACTIVE: "ทำงาน",
  RESIGNED: "ลาออก",
  TERMINATED: "เลิกจ้าง",
  FIRED: "ไล่ออก",
});

export default {
  name: "EmployeeTimeOffApprovalDialog",
  props: ["dialogStatus"],
  data: () => ({
    defaultPFImage: defaultImage,
    isLoading: false,
    show: false,
    resolve: null,
    reject: null,
    isFail: false,
    isNotEdited: true,
    initStatus: null,
    initDetail: null,
    initFine: null,
    isNotDetailEdited: true,
    isNotFineEdited: true,
    isNotStatusChanged: true,
    isWaitToUploadAttachImages: false,

    statData: {
      lastOffStartDate: null,
      lastOffEndDate: null,
      stats: {
        businessLeave: 0,
        sickLeave: 0,
        familyFuneralLeave: 0,
        holidayLeave: 0,
        giveBirthLeave: 0,
        militaryServiceLeave: 0,
        otherLeave: 0,
      },
      limit: {
        businessLeave: 0,
        sickLeave: 0,
        familyFuneralLeave: 0,
        holidayLeave: 0,
        giveBirthLeave: 0,
        militaryServiceLeave: 0,
        otherLeave: 0,
      },
    },
    statusIcon: {
      APPROVED: "mdi-account-check",
      NOT_APPROVED: "mdi-account-cancel",
    },
    statusList: [
      { key: "APPROVED", value: "อนุมัติ" },
      { key: "NOT_APPROVED", value: "ไม่อนุมัติ" },
    ],
    timeOffStatus: {
      APPROVED: null,
      NOT_APPROVED: null,
    },
    timeOffRequestForm: {
      empStatus: null,
      status: null,
      name: null,
      firstName: null,
      lastName: null,
      salutation: null,
      nickName: null,
      empId: null,
      userId: null,
      salaryInDay: null,
      fine: null,
      phoneNumber: null,
      position: null,
      startDate: null,
      endDate: null,
      period: null,
      timeoffType: null,
      note: null,
      approvedDetail: null,
      images: [],
    },
    id: null,
    jobInRange: null,
    //
    canEditPayment: false,
  }),
  watch: {
    "timeOffRequestForm.fine": function (value) {
      if (!_.isNil(value)) {
        this.isNotFineEdited = _.isEqual(value, this.initFine);
        if (
          this.isNotDetailEdited &&
          !this.isWaitToUploadAttachImages &&
          this.isNotStatusChanged &&
          this.isNotFineEdited
        ) {
          this.isNotEdited = true;
        } else {
          this.isNotEdited = false;
        }
      }
    },

    "timeOffRequestForm.approvedDetail": function (value) {
      if (!_.isNil(value)) {
        this.isNotDetailEdited = _.isEqual(value, this.initDetail);
        if (
          this.isNotDetailEdited &&
          !this.isWaitToUploadAttachImages &&
          this.isNotStatusChanged &&
          this.isNotFineEdited
        ) {
          this.isNotEdited = true;
        } else {
          this.isNotEdited = false;
        }
      }
    },
    "timeOffRequestForm.status": function (value) {
      if (!_.isNil(this.initStatus) && !_.isNil(value)) {
        this.isNotStatusChanged = _.isEqual(this.initStatus, value.key);
        if (
          this.isNotDetailEdited &&
          !this.isWaitToUploadAttachImages &&
          this.isNotStatusChanged &&
          this.isNotFineEdited
        ) {
          this.isNotEdited = true;
        } else {
          this.isNotEdited = false;
        }
      }
    },

    isWaitToUploadAttachImages(value) {
      if (value || !this.isNotDetailEdited) {
        this.isNotEdited = false;
      } else {
        this.isNotEdited = true;
      }
      // console.log("waitImageEdited", this.isNotEdited);
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusText: "jobs/jobsStatusText",
      vehicleStatusColor: "vehicle/vehicleStatusColor",
      empTimeOffStatusColor: "employee/empTimeOffStatusColor",
      empTimeOffStatusText: "employee/empTimeOffStatusText",
    }),

    calculatePrevTotal() {
      let total = 0;
      let sickNum = 0;
      let businessNum = 0;
      let holidayLeave = 0;
      let familyFuneralNum = 0;
      let giveBirthLeave = 0;
      let militaryServiceNum = 0;
      let otherLeave = 0;

      let statsHistory = this.statData;
      if (!_.isNil(statsHistory["stats"]["sickLeave"])) {
        sickNum = statsHistory["stats"]["sickLeave"];
      }
      if (!_.isNil(statsHistory["stats"]["businessLeave"])) {
        businessNum = statsHistory["stats"]["businessLeave"];
      }
      if (!_.isNil(statsHistory["stats"]["holidayLeave"])) {
        holidayLeave = statsHistory["stats"]["holidayLeave"];
      }
      if (!_.isNil(statsHistory["stats"]["familyFuneralLeave"])) {
        familyFuneralNum = statsHistory["stats"]["familyFuneralLeave"];
      }
      if (!_.isNil(statsHistory["stats"]["giveBirthLeave"])) {
        giveBirthLeave = statsHistory["stats"]["giveBirthLeave"];
      }
      if (!_.isNil(statsHistory["stats"]["militaryServiceLeave"])) {
        militaryServiceNum = statsHistory["stats"]["militaryServiceLeave"];
      }
      if (!_.isNil(statsHistory["stats"]["otherLeave"])) {
        otherLeave = statsHistory["stats"]["otherLeave"];
      }
      total =
        sickNum +
        businessNum +
        holidayLeave +
        familyFuneralNum +
        giveBirthLeave +
        militaryServiceNum +
        otherLeave;

      return total;
    },

    getMaxGiveBirthLeave() {
      if (!_.isNil(this.timeOffRequestForm["salutation"])) {
        if (this.timeOffRequestForm["salutation"]["th"] !== "นาย") {
          return 45;
        }
      }
      return 3;
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    resetFormData() {
      this.timeOffRequestForm["status"] = null;
      this.timeOffRequestForm["name"] = null;
      this.timeOffRequestForm["firstName"] = null;
      this.timeOffRequestForm["lastName"] = null;
      this.timeOffRequestForm["salutation"] = null;
      this.timeOffRequestForm["nickName"] = null;
      this.timeOffRequestForm["empId"] = null;
      this.timeOffRequestForm["userId"] = null;
      this.timeOffRequestForm["phoneNumber"] = null;
      this.timeOffRequestForm["position"] = null;
      this.timeOffRequestForm["startDate"] = null;
      this.timeOffRequestForm["endDate"] = null;
      this.timeOffRequestForm["period"] = null;
      this.timeOffRequestForm["timeoffType"] = null;
      this.timeOffRequestForm["timeType"] = null;
      this.timeOffRequestForm["note"] = null;
      this.timeOffRequestForm["fine"] = null;
      this.timeOffRequestForm["salaryInDay"] = null;
      this.timeOffRequestForm["status"] = null;
      this.timeOffRequestForm["approvedDetail"] = null;
      this.timeOffRequestForm["images"] = [];
      this.isNotEdited = true;

      this.statData = {
        lastOffStartDate: null,
        lastOffEndDate: null,
        stats: {
          businessLeave: 0,
          sickLeave: 0,
          familyFuneralLeave: 0,
          holidayLeave: 0,
          giveBirthLeave: 0,
          militaryServiceLeave: 0,
          otherLeave: 0,
        },
        limit: {
          businessLeave: 0,
          sickLeave: 0,
          familyFuneralLeave: 0,
          holidayLeave: 0,
          giveBirthLeave: 0,
          militaryServiceLeave: 0,
          otherLeave: 0,
        },
      };

      this.id = null;
      this.jobInRange = null;
    },

    async setFormData(item) {
      this.timeOffRequestForm["profileImage"] = item["profileImage"];
      this.timeOffRequestForm["name"] = item["name"];

      this.timeOffRequestForm["firstName"] = item["firstName"];
      this.timeOffRequestForm["lastName"] = item["lastName"];
      this.timeOffRequestForm["salutation"] = item["salutation"];
      this.timeOffRequestForm["nickName"] = item["nickName"];

      this.timeOffRequestForm["empId"] = item["empId"];
      this.timeOffRequestForm["userId"] = item["userId"];

      this.timeOffRequestForm["salaryInDay"] = item["salaryInDay"];
      this.timeOffRequestForm["fine"] = item["fine"];
      this.timeOffRequestForm["status"] = item["status"];
      this.initStatus = _.clone(this.timeOffRequestForm["status"]);
      this.statusOnClick({
        key: this.initStatus,
        value: this.empTimeOffStatusText(this.initStatus),
      });

      this.timeOffRequestForm["phoneNumber"] = item["phoneNumber"];
      this.timeOffRequestForm["position"] = item["position"];
      this.timeOffRequestForm["startDate"] = dateStringToDateFormat(
        item["startDate"]
      );
      this.timeOffRequestForm["startDateOrigianl"] = item["startDate"];
      this.timeOffRequestForm["endDate"] = dateStringToDateFormat(
        item["endDate"]
      );

      // let startDate = new Date(item["startDate"]);
      // let endDate = new Date(item["endDate"]);
      // let diff_days = parseInt(
      //   (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
      // );
      this.timeOffRequestForm["period"] = item["period"];

      if (item["timeType"] == "SECOND_HALF") {
        this.timeOffRequestForm["timeType"] = "(บ่าย)";
      } else if (item["timeType"] == "FIRST_HALF") {
        this.timeOffRequestForm["timeType"] = "(เช้า)";
      } else {
        this.timeOffRequestForm["timeType"] = "";
      }

      this.timeOffRequestForm["timeoffType"] = item["timeoffType"];
      this.timeOffRequestForm["note"] =
        item["note"] || item["detail"] ? item["note"] + item["detail"] : "-";
      this.timeOffRequestForm["approvedDetail"] = item["approvedDetail"];
      this.timeOffRequestForm["images"] = item["images"];
      this.initDetail = _.clone(item["approvedDetail"]);
      this.initFine = _.clone(item["fine"]);
      //
      this.id = item["_id"];
    },

    async getStat() {
      this.isLoading = true;
      let year_stat = new Date();
      if (!_.isNil(this.timeOffRequestForm["startDate"])) {
        year_stat = new Date(this.timeOffRequestForm["startDate"]);
      }

      const queryString = new URLSearchParams({
        id: this.id,
        userId: this.timeOffRequestForm["userId"],
        year: year_stat.getFullYear(),
        startDate: this.timeOffRequestForm["startDateOrigianl"],
      }).toString();
      //
      let responseData = await getEmployeeTimeOffStatService(
        this.$store,
        queryString
      );

      // let responseData = await getVehicleFundamental(this.$store, "type=input");
      if (responseData["code"] === 200) {
        const { data } = responseData;
        this.statData["lastOffStartDate"] = data["lastOffStartDate"];
        this.statData["lastOffEndDate"] = data["lastOffEndDate"];

        for (let stat of data["stats"]) {
          if (stat["type"] == "ลาป่วย") {
            this.statData["stats"]["sickLeave"] = stat["val"];
            this.statData["limit"]["sickLeave"] = stat["limit"];
          }
          if (stat["type"] == "ลากิจ") {
            this.statData["stats"]["businessLeave"] = stat["val"];
            this.statData["limit"]["businessLeave"] = stat["limit"];
          }
          if (stat["type"] == "ลาบุคคลในครอบครัวเสียชีวิต") {
            this.statData["stats"]["familyFuneralLeave"] = stat["val"];
            this.statData["limit"]["familyFuneralLeave"] = stat["limit"];
          }
          if (stat["type"] == "ลาพักร้อน") {
            this.statData["stats"]["holidayLeave"] = stat["val"];
            this.statData["limit"]["holidayLeave"] = stat["limit"];
          }
          if (stat["type"] == "ลาคลอด") {
            this.statData["stats"]["giveBirthLeave"] = stat["val"];
            this.statData["limit"]["giveBirthLeave"] = stat["limit"];
          }
          if (stat["type"] == "ลารับราชการทหาร") {
            this.statData["stats"]["militaryServiceLeave"] = stat["val"];
            this.statData["limit"]["militaryServiceLeave"] = stat["limit"];
          }
          if (stat["type"] == "ลาอื่นๆ") {
            this.statData["stats"]["otherLeave"] = stat["val"];
            this.statData["limit"]["otherLeave"] = stat["limit"];
          }
        }
        //
        this.jobInRange = data["jobInRangeList"];
        this.canEditPayment = data["canEditPayment"] || false;
      } else {
        this.isFail = true;
      }
      this.isLoading = false;
    },

    getStatusColor(status) {
      if (this.timeOffRequestForm["status"] !== null) {
        let prevStatus = this.timeOffRequestForm["status"].key;
        this.timeOffStatus[prevStatus] = true;
      }
      if (this.timeOffStatus[status.key]) {
        return this.empTimeOffStatusColor(status.key);
      }
      return "";
    },

    statusOnClick(selectedStatus) {
      this.timeOffStatus["APPROVED"] = null;
      this.timeOffStatus["NOT_APPROVED"] = null;

      this.timeOffStatus[selectedStatus.key] = true;
      this.timeOffRequestForm["status"] = selectedStatus;

      if (selectedStatus["key"] === "NOT_APPROVED") {
        this.timeOffRequestForm["fine"] = 0;
      }

      // if (this.initStatus !== null) {
      //   if (this.initStatus === selectedStatus.key) {
      //     this.isNotEdited = true;
      //   }
      //   // else {
      //   //   this.isNotEdited = false;
      //   // }
      // }
    },

    getPriceString(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getStatusOutline(status) {
      if (this.timeOffRequestForm["status"] !== null) {
        let prevStatus = this.timeOffRequestForm["status"].key;
        this.timeOffStatus[prevStatus] = true;
      }
      if (this.timeOffStatus[status.key]) {
        return false;
      }
      return true;
    },

    open(item) {
      this.resetFormData();
      if (!_.isNil(item)) {
        this.setFormData(item);
        this.getStat();
      }
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    getStatusEmployeeText(status) {
      switch (status) {
        case "APPLIED":
          return EmployeeStatus.APPLIED;
        case "REJECTED":
          return EmployeeStatus.REJECTED;
        case "PROBATION":
          return EmployeeStatus.PROBATION;
        case "ACTIVE":
          return EmployeeStatus.ACTIVE;
        case "RESIGNED":
          return EmployeeStatus.RESIGNED;
        case "TERMINATED":
          return EmployeeStatus.TERMINATED;
        case "FIRED":
          return EmployeeStatus.FIRED;
        default:
          return null;
      }
    },
    getStatusEmployeeColor(status) {
      switch (status) {
        case "APPLIED":
          return "subtitle darken-2";
        case "REJECTED":
          return "subtitle darken-4";
        case "PROBATION":
          return "primary";
        case "ACTIVE":
          return "success";
        case "RESIGNED":
          return "warning";
        case "TERMINATED":
          return "error lighten-1";
        case "FIRED":
          return "error darken-1";
        default:
          return null;
      }
    },

    isWaitToUploadImages(isWait) {
      this.isWaitToUploadAttachImages = isWait;
    },

    handleApprovedClick() {},
    handleRejectedClick() {},
    async saveStatus() {
      // console.log(this.timeOffRequestForm);
      if (this.timeOffRequestForm["status"] !== null) {
        if (this.timeOffRequestForm["status"].key !== "NEW") {
          let respUploadImage = await this.$refs[
            "vehicleCRUDUploadImageFile"
          ].upload();
          if (!respUploadImage.status) {
            // is upload has error response restore all file
            this.callUploadFileErrorDialog();
            this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
            // this.disableLoading();
          } else {
            let _formData = {
              fine: this.timeOffRequestForm["fine"],
              approvedDetail: this.timeOffRequestForm["approvedDetail"],
              status: this.timeOffRequestForm["status"].key,
              images: respUploadImage.result,
            };
            this.resetFormData();
            this.resolve(_formData);
            this.show = false;
          }
        } else {
          await this.$refs.alertApproval.open(
            "กรุณากด อนุมัติ หรือ ไม่อนุมัติ",
            null,
            DialogType.ACTION,
            {
              noconfirm: false,
            }
          );
        }
      }
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    cancelStatus() {
      this.resetFormData();
      // this.$refs.form.reset();
      this.resolve(null);
      this.show = false;
    },

    processDateToDisplay(_date) {
      return dateStringToDateFormat(_date, {
        type: "datetime",
        returnThai: false,
        showSecond: false,
      });
    },
  },
  components: {
    CustomTextInputImprove,
    PrimaryButton,
    AppDialogConfirm,
    CustomTextareaImprove,
    FormDataUploadInput,
    CustomTextInputCurrency,
    AppOverlay,
  },
};
</script>

<style scoped>
.status-color {
  color: white;
}
</style>
