<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    v-if="['login', 'logout'].includes(currentRouteName) !== true"
  >
    <v-list>
      <div
        class="text-h6 pl-6 py-1 font-weight-bold primary--text text-uppercase"
      >
        Chokchai aree
        <div class="red-alert" v-if="showDev">!!!Dev Version!!!</div>
        <div class="red-alert" v-if="showTest">!!!Test Version!!!</div>
        <div class="red-alert" v-if="showDemo">!!!Demo Version!!!</div>
      </div>

			<!-- <v-divider></v-divider> -->

			<v-list-item-group v-model="currentMainMenu" mandatory>
				<template v-for="(item, index) in menuList">
					<!-- if it hasn't children -->
					<v-list-item
						v-if="!item.children"
						:key="`${index}-main`"
						:to="`${item.path}${item.query}`"
						link
						active-class="primary"
						class="pl-6"
						v-bind:class="[
							{ 'primary white--text': currentSubMenu === item.topic },
						]"
					>
						<v-list-item-icon class="mr-4">
							<v-icon> {{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-title class="custom-text">{{
							item.title
						}}</v-list-item-title>
					</v-list-item>
					<!-- else if it has children -->
					<v-list-group v-else :value="true" :key="`${index}-sub`" no-action>
						<!-- this template is for the title of top-level items with children -->
						<template v-slot:activator>
							<v-list-item-icon class="pl-2 mr-6">
								<v-icon> {{ item.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title class="custom-text">{{
								item.title
							}}</v-list-item-title>
						</template>

						<v-list-item
							v-for="subItem in item.children"
							v-bind:class="[
								{ 'primary white--text': currentSubMenu === subItem.topic },
							]"
							active-class="primary"
							:key="subItem.name"
							:to="`${item.path}${subItem.path}`"
							dense
							link
							@click="refreshKey(subItem.name)"
						>
							<v-list-item-content>
								<v-list-item-title
									:class="[
										'custom-text',
										'text-wrap',
										{
											'font-weight-bold': subItem.fontEmphasize,
										},
									]"
								>
									{{ subItem.title }}
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-icon color="yellow darken-3" v-if="subItem.fontEmphasize">
									mdi-star
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</v-list-group>
				</template>
			</v-list-item-group>
		</v-list>

		<!--  -->
		<template v-slot:append>
			<v-list two-line>
				<v-divider></v-divider>
				<v-list-item to="/profile">
					<v-list-item-avatar>
						<v-img :src="profileImageSrc"></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title
							v-html="
								$store.getters.userInfo.firstName.th +
								' ' +
								$store.getters.userInfo.lastName.th
							"
						></v-list-item-title>
						<v-list-item-subtitle
							v-html="$store.getters.userInfo.email"
						></v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-icon color="grey lighten-1">mdi-cog-outline</v-icon>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script>
	import { mapActions } from "vuex";
	import defaultImage from "@/assets/user.png";
	import { getProfileImage } from "@/services/api/user";
	import _ from "lodash";
	import {
		URL_CONTRACT_LIST,
		URL_EMPLOYEE_LIST,
		URL_EMPLOYEE_OVERVIEW_LIST,
		URL_EMPLOYEE_OVERVIEW_DASHBOARD,
		URL_EMPLOYEE_PENALTY,
		URL_EMPLOYEE_TIMEOFF,
		URL_EMPLOYEE_GROUP_LIST,
		URL_JOBS_LIST,
		URL_CONTRACT_OVERVIEW_DASHBOARD,
		URL_MJOBS_LIST,
		URL_ROUTES_LIST,
		URL_VEHICLE_CREATE_CHECKLIST,
		URL_VEHICLE_CHECKLIST,
		URL_VEHICLE_OVERVIEW_DASHBOARD,
		URL_VEHICLE_DOC_ACT,
		URL_VEHICLE_DOC_EVENT_LIST,
		URL_VEHICLE_DOC_FUEL,
		URL_VEHICLE_DOC_INS,
		URL_VEHICLE_DOC_TAX,
		URL_VEHICLE_DOC_GPS,
		URL_VEHICLE_DOC_LIVE,
		URL_VEHICLE_DOC_LOAN,
		URL_VEHICLE_LIST,
		URL_WORKSHEET_LIST,
		URL_EVIDENCE_LIST,
		URL_WORKSHEET_OVERVIEW_DASHBOARD,
		URL_WORKSHEET_OVERVIEW_PROPORTION,
		URL_JOB_MONITOR_LIST,
	} from "@/services/routerQueryPath";

export default {
  name: "AppDrawer",
  mounted() {
    if (process.env.VUE_APP_DEV == "true") {
      this.showDev = true;
    }
    if (process.env.VUE_APP_TEST == "true") {
      this.showTest = true;
    }
    if (process.env.VUE_APP_DEMO == "true") {
      this.showDemo = true;
    }

    //
    this.currentSubMenu = this.$route.meta.topic;
    this.backRouteTrackName = this.$route.name;
  },
  data() {
    return {
      showDev: false,
      showTest: false,
      showDemo: false,
      userMetaTopic: {},
      currentStatusDrawer: 0,
      items: [
        {
          name: "dashboard",
          title: "แดชบอร์ด",
          icon: "mdi-chart-box",
          path: "/",
          permissionTopic: ["*"],
          group: null,
          topic: "home",
        },
        {
          name: "employee",
          title: "พนักงาน",
          icon: "mdi-account-multiple",
          path: "/employee",
          permissionTopic: ["ADMIN", "USER"],
          children: [
            {
              name: "employeeList",
              title: "พนักงาน",
              path: URL_EMPLOYEE_LIST,
              permission: ["ADMIN_ALL", "USER_LIST"],
              topic: "employee",
              fontEmphasize: false,
            },
            {
              name: "employeeTimeOffList",
              title: "ใบลา",
              path: URL_EMPLOYEE_TIMEOFF,
              permission: ["ADMIN_ALL", "USER_TIMEOFF"],
              topic: "employeeTimeOff",
              fontEmphasize: false,
            },
            {
              name: "employeePenaltyList",
              title: "พฤติกรรม",
              path: URL_EMPLOYEE_PENALTY,
              permission: ["ADMIN_ALL", "USER_PENALITY"],
              topic: "employeePenalty",
              fontEmphasize: false,
            },
            {
              name: "employeePenaltyOverviewList",
              title: "สรุปพฤติกรรม",
              path: URL_EMPLOYEE_OVERVIEW_LIST,
              permission: ["ADMIN_ALL", "USER_PENALITY"],
              topic: "employeePenaltyOverview",
              fontEmphasize: false,
            },
            {
              name: "employeeOverview",
              title: "รายงานภาพรวมพนักงาน",
              path: URL_EMPLOYEE_OVERVIEW_DASHBOARD,
              permission: ["ADMIN_ALL", "USER_TOTAL_REPORT"],
              topic: "-",
              fontEmphasize: false,
            },
            // {
            //   name: "employeeJob",
            //   title: "บุคคลและงาน",
            //   path: "/job",
            //   permission: ["ADMIN_ALL", "USER_LIST"],
            //   topic: "employeeJob",
            //   fontEmphasize: false,
            // },
          ],
        },
        {
          name: "vehicle",
          title: "รถโดยสาร",
          icon: "mdi-car-side",
          path: "/vehicle",
          permissionTopic: ["ADMIN", "VEHICLE"],
          children: [
            {
              name: "vehicleList",
              title: "รถโดยสาร",
              path: URL_VEHICLE_LIST,
              permission: ["ADMIN_ALL", "VEHICLE_LIST"],
              topic: "vehicle",
              fontEmphasize: false,
            },
            {
              name: "vehicleInsurList",
              title: "ประกันภัย",
              path: URL_VEHICLE_DOC_INS,
              permission: ["ADMIN_ALL", "VEHICLE_EXPENSES"],
              topic: "vehicleIns",
              fontEmphasize: false,
            },
            {
              name: "vehicleActList",
              title: "พ.ร.บ.",
              path: URL_VEHICLE_DOC_ACT,
              permission: ["ADMIN_ALL", "VEHICLE_EXPENSES"],
              topic: "vehicleAct",
              fontEmphasize: false,
            },
            {
              name: "vehicleTaxList",
              title: "ภาษี",
              path: URL_VEHICLE_DOC_TAX,
              permission: ["ADMIN_ALL", "VEHICLE_EXPENSES"],
              topic: "vehicleTax",
              fontEmphasize: false,
            },
            {
              name: "vehicleFeulList",
              title: "เติมน้ำมัน",
              path: URL_VEHICLE_DOC_FUEL,
              permission: ["ADMIN_ALL", "FUELPAYMENT_LIST"],
              topic: "vehicleFuel",
              fontEmphasize: false,
            },
			{
				name: "vehicleEventList",
				title: "ซ่อมบำรุง",
				path: URL_VEHICLE_DOC_EVENT_LIST,
				permission: ["ADMIN_ALL", "VEHIREPARATION_LIST"],
				topic: "vehicleEvent",
				fontEmphasize: false,
			},
			// {
			// 	name: "vehicleGPSList",
			// 	title: "GPS",
			// 	path: URL_VEHICLE_DOC_GPS,
			// 	permission: ["ADMIN_ALL", "VEHICLE_EXPENSES"],
			// 	topic: "vehicleGPS",
			// 	fontEmphasize: false,
			// },
			// {
			// 	name: "vehicleLive",
			// 	title: "Live",
			// 	path: URL_VEHICLE_DOC_LIVE,
			// 	permission: ["ADMIN_ALL", "VEHICLE_EXPENSES"],
			// 	topic: "vehicleVideo",
			// 	fontEmphasize: false,
			// },
			{
				name: "vehicleLoanList",
				title: "ค่างวดรถ",
				path: URL_VEHICLE_DOC_LOAN,
				permission: ["ADMIN_ALL", "VEHICLE_EXPENSES"],
				topic: "vehicleLoan",
				fontEmphasize: false,
			},
			{
				name: "vehicleOverview",
				title: "รายงานภาพรวมรถโดยสาร",
				path: URL_VEHICLE_OVERVIEW_DASHBOARD,
				permission: ["ADMIN_ALL", "VEHICLE_TOTAL_REPORT"],
				topic: "-",
				fontEmphasize: false,
			},
          ],
        },
		{
			name: "checkList",
			title: "ตรวจสภาพ",
			icon: "mdi-clipboard-check-multiple-outline",
			path: "/vehicle",
			permissionTopic: ["ADMIN", "VEHICLE"],
			children: [
				{
					name: "vehicleCreateCheckList",
					title: "โจทย์ใบตรวจสภาพ",
					path: URL_VEHICLE_CREATE_CHECKLIST,
					permission: ["ADMIN_ALL"],
					topic: "vehicleCreateCheckList",
					fontEmphasize: false,
				},
				{
					name: "vehicleCheckList",
					title: "ใบตรวจสภาพ",
					path: URL_VEHICLE_CHECKLIST,
					permission: ["ADMIN_ALL"],
					topic: "vehicleCheckList",
					fontEmphasize: false,
				},
			],
		},
        {
          name: "contract",
          title: "สัญญาและงาน",
          icon: "mdi-file-sign",
          path: "/caj",
          permissionTopic: ["ADMIN", "CONTRACT", "JOBS"],
          children: [
            {
              name: "jobsList",
              title: "งานเดินรถ",
              path: URL_JOBS_LIST,
              permission: ["ADMIN_ALL", "JOBS_LIST"],
              topic: "job",
              fontEmphasize: true,
            },
            {
              name: "multipleJobList",
              title: "วางแผนงานจำนวนมาก",
              path: URL_MJOBS_LIST,
              permission: [
                "ADMIN_ALL",
                "JOBS_MULTIPLE_CRUD",
                "JOBS_MULTIPLE_TRACKING",
              ],
              topic: "multiple-job",
              fontEmphasize: false,
            },

			{
				name: "contractList",
				title: "สัญญาเดินรถ",
				path: URL_CONTRACT_LIST,
				permission: ["ADMIN_ALL", "CONTRACT_LIST"],
				topic: "contract",
				fontEmphasize: false,
			},

			{
				name: "routesList",
				title: "เส้นทางหลัก",
				path: URL_ROUTES_LIST,
				permission: ["ADMIN_ALL", "CONTRACT_ROUTE"],
				topic: "routes",
				fontEmphasize: false,
			},

            {
              name: "employeeGroupList",
              title: "กลุ่มเดินรถ",
              path: URL_EMPLOYEE_GROUP_LIST,
              permission: ["ADMIN_ALL", "JOBS_MULTIPLE_CRUD"],
              topic: "employeeGroup",
              fontEmphasize: false,
            },
            {
              name: "contractDashboardOverview",
              title: "รายงานภาพรวมสัญญา",
              path: URL_CONTRACT_OVERVIEW_DASHBOARD,
              permission: ["ADMIN_ALL", "CONTRACT_TOTAL_REPORT"],
              topic: "contractDashboardOverview",
              fontEmphasize: false,
            },
            {
              name: "jobsMonitoring",
              title: "มอนิเตอร์",
              path: URL_JOB_MONITOR_LIST,
              permission: ["ADMIN_ALL"],
              topic: "jobsMonitorList",
              fontEmphasize: false,
            },
          ],
        },
        {
          name: "worksheet",
          title: "ด้านการเงิน",
          icon: "mdi-account-cash",
          path: "/worksheet",
          permissionTopic: ["ADMIN", "PAYMENT"],
          children: [
            {
              name: "worksheetList",
              title: "สรุปใบงาน",
              path: URL_WORKSHEET_LIST,
              permission: ["ADMIN_ALL", "PAYMENT_LIST"],
              topic: "worksheet",
              fontEmphasize: false,
            },
            {
              name: "evidenceList",
              title: "หลักฐานเพิ่มเติม",
              path: URL_EVIDENCE_LIST,
              permission: ["ADMIN_ALL", "PAYMENT_EVIDENCE_CRUD"],
              topic: "evidence",
              fontEmphasize: false,
            },
            {
              name: "worksheetOverview",
              title: "รายงานภาพรวมการเงิน",
              path: URL_WORKSHEET_OVERVIEW_DASHBOARD,
              permission: ["ADMIN_ALL", "PAYMENT_VIEW_TOTAL_REPORT"],
              topic: "-",
              fontEmphasize: false,
            },
            {
              name: "worksheetOverviewProportion",
              title: "สัดส่วนด้านการเงินทั้งหมด",
              path: URL_WORKSHEET_OVERVIEW_PROPORTION,
              permission: ["ADMIN_ALL", "PAYMENT_VIEW_TOTAL_REPORT"],
              topic: "-",
              fontEmphasize: false,
            },
          ],
        },
      ],
      //
      showSubItem: false,
      profileImageSrc: defaultImage,
      profileImageSrcChecking: "",
      currentMainMenu: 0,
      currentSubMenu: "",
      backRouteTrackName: "",
    };
  },
  watch: {
    "$store.getters.userInfo": function () {
      if (this.$store.getters.userInfo !== null) {
        if (this.profileImageSrcChecking !== this.profileImageSrc) {
          this.getUserProfile();
        }
      } else {
        this.profileImageSrcChecking = "";
        this.profileImageSrc = defaultImage;
      }
    },
    $route(to, from) {
      this.currentSubMenu = to.meta.topic;
    },
  },
  methods: {
    ...mapActions(["updateDrawerVuex"]),
    refreshKey(path) {
      if (this.backRouteTrackName === path) {
        setTimeout(() => {
          this.$store.dispatch("updateReloadViewKey");
        }, 250);
      } else {
        this.backRouteTrackName = this.$route.name;
      }
    },
    async getUserProfile() {
      const userProfile = await getProfileImage(this.$store);
      if (userProfile["code"] === 200) {
        if (userProfile["data"]["src"] !== null) {
          this.profileImageSrc = userProfile["data"]["src"];
          this.profileImageSrcChecking = userProfile["data"]["src"];
        }
      } else {
        console.log("AppDrawer getUserProfile::err");
      }
    },
    checkTopicInExisting(topicName, index) {
      if (_.isNil(this.userMetaTopic["topicName"])) {
        this.userMetaTopic[topicName] = index;
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },

			menuList() {
				if (this.$store.getters.userInfo !== null) {
					const perm_list = this.$store.getters.userInfo.permissionTopic;
					const total_perm_list = this.$store.getters.userInfo.permissions;
					let _item = [];
					for (let i = 0; i < this.items.length; i++) {
						let currentItem = _.clone(this.items[i]);

						if (currentItem["permissionTopic"].includes("*")) {
							// can all
							_item.push(currentItem);
						} else {
							// main perm
							if (
								currentItem["permissionTopic"].some((perm) =>
									perm_list.includes(perm)
								)
							) {
								// if true
								// filter sub perm
								// if has children
								if (_.isNil(currentItem.children) === false) {
									let selectedChildren = [];

									for (let j = 0; j < currentItem.children.length; j++) {
										if (
											currentItem.children[j]["permission"].some((perm) =>
												total_perm_list.includes(perm)
											)
										) {
											selectedChildren.push(currentItem.children[j]);
										}
									}
									currentItem.children = selectedChildren;
									_item.push(currentItem);
								} else {
									_item.push(currentItem);
								}
							}
						}
					} // end loop
					let countIndex = 0;
					for (let i = 0; i < _item.length; i++) {
						if (!_.isNil(_item[i]["children"])) {
							let upSide = countIndex + 1;
							for (let j = 0; j < _item[i]["children"].length; j++) {
								this.checkTopicInExisting(
									_item[i]["children"][j]["topic"],
									upSide
								);
								upSide = upSide + 1;
							}
							countIndex = upSide;
						} else {
							this.checkTopicInExisting(_item[i]["topic"], countIndex);
							countIndex += 1;
						}
					}
					this.currentMainMenu =
						this.userMetaTopic[this.$route["meta"]["topic"]];
					return _item;
				}
				return [];
			},
			drawer: {
				get() {
					return this.$store.getters.appDrawer;
				},
				set(state) {
					this.updateDrawerVuex(state);
				},
			},
		},
	};
</script>

<style scoped>
	.v-list-item--active {
		/* background-color: #567df4; */
		/* border-bottom: 0.2em solid #0b4de7; */
		color: white !important;
	}

	.no-uppercase {
		text-transform: unset !important;
	}

	.custom-text {
		font-size: 14px;
		font-weight: 400;
	}

	.red-alert {
		font-size: 20px;
		font-weight: bold;
		color: rgb(255, 0, 0);
	}
</style>
