<template>
	<div name="vehicleLoanManage">
		<AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

		<v-container>
			<div
				name="vehicleInformationForm"
				class="mb-5"
				v-if="isPermissionCanView"
			>
				<v-form ref="form" v-model="valid">
					<CustomCardFormViewModern
						:title="title"
						:isShowTitle="true"
						:isTitleHighlight="true"
					>
						<v-row no-gutters class="px-6">
							<v-col cols="12">
								<v-card elevation="0" class="px-0">
									<v-row no-gutters>
										<v-col cols="6">
											<!-- <CustomAutocomplete
                        v-model="formData.vehicle"
                        title="เลขป้ายทะเบียน"
                        :isRequired="true"
                        :items="vehicleList"
                        menu-props="auto"
                        :disabled="!isPermissionCanEdit"
                        selectionTextIf="licenseNumber"
                        itemTextListTextIf="licenseNumber"
                        itemText="licenseNumber"
                        itemValue="key"
                        :returnObject="true"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุเลขป้ายทะเบียน']"
                      >
                      </CustomAutocomplete> -->
											<InputView
												class="px-3"
												title="เลขป้ายทะเบียน"
												:isRequired="true"
											>
												<AppComboboxVehicleSearching
													ref="AppAutoCompleteVehicleSearchingRef"
													v-model="vehicleSearch"
													:disabled="!isPermissionCanEdit"
													:isRequired="true"
													:rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
												/>
											</InputView>
										</v-col>

										<v-col cols="6">
											<CustomTextInputCurrency
												data-input-name="amount"
												v-model="formData.amount"
												title="ราคาค่างวดรถ (บาท)"
												ref="loanAmount"
												:is-required="true"
												:readonly="!isPermissionCanEdit"
												:rules="[(v) => !!v || 'จำเป็นต้องระบุค่างวดรถ']"
											>
											</CustomTextInputCurrency>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="6">
											<CustomSelectsArrOfObj
												ref="loanRoundpayDay"
												v-model="formData.loanRoundpayDay"
												class="custom-placeholer-color"
												:items="dayNumber"
												:isRequired="true"
												:disabled="!isPermissionCanEdit"
												:loading="isLoading"
												title="วันที่ต้องชำระประจำเดือน"
												item-text="value"
												item-value="key"
												:returnObject="true"
											>
											</CustomSelectsArrOfObj>
										</v-col>
										<v-col cols="6">
											<CustomTextInputImprove
												ref="financeCompany"
												v-model="formData.financeCompany"
												title="ชื่อไฟแนนซ์"
												placeholder="ชื่อไฟแนนซ์"
												:disabled="!isPermissionCanEdit"
												:isRequired="true"
												:expendMargin="true"
											></CustomTextInputImprove>
										</v-col>
									</v-row>
									<v-row no-gutters>
										<v-col cols="6">
											<CustomDateTypingPickerFormInput
												placeholder="เลือก"
												title="ณ วันที่ (วัน/เดือน/ปี)"
												v-model="formData.issueDate"
												:disabled="!isPermissionCanEdit"
												:isRequired="true"
												:rules="[(v) => !!v || 'จำเป็นต้องเลือกวันที่']"
											/>
										</v-col>
										<!-- <v-col cols="6">
											<CustomDateTypingPickerFormInput
												placeholder="เลือก"
												title="สิ้นสุดวันที่ (วัน/เดือน/ปี)"
												v-model="formData.expiryDate"
												:disabled="!isPermissionCanEdit"
												:isRequired="true"
												:rules="[(v) => !!v || 'จำเป็นต้องเลือกวันที่สิ้นสุด']"
											/> </v-col
									> -->
									</v-row>
									<v-row no-gutters class="px-3">
										<v-col cols="12">
											<FormDataUploadInput
												ref="vehicleCRUDUploadFile"
												component-name="vehicleCRUDUploadFileForm"
												title="ชุดเอกสาร (pdf)"
												placeholder="เพิ่มเอกสารกดที่นี่"
												:uploaded-data="formData.files"
												:is-permission-can-edit="!isPermissionCanEdit"
												uploadTag="VEHICLE_LOAN"
											/>
										</v-col>
									</v-row>
									<v-row dense class="px-3">
										<v-col cols="12">
											<FormDataUploadInput
												ref="vehicleCRUDUploadImageFile"
												component-name="vehicleCRUDUploadImageFileForm"
												title="รูปภาพ (jpg, png)"
												placeholder="เพิ่มรูปภาพที่นี่"
												:isImageType="true"
												:uploaded-data="formData.images"
												:is-permission-can-edit="!isPermissionCanEdit"
												uploadTag="VEHICLE_LOAN"
											/>
										</v-col>
									</v-row> </v-card></v-col
						></v-row>
						<AppSubmitFormButton @on-click-save="saveChange()" />
					</CustomCardFormViewModern>
				</v-form>
				<AppDialogConfirm ref="alertSaving" />
				<AppDialogConfirm ref="dialogAlert" />
			</div>
			<AppOverlay :isLoading="isLoading" />
			<AppPageNotFound :show="isPermissionCanView" />
		</v-container>
	</div>
</template>
<script>
	import CustomTextInput from "@/components/CustomTextInput.vue";
	import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
	import AppOverlay from "@/components/AppOverlay.vue";
	import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
	import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
	import { getAppDataService } from "@/services/api/app";
	import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
	import { DialogType } from "@/services/dialog";
	import AppFilesUploadVue from "@/components/AppFilesUpload.vue";
	import { errorMessageMapper } from "@/services/error";
	import InputView from "@/components/InputView.vue";
	import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
	import AppComboboxVehicleSearching from "@/components/AppComboboxVehicleSearching.vue";
	import {
		getVehicleDocsVelist,
		getVehicleDocsService,
		postVehicleDocsService,
		putVehicleDocsService,
	} from "@/services/api/vehicle";
	import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import { processPermission } from "@/services/permissions";
	import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
	import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
	import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
	import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
	import {
		URL_VEHICLE_DOC_ACT,
		URL_VEHICLE_DOC_LOAN,
	} from "@/services/routerQueryPath";

	export default {
		name: "VehicleLoanManage",
		components: {
			CustomTextInput,
			CustomCardFormViewModern,
			AppOverlay,
			CustomAutocomplete,
			AppDialogConfirm,
			CustomDateTypingPickerFormInput,
			AppFilesUploadVue,
			InputView,
			AppBreadCrumbs,
			AppPageNotFound,
			CustomTextInputImprove,
			AppSubmitFormButton,
			FormDataUploadInput,
			CustomTextInputCurrency,
			AppComboboxVehicleSearching,
			CustomSelectsArrOfObj,
		},
		data() {
			return {
				breadcrumbsItems: [
					{
						text: "รายการค่างวดรถ",
						disabled: false,
						to: `/vehicle${URL_VEHICLE_DOC_LOAN}`,
						exact: true,
					},
					{
						text: "จัดการข้อมูลค่างวดรถ",
						disabled: true,
						to: "",
					},
				],
				isPermissionCanEdit: false,
				isPermissionCanView: false,
				title: "เพิ่มข้อมูลค่างวดรถ",
				valid: false,
				isLoading: false,
				// vehicleList: null,
				vehicleSearch: null,
				input: 1,
				docType: "tax",
				companyType: "com_tax",
				docsCompanySelected: null,
				docsCompanyList: [],
				isFail: false,
				_id: null,

				upFiles: [], // wait from appFile component
				upImages: [], // wait from appFile component
				localUpFile: [],
				localUpImages: [],
				dayNumber: [],

				formData: {
					doc: null,
					vehicle: null,
					type: null,
					amount: null,
					company: null,
					issueDate: null,
					expiryDate: null,
					loanRoundpayDay: null,
					financeCompany: null,
					files: [],
					images: [],
				},
				alertOptions: {
					color: "grey lighten-3",
					width: 400,
					zIndex: 200,
					noconfirm: false,
				},
				permList: {
					admin_all: false,
					vehicle_list: false,
					vehicle_info: false,
					vehicle_info_crud: false,
				},
			};
		},
		watch: {
			upFiles(val) {
				// console.log(val);
			},
			upImages(val) {
				// console.log(val);
			},
			"$route.params.id"(newId, oldId) {
				if (newId !== undefined) {
					this.startPage();
				}
			},
			vehicleSearch(value) {
				if (!_.isNil(value)) {
					if (!_.isString(value)) {
						this.formData["vehicle"] = _.cloneDeep(value);
					} else {
						this.formData["vehicle"] = null;
					}
				}
			},
		},
		methods: {
			startPage() {
				if (this.$route.params.id !== undefined) {
					this.title = "แก้ไขข้อมูลค่างวดรถ";
					this._id = this.$route.params.id;
				} else {
					this._id = null;
					this.title = "เพิ่มข้อมูลค่างวดรถ";
				}
				this.getDayNumber();
				this.getData();
			},
			async getData() {
				this.isLoading = true;
				if (this.isPermissionCanView) {
					// get vehicle list
					// let respVelist = await getVehicleDocsVelist(this.$store);
					// if (respVelist["code"] === 200) {
					//   this.vehicleList = respVelist.data;
					// } else {
					//   this.isFail = true;
					// }

					if (!this.isFail) {
						// get company list
						this.docsCompanyList = await getAppDataService(
							this.$store,
							this.companyType
						);

						// edit
						if (this._id !== null) {
							let params = new URLSearchParams({
								id: this._id,
								doc: this.docType,
								input: this.input,
							}).toString();
							let respData = await getVehicleDocsService(this.$store, params);
							if (respData["code"] === 200) {
								this.formData = _.clone(respData["data"]);
								if (!_.isNil(this.formData["vehicle"])) {
									this.vehicleSearch = _.cloneDeep(this.formData["vehicle"]);
								}
								// reset
								this.upImages = [];
								this.upFiles = [];
								this.localUpFile = [];
								this.localUpImages = [];
								this.localUpFile = _.clone(this.formData["files"]);
								this.localUpImages = _.clone(this.formData["images"]);
							} else {
								this.isFail = true;
							}
						}
					}
				}

				this.isLoading = false;
			},
			getDayNumber() {
				let i = 1;
				while (i <= 31) {
					this.dayNumber.push(i);
					i++;
				}
			},
			async saveChange() {
				if (this.$refs.form.validate() === true) {
					this.isLoading = true;
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
					this.formData.doc = this.docType;

					let _toUpdateByAPI = false;
					let resUploadFile = await this.$refs[
						"vehicleCRUDUploadFile"
					].upload();
					let respUploadImage = await this.$refs[
						"vehicleCRUDUploadImageFile"
					].upload();

					if (this.docsCompanySelected !== null) {
						this.formData.company["key"] = this.docsCompanySelected.key;
						this.formData.company["nameTh"] = this.docsCompanySelected.nameTh;
					}

					// check file upload is faill alert error
					// TODO::
					if (!resUploadFile.status || !respUploadImage.status) {
						// is upload has error response restore all file
						this.callUploadFileErrorDialog();
						this.$refs["vehicleCRUDUploadFile"].restoreFile();
						this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
						// this.disableLoading();
					} else {
						const sendingFormData = _.cloneDeep(this.formData);
						sendingFormData.files = resUploadFile.result;
						sendingFormData.images = respUploadImage.result;
						if (this._id !== null) {
							let resp = await putVehicleDocsService(
								this.$store,
								this._id,
								sendingFormData
							);
							if (resp["code"] === 200) {
								this.isLoading = false;
								this.alertSaveChange(this._id);
								_toUpdateByAPI = true;
								this.getData();
							} else {
								_toUpdateByAPI = false;
							}
						} else {
							let resp = await postVehicleDocsService(
								this.$store,
								sendingFormData
							);
							this.isLoading = false;
							if (resp["code"] === 200) {
								_toUpdateByAPI = true;
								this.alertSaveChange(this._id);
								this.resetFormData();
								this.$router.push(`/vehicle${URL_VEHICLE_DOC_LOAN}`);
							} else {
								_toUpdateByAPI = false;
							}
						}
					}
					if (_toUpdateByAPI === false) {
						// alert fail to update data
						this.$refs["vehicleCRUDUploadFile"].restoreFile();
						this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
					}
					this.isLoading = false;
				}
			},

			// ==== for app upload ====
			remove_upfile(index) {
				this.$refs.mulFilesUpload.removeInput(index);
			},
			remove_imagefile(index) {
				this.$refs.mulImagesUpload.removeInput(index);
			},
			// ====
			remove_localImagefile(atLocation) {
				this.localUpImages.splice(atLocation, 1);
			},
			remove_localUpfile(atLocation) {
				this.localUpFile.splice(atLocation, 1);
			},

			async callUploadFileErrorDialog(respMessage) {
				let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
				for (let i = 0; i < respMessage.length; i++) {
					custom_error_text =
						custom_error_text + errorMessageMapper[respMessage[i]];
				}
				await this.$refs.dialogAlert.open(
					custom_error_text,
					null,
					DialogType.ERROR,
					{
						noconfirm: false,
					}
				);
				// clear data
			},

			async callUploadImageErrorDialog(respMessage) {
				let custom_error_text = "การอัพโหลดรูปไม่สำเร็จ ";
				for (let i = 0; i < respMessage.length; i++) {
					custom_error_text =
						custom_error_text + errorMessageMapper[respMessage[i]];
				}
				await this.$refs.dialogAlert.open(
					custom_error_text,
					null,
					DialogType.ERROR,
					{
						noconfirm: false,
					}
				);
				// clear data
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "VEHICLE_EXPENSES");
					if (
						this.permList.admin_all === true ||
						this.permList.vehicle_info_crud === true
					) {
						this.isPermissionCanView = true;
						this.isPermissionCanEdit = true;
					}
				}
			},

			async alertSaveChange(_id) {
				if (_id !== null) {
					await this.$refs.alertSaving.open(
						"แก้ไขข้อมูลสำเร็จ!",
						null,
						DialogType.POSITIVE,
						this.alertOptions
					);
				} else {
					await this.$refs.alertSaving.open(
						"เพิ่มข้อมูลสำเร็จ!",
						null,
						DialogType.POSITIVE,
						this.alertOptions
					);
				}
			},
			resetFormData() {
				this.formData = {
					doc: null,
					vehicle: null,
					type: null,
					amount: null,
					company: null,
					issueDate: null,
					expiryDate: null,
					files: [],
					images: [],
				};
				this.clearInput();
			},
			clearInput() {
				this.docsCompanySelected = null;
				this.docsCompanyList = [];
				// this.vehicleList = null;
				this.vehicleSearch = null;
				this.upFiles = [];
				this.upImages = [];
				this.localUpFile = [];
				this.localUpImages = [];
			},
		},
		mounted() {
			this.checkUserPermissions();
			this.startPage();
		},
	};
</script>
<style scoped>
	.container {
		max-width: 1500px;
	}
</style>
