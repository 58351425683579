<template>
	<div name="CustomTextarea" :class="expendMargin ? 'px-3' : ''">
		<InputView
			:title="title"
			:small="small"
			:bottom-margin="inputViewBottomMargin"
			:isRequired="isRequired"
			:isTitleDisabled="isTitleDisabled"
		>
			<v-textarea
				v-model="valueInput"
				class="app-custom-input custom-placeholer-color"
				:placeholder="placeholder"
				:type="type"
				:background-color="getBackgrounddColor()"
				:height="height"
				:rules="rules"
				:hide-details="hideDetail"
				outlined
				dense
				:disabled="disabled"
				:name="dataInputName"
			></v-textarea>
		</InputView>
	</div>
</template>
<script>
	import InputView from "./InputView.vue";
	// https://stackoverflow.com/questions/51958950/error-message-props-with-type-object-array-must-use-a-factory-function-to-retu
	export default {
		name: "CustomTextareaImprove",

		components: {
			InputView,
		},

		props: {
			value: {},
			type: {
				type: String,
				default: "text",
			},
			height: {
				type: Number,
				default: 100,
			},
			placeholder: {
				type: String,
				default: "ข้อความ...",
			},
			title: {
				type: String,
				require: true,
			},
			hideDetail: {
				type: Boolean,
				default: false,
			},
			isRequired: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			isTitleDisabled: {
				type: Boolean,
				default: false,
			},
			rules: {
				type: Array,
				default: () => [],
			},
			loading: {
				default: false,
				type: Boolean,
			},
			dataInputName: {
				type: String,
				default: "",
			},
			expendMargin: {
				type: Boolean,
				default: true,
			},
			inputViewBottomMargin: {
				type: Boolean,
				default: true,
			},
			small: {
				type: Boolean,
				default: false,
			},
		},

		computed: {
			valueInput: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit("input", val);
				},
			},
		},

		methods: {
			getBackgrounddColor() {
				if (this.disabled) {
					return this.$vuetify.theme.themes.light.backgroudDisableInputView;
				} else {
					return this.$vuetify.theme.themes.light.backgroundInputView;
				}
			},
		},
	};
</script>
