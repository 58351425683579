<template>
	<div id="WSSummaryList" :class="[{ white: !isPermissionCanView }]">
		<v-container v-if="isPermissionCanView" fluid class="pb-6">
			<v-row class="mb-1">
				<v-col cols="auto">
					<div class="text-h5 mt-1 white--text">รายการสรุปใบงาน</div>
				</v-col>

				<v-spacer></v-spacer>

				<v-col cols="12" sm="4">
					<AppAutoCompleteUserSearching
						:key="componentRefreshCounter"
						:tableLoading="isLoading"
						ref="AppAutoCompleteUserSearchingRef"
						@searchItemChange="getSearchItem"
						:isDark="true"
					/>
				</v-col>
				<v-col cols="12" sm="2">
					<AppAutoCompleteWorksheetSearching
						:key="componentRefreshCounter"
						:tableLoading="isLoading"
						ref="AppAutoCompleteUserSearchingRef"
						@searchItemChange="getSearchItemByPaymentName"
						:isDark="true"
					/>
				</v-col>
				<v-col cols="auto">
					<v-menu
						v-model="isShowMenu"
						:close-on-content-click="false"
						:nudge-width="200"
						:nudge-bottom="10"
						:nudge-left="100"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="#D5DBDB"
								light
								large
								v-bind="attrs"
								v-on="on"
								elevation="0"
								outlined
							>
								<v-icon left dark> mdi-filter </v-icon>
								<div class="normal-button-text white--text">
									{{ "ตัวกรอง" }}
								</div>
							</v-btn>
						</template>

						<WorksheetFilterGroup
							:key="componentRefreshCounter"
							@close-menu="isShowMenu = false"
							@returnFiltedData="getfilterItem"
						/>
					</v-menu>
				</v-col>
			</v-row>
			<v-col
				class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4 pb-0"
			>
				<!-- <v-card class="d-flex flex-row" flat tile width="320"> -->
				<v-select
					class="app-custom-input"
					:items="sortList"
					label="เรียงลำดับโดย"
					style="width: 100px"
					dense
					dark
					outlined
					v-model="sortVal"
					@change="tableSortHandler"
				></v-select>
				<!-- </v-card> -->
				<v-divider class="mx-3 mb-6" dark vertical></v-divider>
				<WorksheetFilterStatus
					:key="componentRefreshCounter"
					:value="statusFilterValue"
					@input="processStatus"
				/>

				<v-spacer></v-spacer>
				<div class="px-3">
					<v-menu bottom left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="primary"
								elevation="0"
								light
								large
								outlined
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-dots-horizontal</v-icon>
							</v-btn>
						</template>

						<v-list dark style="background-color: #323841">
							<v-list-item @click="handleFinanceFileImport()">
								<v-list-item-title class="text-app-title">
									นำเข้ารายการเงินเพิ่มเติม</v-list-item-title
								>
							</v-list-item>
							<v-list-item @click="handleStatusFileImport()">
								<v-list-item-title class="text-app-title">
									นำเข้าการเปลี่ยนสถานะ</v-list-item-title
								>
							</v-list-item>
							<v-list-item @click="exportBankPayroll()">
								<v-list-item-title class="text-app-title">
									ส่งออกรายการจ่าย</v-list-item-title
								>
							</v-list-item>
						</v-list>
					</v-menu>
					<input
						ref="financeUploader"
						class="d-none"
						type="file"
						@change="onFinanceFileChanged"
					/>
					<input
						ref="statusUploader"
						class="d-none"
						type="file"
						@change="onStatusFileChanged"
					/>
				</div>
				<!-- <div>
          <v-btn
            class="ml-3"
            color="primary"
            elevation="0"
            light
            large
            outlined
            :loading="isFinanceSelecting"
            @click="handleFinanceFileImport()"
          >
            Import Finance
          </v-btn>
          <input
            ref="financeUploader"
            class="d-none"
            type="file"
            @change="onFinanceFileChanged"
          />
        </div>
        <v-btn
          @click="exportBankPayroll()"
          class="ml-3"
          color="primary"
          elevation="0"
          light
          large
          outlined
          >Export
        </v-btn>
        <div>
          <v-btn
            class="mx-3"
            color="primary"
            elevation="0"
            light
            large
            outlined
            :loading="isStatusSelecting"
            @click="handleStatusFileImport"
          >
            Import Status
          </v-btn>

          <input
            ref="statusUploader"
            class="d-none"
            type="file"
            @change="onStatusFileChanged"
          />
        </div> -->
				<NormalButtonDark icon="mdi-refresh" @on-click="reset()">
					รีเซ็ต
				</NormalButtonDark>
			</v-col>
			<WorksheetMonthlyTab
				class="px-3"
				@processSelectedMonth="processSelectedMonth"
			/>
			<v-list color="#222831" v-if="worksheetDataList.length > 0" class="pb-0">
				<v-list-item
					class="px-0"
					v-for="(item, index) in worksheetDataList"
					:key="index"
				>
					<v-card class="mb-4 pr-5" width="100%" color="#323841" dark>
						<v-row>
							<v-col cols="10" class="pb-8 pt-4">
								<v-row no-gutters class="ml-4">
									<!-- <v-list-item-icon>
										<v-icon>mdi-file-document</v-icon>
									</v-list-item-icon> -->
									<v-list-item-avatar size="60" class="mr-3">
										<v-img
											:src="
												item.profileImage === null
													? defaultPFImage
													: item.profileImage
											"
										>
										</v-img>
									</v-list-item-avatar>
									<v-list-item-content>
										<div>
											<v-list-item-title
												class="text-app-detail-large white--text font-weight-bold"
											>
												{{
													`${
														item.salutation === null ? "" : item.salutation.th
													}${
														item.firstName === null ? "" : item.firstName.th
													} ${item.lastName === null ? "" : item.lastName.th} `
												}}
												{{
													item.nickName !== null
														? item.nickName.th !== null
															? `(${item.nickName.th})`
															: "-"
														: "-"
												}}</v-list-item-title
											>
											<span class="text-app-normal" v-if="item.empId">
												{{ item.empId }}
											</span>
											<span
												class="text-app-normal"
												v-if="item.empId && item.position"
												>|</span
											>
											<span class="text-app-normal" v-if="item.position">
												{{ item.position }}
											</span>
										</div>
										<span class="text-app-normal" v-if="item.email"
											>อีเมล: {{ item.email }}</span
										>
										<div v-if="item.phoneNumber">
											<span class="text-app-normal"> โทร: </span>
											<span
												class="text-app-normal"
												v-for="(number, idx) in item.phoneNumber"
												:key="idx"
											>
												{{ number }}
												<span v-if="idx < item.phoneNumber.length - 1">,</span>
											</span>
										</div>
									</v-list-item-content>

									<v-list-item-action-text
										:class="[{ 'mr-3': isPermissionCanEdit }, 'mt-3']"
										v-if="isPermissionCanView"
									>
										<v-btn
											:disabled="!isPermissionCanEdit"
											text
											color="#72D5FF"
											class="font-weight-bold"
											@click="handleDownloadPDF(item._id)"
										>
											<v-icon left> mdi-download </v-icon>
											ดาวน์โหลดใบเสร็จ
										</v-btn>
									</v-list-item-action-text>

									<v-list-item-action-text
										class="mt-3"
										v-if="permList.payment_report || permList.payment_crud"
									>
										<v-btn
											text
											color="#72D5FF"
											class="font-weight-bold"
											@click="handleEditClick(item._id)"
										>
											<v-icon left>
												{{ isPermissionCanEdit ? "mdi-pencil" : "mdi-eye" }}
											</v-icon>
											{{ editTextBtn }}
										</v-btn>
									</v-list-item-action-text>
								</v-row>
								<v-divider inset></v-divider>

								<v-list-item>
									<v-list-item-icon> </v-list-item-icon>
									<v-row class="mt-1">
										<v-col cols="6" md="2">
											<v-list-item-title class="subtitle_style">
												รหัสใบงาน
											</v-list-item-title>
											<v-list-item-subtitle>
												<div class="value_style">
													{{ item.paymentName ? item.paymentName : "-" }}
												</div>
											</v-list-item-subtitle>
										</v-col>
										<v-col cols="6" md="2">
											<v-list-item-title class="subtitle_style">
												วันที่เริ่มงาน
											</v-list-item-title>
											<v-list-item-subtitle>
												<div class="value_style">
													{{
														item.createdDate
															? dateStringToDateFormat(item.createdDate)
															: "-"
													}}
												</div>
											</v-list-item-subtitle>
										</v-col>
										<v-col cols="6" md="2">
											<v-list-item-title class="subtitle_style">
												วันที่สรุปยอด
											</v-list-item-title>
											<v-list-item-subtitle>
												<div class="value_style">
													{{
														item.summarizedDate
															? dateStringToDateFormat(item.summarizedDate)
															: "-"
													}}
												</div>
											</v-list-item-subtitle>
										</v-col>
										<v-col cols="6" md="2">
											<v-list-item-title class="subtitle_style">
												จำนวนงาน
											</v-list-item-title>
											<v-list-item-subtitle>
												<div class="value_style">
													{{
														item.contractJobAmount
															? item.contractJobAmount
															: "-"
													}}
												</div>
											</v-list-item-subtitle>
										</v-col>
										<v-col cols="6" md="4" v-if="item.note">
											<v-list-item-title class="subtitle_style">
												หมายเหตุ
											</v-list-item-title>
											<v-list-item-subtitle>
												<div class="value_style one_row_only">
													{{ item.note }}
												</div>
											</v-list-item-subtitle>
										</v-col>
									</v-row>
								</v-list-item>
							</v-col>

							<v-col cols="2">
								<v-row class="mt-2 text-center">
									<v-col cols="12" align="center">
										<v-card
											rounded="pill"
											outlined
											:color="worksheetStatusCardColor(item.status)"
											height="34"
											width="130"
										>
											<div class="status_text_style">
												{{ worksheetStatusText(item.status) }}
											</div>
										</v-card>
									</v-col>

									<v-col cols="12">
										<div
											class="total_money_style"
											v-if="item.netAmount !== null"
										>
											{{ getPriceString(item.netAmount) }} บาท
										</div>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card>
				</v-list-item>
			</v-list>
			<v-list
				v-if="!isLoading && worksheetDataList.length === 0"
				dense
				class="text-center mt-3"
				color="#222831"
			>
				<v-list-item dark>
					<v-list-item-content>
						<v-list-item-title class="text-app-detail-md pb-3"
							>ไม่มีข้อมูล
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-row v-if="isLoading">
				<v-col cols="12">
					<div
						dense
						class="text-center text-app-detail-md mt-3"
						color="#222831"
					>
						<p>กำลังดึงข้อมูล</p>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="!isLoading">
				<v-col cols="12">
					<div class="text-center">
						<v-pagination
							dark
							v-model="queryOptionsTable.page"
							:length="pageDataLength"
						></v-pagination>
					</div>
				</v-col>
			</v-row>

			<WorksheetSummaryDetail ref="worksheetSummaryDialog" />
			<WorksheetExcelPreviewDialog ref="worksheetExcelPreviewDialog" />
			<WorksheetExportBankPayrollDialog
				ref="worksheetExportBankPayrollDialog"
			/>
			<WorksheetStatusExcelPreviewDialog
				ref="worksheetStatusExcelPreviewDialog"
			/>
		</v-container>
		<AppOverlay :absolute="false" :isLoading="isLoading" />
		<AppPageNotFound :show="isPermissionCanView" />
	</div>
</template>

<script>
	import WorksheetMonthlyTab from "./components/WorksheetMonthlyTab.vue";
	import WorksheetSummaryDetail from "./worksheet_summary_detail/WorksheetSummaryDetail.vue";
	import WorksheetExcelPreviewDialog from "./worksheet_summary_detail/WorksheetExcelPreviewDialog.vue";
	import WorksheetStatusExcelPreviewDialog from "./worksheet_summary_detail/WorksheetStatusExcelPreviewDialog.vue";
	import WorksheetExportBankPayrollDialog from "./worksheet_summary_detail/WorksheetExportBankPayrollDialog.vue";
	import WorksheetFilterGroup from "./components/WorksheetFilterGroup.vue";
	import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
	import WorksheetFilterStatus from "./components/WorksheetFilterStatus.vue";
	import NormalButtonDark from "@/components/NormalButtonDark.vue";
	import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
	import { processPermission } from "@/services/permissions";
	import { DialogType } from "@/services/dialog";
	import { dateStringToDateFormat } from "@/services/appDate";
	import AppOverlay from "@/components/AppOverlay.vue";
	import defaultImage from "@/assets/user.png";
	import AppAutoCompleteWorksheetSearching from "@/components/AppWorksheetSearching.vue";
	import {
		getWorksheetListService,
		postDownloadPDFService,
	} from "@/services/api/worksheet";
	import { mapGetters } from "vuex";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import { read, utils, writeFile } from "xlsx";

	export default {
		name: "worksheetSummaryList",

		components: {
			AppOverlay,
			WorksheetMonthlyTab,
			WorksheetSummaryDetail,
			WorksheetExcelPreviewDialog,
			WorksheetExportBankPayrollDialog,
			WorksheetStatusExcelPreviewDialog,
			AppAutoCompleteUserSearching,
			WorksheetFilterGroup,
			WorksheetFilterStatus,
			NormalButtonDark,
			AppAutoCompleteWorksheetSearching,
			AppPageNotFound,
			AppDialogConfirm,
		},

		data() {
			return {
				isLoading: false,
				componentRefreshCounter: 0,
				worksheetDataList: [],
				queryFilterList: {},
				queryStringSearch: "", // combin filter and search text
				querySearchAndFilterList: {}, // combine filter and search
				querySearchAndFilterListBackground: {},
				statusFilterValue: "ALL",
				pageDataLength: 0,
				currentPage: 1,

				isFinanceSelecting: false,
				isStatusSelecting: false,
				selectedFile: null,

				empData: null,
				empStatData: null,

				selectedMonth: null,

				fileUploadErrorMessageType: 0,
				headerName: ["ID_EMP", "N_EMP", "CODE", "ID_CON"],
				headerKey: {
					ID_EMP: "ID_EMP",
					N_EMP: "N_EMP",
					CODE: "CODE",
					ID_CON: "ID_CON",
				},
				errorMsgEachRow: [],
				alertOptions: {
					color: "grey lighten-3",
					width: 400,
					zIndex: 200,
					noconfirm: false,
				},

				queryOptionsTable: {
					limit: 20, // itemsPerPage = limit
					by: "createddate", // key
					order: "desc", // asc or desc
					page: 1, // current page
				},
				sortList: [
					{
						text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
						value: "createddate-asc",
					},
					{
						text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
						value: "createddate-desc",
					},
					{
						text: "ชื่อ-นามสกุล เรียงจาก A-Z",
						value: "name-asc",
					},
					{
						text: "ชื่อ-นามสกุล เรียงจาก Z-A",
						value: "name-desc",
					},
					{
						text: "ยอดสุทธิ เรียงจาก A-Z",
						value: "netAmount-asc",
					},
					{
						text: "ยอดสุทธิ เรียงจาก Z-A",
						value: "netAmount-desc",
					},
				],
				financialDocURL: null,
				checkboxData: {
					thisMonth: 0,
				},
				sortVal: null,
				isShowMenu: false,
				permList: {
					admin_all: false,
					payment_list: false,
					payment_crud: false,
					payment_report: false,
					payment_update_status: false,
					payment_update_close_status: false,
					payment_view_total_report: false,
				},
				defaultPFImage: defaultImage,
				editTextBtn: "แก้ไข",
				rejectTextBtn: "ปฏิเสธ",
				isPermissionCanEdit: false,
				isPermissionCanView: false,
				shiftedData: [],
				countShifted: 0,
			};
		},
		watch: {
			"queryOptionsTable.page": function (newVal) {
				this.currentPage = newVal;
				this.worksheetDataList = [];
				this.tablePageHandler(newVal);
				// this.getData();
			},
		},
		computed: {
			...mapGetters({
				worksheetStatusColor: "worksheet/worksheetStatusColor",
				worksheetStatusCardColor: "worksheet/worksheetStatusCardColor",
				worksheetStatusText: "worksheet/worksheetStatusText",
			}),
		},
		mounted() {
			this.checkUserPermissions();
			if (this.isPermissionCanView) {
				this.processRouterQuery(this.$route.query);
			}
		},
		methods: {
			dateStringToDateFormat: dateStringToDateFormat,
			setRouterQuery(queryObj) {
				this.$router
					.replace({
						path: this.$router.path,
						query: queryObj,
					})
					.catch(() => {});
			},
			setQueryStringSearch(queryObj) {
				this.queryStringSearch = new URLSearchParams(queryObj).toString();
			},

			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "PAYMENT");
					if (this.permList.payment_list === true) {
						this.isPermissionCanView = true;
					}

					if (this.permList.payment_report === true) {
						this.isPermissionCanView = true;
						// this.isPermissionCanEdit = true;
						this.editTextBtn = "ดูข้อมูล";
					}

					if (
						this.permList.admin_all === true ||
						this.permList.payment_crud === true
					) {
						this.isPermissionCanView = true;
						this.isPermissionCanEdit = true;
						this.editTextBtn = "แก้ไข";
					}
				}
			},

			processRouterQuery(_queryRoute) {
				if (Object.keys(_queryRoute).length > 0) {
					Object.keys(_queryRoute).forEach((key) => {
						if (!_.isNil(_queryRoute[key])) {
							switch (key) {
								case "limit":
								// this.queryOptionsTable[key] = 2;
								// break;
								case "page":
									try {
										this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
									} catch (error) {
										this.queryOptionsTable[key] = this.queryOptionsTable[key];
									}
									break;
								case "by":
								case "order":
									this.queryOptionsTable[key] = _queryRoute[key];
									break;
								default:
									// filter and search
									this.querySearchAndFilterList[key] = _queryRoute[key];
									break;
							}
						}
					});

					this.querySearchAndFilterList = {
						...this.queryOptionsTable,
						...this.querySearchAndFilterList,
					};
				} else {
					// set default parameter
					this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
				}
				//
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				this.setRouterQuery(this.querySearchAndFilterList);
				// this.getData();
			},

			async getData() {
				this.isLoading = true;
				this.worksheetDataList = [];

				this.queryStringSearch = new URLSearchParams(
					this.querySearchAndFilterList
				).toString();

				let responseData = await getWorksheetListService(
					this.$store,
					this.queryStringSearch
				);

				if (responseData.code == 200) {
					//
					this.serverItemsLength = responseData.data["total"];
					//
					let worksheets = responseData.data["paginatedResults"];
					this.worksheetDataList = _.cloneDeep(worksheets);
					this.pageDataLength = Math.ceil(
						responseData.data["total"] / this.queryOptionsTable["limit"]
					);
				} else {
					this.worksheetDataList = [];
				}
				this.isLoading = false;
			},

			async getShiftedData() {
				let shiftedData = [];

				this.queryStringSearch = new URLSearchParams(
					this.querySearchAndFilterListBackground
				).toString();

				let responseData = await getWorksheetListService(
					this.$store,
					this.queryStringSearch
				);
				if (responseData.code == 200) {
					//
					//
					shiftedData = _.cloneDeep(responseData.data["paginatedResults"]);
				}
				return shiftedData;
			},

			async handleEditClick(id) {
				const respWorksheet = await this.$refs.worksheetSummaryDialog.open(id);
				if (!_.isNil(respWorksheet)) {
					if (respWorksheet["code"] === 200) {
						if (!_.isNil(respWorksheet["data"])) {
							let newValue = _.clone(respWorksheet["data"]);
							let editIndex = this.worksheetDataList.findIndex(
								(x) => x["_id"] == newValue["_id"]
							);
							if (this.statusFilterValue === "ALL") {
								this.worksheetDataList.splice(editIndex, 1, newValue);
							} else {
								if (this.statusFilterValue !== newValue["status"]) {
									this.processShiftingItem(editIndex);
								} else {
									this.worksheetDataList.splice(editIndex, 1, newValue);
								}
							}
						}
					} else {
						// insert fail
					}
				}
			},

			async processShiftingItem(editIndex) {
				let _queryParameter = {};

				_queryParameter["skip"] =
					this.queryOptionsTable["limit"] * this.currentPage - 1;
				_queryParameter["limit"] = 1;

				this.processSearchingParamsBackground(_queryParameter);
				this.shiftedData = await this.getShiftedData();

				if (!_.isNil(this.shiftedData)) {
					this.worksheetDataList.splice(editIndex, 1);
					if (this.shiftedData.length > 0) {
						this.worksheetDataList.push(this.shiftedData[0]);
					}

					this.countShifted += 1;

					this.pageDataLength = Math.ceil(
						(this.serverItemsLength - this.countShifted) /
							this.queryOptionsTable["limit"]
					);

					if (!_.isNil(this.worksheetDataList)) {
						if (
							this.pageDataLength > 0 &&
							this.worksheetDataList.length === 0
						) {
							this.queryOptionsTable["page"] -= 1;
						}
					}
				}
			},

			async handleDownloadPDF(id) {
				this.isLoading = true;
				if (!_.isNil(id)) {
					let queryString = { id: id };
					let respData = await postDownloadPDFService(this.$store, queryString);
					//
					if (respData["code"] === 200) {
						this.financialDocURL = respData["data"]["pdfURL"];
						window.open(this.financialDocURL, "_blank");
						// await this.getData();
					} else {
						// insert fail
					}
				}
				this.isLoading = false;
			},

			processSelectedMonth(val) {
				// this.selectedMonth = val;
				// this.selectedMonth = { startDate: "2023-12-01", endDate: "2023-12-31" };
				this.getfilterItem(val);
			},

			changeValueOfCheckboxStatus() {
				let searchObj = {};
				Object.keys(this.checkboxData).forEach((key) => {
					if (this.checkboxData[key]) {
						searchObj[key] = "1";
					} else {
						delete this.querySearchAndFilterList[key];
					}
				});

				this.querySearchAndFilterList["page"] = 1;

				this.processSearchingParams(searchObj);
				// this.setRouterQuery(this.querySearchAndFilterList);
				this.getData();

				this.tablePageHandler(1);
			},
			// === end action button of table
			tableUpdateHandler(type, value) {
				switch (type) {
					case "sort":
						let _fieldSortBy = value.split("-");
						this.queryOptionsTable["by"] = [_fieldSortBy[0]];
						this.queryOptionsTable["order"] = [_fieldSortBy[1]];
						break;
					default:
						this.queryOptionsTable[type] = value;
						break;
				}

				this.querySearchAndFilterList = {
					...this.querySearchAndFilterList,
					...this.queryOptionsTable,
				};

				this.setRouterQuery(this.querySearchAndFilterList);
				this.getData();
			},
			tablePageHandler(value) {
				this.tableUpdateHandler("page", value);
			},
			tableItemPerPageHandler(value) {
				this.tableUpdateHandler("limit", value);
			},
			tableSortHandler(value) {
				this.tableUpdateHandler("sort", value);
			},

			getPriceString(number) {
				return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},

			//
			// search method scope
			//
			processSearchingParams(_queryParameter) {
				// combine query search
				this.querySearchAndFilterList = {
					...this.queryOptionsTable, // default table
					...this.querySearchAndFilterList, // old query data
					..._queryParameter,
				};
				this.setRouterQuery(this.querySearchAndFilterList);
			},

			processSearchingParamsBackground(_queryParameter) {
				// combine query search
				this.querySearchAndFilterListBackground = {
					...this.queryOptionsTable, // default table
					...this.querySearchAndFilterList, // old query data
					..._queryParameter,
				};
			},

			processStatus(newStatusVal) {
				this.worksheetDataList = [];
				this.statusFilterValue = newStatusVal;
				this.countShifted = 0;

				if (_.isNil(this.statusFilterValue)) {
					// remove from list
					delete this.querySearchAndFilterList["status"];
					return;
				}

				delete this.querySearchAndFilterList["skip"];

				this.queryOptionsTable["page"] = 1;
				this.querySearchAndFilterList["status"] = this.statusFilterValue;
				if (this.querySearchAndFilterList["status"] === "ALL") {
					// remove from list
					delete this.querySearchAndFilterList["status"];
				}
				// this.setQueryStringSearch(this.querySearchAndFilterList);
				this.setRouterQuery(this.querySearchAndFilterList);
				this.getData();
			},

			getSearchItemByPaymentName(_searchingParameter) {
				//
				// callback from searching component when enter
				//
				this.queryStringSearchList = {};
				delete this.querySearchAndFilterList["codeSearch"]; // id
				let _queryParameter = {};

				if (!_.isNil(_searchingParameter)) {
					if (_searchingParameter["codeSearch"]) {
						// is true => select at
						_queryParameter["codeSearch"] = _searchingParameter.value;
					} else if (
						!_searchingParameter["codeSearch"] &&
						!_.isNil(_searchingParameter.value)
					) {
						if (_searchingParameter.value.length > 0) {
							// not empty
							_queryParameter["codeSearch"] = _searchingParameter.value;
						}
					}
				}

				// _searchingParameter

				this.processSearchingParams(_queryParameter);
				this.getData();
			},

			vaildateHeader(excelJsonData) {
				//excelJsonData: any[]
				for (let i = 0; i < this.headerName.length; i++) {
					if (excelJsonData[0].includes(this.headerName[i]) === false) {
						return false;
					}
				}
				return true;
			},

			async exportBankPayroll() {
				const bankPayroll =
					await this.$refs.worksheetExportBankPayrollDialog.open();
			},

			async uploadFinanceExcel() {
				let fileInput = this.selectedFile;

				const readUploadedFileAsText = (inputFile) => {
					const temporaryFileReader = new FileReader();

					return new Promise((resolve, reject) => {
						//error
						temporaryFileReader.onerror = () => {
							temporaryFileReader.abort();
							reject(new Error("INPUT_FILE"));
						};
						//set
						temporaryFileReader.onload = () => {
							let arrayBuffer = temporaryFileReader.result;
							let workbook = read(arrayBuffer, {
								type: "binary",
							});

							let firstSheet = workbook.SheetNames[0];
							let exceljsondata = utils.sheet_to_json(
								workbook.Sheets[firstSheet],
								{
									header: 1,
								}
							);
							resolve(exceljsondata);
						};

						temporaryFileReader.readAsBinaryString(inputFile);
					});
				};

				try {
					let excelArrayData = await readUploadedFileAsText(fileInput);
					// console.log("excelArrayData", excelArrayData);
					// let excelArrayData = await readUploadedFileAsText(null);
					if (excelArrayData.length < 1) {
						throw new Error("EMPTY_FILE");
					}

					// if (!this.vaildateHeader(excelArrayData)) {
					//   throw new Error("NOT_VAILDATE_HEADER");
					// }

					// prcoess to json object
					let empJsonData = new Array();
					let userJsonData = new Array();
					let errorAtKey = false;
					for (let i = 1; i < excelArrayData.length; i++) {
						let ObjEmpData = {};
						let errorEmpEachKey = [];
						for (let j = 0; j < this.headerName.length; j++) {
							if (excelArrayData[i][j] === undefined) {
								ObjEmpData[this.headerKey[this.headerName[j]]] = null;
								errorEmpEachKey.push(false);
								// errorAtKey = true;
							} else {
								ObjEmpData[this.headerKey[this.headerName[j]]] =
									excelArrayData[i][j];
								errorEmpEachKey.push(true);
							}
						}
						// this.errorMsgEachRow.push(errorEachKey);
						empJsonData.push(ObjEmpData);

						let ObjData = {};
						let errorEachKey = [];
						let maxCol = Math.max(
							excelArrayData[0].length,
							excelArrayData[1].length,
							excelArrayData[2].length
						);
						for (let j = 0; j < maxCol; j++) {
							if (excelArrayData[0][j] === undefined) {
								let emptyHeader = `${excelArrayData[0][j - 1]}_status`;
								if (excelArrayData[i][j] === undefined) {
									ObjData[emptyHeader] = null;
								} else {
									ObjData[emptyHeader] = excelArrayData[i][j];
								}

								errorEachKey.push(false);
							} else {
								if (excelArrayData[i][j] === undefined) {
									ObjData[excelArrayData[0][j]] = null;
								} else {
									ObjData[excelArrayData[0][j]] = excelArrayData[i][j];
								}

								errorEachKey.push(true);
							}
						}
						this.errorMsgEachRow.push(errorEachKey);
						userJsonData.push(ObjData);
					}

					if (errorAtKey) {
						//

						throw new Error("FIELDS_REQUIRED");
					}

					this.empData = empJsonData;
					this.empStatData = userJsonData;

					// console.log(empJsonData);
					// console.log(userJsonData);
					// console.log(JSON.stringify(userJsonData));
					// console.log(this.errorMsgEachRow);
				} catch (error) {
					console.error(error);
				}
			},

			async uploadStatusExcel() {
				let fileInput = this.selectedFile;

				const readUploadedFileAsText = (inputFile) => {
					const temporaryFileReader = new FileReader();

					return new Promise((resolve, reject) => {
						//error
						temporaryFileReader.onerror = () => {
							temporaryFileReader.abort();
							reject(new Error("INPUT_FILE"));
						};
						//set
						temporaryFileReader.onload = () => {
							let arrayBuffer = temporaryFileReader.result;
							let workbook = read(arrayBuffer, {
								type: "binary",
							});

							let firstSheet = workbook.SheetNames[0];
							let exceljsondata = utils.sheet_to_json(
								workbook.Sheets[firstSheet],
								{
									header: 1,
								}
							);
							resolve(exceljsondata);
						};

						temporaryFileReader.readAsBinaryString(inputFile);
					});
				};

				try {
					let excelArrayData = await readUploadedFileAsText(fileInput);
					// console.log("excelArrayData", excelArrayData);
					// let excelArrayData = await readUploadedFileAsText(null);
					if (excelArrayData.length < 1) {
						throw new Error("EMPTY_FILE");
					}

					// if (!this.vaildateHeader(excelArrayData)) {
					//   throw new Error("NOT_VAILDATE_HEADER");
					// }

					// prcoess to json object
					let empJsonData = new Array();
					let userJsonData = new Array();
					let errorAtKey = false;
					for (let i = 1; i < excelArrayData.length; i++) {
						let ObjEmpData = {};
						let errorEmpEachKey = [];
						for (let j = 0; j < this.headerName.length; j++) {
							if (excelArrayData[i][j] === undefined) {
								ObjEmpData[this.headerKey[this.headerName[j]]] = null;
								errorEmpEachKey.push(false);
								// errorAtKey = true;
							} else {
								ObjEmpData[this.headerKey[this.headerName[j]]] =
									excelArrayData[i][j];
								errorEmpEachKey.push(true);
							}
						}
						// this.errorMsgEachRow.push(errorEachKey);
						empJsonData.push(ObjEmpData);

						let ObjData = {};
						let errorEachKey = [];
						let maxCol = Math.max(
							excelArrayData[0].length,
							excelArrayData[1].length
						);
						for (let j = 0; j < maxCol; j++) {
							if (excelArrayData[i][j] === undefined) {
								ObjData[excelArrayData[0][j]] = null;
							} else {
								ObjData[excelArrayData[0][j]] = excelArrayData[i][j];
							}

							errorEachKey.push(true);
						}
						this.errorMsgEachRow.push(errorEachKey);
						userJsonData.push(ObjData);
					}

					if (errorAtKey) {
						//

						throw new Error("FIELDS_REQUIRED");
					}

					this.empData = empJsonData;
					this.empStatData = userJsonData;

					// console.log(empJsonData);
					// console.log(userJsonData);
					// console.log(JSON.stringify(userJsonData));
					// console.log(this.errorMsgEachRow);
				} catch (error) {
					console.error(error);
				}
			},

			handleFinanceFileImport() {
				this.isFinanceSelecting = true;
				// After obtaining the focus when closing the FilePicker, return the button state to normal
				window.addEventListener(
					"focus",
					() => {
						this.isFinanceSelecting = false;
					},
					{ once: true }
				);

				// Trigger click on the FileInput
				this.$refs.financeUploader.click();
			},

			async onFinanceFileChanged(e) {
				this.selectedFile = e.target.files[0];
				// console.log("selectedFile", this.selectedFile);
				await this.uploadFinanceExcel();

				const excelPreview = await this.$refs.worksheetExcelPreviewDialog.open(
					this.empData,
					this.empStatData
				);

				this.$refs.financeUploader.value = null;

				if (!_.isNil(excelPreview)) {
					if (excelPreview) {
						this.getData();
					}
				}
			},

			handleStatusFileImport() {
				this.isStatusSelecting = true;
				// After obtaining the focus when closing the FilePicker, return the button state to normal
				window.addEventListener(
					"focus",
					() => {
						this.isStatusSelecting = false;
					},
					{ once: true }
				);

				// Trigger click on the FileInput
				this.$refs.statusUploader.click();
			},

			async onStatusFileChanged(e) {
				this.selectedFile = e.target.files[0];
				// console.log("selectedFile", this.selectedFile);
				await this.uploadStatusExcel();

				const excelPreview =
					await this.$refs.worksheetStatusExcelPreviewDialog.open(
						this.empData,
						this.empStatData
					);

				this.$refs.statusUploader.value = null;

				if (!_.isNil(excelPreview)) {
					if (excelPreview) {
						this.getData();
					}
				}
			},

			getSearchItem(_searchingParameter) {
				//
				// callback from searching component when enter
				//
				this.queryStringSearchList = {};
				delete this.querySearchAndFilterList["empid"]; // id
				delete this.querySearchAndFilterList["idname"]; // name
				let _queryParameter = {};
				if (!_.isNil(_searchingParameter)) {
					if (_searchingParameter["empId"]) {
						// is true => select at
						_queryParameter["empid"] = _searchingParameter.key;
					} else if (
						!_searchingParameter["empId"] &&
						!_.isNil(_searchingParameter.key)
					) {
						if (_searchingParameter.key.length > 0) {
							// not empty
							_queryParameter["idname"] = _searchingParameter.key;
						}
					} else if (!_.isNil(_searchingParameter["value"])) {
						_queryParameter["idname"] = _searchingParameter["value"];
					}
				}

				// _searchingParameter

				this.processSearchingParams(_queryParameter);
				this.getData();
			},

			getfilterItem(_queryParameter) {
				//
				// callback from filter group
				//
				console.log("_queryParameter", _queryParameter);
				if (!_.isEmpty(this.queryFilterList)) {
					Object.keys(this.queryFilterList).forEach((key) => {
						delete this.querySearchAndFilterList[key];
					});
				}
				this.queryOptionsTable["page"] = 1;
				this.queryFilterList = _queryParameter;

				this.processSearchingParams(_queryParameter);
				this.getData();
			},
			//
			//  end method scope
			//
			reset() {
				if (this.queryStringSearch.length !== 0) {
					this.componentRefreshCounter += 1;
				}
				this.queryOptionsTable = {
					limit: 20, // itemsPerPage = limit
					by: "createddate", // key
					order: "desc", // asc or desc
					page: 1, // current page
				};
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				this.querySearchAndFilterList = {};
				this.currentPage = 1;
				this.shiftedData = [];
				this.countShifted = 0;
				this.processSearchingParams();
				// this.getData();
			},
		},
	};
</script>

<style scoped>
	#WSSummaryList {
		display: flex;
		height: 100%;
		/* justify-content: center;
  align-items: center; */
		flex-direction: column;
		background-color: #222831;
	}

	.one_row_only {
		white-space: nowrap;
		word-break: normal;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.name_style {
		font-size: 20px;
		font-weight: 500;
	}

	.subtitle_style {
		font-size: 14px;
		font-weight: normal;
		color: #8a96a4;
	}

	.value_style {
		padding-top: 6px;
		font-size: 14px;
		font-weight: bold;
		color: #fcfdfd;
	}

	.status_paid_style {
		border: 2px solid #6ecc95;
		background-color: rgba(110, 204, 149, 0.3);
	}

	.status_not_yet_paid_style {
		border: 2px solid #8a96a4;
		background-color: rgba(138, 150, 164, 0.3);
	}

	.status_text_style {
		font-size: 16px;
		font-weight: 600;
		color: #ffffff;
		text-align: center;
		padding-top: 4px;
	}

	.total_money_style {
		font-size: 20px;
		font-weight: bold;
		padding-left: 10px;
	}
</style>
