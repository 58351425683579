<template>
  <div name="permissionSettings">
    <v-form ref="formCompanyData" v-model="valid">
      <CustomCardFormViewModern
        title="ข้อมูลสถานะพนักงาน"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div class="px-6">
          <v-row no-gutters class="pr-3 pb-6">
            <v-col cols="6">
              <v-col class="pb-2">
                <span class="title-input"> สถานะพนักงาน: </span>
                <v-chip
                  v-if="empStatus !== null"
                  class="ml-3"
                  filter
                  outlined
                  :class="`${getStatusEmployeeColor(empStatus)}`"
                >
                  <span
                    :class="`${getStatusEmployeeColor(empStatus)}--text px-1`"
                    >{{ getStatusEmployeeText(empStatus) }}</span
                  >
                </v-chip>
                <span v-else class="title-input">-</span>
                <span class="text-app-detail-normal px-3">|</span>
                <span class="title-input"
                  >อายุงาน: {{ empJoinDay !== null ? empJoinDay : "-" }}</span
                >
              </v-col>
              <!-- <v-col class="py-0" v-if="empStatus">
                <v-chip
                  filter
                  outlined
                  :color="getStatusEmployeeColor(empStatus)"
                >
                  <span class="px-1">{{
                    getStatusEmployeeText(empStatus)
                  }}</span>
                </v-chip>
              </v-col> -->
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <PrimaryButton
                v-if="canEdit"
                icon="mdi-plus"
                :color="`success`"
                @on-click="handleAddClick()"
              >
                บันทึกข้อมูลสถานะพนักงาน
              </PrimaryButton>
            </v-col>
            <!-- <v-row no-gutters class="px-0 pt-3">
              <v-chip
                filter
                outlined
                :color="getStatusEmployeeColor(empStatus)"
              >
                <span class="px-1">{{ getStatusEmployeeText(empStatus) }}</span>
              </v-chip>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <PrimaryButton
                  v-if="canEdit"
                  icon="mdi-plus"
                  :color="`success`"
                  @on-click="handleAddClick()"
                >
                  บันทึกข้อมูลสถานะพนักงาน
                </PrimaryButton>
              </v-col>
            </v-row> -->
          </v-row>
        </div>
        <div class="px-6 pb-3">
          <div v-if="tableData !== null && tableData.length == 0" class="pt-3">
            <v-divider></v-divider>
            <v-col align="center">{{ dataTableNoDataText }}</v-col>
          </div>
          <v-data-table
            v-else
            :headers="tableHeaders"
            :items="tableData"
            :loading="tableLoading"
            :no-data-text="dataTableNoDataText"
            :loading-text="dataTableLoadingText"
            :hide-default-footer="true"
            class="app-datatable"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-list-item v-if="item.status !== null" class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="text-app-title">
                    <span
                      v-if="item.previousStatus !== null"
                      :class="`${getStatusEmployeeColor(
                        item.previousStatus
                      )}--text`"
                      >{{ getStatusEmployeeText(item.previousStatus) }}</span
                    >
                    <v-icon class="px-3" v-if="item.previousStatus !== null"
                      >mdi-arrow-right-thin</v-icon
                    >
                    <span
                      :class="`${getStatusEmployeeColor(item.status)}--text`"
                      >{{ getStatusEmployeeText(item.status) }}</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <v-list-item v-if="item.detail !== null" class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="text-app-title">
                    {{ item.detail }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-else>-</div>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <v-list-item v-if="item.createdAt !== null" class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="text-app-title">
                    {{
                      dateStringToDateFormat(item.createdAt, {
                        type: "datetime",
                        returnThai: false,
                      })
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.issueDate`]="{ item }">
              <v-list-item v-if="item.issueDate !== null" class="px-0">
                <v-list-item-content>
                  <v-list-item-title class="text-app-title">
                    {{
                      dateStringToDateFormat(item.issueDate, {
                        type: "date",
                        returnThai: false,
                      })
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.userId`]="{ item }">
              <v-list-item
                v-if="item.userId !== null && item.userId !== undefined"
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-app-title">
                    {{
                      item.userId.dpNameTh !== null ? item.userId.dpNameTh : "-"
                    }}
                    <span
                      v-if="
                        item.userId.dpNickNameTh !== null &&
                        item.userId.dpNickNameTh !== undefined
                      "
                    >
                      ({{ item.userId.dpNickNameTh }})
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-else>-</div>
            </template>
            <!-- <template v-slot:[`item.tools`]="{ item }">
            <v-btn
              plain
              class="text-decoration-underline error--text diplay--inline-block"
              :disabled="!canEdit"
              @click="handleDeleteItem(item._id)"
            >
              ลบ
            </v-btn>
          </template> -->
          </v-data-table>
        </div>
        <!-- <AppSubmitFormButton
          @on-click-save="saveChange()"
          v-if="canEdit"
          text="บันทึกข้อมูลสถานะพนักงาน"
        /> -->
      </CustomCardFormViewModern>
    </v-form>
    <EmployeeChangeStatusDialog ref="changeStatusDialog" />
  </div>
</template>
<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import PrimaryButton from "@/components/PrimaryButton.vue";
import EmployeeChangeStatusDialog from "@/views/employee/employee_detail/components/EmployeeChangeStatusDialog.vue";
import _ from "lodash";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import {
  getEmployeeStatusService,
  postEmployeeStatusService,
} from "@/services/api/employee";
import { processPermission } from "@/services/permissions";
import { mapGetters } from "vuex";

const EmployeeStatus = Object.freeze({
  APPLIED: "ขอสมัคร",
  REJECTED: "ไม่รับ",
  PROBATION: "ทดลองงาน",
  ACTIVE: "ทำงาน",
  RESIGNED: "ลาออก",
  TERMINATED: "เลิกจ้าง",
  FIRED: "ไล่ออก",
});

export default {
  name: "EmployeeStatusDataView",

  components: {
    CustomCardFormViewModern,
    AppSubmitFormButton,
    PrimaryButton,
    EmployeeChangeStatusDialog,
  },

  props: {
    data: {
      type: [Object, null],
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      require: true,
    },
    canEdit: true,
  },
  computed: {
    // ...mapGetters({
    //   empStatusCardColor: "employee/empStatusCardColor",
    // }),
  },
  data() {
    return {
      valid: false,
      contractPermissionAll: false,
      contractPermissionSome: false,
      dataTableFooterProps,
      dataTableNoDataText,
      dataTableLoadingText,
      tableLoading: false,
      tableData: [],
      respTableData: [],
      tableHeaders: [
        {
          text: "การเปลี่ยนสถานะ",
          align: "start",
          sortable: false,
          value: "status",
          width: "15%",
        },
        {
          text: "สาเหตุ",
          align: "start",
          sortable: false,
          value: "detail",
          width: "30%",
        },
        {
          text: "เวลาที่เปลี่ยน",
          align: "start",
          sortable: false,
          value: "issueDate",
        },
        {
          text: "เวลาที่บันทึก",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "ผู้เปลี่ยนแปลง",
          align: "start",
          sortable: false,
          value: "userId",
        },
      ],
      empStatus: null,
      empJoinDay: null,

      formData: {
        permissions: [],
        permissionSelectedContractId: [],
      },

      employeeStatusItems: [
        "APPLIED",
        "REJECTED",
        "PROBATION",
        "ACTIVE",
        "RESIGNED",
        "TERMINATED",
        "FIRED",
      ],
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },

      permissionSettingItems: [], // all item;
      defaultPermissionSettingItems: [], // for select
      positionPermissionCheckpointInit: false,
    };
  },

  watch: {
    respTableData(newValue) {
      if (!_.isNil(newValue)) {
        this.tableData = _.clone(newValue);
        if (newValue.length > 0) {
          for (let i = 0; i < newValue.length; i++) {
            if (i < newValue.length - 1) {
              this.tableData[i]["previousStatus"] = newValue[i + 1]["status"];
            } else {
              this.tableData[i]["previousStatus"] = null;
            }
          }
        }
      }
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    dateStringToDateFormat: dateStringToDateFormat,

    async getData() {
      this.tableLoading = true;
      if (!_.isNil(this.userId)) {
        let responseData = await getEmployeeStatusService(
          this.$store,
          this.userId
        );
        if (responseData.code == 200) {
          this.empStatus = responseData.data.status;
          this.empJoinDay = responseData.data.joinDay;
          this.respTableData = responseData.data.list;
        } else {
          this.respTableData = [];
        }
      }

      if (_.isNil(this.empStatus)) {
        this.handleAddClick();
      }

      this.tableLoading = false;
    },

    getStatusEmployeeText(status) {
      switch (status) {
        case "APPLIED":
          return EmployeeStatus.APPLIED;
        case "REJECTED":
          return EmployeeStatus.REJECTED;
        case "PROBATION":
          return EmployeeStatus.PROBATION;
        case "ACTIVE":
          return EmployeeStatus.ACTIVE;
        case "RESIGNED":
          return EmployeeStatus.RESIGNED;
        case "TERMINATED":
          return EmployeeStatus.TERMINATED;
        case "FIRED":
          return EmployeeStatus.FIRED;
        default:
          return null;
      }
    },

    getStatusEmployeeColor(status) {
      switch (status) {
        case "APPLIED":
          return "text--darken-2 subtitle";
        case "REJECTED":
          return "text--darken-4 subtitle";
        case "PROBATION":
          return "primary";
        case "ACTIVE":
          return "success";
        case "RESIGNED":
          return "warning";
        case "TERMINATED":
          return "text--lighten-1 error";
        case "FIRED":
          return "text--darken-1 error";
        default:
          return null;
      }
    },

    async handleAddClick() {
      const updateEmpStatusData = await this.$refs.changeStatusDialog.open(
        this.empStatus
      );
      if (updateEmpStatusData !== null) {
        let respData = await postEmployeeStatusService(
          this.$store,
          this.userId,
          updateEmpStatusData
        );
        //
        if (respData["code"] === 200) {
          this.getData();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.required-color {
  color: var(--v-error);
}
</style>
