<template>
	<v-container name="vehicleLoanList" fluid>
		<div v-if="isPermissionCanView">
			<v-row class="mb-1">
				<v-col cols="auto">
					<h1 class="text-h5 mt-1">รายการค่างวดรถ</h1>
				</v-col>

				<v-spacer></v-spacer>

				<v-col cols="12" sm="4">
					<AppAutoCompleteVehicleSearching
						:key="componentRefreshCounter"
						:tableLoading="tableLoading"
						ref="AppAutoCompleteVehicleSearchingRef"
						@searchItemChange="getSearchItem"
					/>
				</v-col>
				<v-col cols="auto">
					<v-menu
						v-model="isShowMenu"
						:close-on-content-click="false"
						:nudge-width="200"
						:nudge-bottom="10"
						:nudge-left="100"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="#D5DBDB"
								light
								large
								v-bind="attrs"
								v-on="on"
								elevation="0"
								outlined
								:style="{
									backgroundColor: 'white !important',
								}"
							>
								<v-icon left color="#4D5656"> mdi-filter </v-icon>
								<div class="normal-button-text">{{ "ตัวกรอง" }}</div>
							</v-btn>
						</template>
						<VehicleDocsFilterGroup
							:key="componentRefreshCounter"
							:docsType="docsType"
							@close-menu="isShowMenu = false"
							@returnFiltedData="getfilterItem"
						/>
					</v-menu>
				</v-col>
				<v-col cols="auto">
					<PrimaryButton
						v-if="isPermissionCanEdit"
						:large="true"
						icon="mdi-plus"
						@on-click="handleAddClick()"
					>
						เพิ่มข้อมูล
					</PrimaryButton>
				</v-col>
			</v-row>
			<v-data-table
				:headers="tableHeaders"
				:items="tableData"
				:items-per-page="queryOptionsTable.limit"
				:page="queryOptionsTable.page"
				:loading="tableLoading"
				:server-items-length="serverItemsLength"
				:footer-props="dataTableFooterProps"
				:no-data-text="dataTableNoDataText"
				:loading-text="dataTableLoadingText"
				@update:page="tablePageHandler"
				@update:items-per-page="tableItemPerPageHandler"
				class="app-datatable"
			>
				<template v-slot:top>
					<div
						class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
					>
						<v-card class="d-flex flex-row" flat tile width="320">
							<v-select
								class="app-custom-input"
								:items="sortList"
								label="เรียงลำดับโดย"
								dense
								outlined
								v-model="sortVal"
								@change="tableSortHandler"
							></v-select>
						</v-card>

						<NormalButton icon="mdi-refresh" @on-click="reset()">
							รีเซ็ต
						</NormalButton>
					</div>
				</template>

				<template v-slot:[`item.licenseNumber`]="{ item }">
					<v-list-item two-line class="px-0">
						<v-list-item-content>
							<v-list-item-title class="text-app-title font-weight-bold">
								<router-link
									class="text-decoration-none"
									:to="'/vehicle/edit/' + item.vehicleId"
									>{{ item.licenseNumber }}</router-link
								></v-list-item-title
							>

							<span
								v-if="
									(item.licenseType !== null) & (item.licenseType !== undefined)
								"
								class="text-app-normal"
							>
								<span class="mx-0 px-0">
									<v-icon
										v-if="vehicleLicenseTypeColor(item.licenseType) === 'white'"
										>mdi-card-text-outline</v-icon
									>
									<v-icon
										v-else
										:color="vehicleLicenseTypeColor(item.licenseType)"
										>mdi-card-text</v-icon
									>
								</span>

								{{ vehicleLicenseTypeText(item.licenseType) }}</span
							>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-slot:[`item.type`]="{ item }">
					<v-list-item two-line class="px-0">
						<v-list-item-content>
							<v-list-item-title
								class="text-app-title font-weight-bold"
								v-if="item.type !== null"
							>
								{{ vehicleTypeText(item.type) }}</v-list-item-title
							>
							<span
								class="mx-0 px-0 text-app-normal"
								v-if="(item.seat !== null) & (item.seat !== undefined)"
							>
								<v-icon>{{ vehicleTypeIcon(item.type) }}</v-icon>
								{{ item.seat }} ที่นั่ง </span
							><span v-else>-</span>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-slot:[`item.licenseType`]="{ item }">
					<v-list-item two-line class="px-0">
						<v-list-item-content>
							<v-list-item-title class="text-app-title">
								{{
									vehicleLicenseTypeText(item.licenseType)
								}}</v-list-item-title
							>
							<span v-if="item.type !== null" class="text-app-normal">{{
								item.type
							}}</span>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-slot:[`item.amount`]="{ item }">
					{{ item.amount.toLocaleString() }}
				</template>

				<template v-slot:[`item.company`]="{ item }">
					<v-list-item two-line class="px-0">
						<v-list-item-content>
							<v-list-item-title class="text-app-title">
								{{ item.company.nameTh || "-" }}</v-list-item-title
							>
							<!-- <span class="text-app-normal">{{ item.type }}</span> -->
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-slot:[`item.issueDate`]="{ item }">
					<v-list-item v-if="item.issueDate !== null" two-line class="px-0">
						<v-list-item-content>
							<v-list-item-title class="text-app-title">
								{{
									dateStringToDateFormat(item.issueDate, {
										returnThai: false,
									})
								}}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<div v-else>-</div>
				</template>

				<template v-slot:[`item.expiryDate`]="{ item }">
					<v-list-item v-if="item.expiryDate !== null" two-line class="px-0">
						<v-list-item-content>
							<v-list-item-title
								:class="
									'text-app-title font-weight-bold ' +
									getExpiredColor(item.remainingDay)
								"
							>
								{{ item.remainingDay }} วัน</v-list-item-title
							>
							<span class="text-app-normal">{{
								dateStringToDateFormat(item.expiryDate, { returnThai: false })
							}}</span>
						</v-list-item-content>
					</v-list-item>
					<div v-else>-</div>
				</template>

				<template v-slot:[`item.paidLoanCount`]="{ item }">
					<span class="text-app-title"> 10</span>
				</template>

				<template v-slot:[`item.financeCompany`]="{ item }">
					<span class="text-app-title"> ABBCD </span>
				</template>
				<template v-slot:[`item.userId`]="{ item }">
					<span class="text-app-title"> {{ item.userId }} </span>
				</template>

				<template v-slot:[`item.tools`]="{ item }">
					<v-menu bottom left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon>mdi-dots-horizontal</v-icon>
							</v-btn>
						</template>

						<v-list>
							<v-list-item
								@click="handleEditClick(item._id)"
								:disabled="!isPermissionCanEdit"
							>
								<v-list-item-icon>
									<v-icon> mdi-pencil</v-icon>
								</v-list-item-icon>
								<v-list-item-title class="text-app-title">
									แก้ไขข้อมูล</v-list-item-title
								>
							</v-list-item>
							<v-list-item
								@click="handleDeleteClick(item._id)"
								:disabled="!isPermissionCanEdit"
							>
								<v-list-item-icon>
									<v-icon> mdi-delete</v-icon>
								</v-list-item-icon>
								<v-list-item-title class="text-app-title"
									>ลบข้อมูล</v-list-item-title
								>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
			</v-data-table>
		</div>
		<AppPageNotFound :show="isPermissionCanView" />
		<AppDialogConfirm ref="confirmDelete" />
	</v-container>
</template>

<script>
	import CustomCardFormView from "@/components/CustomCardFormView.vue";
	import { processPermission } from "@/services/permissions";
	import NormalButton from "@/components/NormalButton.vue";
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import { mapGetters } from "vuex";
	import {
		getVehicleDocs,
		deleteVehicleDocsService,
	} from "@/services/api/vehicle";
	import VehicleDocsFilterGroup from "@/views/vehicle/components/VehicleDocsFilterGroup.vue";
	import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
	import { DialogType } from "@/services/dialog";
	import { dateStringToDateFormat } from "@/services/appDate";
	import {
		dataTableFooterProps,
		dataTableNoDataText,
		dataTableLoadingText,
	} from "@/services/AppConig";
	import AppAutoCompleteVehicleSearching from "@/components/AppAutoCompleteVehicleSearching.vue";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import _ from "lodash";
	import {
		appDecodeSingleURIComponentHelper,
		appEncodeURIComponentHelper,
	} from "@/services/appStringHelpper";

	export default {
		name: "vehicleLoanList",
		components: {
			CustomCardFormView,
			NormalButton,
			PrimaryButton,
			VehicleDocsFilterGroup,
			AppDialogConfirm,
			AppAutoCompleteVehicleSearching,
			AppPageNotFound,
		},
		data() {
			return {
				componentRefreshCounter: 0,
				title: "รายการภาษีรถ",
				_licenseNumber: null,
				docsType: "loan",
				dataTableFooterProps,
				dataTableNoDataText,
				dataTableLoadingText,
				tableLoading: false,
				field_sort_by: null,
				serverItemsLength: -1,
				queryStringSearch: "",
				sortVal: null,
				itemsPerPage: 20,
				tableData: [],
				respTableData: [],
				queryStringSearchList: {},
				queryFilterList: {},
				querySearchAndFilterList: {},
				queryOrder: {
					// query : formData
					company: "company",
					///
					minissuedate: "minissuedate",
					maxissuedate: "maxissuedate",
					//
					minexpdate: "minexpdate",
					maxexpdate: "maxexpdate",
				},
				queryOptionsTable: {
					doc: "tax",
					limit: 20, // itemsPerPage = limit
					by: "createddate", // key
					order: "desc", // asc or desc
					page: 1, // current page
				},

				permList: {},
				isPermissionCanView: false,
				isPermissionCanEdit: false,
				isShowMenu: false,
				sortList: [
					{
						text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
						value: "createddate-asc",
					},
					{
						text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
						value: "createddate-desc",
					},
					{
						text: "วันหมดอายุ เรียงจาก น้อย-มาก",
						value: "expdate-asc",
					},
					{
						text: "วันหมดอายุ เรียงจาก มาก-น้อย",
						value: "expdate-desc",
					},
				],

				tableHeaders: [
					{
						text: "เลขป้ายทะเบียน",
						align: "start",
						sortable: false,
						value: "licenseNumber",
						width: "15%",
					},
					{
						text: "ชนิดรถยนต์",
						align: "start",
						sortable: false,
						value: "type",
						width: "10%",
					},
					{
						text: "ค่างวด (บาท)",
						align: "start",
						sortable: false,
						value: "amount",
						align: "right",
					},

					{
						text: "วันชำระ",
						align: "start",
						sortable: false,
						value: "issueDate",
						align: "center",
					},
					{
						text: "วันหมดอายุ",
						align: "start",
						sortable: false,
						value: "expiryDate",
						align: "left",
						width: "10%",
					},
					{
						text: "ชำระแล้ว (งวด)",
						align: "start",
						sortable: false,
						value: "paidLoanCount",
						align: "center",
					},
					{
						text: "ชื่อไฟแนนซ์",
						align: "start",
						sortable: false,
						value: "financeCompany",
						align: "left",
					},
					{
						text: "ผู้เพิ่มหลักฐาน",
						align: "start",
						sortable: false,
						value: "userId",
						align: "center",
					},
					{
						text: "",
						value: "tools",
						sortable: false,
						align: "center",
						width: "10%",
					},
				],
			};
		},
		computed: {
			...mapGetters({
				vehicleLicenseTypeText: "vehicle/vehicleLicenseTypeText",
				vehicleLicenseTypeColor: "vehicle/vehicleLicenseTypeColor",
				vehicleTypeText: "vehicle/vehicleTypeText",
				vehicleTypeIcon: "vehicle/vehicleTypeIcon",
			}),
			tableRows() {
				let rows = this.respTableData.map((row) => {
					let userFullName = "-";
					let user = this.checkUndefinedAndNull(row, "userId", false);
					if (user !== "-") {
						userFullName = user.firstName.th + " " + user.lastName.th;
					}
					return {
						_id: row._id,
						licenseNumber: row.licenseNumber,
						licenseType: row.licenseType,
						seat: row.seat,
						amount: this.checkUndefinedAndNullAmount(row, "amount", false),
						type: row.type,
						company: this.checkUndefinedAndNull(row, "company", false),
						issueDate: row.issueDate,
						remainingDay: this.checkUndefinedAndNull(
							row,
							"remainingDay",
							false
						),
						userId: userFullName,
						vehicleId: row.vehicleId,
						expiryDate: this.checkUndefinedAndNull(row, "expiryDate", false),
						tools: [],
					};
				});
				return rows;
			},
		},
		mounted() {
			this.checkUserPermissions();
			this.processRouterQuery(this.$route.query);
		},
		methods: {
			dateStringToDateFormat: dateStringToDateFormat,
			processRouterQuery(_queryRoute) {
				if (Object.keys(_queryRoute).length > 0) {
					Object.keys(_queryRoute).forEach((key) => {
						if (!_.isNil(_queryRoute[key])) {
							switch (key) {
								case "text":
									this.querySearchAndFilterList[key] =
										appDecodeSingleURIComponentHelper(_queryRoute[key]);
									break;
								case "limit":
								case "page":
									try {
										this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
									} catch (error) {
										this.queryOptionsTable[key] = this.queryOptionsTable[key];
									}
									break;
								case "by":
								case "order":
									this.queryOptionsTable[key] = _queryRoute[key];
									break;
								default:
									// filter and search
									this.querySearchAndFilterList[key] = _queryRoute[key];
									break;
							}
						}
					});

					this.querySearchAndFilterList = {
						...this.queryOptionsTable,
						...this.querySearchAndFilterList,
					};
				} else {
					// set default parameter
					this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
				}
				//
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				// this.setRouterQuery(this.querySearchAndFilterList);
				this.getData();
			},
			setRouterQuery(queryObj) {
				const _queryObj = appEncodeURIComponentHelper(queryObj);

				this.$router
					.replace({
						path: this.$router.path,
						query: _queryObj,
					})
					.catch(() => {});
			},
			tableUpdateHandler(type, value) {
				switch (type) {
					case "sort":
						let _fieldSortBy = value.split("-");
						this.queryOptionsTable["by"] = [_fieldSortBy[0]];
						this.queryOptionsTable["order"] = [_fieldSortBy[1]];
						break;
					default:
						this.queryOptionsTable[type] = value;
						break;
				}

				this.querySearchAndFilterList = {
					...this.querySearchAndFilterList,
					...this.queryOptionsTable,
				};

				this.setRouterQuery(this.querySearchAndFilterList);
				this.getData();
			},
			tablePageHandler(value) {
				this.tableUpdateHandler("page", value);
			},
			tableItemPerPageHandler(value) {
				this.tableUpdateHandler("limit", value);
			},
			tableSortHandler(value) {
				this.tableUpdateHandler("sort", value);
			},
			async getData() {
				this.tableLoading = true;
				this.tableData = [];
				this.queryStringSearch = new URLSearchParams(
					this.querySearchAndFilterList
				).toString();

				let responseData = await getVehicleDocs(
					this.$store,
					this.queryStringSearch
				);
				if (responseData.code == 200) {
					this.serverItemsLength = responseData.data["total"];
					let vehicleInsuranceList = responseData.data["vehicleList"];
					this.respTableData = vehicleInsuranceList;
					this.tableData = this.tableRows;
				} else {
					this.tableData = [];
				}

				this.tableLoading = false;
			},
			getSearchItem(_searchingParameter) {
				//
				// callback from searching component when enter
				//
				this.queryStringSearchList = {};
				delete this.querySearchAndFilterList["text"];
				delete this.querySearchAndFilterList["cassieid"];
				let _queryParameter = {};
				if (!_.isNil(_searchingParameter)) {
					if (!_.isNil(_searchingParameter["key"])) {
						_queryParameter["cassieid"] = _searchingParameter["key"];
						_queryParameter["text"] = _searchingParameter["value"];
					} else {
						if (!_.isNil(_searchingParameter.value)) {
							_queryParameter["text"] = _searchingParameter["value"];
						}
					}
				}

				this.processSearchingParams(_queryParameter);
				this.getData();
			},
			processSearchingParams(_queryParameter) {
				// combine query search
				this.querySearchAndFilterList = {
					...this.queryOptionsTable, // default table
					...this.querySearchAndFilterList, // old query data
					..._queryParameter,
				};
				this.setRouterQuery(this.querySearchAndFilterList);
			},
			getfilterItem(_queryParameter) {
				//
				// callback from filter group
				//
				// if (!_.isEmpty(this.queryFilterList)) {
				//   Object.keys(this.queryFilterList).forEach((key) => {
				//     delete this.querySearchAndFilterList[key];
				//   });
				// }
				// this.queryFilterList = _queryParameter;

				Object.keys(this.queryOrder).forEach((key) => {
					delete this.querySearchAndFilterList[key];
				});

				this.processSearchingParams(_queryParameter);
				this.getData();
			},

			handleAddClick() {
				this.$router.push("/vehicle/loan/create");
			},
			handleEditClick(_id) {
				this.$router.push(`/vehicle/loan/edit/${_id}`);
			},
			async handleDeleteClick(_id) {
				if (
					await this.$refs.confirmDelete.open(
						"ต้องการลบข้อมูลนี้ ?",
						null,
						DialogType.ACTION,
						{
							noconfirm: true,
						}
					)
				) {
					await deleteVehicleDocsService(this.$store, _id, this.docsType);
					this.getData();
				}
			},
			checkUndefinedAndNullAmount(item, key, returnBoolean) {
				if (item.hasOwnProperty(key) === false) {
					return returnBoolean ? false : "-";
				}

				if (item[key] === null) {
					return returnBoolean ? false : "-";
				}
				// if string
				if (item[key].length === 0) {
					return returnBoolean ? false : "-";
				}

				return parseFloat(item[key]);
			},
			checkUndefinedAndNull(item, key, returnBoolean) {
				if (item.hasOwnProperty(key) === false) {
					return returnBoolean ? false : "-";
				}

				if (item[key] === null) {
					return returnBoolean ? false : "-";
				}
				// if string
				if (item[key].length === 0) {
					return returnBoolean ? false : "-";
				}

				return item[key];
			},
			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "VEHICLE_EXPENSES");

					if (
						this.permList.admin_all === true ||
						this.permList.vehicle_expenses === true
					) {
						this.isPermissionCanView = true;
						this.isPermissionCanEdit = true;
					}
				}
			},

			getExpiredColor(day) {
				if (day < this.$store.getters["vehicle/vehicleExpiryDay"]["red"]) {
					return "red--text text--darken-1 font-weight-bold";
				} else if (
					day < this.$store.getters["vehicle/vehicleExpiryDay"]["orange"]
				) {
					return "amber--text text--darken-2 font-weight-bold";
				}
				return "green--text text--darken-1 font-weight-bold";
			},
			reset() {
				if (this.queryStringSearch.length !== 0) {
					this.componentRefreshCounter += 1;
				}

				this.queryOptionsTable = {
					doc: "tax",
					limit: 20, // itemsPerPage = limit
					by: "createddate", // key
					order: "desc", // asc or desc
					page: 1, // current page
				};
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				this.querySearchAndFilterList = {};
				this.processSearchingParams();
				this.getData();
			},
		},
	};
</script>
