<template>
  <div name="CustomCardFormViewModern" :class="[{ 'mb-1': addMargin }]">
    <v-card
      rounded="lg"
      :elevation="elevation"
      :class="[{ 'fill-height': fillHeight }]"
    >
      <v-card-title
        v-if="isShowTitle"
        :class="
          setPaddingToCardTitle
            ? 'text-title font-weight-bold'
            : 'text-title font-weight-bold py-0'
        "
      >
        <v-row v-if="isTitleHighlight" class="pl-3 pr-3 pt-3">
          <v-col cols="12">
            <v-card color="main-bg-color" flat class="d-flex px-6 py-3">
              <p class="ma-0 pa-0 pt-1">{{ title }}</p>
              <v-spacer></v-spacer>
              <slot name="toolBtn"></slot>
            </v-card>
          </v-col>
        </v-row>
        <div v-else-if="isAddTitleMargin" class="pl-6 pr-3 pt-3">
          {{ title }}
        </div>
        <div v-else>{{ title }}</div>
      </v-card-title>

      <v-card-text :class="setPaddingToCardText ? 'pa-0' : ''">
        <slot></slot>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import HeaderCardModern from "@/components/HeaderCardModern.vue";

export default {
  name: "CustomCardFormViewModern",

  components: {
    HeaderCardModern,
  },

  props: {
    title: String,
    isShowTitle: Boolean,
    isTitleHighlight: {
      type: Boolean,
      default: false,
    },
    elevation: { type: Number, default: 2 },
    addMargin: {
      type: Boolean,
      default: true,
    },
    isAddTitleMargin: {
      type: Boolean,
      default: false,
    },
    setPaddingToCardTitle: {
      type: Boolean,
      default: true,
    },
    setPaddingToCardText: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped></style>
