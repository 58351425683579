<template>
  <CustomCardFormViewModern
    title="QR Code"
    class="pb-1"
    :elevation="0"
    :isShowTitle="false"
    :setPaddingToCardText="true"
    align="center"
  >
    <a ref="saveQrHref" @click="downloadQr">
      <canvas
        id="myCanvas"
        :width="canvasWidth"
        :height="canvasHeight"
        ref="qrcodeCanvas"
      ></canvas>
    </a>
  </CustomCardFormViewModern>
</template>

<script>
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import _ from "lodash";

export default {
  name: "VehicleQRCode",
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => {
    return {
      canvasWidth: 196, // 196
      canvasHeight: 230,
      currentImage: null,
    };
  },

  watch: {
    data: {
      immediate: false,
      handler: function (newValue) {
        if (!_.isNil(newValue)) {
          this.drawing();
        }
      },
    },
  },
  methods: {
    // https://codesandbox.io/embed/github/johnlindquist/fetch-image/tree/master/?module=/src/index.js
    // https://stackoverflow.com/questions/33806624/save-fabricjs-canvas-as-image-on-the-pc
    async setImage(url, ctx) {
      let img = new Image();
      await new Promise((r) => (img.onload = r), (img.src = url));
      ctx.drawImage(img, 0, 0);
    },

    async drawing() {
      let ctx = this.$refs.qrcodeCanvas.getContext("2d");
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      await this.setImage(this.data.qrcodeImages, ctx);
      //
      // (async function () {
      //   const response = await fetch(`https://placekitten.com/320/240`);
      //   const blob = await response.blob();

      //   const url = URL.createObjectURL(blob.slice(0, 4000));

      //   const image = new Image();
      //   image.src = url;

      //   document.getElementById("app").appendChild(image);
      // })();
      //
      // ==> insert text
      ctx.fillStyle = "black";
      ctx.font = "30px Noto Sans Thai";
      ctx.fillText(this.data.licenseNumber || "", this.canvasWidth / 5, 220);
    },

    async downloadQr() {
      // do later
      let link = document.createElement("a");
      link.download = "filename.png";
      link.href = document.getElementById("myCanvas").toDataURL({
        format: "png",
        quality: 0.8,
      });
      link.click();
    },
  },
  components: { CustomCardFormViewModern },
};
</script>

<style lang="scss" scoped></style>
