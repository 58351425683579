<template>
  <div name="FinancialAccountData">
    <v-form ref="formFinancial" v-model="valid">
      <CustomCardFormViewModern
        title="ข้อมูลผู้รับเงิน"
        :isShowTitle="true"
        :isTitleHighlight="true"
      >
        <div>
          <v-row no-gutters class="mx-6">
            <v-col>
              <v-checkbox
                class="px-2"
                v-model="useTheSameNameAsIDCard"
                label="ใช้ข้อมูลเดียวกับข้อมูลส่วนตัว"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-6">
            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsImprove
                ref="salutation"
                v-model="formData.payeeData.salutation"
                :disabled="useTheSameNameAsIDCard"
                title="คำนำหน้า (ภาษาไทย)"
                :items="['นาย', 'นางสาว', 'นาง']"
                :readonly="!enableEdit"
                dataInputName="payeeDataSalutation"
              />
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <CustomTextInputImprove
                v-model="formData.payeeData.firstName"
                ref="firstnameTh"
                title="ชื่อผู้รับเงิน (ภาษาไทย)"
                inputType="thOnly"
                placeholder=""
                :disabled="useTheSameNameAsIDCard"
                :readonly="!enableEdit"
                dataInputName="payeeDataFirstName"
              />
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <CustomTextInputImprove
                v-model="formData.payeeData.lastName"
                ref="lastnameTh"
                title="นามสกุลผู้รับเงิน (ภาษาไทย)"
                inputType="thOnly"
                placeholder=""
                :disabled="useTheSameNameAsIDCard"
                :readonly="!enableEdit"
                dataInputName="payeeDataLastName"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-6">
            <v-col cols="12" lg="3" sm="6">
              <CustomTextInputImprove
                v-model="formData.payeeData.IssuingWithholdingTaxIdCard"
                ref="idCard"
                inputType="numberWithoutSign"
                title="เลขบัตรประชาชนผู้รับเงิน"
                placeholder="ตัวเลข 13 ตัว"
                :disabled="useTheSameNameAsIDCard"
                :readonly="!enableEdit"
                dataInputName="payeeDataIssuingWithholdingTaxIdCard"
              />
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsArrOfObj
                ref="accountType"
                v-model="formData.payeeData.accountType"
                title="ประเภทบัญชี"
                :items="[
                  { key: 'LC', value: 'บจก' },
                  { key: 'LP', value: 'หจก' },
                  { key: 'NONE', value: 'ไม่มี' },
                ]"
                :readonly="!enableEdit"
                data-input-name="payeeDataAccountType"
                :is-required="false"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-6">
            <v-col cols="12" lg="6" sm="6" class="px-3">
              <v-img
                :src="bookbankImageInput.src"
                height="365"
                contain
                class="backgroud-image"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" justify="center">
                    <v-icon x-large>mdi-image</v-icon>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="12" lg="6" sm="6">
              <v-col cols="12" lg="12" sm="6" class="py-0">
                <span class="title-input">
                  รูปภาพหน้าบัญชีธนาคาร (ไม่เกิน 10MB)</span
                >
                <!-- <span class="red--text"> * </span> -->
                <div class="mt-2"></div>
                <AppFilesUpload
                  placeholder="เลือกรูปภาพ"
                  :isClearInput="false"
                  :multiple="false"
                  :small-chips="false"
                  :allow-image-only="true"
                  :upload-with-scale="false"
                  :isPrependInnerIcon="true"
                  v-model="formData.payeeData.bookbankImage"
                  ref="AppFilesUploadBookbankImage"
                  :disabled="!canEdit"
                  uploadTag="EMP_BOOK_BANK"
                />
              </v-col>
              <v-col cols="12" lg="12" class="pa-0">
                <CustomAutocomplete
                  v-model="formData.payeeData.bank"
                  ref="bank"
                  menu-props="auto"
                  title="ชื่อธนาคาร"
                  :items="bankList"
                  itemText="nameTh"
                  itemValue="key"
                  :readonly="!enableEdit"
                  dataInputName="payeeDataBank"
                >
                </CustomAutocomplete>
              </v-col>
              <v-col cols="12" lg="12" class="pa-0">
                <CustomTextInputImprove
                  ref="bankBranch"
                  v-model="formData.payeeData.bankBranch"
                  title="สาขาธนาคาร"
                  placeholder="ชื่อสาขา"
                  :readonly="!enableEdit"
                  dataInputName="payeeDataBankBranch"
                />
              </v-col>
              <v-col cols="12" lg="12" class="pa-0">
                <CustomTextInputImprove
                  ref="accountNumber"
                  v-model="formData.payeeData.bankAccountNumber"
                  title="เลขที่บัญชีธนาคาร"
                  placeholder="ตัวเลขบัญชี"
                  inputType="numberWithoutSign"
                  :readonly="!enableEdit"
                  dataInputName="payeeDataBankAccountNumber"
                />
              </v-col>
            </v-col>
          </v-row>

          <!-- <div class="mt-6 mb-4">
            <v-divider></v-divider>
          </div> -->
          <v-row class="px-3 mb-3 mt-0">
            <v-col cols="12"
              ><v-card color="main-bg-color" flat
                ><v-card-title class="px-6 py-3 text-title font-weight-bold">
                  ข้อมูลค่าจ้าง</v-card-title
                >
              </v-card></v-col
            ></v-row
          >
          <v-row no-gutters class="mx-6">
            <!-- <v-col cols="12">
              <v-card elevation="0" class="pt-4 pb-2 px-3">
                <div class="title-form">ข้อมูลค่าจ้าง</div>
              </v-card>
            </v-col> -->

            <v-col cols="12" lg="3" sm="6">
              <CustomTextInputCurrency
                title="ค่าจ้าง (฿)"
                ref="salary"
                v-model="formData.payeeData.salary"
                placeholder="จำนวนเงินหน่วยบาท"
                :readonly="!enableEdit"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                dataInputName="payeeDataIssuingSalary"
              />
            </v-col>

            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsImprove
                ref="jobType"
                v-model="formData.payeeData.jobType"
                placeholder="เลือก"
                title="ประเภทการจ้าง"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :items="jobTypeItems"
                :disabled="!enableEdit"
                dataInputName="jobType"
              />
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsImprove
                ref="hiringType"
                v-model="formData.payeeData.hiringType"
                placeholder="เลือก"
                title="บุคคลภายใน/ภายนอก"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :items="inOutSourceItems"
                :disabled="!enableEdit"
                dataInputName="hiringType"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-6">
            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsArrOfObj
                ref="payrollPayment"
                v-model="formData.payeeData.payday"
                class="custom-placeholer-color"
                :items="dayNumber"
                :disabled="!enableEdit"
                title="วันที่จ่ายเงินเดือน"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                placeholder="วันที่ (Payroll Date)"
                item-text="value"
                item-value="key"
                :returnObject="true"
              >
              </CustomSelectsArrOfObj>
            </v-col>

            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsArrOfObj
                ref="cutPaymentDate"
                v-model="formData.payeeData.cutPaymentDate"
                class="custom-placeholer-color"
                :items="dayNumber"
                :disabled="!enableEdit"
                title="วันที่ตัดยอด"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                placeholder="วันที่ตัดยอด"
                item-text="value"
                item-value="key"
                :returnObject="true"
              >
              </CustomSelectsArrOfObj>
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-6">
            <v-col cols="12" lg="3" sm="6">
              <CustomTextInputImprove
                class="ma-0"
                input-type="floatNumberWithOutSign"
                v-model="formData.payeeData.brokerPercent"
                title="ค่าคอมมิชชั่นจากงาน (%)"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                dataInputName="brokerPercent"
              ></CustomTextInputImprove>
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsImprove
                ref="socialInsurance"
                v-model="formData.payeeData.socialInsurance"
                placeholder="เลือก"
                title="ประกันสังคม"
                :items="socialInsuranceItems"
                :disabled="!enableEdit"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                dataInputName="socialInsurance"
              />
            </v-col>

            <v-col cols="12" lg="3" sm="6">
              <CustomSelectsImprove
                ref="vatPercent"
                v-model="formData.payeeData.vatPercent"
                placeholder="เลือก"
                title="หัก ณ ที่จ่าย (%)"
                :items="vatPercentItems"
                :disabled="!enableEdit"
                :isRequired="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                dataInputName="vatPercent"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-9">
            <v-col cols="12">
              <FormDataUploadInput
                ref="payeeContractUploadFile"
                component-name="payeeContractUploadFileForm"
                title="ชุดเอกสารสัญญาจ้าง (pdf ไม่เกิน 10MB)"
                placeholder="เพิ่มเอกสารสัญญาจ้างกดที่นี่"
                :uploaded-data="formData.payeeData.files"
                :is-permission-can-edit="!enableEdit"
                uploadTag="PAYEE_CONTRACT"
              />
            </v-col>
          </v-row>

          <!-- <div class="mt-6 mb-4">
            <v-divider></v-divider>
          </div> -->

          <AddressFormView
            class="mx-0"
            ref="payeeAddressRef"
            title="ที่อยู่ผู้รับเงิน"
            :isRequired="false"
            :isTitleHighlight="true"
            :addressData="formData.address.payeeAddress"
            :disabled="payeeAddressRadioSelected !== 0 || !enableEdit"
            prefixName="payeeAddress"
          >
            <template v-slot:checkbox>
              <v-radio-group
                v-model="payeeAddressRadioSelected"
                row
                dense
                class="px-5"
              >
                <v-radio
                  v-for="item in payeeAddressRadioList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </template>
          </AddressFormView>

          <!-- <div class="mt-6 mb-4">
            <v-divider></v-divider>
          </div> -->

          <AddressFormView
            class="mx-0"
            ref="addressForIssuingWithholdingRef"
            title="ที่อยู่ เพื่อออก ณ ที่จ่าย"
            :isRequired="false"
            :isTitleHighlight="true"
            :addressData="formData.address.addressForIssuingWithholding"
            :disabled="payeeWithholdingRadioSelected || !enableEdit"
            prefixName="addressForIssuingWithholding"
          >
            <!-- <template v-slot:checkbox>
              <v-checkbox
                class="px-5"
                v-model="useTheSameAddressAsPayeeCheckbox"
                label="ใช้ที่อยู่เดียวกับผู้รับเงิน"
                dense
              ></v-checkbox>
            </template> -->

            <template v-slot:checkbox>
              <v-radio-group
                v-model="payeeWithholdingRadioSelected"
                row
                dense
                class="px-5"
              >
                <v-radio
                  v-for="item in payeeWithholdingRadioList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </template>
          </AddressFormView>
        </div>
        <AppSubmitFormButton
          @on-click-save="saveChange()"
          v-if="enableEdit"
          text="บันทึกข้อมูลผู้รับเงิน"
        />
      </CustomCardFormViewModern>
    </v-form>
    <AppDialogConfirm ref="dialogAlert" />
  </div>
</template>
<script>
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomSelectsImprove from "@/components/CustomSelectsImprove.vue";
import CustomTextarea from "@/components/CustomTextarea.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import AddressFormView from "./AddressFormView.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import _ from "lodash";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import AppFilesUpload from "@/components/AppFilesUpload.vue";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";

export default {
  name: "FinancialAccountData",
  components: {
    CustomTextarea,
    CustomAutocomplete,
    AddressFormView,
    CustomCardFormViewModern,
    CustomTextInputImprove,
    CustomSelectsImprove,
    CustomSelectsArrOfObj,
    AppSubmitFormButton,
    CustomTextInputCurrency,
    AppFilesUpload,
    FormDataUploadInput,
    AppDialogConfirm,
  },

  props: {
    userId: {
      type: [String, null],
      default: null,
    },
    data: {
      type: [Object, null],
      default: null,
    },

    // addressData: {
    //   type: [Object, null],
    //   default: null,
    // },
    canEdit: true,
  },

  data() {
    return {
      valid: false,
      formData: {
        address: {
          payeeAddress: {
            prefix: null,
            road: null,
            subdistrict: null,
            district: null,
            province: null,
            postalCode: null,
            provinceId: null,
            districtId: null,
            subDistrictId: null,
          },
          addressForIssuingWithholding: {
            prefix: null,
            road: null,
            subdistrict: null,
            district: null,
            province: null,
            postalCode: null,
            provinceId: null,
            districtId: null,
            subDistrictId: null,
          },
        },
        payeeData: {
          bankAccountNumber: null,
          bank: null,
          bankBranch: null,
          bookbankImage: null,
          jobType: null,
          hiringType: null,
          socialInsurance: null,
          vatPercent: null,
          files: [],
          salutation: null,
          fullName: null,
          firstName: null,
          lastName: null,
          payday: null,
          cutPaymentDate: null,
          brokerPercent: null,
          salary: null,
          IssuingWithholdingTaxIdCard: null,
          IssuingWithholdingTaxFullName: null,
          IssuingWithholdingSalutation: null,
          IssuingWithholdingFirstName: null,
          IssuingWithholdingLastName: null,
          accountType: "NONE",
        },
      },
      addressTemplete: {
        prefix: null,
        road: null,
        subdistrict: null,
        district: null,
        province: null,
        postalCode: null,
        provinceId: null,
        districtId: null,
        subDistrictId: null,
      },
      //
      USE_ANOTHER_ADDRESS: 0,
      USE_PERMANENT_ADRESS: 1,
      USE_CURRENT_ADDRESS: 2,

      USE_PAYEE_ADDRESS: 2,

      useTheSameNameAsIDCard: false,
      useTheSameAddressAsPayeeCheckbox: false,
      payeeAddressRadioSelected: 0,
      payeeWithholdingRadioSelected: 0,

      bankList: [],
      bookbankRawSource: null,
      bookbankImageInput: {
        src: "",
      },
      payeeAddressRadioList: [
        { label: "ที่อยู่อื่น", value: 0 },
        { label: "ใช้ที่อยู่เดียวกับบัตรประชาชน", value: 1 },
        { label: "ใช้ที่อยู่เดียวกับที่อยู่ปัจจุบัน", value: 2 },
      ],
      payeeWithholdingRadioList: [
        { label: "ที่อยู่อื่น", value: 0 },
        { label: "ใช้ที่อยู่เดียวกับบัตรประชาชน", value: 1 },
        { label: "ใช้ที่อยู่เดียวกับผู้รับเงิน", value: 2 },
      ],
      jobTypeItems: ["รายเดือน", "รายวัน", "ตาม Job", "Part-Time"],
      socialInsuranceItems: ["เอา", "ไม่เอา"],
      inOutSourceItems: ["ภายใน", "ภายนอก"],
      vatPercentItems: ["0", "1", "3"],
      focus: {
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      },
      dayNumber: [],
    };
  },

  computed: {
    enableEdit: {
      get() {
        return this.canEdit;
      },
    },
  },

  watch: {
    data(newValue) {
      this.processData(newValue);
    },

    "formData.payeeData.bookbankImage"(newFile) {
      if (!_.isNil(newFile)) {
        let isSelectedFile = Array.isArray(newFile);
        if (isSelectedFile) {
          if (newFile.length !== 0) {
            this.bookbankImageInput = _.clone(newFile[0]);
          } else {
            if (!_.isNil(this.bookbankRawSource)) {
              this.bookbankImageInput.src = this.bookbankRawSource.src;
            } else {
              this.bookbankImageInput.src = "";
            }
          }
        } else {
          this.bookbankRawSource = newFile;
          this.bookbankImageInput.src = newFile.src;
          // this.saveChange();
        }
      }
    },

    useTheSameNameAsIDCard(newValue) {
      if (newValue === true) {
        this.formData.payeeData.salutation = !_.isNil(
          this.$store.state.employee.idCardData
        )
          ? this.$store.state.employee.idCardData.salutation.th
          : null;

        this.formData.payeeData.firstName = !_.isNil(
          this.$store.state.employee.idCardData
        )
          ? this.$store.state.employee.idCardData.firstName.th
          : null;

        this.formData.payeeData.lastName = !_.isNil(
          this.$store.state.employee.idCardData
        )
          ? this.$store.state.employee.idCardData.lastName.th
          : null;

        this.formData.payeeData.IssuingWithholdingTaxIdCard = !_.isNil(
          this.$store.state.employee.idCardData
        )
          ? this.$store.state.employee.idCardData.idCardNumber
          : null;
      } else {
        this.formData.payeeData.salutation = null;
        this.formData.payeeData.firstName = null;
        this.formData.payeeData.lastName = null;
        this.formData.payeeData.IssuingWithholdingTaxIdCard = null;
      }
    },

    useTheSameAddressAsPayeeCheckbox(newValue) {
      if (newValue === true) {
        let getPermanentAddress =
          this.$refs.payeeAddressRef.getCurrentStateData();
        this.formData.address.addressForIssuingWithholding =
          _.clone(getPermanentAddress);
      } else {
        this.formData.address.addressForIssuingWithholding = _.clone(
          this.addressTemplete
        );
      }
    },

    payeeAddressRadioSelected(newValue) {
      if (newValue === this.USE_PERMANENT_ADRESS) {
        this.formData.address.payeeAddress = !_.isNil(
          this.$store.state.employee.addressData
        )
          ? this.$store.state.employee.addressData.permanentAddress
          : null;
      } else if (newValue === this.USE_CURRENT_ADDRESS) {
        this.formData.address.payeeAddress = !_.isNil(
          this.$store.state.employee.addressData
        )
          ? this.$store.state.employee.addressData.currentAddress
          : null;
      } else if (newValue === this.USE_ANOTHER_ADDRESS) {
        this.formData.address.payeeAddress = _.clone(this.addressTemplete);
      }
    },
    payeeWithholdingRadioSelected(newValue) {
      if (newValue === this.USE_PAYEE_ADDRESS) {
        let getPermanentAddress =
          this.$refs.payeeAddressRef.getCurrentStateData();
        this.formData.address.addressForIssuingWithholding =
          _.clone(getPermanentAddress);
      } else if (newValue === this.USE_PERMANENT_ADRESS) {
        this.formData.address.addressForIssuingWithholding = !_.isNil(
          this.$store.state.employee.addressData
        )
          ? this.$store.state.employee.addressData.permanentAddress
          : null;
      } else if (newValue === this.USE_ANOTHER_ADDRESS) {
        this.formData.address.addressForIssuingWithholding = _.clone(
          this.addressTemplete
        );
      }
    },
  },

  mounted() {
    this.getDayNumber();
  },

  methods: {
    processData(_vd) {
      if (_.isPlainObject(_vd)) {
        //
        if (!_.isNil(_vd["payeeData"])) {
          if (_.isNil(_vd.payeeData.files)) {
            _vd.payeeData.files = [];
          }

          _vd["payeeData"]["brokerPercent"] = _.clone(
            _vd["payeeData"]["brokerPercent"].toString()
          );
          this.formData = _.clone(_vd);
        }
        // process app data
        this.bankList = _vd["_appCompanyData"]["bank"];
      } else {
        this.canEdit = false;
      }
    },

    redictToInvalid() {
      this.$refs.formFinancial.$el.scrollIntoView();
      return true;
    },

    async saveChange() {
      if (this.$refs.formFinancial.validate() === true) {
        let returnBookbankImage = null;
        if (!_.isNil(this.formData.payeeData.bookbankImage)) {
          let isNewBookbank = Array.isArray(
            this.formData.payeeData.bookbankImage
          );
          if (isNewBookbank) {
            // Adding New Book Bank
            if (this.formData.payeeData.bookbankImage.length > 0) {
              let resultBookbankImageUpload =
                await this.$refs.AppFilesUploadBookbankImage.uploadAction();
              if (resultBookbankImageUpload.length !== 0) {
                if (resultBookbankImageUpload[0]["code"] >= 400) {
                  // is error
                  throw resultBookbankImageUpload[0];
                  this.$refs.AppFilesUploadBookbankImage.clearInput();
                } else {
                  returnBookbankImage = resultBookbankImageUpload[0].data;
                }
              }
            } else if (!_.isNil(this.bookbankRawSource)) {
              returnBookbankImage = {
                fileKey: this.bookbankRawSource.fileKey,
              };
            }
          } else {
            returnBookbankImage = {
              fileKey: this.formData.payeeData.bookbankImage.fileKey,
            };
          }
        }

        let resUploadFile = await this.$refs[
          "payeeContractUploadFile"
        ].upload();

        if (!resUploadFile.status) {
          // is upload has error response restore all file
          this.callUploadFileErrorDialog();
          this.$refs["payeeContractUploadFile"].restoreFile();
          // this.disableLoading();
        } else {
          let getPayeeAddressData =
            this.$refs.payeeAddressRef.getCurrentStateData();
          let getForIssuingWithholdingData =
            this.$refs.addressForIssuingWithholdingRef.getCurrentStateData();
          // filter used key
          let filterOutField = ["subDistrictList", "districtList"];
          getForIssuingWithholdingData = _.pickBy(
            getForIssuingWithholdingData,
            (value, key) => !filterOutField.includes(key)
          );

          getPayeeAddressData = _.pickBy(
            getPayeeAddressData,
            (value, key) => !filterOutField.includes(key)
          );

          let addressDataList = {
            payeeAddress: { ...getPayeeAddressData },
            addressForIssuingWithholding: { ...getForIssuingWithholdingData },
          };

          let responseData = _.pick(_.cloneDeepWith(this.formData), [
            "payeeData",
          ]);

          responseData["payeeData"]["IssuingWithholdingSalutation"] =
            responseData["payeeData"]["salutation"];
          responseData["payeeData"]["IssuingWithholdingFirstName"] =
            responseData["payeeData"]["firstName"];
          responseData["payeeData"]["IssuingWithholdingLastName"] =
            responseData["payeeData"]["lastName"];

          responseData["address"] = { ...addressDataList };

          const filterNameTh = ["district", "subdistrict", "province"];
          const addressField = ["payeeAddress", "addressForIssuingWithholding"];
          for (let i = 0; i < addressField.length; i++) {
            for (let j = 0; j < filterNameTh.length; j++) {
              responseData["address"][addressField[i]][filterNameTh[j]] =
                !_.isNil(
                  responseData["address"][addressField[i]][filterNameTh[j]]
                )
                  ? responseData["address"][addressField[i]][filterNameTh[j]][
                      "nameTh"
                    ]
                  : null;
            }
          }

          responseData["payeeData"]["bookbankImage"] = {
            ...returnBookbankImage,
          };

          responseData["payeeData"]["files"] = resUploadFile.result;
          // responseData["payeeData"]["files"] = [];

          this.$emit("on-click-save", responseData);
        }
      } else {
        this.redictToInvalid();
      }
    },
    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },
    getDayNumber() {
      let i = 1;
      while (i <= 31) {
        this.dayNumber.push(i);
        i++;
      }
    },
  },
};
</script>

<style>
.v-label {
  font-size: 14px !important;
}
</style>
