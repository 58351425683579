<template>
  <CustomCardFormViewModern
    title="Passenger QR Code"
    :isShowTitle="false"
    :setPaddingToCardText="true"
    align="center"
  >
    <div v-if="image">
      <a ref="saveQrHref" @click="downloadQr">
        <v-img :src="image"></v-img>
      </a>
      <p class="pb-2">คลิกที่รูปเพื่อดาว์นโหลด</p>
    </div>

    <div class="pb-3" v-if="isError">
      <p class="text-app-md">ไม่สามารถรูปภาพได้</p>
    </div>

    <div class="py-3" v-if="isLoading">
      <p class="text-app-md">กำลังโหลดรูปภาพ....</p>
    </div>
  </CustomCardFormViewModern>
</template>

<script>
import { getContractPassageQRCodeService } from "@/services/api/contract";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";

export default {
  name: "ContractPassagerQRCodeView",
  props: {
    contractId: {
      type: String,
      required: true,
    },
    contractNumber: {
      type: [String, null],
      default: null,
    },
  },

  components: {
    CustomCardFormViewModern,
  },

  data() {
    return {
      image: null,
      isError: false,
      isLoading: false,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      this.isLoading = true;
      const queryString = new URLSearchParams({
        id: this.contractId,
      }).toString();
      try {
        const responseData = await getContractPassageQRCodeService(
          this.$store,
          queryString
        );
        if (responseData["code"] === 200) {
          this.image = responseData["data"]["img"];
        } else {
          throw "REQUEDT_FAIL";
        }
      } catch (error) {
        this.isError = true;
      }
      this.isLoading = false;
    },
    async downloadQr() {
      let link = document.createElement("a");
      link.download = `${this.contractNumber}-passage-qr.png`;
      link.href = this.image;
      link.click();
    },
  },
};
</script>
