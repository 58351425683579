var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"name":"vehicletListView","fluid":""}},[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-h5 mt-1"},[_vm._v("รายการวางงานจำนวนมาก")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.isPermissionCanEdit)?_c('PrimaryButton',{attrs:{"large":true,"icon":"mdi-plus"},on:{"on-click":function($event){return _vm.handleAddClick()}}},[_vm._v(" เพิ่มข้อมูล ")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"app-datatable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"items-per-page":_vm.queryOptionsTable.limit,"page":_vm.queryOptionsTable.page,"loading":_vm.tableLoading,"server-items-length":_vm.serverItemsLength,"footer-props":_vm.dataTableFooterProps,"no-data-text":_vm.dataTableNoDataText,"loading-text":_vm.dataTableLoadingText},on:{"update:page":_vm.tablePageHandler,"update:items-per-page":_vm.tableItemPerPageHandler},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"app-table-list-identity pt-4 px-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"320px"}},[_c('v-select',{staticClass:"app-custom-input",attrs:{"items":_vm.sortList,"label":"เรียงลำดับโดย","dense":"","outlined":"","hide-details":""},on:{"change":_vm.tableSortHandler},model:{value:(_vm.sortVal),callback:function ($$v) {_vm.sortVal=$$v},expression:"sortVal"}})],1),_c('v-spacer'),_c('NormalButton',{attrs:{"icon":"mdi-refresh"},on:{"on-click":function($event){return _vm.reset()}}},[_vm._v(" รีเซ็ต ")])],1)])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-title',{staticClass:"text-app-title font-weight-bold primary--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openSheet(item._id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.responsibleUser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.responsibleUser ? item.responsibleUser.dpNameTh : "-")+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateStringToDateFormat(item.createdAt, { returnThai: false }))+" ")]}},{key:"item.contract",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contract ? ((item.contract.title) + " (" + (item.contract.contractNumber) + ")") : "-")+" ")]}},{key:"item.tools",fn:function(ref){
var item = ref.item;
return [(_vm.permList.jobs_multiple_crud || _vm.permList.admin_all)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[(_vm.permList.jobs_multiple_crud || _vm.permList.admin_all)?_c('v-list-item',{on:{"click":function($event){return _vm.handleEditClick(item._id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-pencil")])],1),_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" "+_vm._s(_vm.editTextBtn)+" ")])],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":item.totalTable > 0},on:{"click":function($event){return _vm.handleDeleteClick(item._id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-delete")])],1),_c('v-list-item-title',{staticClass:"text-app-title"},[_vm._v(" ลบ ")])],1)],1)],1):_vm._e()]}}],null,true)}),_c('AppDialogConfirm',{ref:"confirmDelete"}),_c('AssignMultipleJobCreateSheetDialog',{ref:"assignMultipleJobCreateSheetDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }