<template>
  <div name="contractManageView">
    <AppBreadCrumbs :show="isPermissionCanView" :items="breadcrumbsItems" />

    <v-container>
      <v-row dense v-if="isPermissionCanView && changeViewLoading">
        <v-col cols="12" sm="12" :md="isEdit ? 9 : 12">
          <v-form ref="form" v-model="valid">
            <CustomCardFormViewModern
              :title="title"
              :isShowTitle="true"
              :isTitleHighlight="true"
            >
              <v-row no-gutters class="px-6">
                <v-col cols="12">
                  <v-card elevation="0" class="px-0">
                    <v-row no-gutters class="px-3 pb-3">
                      <v-col cols="12">
                        <span class="title-input"> สถานะ </span>
                        <span class="required-color"> * </span>
                      </v-col>
                      <v-col cols="6" md="8" sm="12">
                        <v-card elevation="0" class="px-0">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="4"
                              lg="4"
                              class="pa-3 pl-0 pr-2"
                              v-for="(value, key) in fundData_status"
                              :key="key"
                            >
                              <v-card
                                :disabled="!isPermissionCanEdit"
                                :color="getStatusColor(value)"
                                @click="statusOnClick(value)"
                                :outlined="getStatusOutline(value)"
                                ><v-card-title class="pt-2 pl-2 pb-0">
                                  <v-icon dark>
                                    mdi-check-circle
                                  </v-icon> </v-card-title
                                ><v-card-text align="center">
                                  <v-icon :dark="vehicleStatus[value.key]">
                                    {{ statusIcon[value.key] }}
                                  </v-icon>
                                  <div
                                    v-if="getStatusOutline(value)"
                                    class="icon pt-2"
                                  >
                                    {{ value.value }}
                                  </div>
                                  <div v-else class="white--text icon pt-2">
                                    {{ value.value }}
                                  </div>
                                </v-card-text></v-card
                              ></v-col
                            >
                          </v-row>
                        </v-card></v-col
                      >
                    </v-row>
                    <v-row no-gutters>
                      <!-- <v-col cols="12" lg="4" sm="4">
                      <CustomAutocomplete
                        v-model="formData.contractNumber"
                        title="รหัสสัญญา"
                        :is-required="true"
                        :items="contractCompanyList"
                        menu-props="auto"
                        :disabled="!isPermissionCanEdit"
                        selectionTextIf="licenseNumber"
                        itemTextListTextIf="licenseNumber"
                        itemText="licenseNumber"
                        itemValue="key"
                        :returnObject="true"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุ รหัสสัญญา']"
                      >
                      </CustomAutocomplete>
                    </v-col> -->
                      <v-col cols="12" lg="4" sm="4">
                        <DynamicContractIdElement
                          v-model="formData.contractNumber"
                          :disabled="!isPermissionCanEdit"
                          placeholder="รหัสสัญญา"
                          title="รหัสสัญญา"
                          :is-required="true"
                          :previousId="prevContractNumber"
                          @readyToSubmit="readyToSubmit"
                        />
                      </v-col>
                      <!-- <v-col cols="12" lg="4" sm="4">
                      <CustomTextInputImprove
                        ref="contractNumber"
                        v-model="formData.contractNumber"
                        title="รหัสสัญญา"
                        placeholder="รหัสสัญญา"
                        :is-required="true"
                        :readonly="!isPermissionCanEdit"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุ รหัสสัญญา']"
                      ></CustomTextInputImprove>
                    </v-col> -->
                      <v-col cols="12" lg="4" sm="4">
                        <CustomTextInputImprove
                          ref="contractName"
                          v-model="formData.title"
                          placeholder="ชื่อสัญญา"
                          title="ชื่อสัญญา"
                          :is-required="true"
                          :readonly="!isPermissionCanEdit"
                          :rules="[(v) => !!v || 'จำเป็นต้องระบุชื่อสัญญา']"
                        ></CustomTextInputImprove>
                      </v-col>
                      <!-- <v-col cols="12" lg="4" sm="4">
                      <CustomAutocomplete
                        v-model="formData.contractCompany"
                        class="custom-placeholer-color"
                        title="บริษัทคู่สัญญา"
                        :is-required="true"
                        :items="contractCompanyList"
                        menu-props="auto"
                        :disabled="!isPermissionCanEdit"
                        selectionTextIf="nameTh"
                        itemTextListTextIf="nameTh"
                        itemText="nameTh"
                        itemValue="key"
                        :returnObject="true"
                        :rules="[(v) => !!v || 'จำเป็นต้องระบุบริษัทคู่สัญญา']"
                      >
                      </CustomAutocomplete>
                    </v-col> -->
                      <v-col cols="12" lg="4" sm="4">
                        <InputView
                          title="บริษัทคู่สัญญา"
                          :is-required="true"
                          class="px-3"
                        >
                          <DynamicCompanyElement
                            v-model="formData.contractCompany"
                            :disabled="!isPermissionCanEdit"
                            placeholder="พิมพ์ค้นหาบริษัทคู่สัญญา หรือเพิ่มบริษัทคู่สัญญาใหม่"
                            :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                          />
                        </InputView>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" lg="4" sm="4">
                        <CustomDateTypingPickerFormInput
                          placeholder="เลือก"
                          title="เปิดสัญญา วันที่ (วัน/เดือน/ปี)"
                          v-model="formData.startDate"
                          :disabled="!isPermissionCanEdit"
                          :is-required="true"
                          :rules="[(v) => !!v || 'จำเป็นต้องเลือกวันที่']"
                        />
                      </v-col>
                      <v-col cols="12" lg="4" sm="4">
                        <CustomDateTypingPickerFormInput
                          placeholder="เลือก"
                          title="สิ้นสุดสัญญา วันที่ (วัน/เดือน/ปี)"
                          v-model="formData.endDate"
                          :disabled="!isPermissionCanEdit"
                          :is-required="true"
                          :rules="[
                            (v) => !!v || 'จำเป็นต้องเลือกวันที่สิ้นสุด',
                          ]"
                        />
                      </v-col>
                      <v-col cols="12" lg="4" sm="4">
                        <!-- <CustomDateTypingPickerFormInput
                          placeholder="เลือก"
                          title="วันที่ตัดยอด (วัน/เดือน/ปี)"
                          v-model="formData.cutPaymentDate"
                          :disabled="!isPermissionCanEdit"
                          :is-required="true"
                          :rules="[(v) => !!v || 'จำเป็นต้องเลือกวันที่ตัดยอด']"
                        /> -->

                        <CustomSelectsArrOfObj
                          ref="cutPaymentDate"
                          v-model="formData.cutPaymentDate"
                          class="custom-placeholer-color"
                          :items="dayNumber"
                          :disabled="!isPermissionCanEdit"
                          title="วันที่ตัดยอด"
                          :isRequired="true"
                          placeholder="เลือก"
                          item-text="value"
                          item-value="key"
                          :returnObject="true"
                          :rules="[(v) => !!v || 'จำเป็นต้องเลือกวันที่ตัดยอด']"
                        >
                        </CustomSelectsArrOfObj>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col auto>
                        <CustomTextareaImprove
                          ref="contractDetail"
                          v-model="formData.detail"
                          title="ข้อมูลเพิ่มเติม"
                          :disabled="!isPermissionCanEdit"
                          :is-required="false"
                        />
                      </v-col>
                    </v-row>

                    <v-row no-gutters class="px-3">
                      <v-col cols="12">
                        <FormDataUploadInput
                          ref="vehicleCRUDUploadFile"
                          component-name="vehicleCRUDUploadFileForm"
                          title="ชุดเอกสาร (pdf)"
                          placeholder="เพิ่มเอกสารกดที่นี่"
                          :uploaded-data="formData.files"
                          :is-permission-can-edit="!isPermissionCanEdit"
                          uploadTag="VEHICLE_CONTRACT"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense class="px-3">
                      <v-col cols="12">
                        <FormDataUploadInput
                          ref="vehicleCRUDUploadImageFile"
                          component-name="vehicleCRUDUploadImageFileForm"
                          title="รูปภาพ (jpg, png)"
                          placeholder="เพิ่มรูปภาพที่นี่"
                          :isImageType="true"
                          :uploaded-data="formData.images"
                          :is-permission-can-edit="!isPermissionCanEdit"
                          uploadTag="VEHICLE_CONTRACT"
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="mt-3 mb-6"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <CustomAutocomplete
                          ref="zone"
                          v-model="formData.zone"
                          title="ที่ตั้งโรงงาน"
                          placeholder=""
                          :is-required="true"
                          :items="zoneList"
                          itemText="nameTh"
                          itemValue="key"
                          :returnObject="true"
                          :readonly="!isPermissionCanEdit"
                          dataInputName="zone"
                        ></CustomAutocomplete>
                      </v-col>
                      <v-col>
                        <CustomTextareaImprove
                          ref="contractDetail"
                          v-model="formData.zoneDetail"
                          title="ข้อมูลที่ตั้งเพิ่มเติม"
                          :disabled="!isPermissionCanEdit"
                          :is-required="false"
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="mt-3 mb-6"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" lg="4" sm="4">
                        <CustomTextInputCurrency
                          ref="contractIncome"
                          v-model="formData.income"
                          title="รายรับที่จะได้โดยประมาณ (บาท)"
                          placeholder=""
                          :is-required="true"
                          :disabled="!isPermissionCanEdit"
                          :rules="[
                            (v) =>
                              !!v || 'จำเป็นต้องระบุรายรับที่จะได้โดยประมาณ',
                          ]"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <AppSubmitFormButton
                :disabled="!isPermissionCanEdit"
                @on-click-save="saveChange()"
              />
            </CustomCardFormViewModern>
          </v-form>

          <AppDialogConfirm ref="alertSaving" />
          <AppDialogConfirm ref="dialogAlert" />
        </v-col>
        <v-col v-if="isEdit" cols="12" sm="12" md="3">
          <ContractPassagerQRCodeView
            :contractId="_id"
            :contract-number="formData.contractNumber"
          />
        </v-col>
      </v-row>

      <AppOverlay :isLoading="isLoading" />
      <AppPageNotFound :show="isPermissionCanView" />
    </v-container>
  </div>
</template>
<script>
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import AppOverlay from "@/components/AppOverlay.vue";
import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import CustomAutocomplete from "@/components/CustomAutocomplete.vue";
import CustomDateTypingPickerFormInput from "@/components/CustomDateTypingPickerFormInput.vue";
import { DialogType } from "@/services/dialog";
import CustomSelectsArrOfObj from "@/components/CustomSelectsArrOfObj.vue";
import { errorMessageMapper } from "@/services/error";
import InputView from "@/components/InputView.vue";
import AppBreadCrumbs from "@/components/AppBreadCrumbs.vue";
import FormDataUploadInput from "@/components/FormDataUploadInput.vue";
import ContractPassagerQRCodeView from "./components/ContractPassagerQRCodeView.vue";
import {
  getContractComlist,
  getContractInfoService,
  postContractService,
  putContractService,
} from "@/services/api/contract";
import AppPageNotFound from "@/components/AppPageNotFound.vue";
import { processPermission } from "@/services/permissions";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import { mapGetters } from "vuex";
import DynamicCompanyElement from "./components/DynamicCompanyElement.vue";
import DynamicContractIdElement from "./components/DynamicContractIdElement.vue";
import AppSubmitFormButton from "@/components/AppSubmitFormButton.vue";
import { getUserAppDataService } from "@/services/api/app";
import _ from "lodash";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import {
  URL_CONTRACT_LIST,
  URL_EMPLOYEE_PENALTY,
} from "@/services/routerQueryPath";
import { appEncodeSingleURIComponentHelper } from "@/services/appStringHelpper";

export default {
  name: "contractManageView",
  computed: {
    ...mapGetters({
      vehicleContractStatusColor: "vehicle/vehicleContractStatusColor",
      vehicleContractStatusText: "vehicle/vehicleContractStatusText",
    }),
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "รายการสัญญาเดินรถ",
          to: `/caj${URL_CONTRACT_LIST}`,
          disabled: false,
          exact: true,
        },
        {
          text: "ข้อมูลสัญญาเดินรถ",
          disabled: true,
          to: "",
        },
      ],
      title: "เพิ่มข้อมูลสัญญาเดินรถ",
      statusIcon: {
        PROCESS: "mdi-car-connected",
        SUSPEND: "mdi-car-clock",
        EXPIRED: "mdi-alpha-x-circle",
      },
      vehicleStatus: {
        PROCESS: null,
        SUSPEND: null,
        EXPIRED: null,
      },
      fundData_status: [
        { key: "PROCESS", value: "ดำเนินการ" },
        { key: "SUSPEND", value: "ระงับ" },
        { key: "EXPIRED", value: "หมดอายุ" },
      ],
      valid: false,
      isPermissionCanEdit: false,
      isPermissionCanView: false,
      isPermissionCanViewInfo: false,
      isLoading: false,
      changeViewLoading: true,
      vehicleList: null,
      docType: "contract",
      companyType: "com_contract",
      contractCompanyList: [],
      appCompanyData: null,
      zoneList: [],
      isFail: false,
      _id: null,
      input: 1,
      checkDuplicated: true,
      isEdit: false,
      dayNumber: [],

      upFiles: [], // wait from appFile component
      upImages: [], // wait from appFile component
      localUpFile: [],
      localUpImages: [],
      prevContractNumber: null,
      isContractNumberReady: false,

      formData: {
        status: null,
        contractNumber: null,
        title: null,
        contractCompany: null,
        detail: null,
        // contractCompany: {
        //   _id: null,
        //   nameTh: null,
        //   nameEn: null,
        //   key: null,
        // },
        zone: null,
        zoneDetail: null,

        startDate: null,
        endDate: null,
        cutPaymentDate: null,

        income: null,

        files: [],
        images: [],
      },
      alertOptions: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
      permList: {
        admin_all: false,
        vehicle_list: false,
        vehicle_info: false,
        vehicle_info_crud: false,
      },
      penalityLink: "",
    };
  },
  watch: {
    upFiles(val) {
      // console.log(val);
    },
    upImages(val) {
      // console.log(val);
    },
    "$route.params.id"(newId, oldId) {
      if (newId !== undefined) {
        this.startPage();
      }
    },
  },
  methods: {
    startPage() {
      if (this.$route.params.id !== undefined) {
        if (this.isPermissionCanViewInfo) {
          this.title = "ดูข้อมูลสัญญารถ";
        } else {
          this.title = "แก้ไขข้อมูลสัญญารถ";
        }

        this._id = this.$route.params.id;
        this.isEdit = true;
      } else {
        this._id = null;
        this.title = "เพิ่มข้อมูลสัญญารถ";
        let initStatus = { key: "PROCESS", value: "ดำเนินการ" };
        this.statusOnClick(initStatus);
      }
      this.getData();
    },
    readyToSubmit(isReady) {
      this.isContractNumberReady = isReady;
    },
    async getData() {
      this.isLoading = true;
      // get vehicle list
      if (this.isPermissionCanView) {
        let resp = await getUserAppDataService(this.$store);
        if (resp["code"] === 200) {
          this.appCompanyData = {
            ...resp.data,
          };
          if (!_.isNil(this.appCompanyData["zone"])) {
            this.zoneList = this.appCompanyData["zone"];
          }
        }

        // edit
        if (this._id !== null) {
          let params = new URLSearchParams({
            id: this._id,
          }).toString();
          let respData = await getContractInfoService(this.$store, params);
          //
          if (respData["code"] === 200) {
            this.formData = _.clone(respData["data"]);
            this.prevContractNumber = this.formData["contractNumber"];
            // reset
            this.upImages = [];
            this.upFiles = [];
            this.localUpFile = [];
            this.localUpImages = [];
            this.localUpFile = _.clone(this.formData["files"]);
            this.localUpImages = _.clone(this.formData["images"]);
            //
            const encodeTitle = appEncodeSingleURIComponentHelper(
              `${this.formData.title} ${this.formData.contractNumber}`
            );

            this.penalityLink = `/employee${URL_EMPLOYEE_PENALTY}&contract=${this.formData._id}&text=${encodeTitle}`;
          } else {
            this.isFail = true;
          }
        }
      }

      this.isLoading = false;
    },
    async saveChange() {
      if (this.$refs.form.validate() === true) {
        this.isLoading = true;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        let _toUpdateByAPI = false;
        let resUploadFile = await this.$refs["vehicleCRUDUploadFile"].upload();
        let respUploadImage = await this.$refs[
          "vehicleCRUDUploadImageFile"
        ].upload();

        if (!resUploadFile.status || !respUploadImage.status) {
          // is upload has error response restore all file
          this.callUploadFileErrorDialog();
          this.$refs["vehicleCRUDUploadFile"].restoreFile();
          this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
          // this.disableLoading();
        } else {
          const sendingFormData = _.cloneDeep(this.formData);
          sendingFormData.files = resUploadFile.result;
          sendingFormData.images = respUploadImage.result;

          if (this._id !== null) {
            let responseData = await putContractService(
              this.$store,
              this._id,
              sendingFormData
            );
            if (responseData["code"] === 200) {
              this.isLoading = false;
              this.alertSaveChange(this._id);
              _toUpdateByAPI = true;
              this.getData();
            } else {
              _toUpdateByAPI = false;
            }
          } else {
            let responseData = await postContractService(
              this.$store,
              sendingFormData
            );
            this.isLoading = false;
            if (responseData["code"] === 200) {
              _toUpdateByAPI = true;
              this.alertSaveChange(this._id);
              this.resetFormData();
              this.$router.push(
                `/caj/contract/edit/${responseData["data"]["contractId"]}`
              );
            } else {
              _toUpdateByAPI = false;
            }
          }
        }

        if (_toUpdateByAPI === false) {
          // alert fail to update data
          this.$refs["vehicleCRUDUploadFile"].restoreFile();
          this.$refs["vehicleCRUDUploadImageFile"].restoreFile();
        }

        this.isLoading = false;
      }
    },

    statusOnClick(selectedStatus) {
      this.vehicleStatus["PROCESS"] = null;
      this.vehicleStatus["SUSPEND"] = null;
      this.vehicleStatus["EXPIRED"] = null;

      this.vehicleStatus[selectedStatus.key] = true;
      this.formData["status"] = selectedStatus["key"];
    },
    getStatusColor(status) {
      // if (this.vehicleStatus[status.key]) {
      //   return this.vehicleContractStatusColor(status.key);
      // }
      // return "";
      if (this.formData["status"] !== null) {
        let prevStatus = this.formData["status"];
        this.vehicleStatus[prevStatus] = true;
      }
      if (this.vehicleStatus[status.key]) {
        return this.vehicleContractStatusColor(status.key);
      }
      return "";
    },
    getStatusOutline(status) {
      // if (this.vehicleStatus[status.key]) {
      //   return false;
      // }
      // return true;
      if (this.formData["status"] !== null) {
        let prevStatus = this.formData["status"];
        this.vehicleStatus[prevStatus] = true;
      }
      if (this.vehicleStatus[status.key]) {
        return false;
      }
      return true;
    },
    getDayNumber() {
      let i = 1;
      while (i <= 31) {
        this.dayNumber.push(i);
        i++;
      }
    },
    // ==== for app upload ====
    remove_upfile(index) {
      this.$refs.mulFilesUpload.removeInput(index);
    },
    remove_imagefile(index) {
      this.$refs.mulImagesUpload.removeInput(index);
    },
    // ====
    remove_localImagefile(atLocation) {
      this.localUpImages.splice(atLocation, 1);
    },
    remove_localUpfile(atLocation) {
      this.localUpFile.splice(atLocation, 1);
    },

    async callUploadFileErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดไฟล์ไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    async callUploadImageErrorDialog(respMessage) {
      let custom_error_text = "การอัพโหลดรูปไม่สำเร็จ ";
      for (let i = 0; i < respMessage.length; i++) {
        custom_error_text =
          custom_error_text + errorMessageMapper[respMessage[i]];
      }
      await this.$refs.dialogAlert.open(
        custom_error_text,
        null,
        DialogType.ERROR,
        {
          noconfirm: false,
        }
      );
      // clear data
    },

    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "CONTRACT");

        if (this.permList.contract_info === true) {
          this.isPermissionCanView = true;
          this.isPermissionCanViewInfo = true;
        }

        if (
          this.permList.admin_all === true ||
          this.permList.contract_info_crud === true
        ) {
          this.isPermissionCanViewInfo = true;
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
        }
      }
    },

    async alertSaveChange(_id) {
      if (_id !== null) {
        await this.$refs.alertSaving.open(
          "แก้ไขข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      } else {
        await this.$refs.alertSaving.open(
          "เพิ่มข้อมูลสำเร็จ!",
          null,
          DialogType.POSITIVE,
          this.alertOptions
        );
      }
    },

    resetFormData() {
      this.changeViewLoading = false;
      this.$refs["form"].reset();
      this.formData = {
        status: null,
        contractNumber: null,
        title: null,
        contractCompany: null,
        zone: null,
        zoneDetail: null,
        detail: null,
        startDate: null,
        endDate: null,
        cutPaymentDate: null,
        income: null,

        files: [],
        images: [],
      };
      this.clearInput();
      this.changeViewLoading = true;
    },
    clearInput() {
      this.vehicleList = null;
      this.appCompanyData = null;
      this.zoneList = [];
      this.upFiles = [];
      this.upImages = [];
      this.localUpFile = [];
      this.localUpImages = [];
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.getDayNumber();
    this.startPage();
  },
  components: {
    CustomTextInput,
    CustomCardFormViewModern,
    AppOverlay,
    CustomAutocomplete,
    AppDialogConfirm,
    CustomDateTypingPickerFormInput,
    InputView,
    AppBreadCrumbs,
    AppPageNotFound,
    CustomTextInputImprove,
    DynamicCompanyElement,
    DynamicContractIdElement,
    AppSubmitFormButton,
    FormDataUploadInput,
    CustomTextareaImprove,
    CustomTextInputCurrency,
    CustomSelectsArrOfObj,
    ContractPassagerQRCodeView,
  },
};
</script>
<style scoped>
.container {
  max-width: 1500px;
}
.image_list_float_left {
  float: right;
}
.title-input {
  font-size: 14px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
.required-color {
  color: var(--v-error);
}
.icon {
  font-size: 20px;
}
</style>
