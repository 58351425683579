import {
  authHttpClientGet,
  authHttpClientPut,
  authHttpClientPost,
  authHttpClientDelete,
} from "@/services/api/my_axios";

import axios from "axios";

const deepBoxApi =
  "https://asia-southeast1-deepboxmanagement.cloudfunctions.net/devapi/box";

export async function getJobListService(store, queryString) {
  let uri = "/job";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function deleteJobService(store, id) {
  let uri = `/job?id=${id}`;
  return await authHttpClientDelete(store, uri);
}

export async function getJobBySeachingService(store, text) {
  let uri = `/job/search?text=${text}`;
  return await authHttpClientGet(store, uri);
}

export async function getJobInfo(store, queryString) {
  let uri = "/job/info";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
// Duplication job
export async function getJobInfoForDuplicateService(store, queryString) {
  let uri = "/job/job-duplicate";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobInfoForDuplicateCheckTimeService(
  store,
  queryString
) {
  let uri = "/job/job-duplicate-checktime";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function postJobInfoForDuplicateService(store, queryString, data) {
  let uri = "/job/job-duplicate";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }
  return await authHttpClientPost(store, uri, data);
}

export async function getAllDriverService(store) {
  let uri = "/job/all-driver";
  return await authHttpClientGet(store, uri);
}

export async function getAllVehicleService(store) {
  let uri = "/job/all-vehicle";
  return await authHttpClientGet(store, uri);
}
// End Duplication job

// Set Status
export async function getJobInfoForStatusService(store, queryString) {
  let uri = "/job/job-status-list";
  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// Marker Service
export async function getMarkerService(store, queryString) {
  let uri = "/job/route";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putMarkerService(store, queryString, data) {
  let uri = "/job/route";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// Job
export async function getGeneratedRouteListService(store, queryString) {
  let uri = "/job/job-route-template-import-contract";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getGeneratedJobNameService(store, queryString) {
  let uri = "/job/job-name";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// contract route
export async function getContractRouteTitleListService(store) {
  let uri = "/job/contract-template-route-list";
  return await authHttpClientGet(store, uri);
}

// Create new job

export async function postJobsService(store, queryString, data) {
  let uri = "/job/";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data);
}

// Edit job

export async function putJobsService(store, queryString, data) {
  let uri = "/job/";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

//
export async function getJobStatusService(store, queryString) {
  let uri = "/job/job-status";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

// update job status
export async function putJobStatusService(store, queryString, data) {
  let uri = "/job/job-status";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// for driver dialog
export async function getAvailableDriverService(store, queryString) {
  let uri = "/job/driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobCalendarOfDriverService(store, queryString) {
  let uri = "/job/driver-calendar";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putDriverToJobService(store, queryString, data) {
  let uri = "/job/driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteDriverFromJobService(store, queryString) {
  let uri = "/job/driver";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

//

export async function getAvailableVehicleService(store, queryString) {
  let uri = "/job/vehicle";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobCalendarOfVehicleService(store, queryString) {
  let uri = "/job/vehicle-calendar";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putVehicleToJobService(store, queryString, data) {
  let uri = "/job/vehicle";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteVehicleFromJobService(store, queryString) {
  let uri = "/job/vehicle";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

// for mobile test update marker
export async function putJobDriverSubmit(store, queryString, data) {
  let uri = "/job/driver-submit";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// checkpoint
export async function putJobDriverCheckpoint(store, queryString, data) {
  let uri = "/job/driver-checkpoint";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function putJobDriverAllCheckpoint(store, queryString, data) {
  let uri = "/job/driver-checkpoints";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// BOX
export async function postRegisterBoxService(store, queryString, data) {
  let uri = "/deepbox/virtual/phone";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data);
}

export async function putRegisterBoxService(store, queryString, data) {
  let uri = "/deepbox/virtual/phone";

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function postSendCoordinatesBoxService(
  store,
  data,
  queryString,
  customHeader
) {
  let uri = `${deepBoxApi}/position`;
  let _customHeader = { headers: customHeader };
  try {
    const response = await axios.post(uri, data, _customHeader);
    return { ...response.data };
  } catch (error) {
    return error;
  }
}

// job recompense other input and expenses
export async function getRecompenseIncomeAndExpensesListService(
  store,
  queryString
) {
  let uri = `/job/recompense`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri, data);
}

export async function putRecompenseIncomeAndExpensesToJobService(
  store,
  queryString,
  data
) {
  let uri = `/job/recompense`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function deleteRecompenseIncomeAndExpensesToJobService(
  store,
  queryString
) {
  let uri = `/job/recompense`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientDelete(store, uri);
}

export async function getJobMarkerTemplateImportService(store, queryString) {
  let uri = `/job/job-route-template-import`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getJobMarkerDetailTemplateImportService(
  store,
  queryString
) {
  let uri = `/job/job-route-template-import-detail`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putJobMarkerTemplateService(store, queryString, data) {
  let uri = `/job/job-route-template`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

export async function postJobMarkerTemplateService(store, queryString, data) {
  let uri = `/job/job-route-template`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPost(store, uri, data, false);
}

// face check
export async function getJobCheckFaceService(store, queryString) {
  let uri = `/job/job-checkface`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function putJobCheckFaceService(store, queryString, data) {
  let uri = `/job/job-checkface`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// verify vehicle
export async function getJobVerifyVehicle(store, queryString) {
  let uri = `/job/job-verify-vehicle`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}

export async function getPassengerQRCode(store, jobId) {
  let uri = "/passenger/qrcode?id=" + jobId;

  return await authHttpClientGet(store, uri);
}

export async function putJobVerifyVehicle(store, queryString, data) {
  let uri = `/job/job-verify-vehicle`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientPut(store, uri, data);
}

// === job evidence ===
export async function getJobsEvidenceService(store, queryString) {
  let uri = `/job/job-evidence`;

  if (queryString !== null) {
    uri = `${uri}?${queryString}`;
  }

  return await authHttpClientGet(store, uri);
}
