<template>
	<v-container fluid>
		<div v-if="isPermissionCanView">
			<v-row class="mb-1">
				<v-col cols="auto">
					<div class="text-h5 mt-1">รายการพนักงาน</div>
				</v-col>

				<v-spacer></v-spacer>

				<v-col cols="12" sm="4">
					<AppAutoCompleteUserSearching
						:key="componentRefreshCounter"
						:tableLoading="tableLoading"
						ref="AppAutoCompleteUserSearchingRef"
						@searchItemChange="getSearchItem"
					/>
				</v-col>
				<v-col cols="auto">
					<v-menu
						v-model="isShowMenu"
						:close-on-content-click="false"
						:nudge-width="200"
						:nudge-bottom="10"
						:nudge-left="100"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="#D5DBDB"
								light
								large
								v-bind="attrs"
								v-on="on"
								elevation="0"
								outlined
								:style="{
									backgroundColor: 'white !important',
								}"
							>
								<v-icon left color="#4D5656"> mdi-filter </v-icon>
								<div class="normal-button-text">{{ "ตัวกรอง" }}</div>
							</v-btn>
						</template>

						<EmployeeFilterGroup
							:key="componentRefreshCounter"
							@close-menu="isShowMenu = false"
							@returnFiltedData="getfilterItem"
						/>
					</v-menu>
				</v-col>

				<v-col cols="auto" v-if="permList.user_info_crud || permList.admin_all">
					<PrimaryButton
						:large="true"
						icon="mdi-plus"
						@on-click="handleAddClick()"
					>
						เพิ่มข้อมูล
					</PrimaryButton>
				</v-col>
			</v-row>

			<v-data-table
				:headers="tableHeaders"
				:items="employeeDataList"
				:items-per-page="queryOptionsTable.limit"
				:page="queryOptionsTable.page"
				:loading="tableLoading"
				:server-items-length="serverItemsLength"
				:footer-props="dataTableFooterProps"
				:no-data-text="dataTableNoDataText"
				:loading-text="dataTableLoadingText"
				@update:page="tablePageHandler"
				@update:items-per-page="tableItemPerPageHandler"
				class="app-datatable"
			>
				<template v-slot:top>
					<div
						class="app-table-list-identity d-flex justify-space-between flex-wrap pt-4 px-4"
					>
						<v-card class="d-flex flex-row" flat tile width="320">
							<v-select
								class="app-custom-input"
								:items="sortList"
								label="เรียงลำดับโดย"
								dense
								outlined
								v-model="sortVal"
								@change="tableSortHandler"
							></v-select>
						</v-card>
						<v-divider class="mx-3 mb-6" vertical></v-divider>

						<v-checkbox
							dense
							class="pl-2 pt-1"
							label="ร้องขอเปลี่ยนรถประจำตัว"
							v-model="checkboxData.requestownedvehicle"
							@change="changeValueOfCheckboxStatus()"
						></v-checkbox>
						<v-spacer></v-spacer>
						<v-btn
							@click="handleSchedule()"
							class="mx-3"
							color="primary"
							elevation="0"
							light
							large
							outlined
							><v-icon>mdi-bird</v-icon></v-btn
						>
						<NormalButton icon="mdi-refresh" @on-click="reset()">
							รีเซ็ต
						</NormalButton>
					</div>
				</template>

				<template v-slot:[`item.name`]="{ item }">
					<v-list-item two-line class="px-0">
						<v-list-item-avatar>
							<v-img
								:src="
									item.profileImage === null
										? defaultPFImage
										: item.profileImage
								"
							>
							</v-img>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-app-title font-weight-bold">
								<router-link
									v-if="
										permList.user_info_crud ||
										permList.admin_all ||
										permList.user_info
									"
									class="text-decoration-none black--text"
									:to="{ name: 'employeeEdit', params: { id: item._id } }"
								>
									{{ item.dpfullName }}
								</router-link>
								<span v-else> {{ item.dpfullName }}</span>
							</v-list-item-title>
							<span class="text-app-normal">{{
								item.email === null ? "-" : `${item.email}`
							}}</span>
						</v-list-item-content>
					</v-list-item>
				</template>

				<template v-slot:[`item.phoneNumber`]="{ item }">
					<div v-if="item.phoneNumber.length !== 0">
						<div
							class="d-block"
							v-for="(tel, idx) in item.phoneNumber"
							:key="'phone_row' + idx"
						>
							<span class="text-app-title"> {{ tel }} </span>
						</div>
					</div>
					<div v-else>-</div>
				</template>

				<template v-slot:[`item.empId`]="{ item }">
					<span class="text-app-title">
						{{ item.empId ? item.empId : "-" }}
					</span>
				</template>

				<template v-slot:[`item.company`]="{ item }">
					<span class="text-app-title">
						{{ item.company.position }}
					</span>
				</template>

				<template v-slot:[`item.empStatus`]="{ item }">
					<div
						v-if="item.empStatus"
						:class="
							item.empStatus == `ACTIVE`
								? `${empStatusTextColor(item.empStatus)} text--darken-2`
								: `${empStatusTextColor(item.empStatus)}`
						"
						dark
					>
						<span class="text-app-title font-weight-bold">
							{{ empStatusText(item.empStatus) }}
						</span>
					</div>
					<div v-else>-</div>
				</template>

				<template v-slot:[`item.age`]="{ item }">
					<span class="text-app-title">
						{{ item.age === 0 || item.age === null ? "-" : item.age }}
					</span>
				</template>

				<template v-slot:[`item.appVersion`]="{ item }">
					<div v-if="item.appVersion">
						<div
							v-for="(version, osIdx) in item.appVersion"
							:key="`${item._id}-${osIdx}`"
							class="text-app-title mb-0"
						>
							<!-- text-left -->
							<p class="mb-0 font-weight-bold">{{ osIdx }}</p>
							<p class="mb-1">{{ version }}</p>
						</div>
					</div>
					<div v-else>-</div>
				</template>

				<template v-slot:[`item.vehicleStatus`]="{ item }">
					<!-- <v-chip label :color="vehicleStatusColor(item.vehicleStatus)" dark small> -->
					<!-- {{ vehicleStatusText(item.vehicleStatus) }} -->
					<!-- </v-chip> -->

					<!-- {{ vehicleStatusText(item.vehicleStatus) }} -->
					<v-list-item
						two-line
						class="px-0"
						v-if="item.ownedVehicle || item.requestOwnedVehicle"
					>
						<!-- <v-list-item-content>
              <v-list-item-title
                class="text-app-title font-weight-bold"
                v-if="item.type !== null"
              >
                {{ vehicleTypeText(item.type) }}
                </v-list-item-title
              >
              <span
                class="mx-0 px-0 text-app-normal"
                v-if="(item.seat !== null) & (item.seat !== undefined)"
              >
                <v-icon>{{ vehicleTypeIcon(item.type) }}</v-icon>
                {{ item.seat }} ที่นั่ง </span
              ><span v-else>-</span>
            </v-list-item-content> -->
						<v-list-item-content
							v-if="item.ownedVehicle"
							:class="
								item.requestOwnedVehicle !== null ? 'text-right' : 'text-center'
							"
						>
							<v-list-item-title class="text-app-title font-weight-bold">
								<a class="black--text" @click="handleApprovingRequest(item)">
									{{ item.ownedVehicle.licenseNumber }}
								</a>
							</v-list-item-title>
							<span class="mx-0 px-0 text-app-normal">
								<a class="normal-text" @click="handleApprovingRequest(item)">
									<span v-if="item.ownedVehicle.type !== null"
										>{{ vehicleTypeText(item.ownedVehicle.type) }}<br />
										<v-icon>{{
											vehicleTypeIcon(item.ownedVehicle.type)
										}}</v-icon></span
									>
									{{ item.ownedVehicle.seat }} ที่นั่ง
								</a>
							</span>
							<!-- <div
                text-xs-center
                align-center
                v-if="item.empStatus == '1'"
              >
                <v-btn
                  icon
                  class="ml-6"
                  color="primary"
                  dark
                  absolute
                  x-small
                  fab
                >
                  <v-icon>mdi-chevron-left-circle-outline</v-icon>
                </v-btn>
              </div> -->
						</v-list-item-content>
						<v-list-item-content class="text-center" v-else
							><span>-</span></v-list-item-content
						>
						<!-- <v-divider
              vertical
              class="mx-5 my-3"
              v-if="item.requestownedvehicle == '1'"
            /> -->

						<v-btn
							v-if="item.requestOwnedVehicle"
							icon
							color="primary"
							dark
							x-small
							fab
							@click="handleApprovingRequest(item)"
						>
							<v-icon>mdi-chevron-left-circle-outline</v-icon>
						</v-btn>

						<v-list-item-content
							class="text-left"
							v-if="item.requestOwnedVehicle"
						>
							<v-list-item-title
								class="text-app-title font-weight-bold primary--text"
							>
								<a @click="handleApprovingRequest(item)">
									{{ item.requestOwnedVehicle.licenseNumber }}
								</a>
							</v-list-item-title>
							<span class="mx-0 px-0 text-app-normal primary--text">
								<a @click="handleApprovingRequest(item)">
									{{ vehicleTypeText(item.requestOwnedVehicle.type) }}<br />
									<v-icon color="primary">{{
										vehicleTypeIcon(item.requestOwnedVehicle.type)
									}}</v-icon>
									{{ item.requestOwnedVehicle.seat }} ที่นั่ง
								</a>
							</span>
						</v-list-item-content>
					</v-list-item>
					<span v-else>-</span>
				</template>

				<template v-slot:[`item.actions`]="{ item }">
					<v-menu bottom left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon>mdi-dots-horizontal</v-icon>
							</v-btn>
						</template>

						<v-list>
							<v-list-item>
								<v-list-item-icon>
									<v-icon> mdi-map-marker</v-icon>
								</v-list-item-icon>
								<v-list-item-title class="text-app-title">
									ที่อยู่ปัจจุบัน</v-list-item-title
								>
							</v-list-item>

							<v-list-item
								v-if="permList.user_report || permList.admin_all"
								@click="openEmployeeDashboard(item, item._id)"
							>
								<v-list-item-icon>
									<v-icon> mdi-file-chart-outline</v-icon>
								</v-list-item-icon>
								<v-list-item-title class="text-app-title">
									รายงานส่วนบุคคล
								</v-list-item-title>
							</v-list-item>

							<v-list-item
								@click="openPenalityTab(item)"
								v-if="permList.user_penality || permList.admin_all"
							>
								<v-list-item-icon>
									<v-icon> mdi-account-details </v-icon>
								</v-list-item-icon>
								<v-list-item-title class="text-app-title">
									พฤติกรรม</v-list-item-title
								>
							</v-list-item>

							<v-list-item
								@click="handleDeleteClick(item)"
								v-if="permList.user_info_crud || permList.admin_all"
							>
								<v-list-item-icon>
									<v-icon> mdi-delete</v-icon>
								</v-list-item-icon>
								<v-list-item-title class="text-app-title"
									>ลบข้อมูล</v-list-item-title
								>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
			</v-data-table>
		</div>

		<AppDialogConfirm ref="confirmDelete" />
		<AppPageNotFound :show="isPermissionCanView" />
		<EmployeeOwnedVehicleDialog ref="employeeOwnedVehicleDialog" />
		<EmployeeJobDialog ref="employeeJobDialogRef" />
	</v-container>
</template>

<script>
	import PrimaryButton from "@/components/PrimaryButton.vue";
	import NormalButton from "@/components/NormalButton.vue";
	import SortButton from "@/components/SortButton.vue";
	import EmployeeFilterGroup from "@/views/employee/components/EmployeeFilterGroup.vue";
	import EmployeeOwnedVehicleDialog from "./components/EmployeeOwnedVehicleDialog.vue";

	import {
		getEmployeeListService,
		deleteEmployeeService,
	} from "@/services/api/employee";
	import AppAutoCompleteUserSearching from "@/components/AppAutoCompleteUserSearching.vue";
	import { mapGetters } from "vuex";
	import defaultImage from "@/assets/user.png";
	import { processPermission } from "@/services/permissions";
	import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
	import { DialogType } from "@/services/dialog";
	import {
		dataTableFooterProps,
		dataTableNoDataText,
		dataTableLoadingText,
	} from "@/services/AppConig";
	import AppPageNotFound from "@/components/AppPageNotFound.vue";
	import {
		appDecodeSingleURIComponentHelper,
		appEncodeSingleURIComponentHelper,
		appEncodeURIComponentHelper,
	} from "@/services/appStringHelpper";
	import { URL_EMPLOYEE_PENALTY } from "@/services/routerQueryPath";

	import EmployeeJobDialog from "@/components/EmployeeJobDialog.vue";

	// import { getPassengerQRCode } from "@/services/api/jobs";

	export default {
		name: "EmployeeListView",
		components: {
			AppDialogConfirm,
			PrimaryButton,
			NormalButton,
			SortButton,
			EmployeeFilterGroup,
			AppAutoCompleteUserSearching,
			AppPageNotFound,
			EmployeeJobDialog,
			EmployeeOwnedVehicleDialog,
		},
		data: () => ({
			componentRefreshCounter: 0,
			dataTableFooterProps,
			dataTableNoDataText,
			dataTableLoadingText,
			checkboxData: {
				requestOwnedVehicle: 0,
			},
			sortList: [
				{
					text: "วันที่เพิ่มข้อมูล เก่าสุด-ล่าสุด",
					value: "createddate-asc",
				},
				{
					text: "วันที่เพิ่มข้อมูล ล่าสุด-เก่าสุด",
					value: "createddate-desc",
				},
				{
					text: "ชื่อ-นามสกุล เรียงจาก A-Z",
					value: "name-asc",
				},
				{
					text: "ชื่อ-นามสกุล เรียงจาก Z-A",
					value: "name-desc",
				},
				{
					text: "รหัสพนักงาน เรียงจาก A-Z",
					value: "empid-asc",
				},
				{
					text: "รหัสพนักงาน เรียงจาก Z-A",
					value: "empid-desc",
				},
				{
					text: "วันเริ่มทำงาน เรียงจากเข้าใหม่",
					value: "joindate-asc",
				},
				{
					text: "วันเริ่มทำงาน เรียงจากเข้าก่อน",
					value: "joindate-desc",
				},
				{
					text: "อายุ เรียงจากน้อยไปมาก",
					value: "age-asc",
				},
				{
					text: "อายุ เรียงจากมากไปน้อย",
					value: "age-desc",
				},
			],
			defaultPFImage: defaultImage,
			tableLoading: false,

			serverItemsLength: 0,
			employeeDataList: [],
			// requestVehicleResp: null,

			queryFilterList: {},
			queryStringSearch: "", // combin filter and search text
			querySearchAndFilterList: {}, // combine filter and search
			queryOptionsTable: {
				limit: 20, // itemsPerPage = limit
				by: "createddate", // key
				order: "desc", // asc or desc
				page: 1, // current page
			},
			sortVal: null,
			isShowMenu: false,
			//
			autoCompleteLoading: false,
			autoCompleteList: [],
			searchEmployeeList: null,
			searchCountTimer: undefined,
			//
			permList: {
				admin_all: false,
				user_list: false,
				user_info: false,
				user_info_fin: false,
				user_info_crud: false,
				user_report: false,
			},
			editTextBtn: "แก้ไขข้อมูล",
			isPermissionCanEdit: false,
			isPermissionCanView: false,
			//
		}),

		mounted() {
			this.checkUserPermissions();
			this.processRouterQuery(this.$route.query);
		},

		methods: {
			processRouterQuery(_queryRoute) {
				if (Object.keys(_queryRoute).length > 0) {
					Object.keys(_queryRoute).forEach((key) => {
						if (!_.isNil(_queryRoute[key])) {
							switch (key) {
								case "text":
									this.querySearchAndFilterList[key] =
										appDecodeSingleURIComponentHelper(_queryRoute[key]);
									break;
								case "limit":
								case "page":
									try {
										this.queryOptionsTable[key] = parseInt(_queryRoute[key]);
									} catch (error) {
										this.queryOptionsTable[key] = this.queryOptionsTable[key];
									}
									break;
								case "by":
								case "order":
									this.queryOptionsTable[key] = _queryRoute[key];
									break;
								case "requestownedvehicle":
									// this.checkboxData[key] = _queryRoute[key] === "ACTIVE" ? 1 : 0;
									if (this.checkboxData[key] === 1) {
										this.querySearchAndFilterList[key] = 1;
									}
									break;
								default:
									// filter and search
									this.querySearchAndFilterList[key] = _queryRoute[key];
									break;
							}
						}
					});

					this.querySearchAndFilterList = {
						...this.queryOptionsTable,
						...this.querySearchAndFilterList,
					};
				} else {
					// set default parameter
					this.querySearchAndFilterList = _.clone(this.queryOptionsTable);
				}
				//
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				this.getData();
			},
			setRouterQuery(queryObj) {
				const _queryObj = appEncodeURIComponentHelper(queryObj);

				this.$router
					.replace({
						path: this.$router.path,
						query: _queryObj,
					})
					.catch(() => {});
			},
			openLocationMap(position) {
				// console.log("Open Map = " + position);
				window.open(
					"https://www.google.com/maps/search/?api=1&query=" + position,
					"_blank"
				);
			},

			// === Start action button of table
			handleAddClick() {
				this.$router.push("/employee/create");
			},

			async handleSchedule() {
				if (!_.isNil(this.employeeDataList)) {
					let initedEmpList = [];
					this.employeeDataList.forEach((employee) => {
						let fullName = `${
							employee["nickName"] ? employee["nickName"]["th"] : ""
						} ${employee["firstName"] ? employee["firstName"]["th"] : ""} ${
							employee["lastName"] ? employee["lastName"]["th"] : ""
						} ${employee["empId"] ? "(" + employee["empId"] + ")" : ""}`;

						if (!initedEmpList.some((e) => e.key === employee["_id"])) {
							initedEmpList.push({
								key: employee["_id"],
								value: fullName,
							});
						}
					});

					let empJob = null;
					if (initedEmpList.length > 0) {
						empJob = await this.$refs.employeeJobDialogRef.open(initedEmpList);
					} else {
						empJob = await this.$refs.employeeJobDialogRef.open();
					}
				}
			},

			async handleApprovingRequest(item) {
				let requestData = _.cloneDeep(item);
				requestData["dpData"] = {
					dpNameTh: item["name"],
					dpPhoneNumeber: item["phoneNumber"][0],
				};
				if (!_.isNil(item["profileImage"])) {
					requestData["profileImage"] = {
						src: item["profileImage"],
					};
				}

				let toMainUpdate = await this.$refs.employeeOwnedVehicleDialog.open(
					item._id,
					requestData
				);

				// console.log("requestData", requestData);
				this.getData();
				// get new data form parent
			},

			handleEditClick(value) {
				this.$router.push("/employee/edit/" + value._id);
			},

			async handleDeleteClick(value) {
				if (
					await this.$refs.confirmDelete.open(
						"ต้องการลบข้อมูลนี้ ?",
						null,
						DialogType.ACTION,
						{
							noconfirm: true,
						}
					)
				) {
					await deleteEmployeeService(this.$store, value._id);
					this.getData();
				}
			},
			// === end action button of table
			tableUpdateHandler(type, value) {
				switch (type) {
					case "sort":
						let _fieldSortBy = value.split("-");
						this.queryOptionsTable["by"] = [_fieldSortBy[0]];
						this.queryOptionsTable["order"] = [_fieldSortBy[1]];
						break;
					default:
						this.queryOptionsTable[type] = value;
						break;
				}

				this.querySearchAndFilterList = {
					...this.querySearchAndFilterList,
					...this.queryOptionsTable,
				};

				this.setRouterQuery(this.querySearchAndFilterList);
				this.getData();
			},
			tablePageHandler(value) {
				this.tableUpdateHandler("page", value);
			},
			tableItemPerPageHandler(value) {
				this.tableUpdateHandler("limit", value);
			},
			tableSortHandler(value) {
				this.tableUpdateHandler("sort", value);
			},

			//
			// search method scope
			//
			processSearchingParams(_queryParameter) {
				// combine query search
				this.querySearchAndFilterList = {
					...this.queryOptionsTable, // default table
					...this.querySearchAndFilterList, // old query data
					..._queryParameter,
				};
				this.setRouterQuery(this.querySearchAndFilterList);
			},
			getSearchItem(_searchingParameter) {
				//
				// callback from searching component when enter
				//
				this.queryStringSearchList = {};
				delete this.querySearchAndFilterList["text"];
				delete this.querySearchAndFilterList["empid"];
				let _queryParameter = {};
				if (!_.isNil(_searchingParameter)) {
					if (!_.isNil(_searchingParameter["key"])) {
						_queryParameter["empid"] = _searchingParameter["key"];
						_queryParameter["text"] = _searchingParameter["value"];
					} else {
						if (!_.isNil(_searchingParameter.value)) {
							_queryParameter["text"] = _searchingParameter["value"];
						}
					}
				}

				this.processSearchingParams(_queryParameter);
				this.getData();
			},

			getfilterItem(_queryParameter) {
				//
				// callback from filter group
				//
				if (!_.isEmpty(this.queryFilterList)) {
					Object.keys(this.queryFilterList).forEach((key) => {
						delete this.querySearchAndFilterList[key];
					});
				}
				this.queryFilterList = _queryParameter;

				this.processSearchingParams(_queryParameter);
				this.getData();
			},
			//
			//  end method scope
			//
			reset() {
				if (this.queryStringSearch.length !== 0) {
					this.componentRefreshCounter += 1;
				}
				this.queryOptionsTable = {
					limit: 20, // itemsPerPage = limit
					by: "createddate", // key
					order: "desc", // asc or desc
					page: 1, // current page
				};
				this.checkboxData = {
					requestOwnedVehicle: 0,
				};
				this.sortVal = `${this.queryOptionsTable["by"]}-${this.queryOptionsTable["order"]}`;
				this.querySearchAndFilterList = {};
				this.processSearchingParams();
				this.getData();
			},
			checkUserPermissions() {
				if (this.$store.getters.userInfo !== null) {
					let getPermission = this.$store.getters.userInfo.permissions;
					this.permList = processPermission(getPermission, "USER");
					if (this.permList.user_list === true) {
						this.isPermissionCanView = true;
					}

					if (this.permList.user_info === true) {
						this.isPermissionCanView = true;
						this.isPermissionCanEdit = true;
						this.editTextBtn = "ดูข้อมูล";
					}

					if (
						this.permList.admin_all === true ||
						this.permList.user_info_crud === true
					) {
						this.isPermissionCanView = true;
						this.isPermissionCanEdit = true;
						this.editTextBtn = "แก้ไขข้อมูล";
					}
				}
			},

			changeValueOfCheckboxStatus() {
				let searchObj = {};
				Object.keys(this.checkboxData).forEach((key) => {
					if (this.checkboxData[key]) {
						searchObj[key] = "1";
					} else {
						delete this.querySearchAndFilterList[key];
					}
				});

				this.querySearchAndFilterList["page"] = 1;
				this.queryOptionsTable["page"] = 1;

				this.processSearchingParams(searchObj);
				this.setRouterQuery(this.querySearchAndFilterList);

				this.getData();
			},

			// async getRequestVehicleData(empID) {
			//   let responseData = await getEmployeeOwnedVehicleService(
			//     this.$store,
			//     empID
			//   );

			//   console.log("Response:", responseData);

			//   if (responseData.code == 200) {
			//     this.requestVehicleResp = responseData["data"];
			//   }
			// },

			async getData() {
				this.tableLoading = true;
				this.employeeDataList = [];

				this.queryStringSearch = new URLSearchParams(
					this.querySearchAndFilterList
				).toString();

				let responseData = await getEmployeeListService(
					this.$store,
					this.queryStringSearch
				);
				if (responseData.code == 200) {
					//
					this.serverItemsLength = responseData.data["total"];
					//
					let userList = responseData.data["userList"];
					userList.forEach((item, idx) => {
						//
						userList[idx][
							"name"
						] = `${item["firstName"]["th"]} ${item["lastName"]["th"]}`;
						// process company
						if (userList[idx]["company"]["position"] === null) {
							userList[idx]["company"]["position"] = "-";
						}

						userList[idx]["dpfullName"] = `${
							userList[idx].salutation === null
								? ""
								: userList[idx].salutation.th
						}${
							userList[idx].firstName === null ? "" : userList[idx].firstName.th
						} ${
							userList[idx].lastName === null ? "" : userList[idx].lastName.th
						} ${
							item.nickName !== null
								? item.nickName.th !== null
									? `(${item.nickName.th})`
									: "-"
								: "-"
						}`;

						// userList[idx][
						//   "name"
						// ] = `${item["firstName"]["en"]} ${item["lastName"]["en"]}`;
					});

					this.employeeDataList = userList;
				} else {
					this.employeeDataList = [];
				}
				this.tableLoading = false;
			},

			openPenalityTab(item) {
				const params = new URLSearchParams({
					empId: item.empId,
					text: appEncodeSingleURIComponentHelper(`พนักงาน ${item.name}`),
				}).toString();

				const goTo = `${window.location.origin}/employee${URL_EMPLOYEE_PENALTY}&${params}`;
				window.open(goTo, "_blank");
			},

			openEmployeeDashboard(item, _id) {
				this.$store.dispatch("employee/updateCurrentEmployeeData", item);
				// this.$router.push(`/employee/dashboard_personal/${_id}`);
				this.$router.push({
					name: "employeeDashboard",
					params: { id: _id, empId: item.empId },
				});
			},
		},
		computed: {
			...mapGetters({
				vehicleStatusColor: "employee/vehicleStatusColor",
				empStatusColor: "employee/empStatusColor",
				empStatusTextColor: "employee/empStatusTextColor",
				vehicleStatusText: "employee/vehicleStatusText",
				empStatusText: "employee/empStatusText",
				vehicleTypeText: "vehicle/vehicleTypeText",
				vehicleTypeIcon: "vehicle/vehicleTypeIcon",
			}),
			tableHeaders() {
				let defaultdata = [
					{
						text: "ชื่อ-นามสกุล ",
						align: "start",
						sortable: false,
						value: "name",
						width: "20%",
					},
					{
						text: "รหัสพนักงาน",
						value: "empId",
						sortable: false,
						align: "left",
					},
					{
						text: "เบอร์ติดต่อ",
						value: "phoneNumber",
						sortable: false,
						align: "left",
					},
					{ text: "ตำแหน่ง", value: "company", sortable: false, align: "left" },
					{ text: "สถานะ", value: "empStatus", sortable: false, align: "left" },
					{ text: "อายุ", value: "age", sortable: false, align: "center" },
					{
						text: "แอปเวอร์ชั่น",
						value: "appVersion",
						sortable: false,
						align: "center",
						width: "15%",
					},
					{
						text: "รถประจำตัว",
						value: "vehicleStatus",
						align: "center",
						sortable: false,
					},
				];
				//
				if (this.isPermissionCanEdit) {
					defaultdata.push({
						text: "",
						value: "actions",
						sortable: false,
						align: "center",
					});
				}
				//
				return defaultdata;
			},
		},
	};
</script>

<style scoped>
	.normal-text {
		color: #88949e;
	}
</style>
