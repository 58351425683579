<template>
  <v-container name="RouteTemplateTimeLabelTab">
    <v-row no-gutters class="pr-3">
      <v-col cols="12">
        <v-form ref="RouteTemplateTimeLabelForm">
          <v-row no-gutters class="mb-6">
            <v-col cols="12" lg="3" sm="3" class="py-0 px-3">
              <CustomTimePickerInput
                title="เวลายืนยันงาน"
                :value="formData.confirmDate"
                :is-required="true"
                :small="true"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :hide-detail="true"
                :is-dark="true"
                @input="handlerInputConfirmDateChange"
                class=""
              />
            </v-col>
            <v-col cols="12" lg="3" sm="3" class="py-0 px-3">
              <CustomTimePickerInput
                title="เวลาเริ่มงาน"
                :value="formData.startDate"
                :is-required="true"
                :small="true"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :hide-detail="true"
                :is-dark="true"
                @input="handlerInputStartDateChange"
                class=""
              />
            </v-col>

            <v-col cols="12" lg="3" sm="3" class="px-3 py-0">
              <CustomTimePickerInput
                title="เวลาจบงาน"
                :value="formData.endDate"
                :is-required="false"
                :small="true"
                :input-view-bottom-margin="false"
                :expend-margin="false"
                :hide-detail="true"
                :is-dark="true"
                @input="handlerInputEndDateChange"
                class=""
              />
            </v-col>
            <v-col cols="12" lg="3" sm="3" class="py-0 px-2">
              <p class="title-input-small mb-0 mt-1">เวลาจบงานโดยประมาณ</p>
              <p class="text-app-title mb-1 mt-1">
                {{
                  formData.estimateEndDate
                    ? timeStringFormat(formData.estimateEndDate, true)
                    : "-"
                }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" lg="10" sm="10">
              <CustomTextInputImprove
                ref="jobTitle"
                v-model="formData.name"
                title="ชื่อป้ายกำกับ"
                placeholder="ชื่อป้ายกำกับ"
                :is-required="true"
                :rules="[(v) => !!v || 'จำเป็นต้องระบุ']"
                :hide-detail="false"
                :small="true"
                :input-view-bottom-margin="false"
              ></CustomTextInputImprove>
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="12" lg="2" sm="2" class="align-self-center mb-1">
              <PrimaryButton
                small
                :height="39"
                :isFullWidth="true"
                icon="mdi-plus"
                @on-click="handleAddClick()"
              >
                เพิ่ม
              </PrimaryButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
      <!-- <v-col cols="2" class="align-self-center pt-2 pl-3">
        <PrimaryButton
          small
          :height="39"
          :isFullWidth="true"
          icon="mdi-plus"
          @on-click="handleAddClick()"
        >
          เพิ่ม
        </PrimaryButton>
      </v-col> -->
    </v-row>

    <div class="px-3 pb-3">
      <!-- <div v-if="tableData !== null && tableData.length == 0" class="pt-3">
        <v-divider></v-divider>
        <v-col align="center">{{ dataTableNoDataText }}</v-col>
      </div> -->
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :loading="tableLoading"
        :no-data-text="dataTableNoDataText"
        :loading-text="dataTableLoadingText"
        :hide-default-footer="true"
        class="app-datatable"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div v-if="item.name !== null">
            <span class="text-app-title"> {{ item.name }} </span>
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.confirmDate`]="{ item }">
          <div v-if="item.confirmDate !== null">
            <span> {{ timeStringFormat(item.confirmDate, false) }} </span>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          <div v-if="item.startDate !== null">
            <span> {{ timeStringFormat(item.startDate, false) }} </span>
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <div v-if="item.endDate !== null">
            <span> {{ timeStringFormat(item.endDate, false) }} </span>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.estimateEndDate`]="{ item }">
          <div v-if="item.estimateEndDate !== null">
            <span> {{ timeStringFormat(item.estimateEndDate, false) }} </span>
          </div>
          <div v-else>-</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }" v-if="isPermissionCanEdit">
          <v-btn
            plain
            class="text-decoration-underline error--text diplay--inline-block"
            @click="handleDeleteItem(item.code)"
          >
            ลบ
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <AppDialogConfirm ref="confirmDelete" />
  </v-container>
</template>

<script>
import CustomTimePickerInput from "@/components/CustomTimePickerInput.vue";
import { getEmployeeSelectList } from "@/services/api/employee";
import CustomTextInputCurrency from "@/components/CustomTextInputCurrency.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { processPermission } from "@/services/permissions";
import { dateStringToDateFormat, timeStringFormat } from "@/services/appDate";
import moment from "moment";
import {
  getTemplateTimeLabelList,
  postTimeLabelTemplate,
  deleteTimeLabelTemplate,
} from "@/services/api/contract_job_routes";

import AppDialogConfirm from "@/components/AppDialogConfirm.vue";
import { DialogType } from "@/services/dialog";
import {
  dataTableFooterProps,
  dataTableNoDataText,
  dataTableLoadingText,
} from "@/services/AppConig";
import _ from "lodash";

export default {
  name: "RouteTemplateTimeLabelTab",
  data: () => ({
    empList: null,
    templateId: null,
    dataTableFooterProps,
    dataTableNoDataText,
    dataTableLoadingText,
    tableLoading: false,
    tableData: [],
    minConfirmDate: null,
    minStartDate: null,
    // respTableData: [],
    formData: {
      name: null,
      confirmDate: null,
      startDate: null,
      endDate: null,
      estimateEndDate: null,
    },
    isPermissionCanEdit: false,
  }),
  computed: {
    tableHeaders() {
      let defaultdata = [
        {
          text: "ชื่อป้ายกำกับ",
          align: "start",
          sortable: false,
          value: "name",
          // width: "30%",
        },
        {
          text: "ยืนยันงาน",
          align: "start",
          sortable: false,
          value: "confirmDate",
          // width: "25%",
        },
        {
          text: "เริ่มงาน",
          align: "start",
          sortable: false,
          value: "startDate",
          // width: "20%",
        },
        {
          text: "จบงาน",
          align: "start",
          sortable: false,
          value: "endDate",
        },
        {
          text: "จบงานโดยประมาณ",
          align: "start",
          sortable: false,
          value: "estimateEndDate",
        },
      ];
      if (this.isPermissionCanEdit) {
        defaultdata.push({
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
          width: "5%",
        });
      }
      return defaultdata;
    },
  },
  methods: {
    timeStringFormat: timeStringFormat,
    dateStringToDateFormat: dateStringToDateFormat,
    async getData() {
      this.tableLoading = true;
      // this.templateId = "63fc13de5e4115bfaf98596b";
      if (!_.isNil(this.templateId)) {
        let responseData = await getTemplateTimeLabelList(
          this.$store,
          this.templateId
        );

        // console.log("responseData", responseData);
        if (responseData.code == 200) {
          this.tableData = responseData.data;
        } else {
          this.tableData = [];
        }
      }
      // load employee list
      let respEmpList = await getEmployeeSelectList(this.$store);
      if (respEmpList["code"] === 200) {
        this.empList = respEmpList["data"];
      } else {
        this.isFail = true;
      }

      this.tableLoading = false;
    },
    async handleAddClick() {
      // this.$store.dispatch("routeSetting/updateMainLoading", true);
      if (this.$refs["RouteTemplateTimeLabelForm"].validate() === true) {
        const requestBodyData = _.clone(this.formData);

        // console.log("requestBodyData", requestBodyData, this.templateId);
        const responseData = await postTimeLabelTemplate(
          this.$store,
          `id=${this.templateId}`,
          requestBodyData
        );

        if (responseData["code"] !== 200) {
          console.error("SAVE_FAIL");
        }
        this.resetFormData();
        this.getData();

        // this.$store.dispatch("routeSetting/updateMainLoading", false);
      } else {
        console.error("FORM_INVALIDATE");
      }
    },
    async handleDeleteItem(code) {
      if (
        await this.$refs.confirmDelete.open(
          "ต้องการลบข้อมูลนี้ ?",
          null,
          DialogType.DELETE,
          {
            noconfirm: true,
          }
        )
      ) {
        console.log("DELETING", this.templateId, code);
        let queryString = `id=${this.templateId}&code=${code}`;
        console.log("queryString", queryString);
        await deleteTimeLabelTemplate(this.$store, queryString);
        this.resetFormData();
        this.getData();
      }
    },
    checkUserPermissions() {
      if (this.$store.getters.userInfo !== null) {
        let getPermission = this.$store.getters.userInfo.permissions;
        this.permList = processPermission(getPermission, "USER");
        if (this.permList.user_list === true) {
          this.isPermissionCanView = true;
        }

        if (this.permList.user_info === true) {
          this.isPermissionCanView = true;
          // this.isPermissionCanEdit = true;
          this.editTextBtn = "ดูข้อมูล";
        }

        if (
          this.permList.admin_all === true ||
          this.permList.user_info_crud === true
        ) {
          this.isPermissionCanView = true;
          this.isPermissionCanEdit = true;
          this.editTextBtn = "แก้ไขข้อมูล";
        }
      }
    },

    handlerInputConfirmDateChange(newValue) {
      this.processDataToUpdate(newValue, "confirmDate");
    },
    handlerInputStartDateChange(newValue) {
      this.processDataToUpdate(newValue, "startDate");
    },
    handlerInputEndDateChange(newValue) {
      this.processDataToUpdate(newValue, "endDate");
    },
    processDataToUpdate(newValue, assignAtValue) {
      // calculate new estimate value
      try {
        const getHourAndMinute = function (m) {
          return m.minutes() + m.hours() * 60;
        };
        // this.formData[assignAtValue] = newValue;
        // let _date = { ...this.formData };

        if (assignAtValue === "confirmDate") {
          this.formData[assignAtValue] = newValue;
          if (!_.isNil(this.formData["startDate"])) {
            if (!_.isNil(this.formData["startDate"])) {
              const confirmDate = moment(this.formData["confirmDate"]);
              let startDate = moment(this.formData["startDate"]);
              if (getHourAndMinute(confirmDate) > getHourAndMinute(startDate)) {
                this.formData["startDate"] = null;
                this.formData["estimateEndDate"] = null;
              } else {
                // not do thing
              }
            }
          } else {
            // not do thing
          }
        } else if (assignAtValue === "startDate") {
          this.formData[assignAtValue] = newValue;
          if (_.isNil(this.formData[assignAtValue])) {
            this.formData["estimateEndDate"] = null;
          } else {
            // check startDate > endDate
            let startDate = moment(this.formData["startDate"]);
            if (!_.isNil(this.formData["endDate"])) {
              const _endDateMoment = moment(this.formData["endDate"]);
              if (startDate.valueOf() > _endDateMoment.valueOf()) {
                this.formData["endDate"] = null;
              }
            }

            // check estimate enddate
            startDate.add(
              this.$store.state.routeSetting.templateData.duration,
              "s"
            );
            // console.log(
            //   startDate,
            //   this.$store.state.jobs.jobData.totalDuration
            // );
            const newEstimateEnddate = startDate.format("YYYY-MM-DDTHH:mm:ss");
            if (newEstimateEnddate !== this.formData["estimateEndDate"]) {
              this.formData["estimateEndDate"] = newEstimateEnddate;
            } else {
              // not do thing
            }

            // check more than end
          }
        } else if (assignAtValue === "endDate") {
          // end date
          this.formData[assignAtValue] = newValue;
          if (!_.isNil(this.formData[assignAtValue])) {
            const startDate = moment(this.formData["startDate"]);
            const endDate = moment(this.formData["endDate"]);
            if (getHourAndMinute(startDate) > getHourAndMinute(endDate)) {
              this.formData["startDate"] = null;
              this.formData["estimateEndDate"] = null;
            } else {
              // not do thing
            }
          }
        }

        this.$store.dispatch("jobs/updateJobData", this.formData);
        this.$store.dispatch("jobs/updateRefreshFormDuplicateCounter");
      } catch (error) {
        console.log(error);
      }
    },

    resetFormData() {
      this.formData = {
        name: null,
        confirmDate: null,
        startDate: null,
        endDate: null,
        estimateEndDate: null,
      };
      this.$refs["RouteTemplateTimeLabelForm"].reset();
    },
  },
  mounted() {
    this.checkUserPermissions();
    this.resetFormData();
    if (!_.isNil(this.$route.params["id"])) {
      this.templateId = this.$route.params["id"];
      this.getData();
    }
    // this.getData();
  },
  components: {
    CustomTextInputCurrency,
    CustomDateTimePickerFormInput,
    CustomTimePickerInput,
    PrimaryButton,
    AppDialogConfirm,
    CustomTextInputImprove,
  },
};
</script>

<style lang="scss" scoped>
.title-input-small {
  font-size: 12px;
  padding-bottom: 5px;
  color: var(--v-bodyText);
  font-weight: bold;
}
</style>
